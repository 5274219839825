import React from 'react';
import { Radio, RadioGroup, FormControlLabel } from '@mui/material';

export default ({ options = [], ...props }) => (
  <RadioGroup {...props}>
    {options.map(option => (
      <FormControlLabel
        key={option.value}
        label={option.label}
        value={option.value}
        control={<Radio disabled={option.disabled} />}
      />
    ))}
  </RadioGroup>
);
