import React from 'react';
import { scaleLinear, scaleBand, select, selectAll, max, axisLeft, axisBottom } from 'd3';
import _ from 'lodash';

const magic = {
  width: 1550,
  height: 680,
  margins: { top: 20, right: 20, bottom: 320, left: 60 },
};

const CarrierRatesChart = ({ data, uom }) => {
  const svg = React.useRef();

  React.useEffect(() => {
    selectAll('#carrier-rates-chart > *').remove();

    const width = magic.width - magic.margins.left - magic.margins.right;
    const height = magic.height - magic.margins.top - magic.margins.bottom;

    const carriersByScac = _.groupBy(data.data, 'scac');
    const carrierMeanRpm = Object.entries(carriersByScac).map(([scac, spend]) => {
      const totalShipments = spend.map(s => s.loads).reduce((agg, curr) => agg + curr, 0);
      const wavgRpm = spend.reduce((agg, curr) => agg + curr[uom].rpm * curr.loads, 0) / totalShipments;
      return {
        scac,
        wavgRpm: wavgRpm.toFixed(2),
      };
    });

    const x = scaleBand().range([0, width]);
    const y = scaleLinear().range([height, 0]);
    const r = scaleLinear().range([4, 15]).domain([data.min_loads, data.max_loads]);

    const rpms = data.data.map(o => o[uom].rpm);

    y.domain([0, max(rpms)]);
    x.domain(Object.keys(carriersByScac));
    x.paddingInner(1);
    x.paddingOuter(0.5);

    const g = select(svg.current)
      .attr('width', magic.width)
      .attr('height', magic.height)
      .append('g')
      .attr('transform', `translate(${magic.margins.left}, ${magic.margins.top})`);

    g.selectAll('avgLines')
      .data(carrierMeanRpm)
      .enter()
      .append('line')
      .attr('x1', d => x(d.scac) - 40)
      .attr('x2', d => x(d.scac) + 40)
      .attr('y1', d => y(d.wavgRpm))
      .attr('y2', d => y(d.wavgRpm))
      .attr('stroke', 'green');

    g.selectAll('ratePoints')
      .data(data.data)
      .enter()
      .append('circle')
      .attr('cx', d => x(d.scac))
      .attr('cy', d => y(d[uom].rpm))
      .attr('r', d => r(d.loads))
      .attr('fill', 'rgba(147,208,174,0.5)')
      .attr('stroke', 'rgba(147,208,174,0.5)');

    g.append('g')
      .attr('transform', `translate(0,${height})`)
      .call(axisBottom(x))
      .selectAll('text')
      .attr('transform', 'rotate(-80)')
      .attr('dy', '-0.5em')
      .attr('dx', '-1em')
      .style('text-anchor', 'end');

    g.append('g')
      .call(axisLeft(y))
      .append('text')
      .attr('fill', '#000')
      .attr('transform', 'rotate(-90)')
      .attr('y', 6)
      .attr('dy', '0.71em')
      .attr('text-anchor', 'end')
      .text('Rate per mile ($)');
  }, [data, uom, svg]);

  return <svg id="carrier-rates-chart" ref={svg} />;
};

export default CarrierRatesChart;
