import React from 'react';
import styled from '@emotion/styled';
import { Box, Typography, Divider, Tooltip } from '@mui/material';
import { InfoOutlined } from '@mui/icons-material';
import { v4 } from 'uuid';
import DividerWithGutter from '../Divider';

const TooltipIcon = styled(Tooltip)`
  margin-right: auto;
  padding-left: 8px;
  align-self: start;
`;

const InfoIcon = styled(InfoOutlined)`
  color: #4a90e2;
  &&& {
    width: 24px;
    height: 24px;
  }
`;

const Wrapper = styled.div`
  padding: ${({ theme }) => `${theme.spacing(2)}`};
`;

const BoxContainer = styled(Box)`
  height: 100%;
`;

const RowContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${({ theme }) => `calc(${theme.spacing(1)} / 2) 0`};
`;

export default ({ title, tooltip, content = [] }) => (
  <BoxContainer boxShadow={title ? 2 : 0} bgcolor="white">
    {React.isValidElement(title)
      ? title
      : title && (
          <Wrapper>
            <Typography variant="h5">{title}</Typography>
            {tooltip && (
              <TooltipIcon title={tooltip}>
                <InfoIcon />
              </TooltipIcon>
            )}
          </Wrapper>
        )}
    {title && <Divider />}
    <Wrapper>
      {React.isValidElement(content)
        ? content
        : content.map(row => {
            const { label, value, variant, isDivider, hasDivider, tooltip: rowTooltip } = row;
            return isDivider ? (
              <DividerWithGutter key={v4()} />
            ) : (
              <div key={v4()}>
                <RowContainer>
                  <Typography variant={variant || 'body1'}>{label}</Typography>
                  {rowTooltip && (
                    <TooltipIcon title={rowTooltip}>
                      <InfoIcon />
                    </TooltipIcon>
                  )}
                  <Typography variant={variant || 'body1'}>{value}</Typography>
                </RowContainer>
                {hasDivider && <DividerWithGutter />}
              </div>
            );
          })}
    </Wrapper>
  </BoxContainer>
);
