import React from 'react';
import FullStory, { setUserVars } from 'react-fullstory';

const Component = () => {
  const env = process.env.REACT_APP_ENV;
  if (env !== 'PROD') {
    return null;
  }
  const org = process.env.REACT_APP_FULLSTORY_ORG;
  if (!org) {
    return null;
  }
  return <FullStory org={org} />;
};

export const initialize = (app, email) => {
  const env = process.env.REACT_APP_ENV;
  if (env !== 'PROD') {
    return;
  }
  if (!app || !email) {
    return;
  }
  console.debug('FullStory initialize', { env, app, email });
  setUserVars({
    app,
    email,
  });
};

export default Component;
