import React from 'react';
import { utility } from '@leaf/components';

import mergeArraysIntoArrayOfObjects from './mergeArraysIntoArrayOfObjects';
import { AutoHeightScrollbar } from '../../Common/Components';
import { numbers as numbersService } from '../../../utility';
import { platformSettings } from '../../Common/Config';
import { sharedChartPropTypes } from './types';
import { calcColorForValue } from './chartsHelper';

import './DashboardProgressBars.scss';
import './shared.scss';

const barHeight = 3;
const barWrapperStyle = { display: 'flex', height: barHeight, backgroundColor: '#cacaca', marginTop: -6 };
const barInnerWrapperStyle = { width: '100%', height: barHeight };

const {
  formattingHelper: { formattingFns },
} = utility;

const DashboardProgressBars = ({ data, config }) => {
  const labelValueArr = mergeArraysIntoArrayOfObjects(
    data.dimensions[0].values,
    data.metrics[0].values,
    'label',
    'value',
  );
  const valuesAsFloatNumbers = data.metrics[0].values.map(v => numbersService.numberAsStringWithCommaToFloat(v));
  const valuesAsPercent = numbersService.toArrayOfFloatPercentsFromNumbers(valuesAsFloatNumbers);

  const newDataExtendedWithPercents = labelValueArr.map((el, index) => ({
    label: el.label,
    value: el.value === null || el.value === 'null' ? null : numbersService.removeDecimals(el.value),
    valueAsPercent: valuesAsPercent[index],
  }));
  const { colors } = config.metrics[0];

  return (
    <div className="padding-bottom-md flex-column flex-grow">
      <AutoHeightScrollbar>
        <div className="dashboard-progress-bars__wrapper padding-horizontal-md">
          {newDataExtendedWithPercents.map((td, index) => {
            let percent;
            if (td.value === null) {
              percent = 0;
            } else {
              percent = config.isPercentBased ? td.value : td.valueAsPercent;
            }
            const color = calcColorForValue(percent, colors);
            let displayPercent;
            if (td.value === null) {
              displayPercent = platformSettings.notAvailableDataString;
            } else {
              displayPercent = config.isPercentBased
                ? `${td.value}%`
                : `${formattingFns[config.metrics[0].numberFormat](td.value, 1)}`;
            }
            return (
              <div key={index} className="dashboard-progress-bar">
                <header className="dashboard-progress-bar__header">
                  <span className="dashboard-progress-bar__header-item">{td.label}</span>
                  <span className="dashboard-progress-bar__header-item">{displayPercent}</span>
                </header>
                <div
                  style={barWrapperStyle}
                  className={index < newDataExtendedWithPercents.length - 1 ? 'margin-bottom-md' : ''}
                >
                  <div style={barInnerWrapperStyle}>
                    <div style={{ height: barHeight, backgroundColor: color, width: `${percent}%` }} />
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </AutoHeightScrollbar>
    </div>
  );
};

DashboardProgressBars.propTypes = sharedChartPropTypes;

export default React.memo(DashboardProgressBars);
