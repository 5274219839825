export const DELIVERY = {
  DROP: 'DROP',
  LIVE: 'LIVE',
  ANY: 'ANY',
  UNKNOWN: 'UNKNOWN',
};

export const DELIVERY_DISPLAY_NAMES = {
  [DELIVERY.DROP]: 'Drop',
  [DELIVERY.LIVE]: 'Live',
  ANY: 'Any',
  UNKNOWN: 'Unknown',
};
