export const USER_TYPE = {
  SHIPPER: 'SHIPPER',
  CARRIER: 'CARRIER',
  BROKER: 'BROKER',
  LE_ADMIN: 'LE_ADMIN',
};

export const USER_TYPE_DISPLAY_NAMES = {
  SHIPPER: 'Shipper',
  CARRIER: 'Carrier',
  BROKER: 'Broker',
  LE_ADMIN: 'LE Admin',
};
