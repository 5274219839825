/* eslint-disable import/named */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useContext, useEffect } from 'react';
import { BrowserRouter, useHistory } from 'react-router-dom';

import { Snackbar, FullStory, LazySpinner, USER_TYPE } from '@leaf/components';

import Theme from 'theme';
import Routes from 'navigation';
import StateProvider, { StateContext } from 'state/StateProvider';
import UserProvider, { UserContext } from 'authentication/UserProvider';
import { useAuth0 } from '@auth0/auth0-react';
import {
  getCurrentUserMetadata,
  getImpersonatedCompanyId,
  removeImpersonatedCompanyId,
  setImpersonatedCompanyId,
} from 'authentication';
import { CHANGE_STATE, SET_COMPANIES } from 'state/stateReducer';
import PrivateRoute from 'navigation/PrivateRoute';
import axios from 'axios';
import { useSnackbar } from 'notistack';
import { isRoutePublic } from 'navigation/publicRouteUtils';

const getCurrentUserData = (token, id) =>
  fetch(process.env.REACT_APP_HASURA_GQL_ENDPOINT, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`,
    },
    body: JSON.stringify({
      query: `
        query getCurrentUser {
          currentUser: user(where: { id: { _eq: "${id}"} }) {
            lastName: last_name
            firstName: first_name
          }
        }
      `,
    }),
  }).then(res => res.json());

const setCurrentUserData = ({
  getAccessTokenSilently,
  user,
  setCurrentUser,
  changeUserContext,
  changeStateContext,
  enqueueSnackbar,
}) => {
  const setAdapt = companyId =>
    axios
      .get(`/analytics/adapt/companies/${companyId}/status`)
      .then(() => changeStateContext({ type: CHANGE_STATE, target: 'adapt', payload: true }))
      .catch(() => changeStateContext({ type: CHANGE_STATE, target: 'adapt', payload: false }));

  getAccessTokenSilently?.()
    .then(token => {
      const metadata = getCurrentUserMetadata(token);
      return getCurrentUserData(token, metadata.uid).then(currentUserResponse => ({
        currentUserResponse,
        metadata,
        token,
      }));
    })
    .then(({ currentUserResponse: { data }, metadata, token }) => {
      const { company_id: companyId, company_type: companyType, role } = metadata;
      const { firstName, lastName } = data.currentUser[0];
      if (companyId && companyId !== '-1') {
        // means this is a client user
        removeImpersonatedCompanyId();
        setCurrentUser({ ...user, firstName, lastName, role, company: { id: companyId, type: companyType } });
        setAdapt(companyId);
      } else {
        // means this is a Leaf admin user
        let impersonatedCompanyId = getImpersonatedCompanyId();
        if (!impersonatedCompanyId) {
          const env = process.env.REACT_APP_ENV;
          let defaultCompanyToImpersonate = '3001';
          if (['PROD', 'PREVIEW'].includes(env)) {
            defaultCompanyToImpersonate = '60';
          }
          impersonatedCompanyId = defaultCompanyToImpersonate;
          setImpersonatedCompanyId(defaultCompanyToImpersonate);
        }
        fetch(process.env.REACT_APP_HASURA_GQL_ENDPOINT, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
          },
          body: JSON.stringify({
            query: `
            query getImpersonationData {
              companiesToImpersonate: company {
                id
                label: name
              }
              impersonatedCompany: company(where: { id: { _eq: "${impersonatedCompanyId}"} }) {
                type: company_type
              }
            }
          `,
          }),
        })
          .then(c => c.json())
          .then(res => {
            changeUserContext({ type: SET_COMPANIES, payload: res.data?.companiesToImpersonate ?? [] });
            if (res.data?.impersonatedCompany?.[0]) {
              axios.defaults.headers.common['Company-Type'] = res.data.impersonatedCompany[0].type;
              setCurrentUser({
                ...user,
                firstName,
                lastName,
                role,
                company: {
                  id: impersonatedCompanyId,
                  type: res.data.impersonatedCompany[0].type,
                },
              });
              setAdapt(impersonatedCompanyId);
            }
          });
      }
    })
    .catch(enqueueSnackbar);
};

const CurrentUser = ({ children }) => {
  const { user, getAccessTokenSilently } = useAuth0();
  const { dispatch: changeUserContext } = useContext(StateContext);
  const { state, dispatch: changeStateContext } = useContext(StateContext);
  const { currentUser, setCurrentUser } = useContext(UserContext);
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  const shouldRenderChildren = () => {
    if (history.location?.pathname.includes('/impersonate/')) {
      return true;
    }
    if (!currentUser) {
      return false;
    }
    const { role } = currentUser;
    if (role === USER_TYPE.LE_ADMIN) {
      return axios.defaults.headers.common['Company-Type'] !== undefined;
    }
    return !!currentUser?.company?.type;
  };

  useEffect(() => {
    if (user) {
      setCurrentUserData({
        getAccessTokenSilently,
        user,
        setCurrentUser,
        changeUserContext,
        changeStateContext,
        enqueueSnackbar,
      });
    }
  }, [user]);

  if (isRoutePublic()) {
    return children;
  }

  const render = shouldRenderChildren() && state.adapt !== null;
  return render ? children : <LazySpinner />;
};

export default () => {
  const { isAuthenticated } = useAuth0();

  if (!isAuthenticated && !isRoutePublic()) {
    return <PrivateRoute />;
  }

  return (
    <React.StrictMode>
      <FullStory.Component />

      <Theme>
        <UserProvider>
          <StateProvider>
            <Snackbar>
              <BrowserRouter>
                <CurrentUser>
                  <Routes />
                </CurrentUser>
              </BrowserRouter>
            </Snackbar>
          </StateProvider>
        </UserProvider>
      </Theme>
    </React.StrictMode>
  );
};
