import React from 'react';
import {
  Table as TableMUI,
  TableRow,
  TableCell as TableCellMUI,
  TableHead,
  TableBody,
  Typography,
} from '@mui/material';
import styled from '@emotion/styled';
import { v4 } from 'uuid';
import { LANE_CARD_HEIGHT_WITH_SPACING } from '../RouteSchedule/RouteSchedule.styled';

const SINGLE_LOCATION_HEIGHT = LANE_CARD_HEIGHT_WITH_SPACING / 2;

const TableCell = styled(TableCellMUI)`
  border-bottom: 1px solid #e5e5e5;
  border-right: 1px solid #e5e5e5;
  border-left: 1px solid #e5e5e5;
`;

const Table = styled(TableMUI)`
  &&& {
    position: absolute;
    top: -61px;
    width: calc(100% - 432px);
    z-index: 1;

    .MuiTableHead-root {
      tr {
        height: 59px;
        position: sticky;
        top: -1px;
        height: 60px;
        z-index: 2;
        background-color: white !important;
        th {
          padding: 0;
        }
        :hover {
          cursor: default;
          background-color: white !important;
        }
      }
    }
    .MuiTableBody-root {
      height: 116px;
      tr:nth-child(even) {
        background-color: white;
      }
    }

    .MuiTableBody-root {
      tr {
        height: ${({ $isFullLaneRowSpan }) =>
          $isFullLaneRowSpan ? `${2 * SINGLE_LOCATION_HEIGHT}` : `${SINGLE_LOCATION_HEIGHT}`}px;
        max-height: ${({ $isFullLaneRowSpan }) =>
          $isFullLaneRowSpan ? `${2 * SINGLE_LOCATION_HEIGHT}` : `${SINGLE_LOCATION_HEIGHT}`}px;
      }
      td {
        padding: 0;
      }
    }
  }
`;

const Wrapper = styled.div`
  height: auto;
  width: 100%;
  background-color: #fafafa;
`;

/* This component is designed to work alongside RouteSchedule component, but it can also work standalone */
export default ({ header = [], body = [], isFullLaneRowSpan = false }) => (
  <Wrapper>
    <Table $isFullLaneRowSpan={isFullLaneRowSpan}>
      <TableHead>
        <TableRow>
          {header.map(headerValue => (
            <TableCell key={v4()} align="center">
              {React.isValidElement(headerValue) ? (
                headerValue
              ) : (
                <Typography variant="caption">{headerValue}</Typography>
              )}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {body.map(rowValues => (
          <TableRow key={v4()}>
            {rowValues.map(rowValue => (
              <TableCell key={v4()} align="center">
                {React.isValidElement(rowValue) ? rowValue : <Typography variant="caption">{rowValue}</Typography>}
              </TableCell>
            ))}
          </TableRow>
        ))}
      </TableBody>
    </Table>
  </Wrapper>
);
