export const PHASE_STATUS = {
  SCOPED: 'SCOPED',
  UNASSIGNED: 'UNASSIGNED',
  OUT_OF_SCOPE: 'OUT_OF_SCOPE',
};

export const PHASE_STATUS_DISPLAY_NAMES = {
  SCOPED: 'Scoped',
  UNASSIGNED: 'Unassigned',
  OUT_OF_SCOPE: 'Out of scope',
};
