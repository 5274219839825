import React, { Component } from 'react';

export default WrappedComponent => {
  return class SafeSetStateHOC extends Component {
    componentWillUnmount() {
      this.originalComponent.setState = () => {};
    }
    saveRef = node => {
      this.originalComponent = node;
    };
    render() {
      return <WrappedComponent ref={this.saveRef} {...this.props} />;
    }
  };
};
