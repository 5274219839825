import React from 'react';
import { Delete, Edit } from '@mui/icons-material';
import RouteCardBody from './RouteCardBody';
import {
  RouteCard,
  RouteCardContent,
  DownIcon,
  IconButtonWrapper,
  IconButtonAction,
  IconButtonContainer,
  RouteScheduleWrapper,
} from './RouteCard.styled';
import RouteSchedule from '../RouteSchedule';
import { getRouteOverviewColor } from '../constants';

const mapDataToRouteSchedule = legs => {
  const retVal = [];
  legs.forEach((leg, index) => {
    retVal.push({
      data: {
        origin: leg.origin,
        pickupType: leg.pickupType,
        destination: leg.destination,
        deliveryType: leg.deliveryType,
        miles: leg.miles,
        laneId: leg.leniId,
        type: 'PTP',
      },
      meta: {
        position: index * 2 + 1,
        color: getRouteOverviewColor(index),
      },
    });
    retVal.push({
      data: {
        deadheadMiles: leg.deadheadMiles,
      },
    });
  });

  return retVal;
};

export default ({
  route,
  isEditable = false,
  handleExpand = () => {},
  routeIndex,
  handleHoverOn = () => {},
  handleHoverOff = () => {},
  copyRoute,
  canExpand = true,
}) => {
  return (
    <>
      <RouteCard
        $expand={route.expand}
        index={routeIndex}
        onClick={canExpand ? event => handleExpand(event, route.id) : undefined}
        onMouseEnter={event => handleHoverOn(event, route.id)}
        onMouseLeave={event => handleHoverOff(event, route.id)}
      >
        <RouteCardContent>
          <RouteCardBody route={route} copyRoute={copyRoute} />
        </RouteCardContent>
        <IconButtonContainer>
          {isEditable && (
            <>
              <IconButtonAction onClick={() => {}}>
                <Delete fontSize="small" />
              </IconButtonAction>
              <IconButtonAction onClick={() => {}}>
                <Edit fontSize="small" />
              </IconButtonAction>
            </>
          )}
          {canExpand && (
            <IconButtonWrapper onClick={event => handleExpand(event, route.id)} $expand={route.expand}>
              <DownIcon fontSize="small" />
            </IconButtonWrapper>
          )}
        </IconButtonContainer>
      </RouteCard>
      {route.expand ? (
        <RouteScheduleWrapper>
          <RouteSchedule
            route={mapDataToRouteSchedule(route.legs)}
            shouldFitContainerHeight={false}
            showHorizontalLine={false}
          />
        </RouteScheduleWrapper>
      ) : null}
    </>
  );
};
