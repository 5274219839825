import React, { useMemo, useReducer } from 'react';
import initialState from './initialState';
import stateReducer from './stateReducer';

export const StateContext = React.createContext();

export default ({ children }) => {
  const [state, dispatch] = useReducer(stateReducer, initialState);
  const contextValue = useMemo(() => ({ state, dispatch }), [state, dispatch]);
  return <StateContext.Provider value={contextValue}>{children}</StateContext.Provider>;
};
