import React from 'react';
import styled from '@emotion/styled';
import { Typography } from '@mui/material';

const Item = styled.div`
  display: flex;
  align-items: center;
`;

const Block = styled.div`
  display: inline-block;
  width: 15px;
  height: 15px;
  background: ${({ color }) => color};
  margin-right: 5px;
`;

const Text = styled.span`
  color: ${({ color }) => color};
`;

const Legend = ({ color, children }) => (
  <Item>
    <Block color={color} />
    <Text color={color}>{children}</Text>
  </Item>
);

const ShipperNetworkMapLegend = () => (
  <div>
    <Legend color="#4B8FE2">
      <Typography variant="body1">Your network</Typography>
    </Legend>

    <Legend color="#4CAF50">
      <Typography variant="body1">Leaf Grid</Typography>
    </Legend>
  </div>
);

export default ShipperNetworkMapLegend;
