import { gql } from 'graphql-request';

export const GQL_ACCESSORIALS = gql`
  query getAccessorials {
    accessorial {
      name
      id
    }
  }
`;
