import _ from 'lodash';

const mergeArraysIntoArrayOfObjects = (array1, array2, key1Name, key2Name) => {
  const zippedArrays = _.zip(array1, array2);
  const arrayPairToObject = pair => _.zipObject([key1Name, key2Name], pair);

  return zippedArrays.map(arrayPairToObject);
};

export default mergeArraysIntoArrayOfObjects;
