import React from 'react';
import styled from '@emotion/styled';
import { Card, CardContent, CardHeader, Grid } from '@mui/material';
import PropTypes from 'prop-types';
import { ON_TIME_STATUS, Form } from '@leaf/components';

const CardHeaderWrapper = styled(CardHeader)`
  text-transform: capitalize;
`;

const GridItem = styled(Grid)`
  .MuiTextField-root {
    width: 100%;
  }
`;

const renderOnTime = status => {
  switch (status) {
    case ON_TIME_STATUS.ON_TIME:
      return 'Yes';
    case ON_TIME_STATUS.LATE:
      return 'Late';
    default:
      return 'N/A';
  }
};

const renderInOutTime = time => (time ? `${time?.[0]} - ${time?.[1]}` : '-');

const wayPointColumn = {
  pickup: { label: 'Origin details', value: 'originAddress' },
  delivery: { label: 'Destination details', value: 'destinationAddress' },
};

const ShipmentPickupDeliveryView = ({ pickupDelivery, type }) => (
  <Card>
    <CardHeaderWrapper title={type} />
    <CardContent>
      <Grid container spacing={4}>
        <GridItem item xs={12}>
          <Form.Generic.Input
            name={wayPointColumn[type].value}
            label={wayPointColumn[type].label}
            value={pickupDelivery[wayPointColumn[type].value]}
            readOnly
          />
        </GridItem>
        <GridItem item xs={12}>
          <Form.Generic.Input
            name={`${type}AppointmentTime`}
            label="Appointment time"
            value={`${pickupDelivery.appointmentTime}`}
            readOnly
          />
        </GridItem>
        <GridItem item xs={12}>
          <Form.Generic.Input
            name={`${type}InOutTime`}
            label="In & out time"
            value={renderInOutTime(pickupDelivery.inOutTime)}
            readOnly
          />
        </GridItem>
        <GridItem item xs={12}>
          <Form.Generic.Input name={`${type}Delay`} label="Delay time" value={`${pickupDelivery.delay}`} readOnly />
        </GridItem>
        <GridItem item xs={12}>
          <Form.Generic.Input
            name={`${type}InOutTime`}
            label="On time"
            value={renderOnTime(pickupDelivery.status)}
            readOnly
          />
        </GridItem>
      </Grid>
    </CardContent>
  </Card>
);

ShipmentPickupDeliveryView.propTypes = {
  type: PropTypes.string.isRequired,
};

export default ShipmentPickupDeliveryView;
