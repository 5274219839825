import styled from '@emotion/styled';
import { KeyboardArrowDown } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import { getRouteOverviewColor } from '../constants';

export const RouteCard = styled('div', {
  shouldForwardProp: prop => prop !== '$expand',
})`
  margin-top: ${({ theme }) => `${theme.spacing(2)}`};
  min-height: 75px;
  width: 100%;
  padding: ${({ theme }) => `${theme.spacing(1)} ${theme.spacing(2)}`};
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.12);
  display: flex;
  align-items: center;
  justify-content: space-between;

  background-color: #fff;
  border-radius: 5px;
  border-top: 1px solid transparent;
  border-right: 1px solid transparent;
  border-bottom: 1px solid transparent;
  ${({ $expand, index }) =>
    $expand ? `border-left: 4px solid transparent` : `border-left: 4px solid ${getRouteOverviewColor(index)}`};

  &: hover {
    ${({ $expand, index }) => !$expand && `border-top: 1px solid ${getRouteOverviewColor(index)}`};
    ${({ $expand, index }) => !$expand && `border-right: 1px solid ${getRouteOverviewColor(index)}`};
    ${({ $expand, index }) => !$expand && `border-bottom: 1px solid ${getRouteOverviewColor(index)}`};
    ${({ $expand, index }) =>
      $expand ? `border-left: 4px solid transparent` : `border-left: 4px solid ${getRouteOverviewColor(index)}`};
  }
`;

export const RouteCardContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => `${theme.spacing(1)}`};
`;

export const DownIcon = styled(KeyboardArrowDown)`
  color: #0064b0;
`;

export const IconButtonWrapper = styled('div', {
  shouldForwardProp: prop => prop !== '$expand',
})`
  transform: ${({ $expand }) => $expand && 'rotate(180deg)'};
  width: 12px;
  height: 12px;

  &.MuiIconButton-root:hover {
    background-color: transparent;
  }
`;

export const IconButtonAction = styled(IconButton)`
  margin: ${({ theme }) => `${theme.spacing(1)} 0px`};
  width: 12px;
  height: 12px;
  &.MuiIconButton-root:hover {
    background-color: transparent;
  }
`;

export const IconButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const RouteScheduleWrapper = styled.div`
  min-height: 75px;
  width: 100%;
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.12);
  display: flex;
  align-items: center;
  flex-direction: column;
`;
