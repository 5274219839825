import React from 'react';
import { Tooltip } from '@mui/material';
import styled from '@emotion/styled';

const ItemContainer = styled.div`
  white-space: normal;
`;

const DataFromListWithToolTip = React.memo(({ data = [] }) => {
  if (!Array.isArray(data)) {
    return null;
  }
  const presentationItems = data.splice(0, 3) ?? [];
  const tooltipItems = data.splice(3, data.length) ?? [];

  return (
    <Tooltip title={tooltipItems.map(item => item.name).join(', ')}>
      <ItemContainer>{presentationItems.map(item => item.name).join(', ')}</ItemContainer>
    </Tooltip>
  );
});

export default DataFromListWithToolTip;
