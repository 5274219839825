import React from 'react';
import { Button, Tooltip, IconButton } from '@mui/material';
import { Link as LinkIcon } from '@mui/icons-material';
import useCopyToClipboard from '../hooks/useCopyToClipboard';
import { generateShareLink } from '../utility/functions';

const CopyToClipboardButton = React.memo(({ shouldGenerateShareLink, id, onMessageHandler, iconButton }) => {
  const copy = useCopyToClipboard(
    successMessage => onMessageHandler(successMessage, { variant: 'success' }),
    errorMessage => onMessageHandler(errorMessage, { variant: 'error' }),
  );

  let text = window.location.href;
  if (shouldGenerateShareLink) {
    const link = generateShareLink('opportunity_details');
    text = `${window.location.host}/${link(id)}`;
  }
  return (
    <Tooltip title="Copy to clipboard">
      {iconButton ? (
        <IconButton
          onClick={event => {
            event.stopPropagation();
            copy(text);
          }}
        >
          <LinkIcon />
        </IconButton>
      ) : (
        <Button variant="outlined" color="secondary" onClick={() => copy(text)}>
          <LinkIcon />
        </Button>
      )}
    </Tooltip>
  );
});

export default CopyToClipboardButton;
