import React from 'react';
import { Typography } from '@mui/material';

export const accessorials = (
  <div>
    <Typography variant="body1">
      In addition to the Basic Freight Charge and other charges set forth above, Carrier will invoice Shipper for the
      accessorial charges noted below.
    </Typography>
    <Typography variant="h4">Detention of Trailer With Tractor</Typography>
    <Typography variant="body1">
      Upon Carrier&#39;s offering of a trailer with tractor for loading or unloading, Shipper or Shipper&#39;s
      consignee, as the case may be, shall be allowed, without charge, 2 hours to complete such loading or unloading and
      release the equipment for dispatch. If Shipper or Shipper&#39;s consignee fails to complete the loading or
      unloading and release the equipment for dispatch within the 2-hour period, Shipper shall pay Carrier a detention
      charge of $50 per hour for each hour or fraction thereof in excess of the 2-hour period. Shipper shall use the
      trailer with tractor for the sole purpose of loading and/or unloading the lading within the scope of this
      Agreement.
    </Typography>
    <Typography variant="h4">Loading and Unloading</Typography>
    <Typography variant="body1">
      If the services Carrier agrees to perform under this Agreement include loading and unloading, Carrier shall be
      paid $100 per stop for loading/unloading activities.
    </Typography>
    <Typography variant="h4">Reconsignment</Typography>
    <Typography variant="body1">
      If Shipper reconsigns or otherwise changes the origin and/or destination of a shipment prior to delivery, the
      applicable rate shall be the rate that would be applied had Carrier been originally directed to pickup/deliver the
      shipment to the new origin/destination, provided that the new origin and destination are within 2 hours driving
      time from the contracted origin and destination. If the new origin/destination is more than 2 hours driving time
      from the contract origin/destination, an additional reconsignment charge equal to $0.20 per additional mile beyond
      the 2 hours driving time area will be added to the applicable base rate by virtue of the reconsignment.
    </Typography>
    <Typography variant="h4">In-Transit Stop-Off / Drop Charges</Typography>
    <Typography variant="body1">
      A single shipment may be stopped at the direction of Shipper for partial loading or partial unloading; provided,
      however, that in the event of any in-transit stop at the direction of Shipper or Shipper&#39;s consignee, Shipper
      shall pay Carrier, in addition to other freight charges due (a) $100 per additional stop, and (b) $0.20 per mile
      required to be deviated from the most practical route otherwise to be traversed from origin to destination in
      addition to the applicable base rate.
    </Typography>
    <Typography variant="h4">Hazardous Materials</Typography>
    <Typography variant="body1">
      Shipper shall identify any loads that contain Hazardous Materials, as defined in the Hazardous Materials
      Transportation Act, 49 U.S.C. §5101 et seq., as amended, and the regulations of the U.S. Department of
      Transportation made thereunder, at least 24 hours in advance of tendering to Carrier. Not less than 12 hours prior
      to the scheduled pick-up time, Carrier shall either: (i) decline such load, or (ii) accept such load on terms and
      conditions identified by Carrier in such acceptance, which terms may include market rates and the pass through of
      any associated costs to Shipper. If Carrier accepts such load, Carrier represents and warrants that it is fully
      qualified and authorized to transport Hazardous Materials in the United States. Carrier and Shipper certify that
      they are familiar with U.S. laws and regulations applicable to transportation of Hazardous Materials and that they
      will comply with all such laws and regulations. Carrier further certifies that its employees, including drivers,
      have been trained and instructed in the proper method of transporting Hazardous Materials. Upon Carrier request,
      Shipper will provide a copy of the Material Safety Data Sheet for the Hazardous Materials.
    </Typography>
    <Typography variant="body1">
      An additional $250.00 will apply to any hazardous shipment where driver certification and placards must be
      displayed.
    </Typography>
    <Typography variant="h4">Tanker Endorsement</Typography>
    <Typography variant="body1">
      Under the new tanker rules published in 2011 by the Federal Motor Carrier Safety Administration (FMCSA), a tank
      is: 1) &quot;Any commercial vehicle that is designed to transport any liquid or gaseous material within a tank or
      tanks having an individual rate capacity of more than 119 gallons,&quot; and 2) &quot;An aggregated capacity of
      1,000 gallons or more that is either permanently or temporarily attached to the vehicle or chassis.&quot; Tanks
      that are manifested either as being &quot;Empty&quot; or as &quot;Residue Last Contained&quot; on a bill of lading
      do not apply under the rule. Upon Shipper request, Carrier will provide tanker certification.
    </Typography>
    <Typography variant="body1">
      An additional $50 will apply to any shipment where a tanker certification is required.
    </Typography>
  </div>
);
