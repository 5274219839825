/* eslint-disable no-restricted-globals */
import { gql } from 'graphql-request';

const WHERE = {
  STATUS_VAR: gql`$status: [String!]`,
  STATUS_CLAUSE: gql`shipment_execution_status: { _in: $status }`,
  CARRIER_VAR: gql`$carrier: [bigint!]`,
  CARRIER_CLAUSE: gql`carrier_id: { _in: $carrier }`,
};

const whereClause = ({ where, contractId, search }) => {
  let whereGQL = gql``;
  const whereVarsKeys = Object.keys(where).filter(k => Array.isArray(where[k]) && where[k].length);

  if (contractId || whereVarsKeys.length || (search !== undefined && search !== '')) {
    whereGQL = gql`
    where: {
        _and: [
          { buy_contract_id: { _eq: "${contractId}" } }

          ${whereVarsKeys.map(key =>
            where[key]
              ? gql`{
                  ${WHERE[`${key.toUpperCase()}_CLAUSE`]}
                }`
              : '',
          )}

          ${
            search !== undefined && search !== ''
              ? gql`{
              _or: [
                ${!isNaN(search) ? gql`{ id: { _eq: "${search}" } }` : ''}
                {origin_address_name: { _ilike: "%${search}%" }}
                {origin_business_name: { _ilike: "%${search}%" }}
                {origin_city: { _ilike: "%${search}%" }}
                {origin_country: { _ilike: "%${search}%" }}
                {origin_state: { _ilike: "%${search}%" }}
                {origin_zip: { _ilike: "%${search}%" }}
                {destination_address_name: { _ilike: "%${search}%" }}
                {destination_business_name: { _ilike: "%${search}%" }}
                {destination_city: { _ilike: "%${search}%" }}
                {destination_country: { _ilike: "%${search}%" }}
                {destination_state: { _ilike: "%${search}%" }}
                {destination_zip: { _ilike: "%${search}%" }}
                {equipment_type: { name: { _ilike: "%${search}%" }}}
                {execution_partner_company: { name: { _ilike: "%${search}%" }}}
              ]
            }`
              : ''
          }
        ]
      }
    `;
  }
  return whereGQL;
};

const whereVar = ({ where }) => gql`
  ${where.status ? WHERE.STATUS_VAR : ``}
  ${where.carrier ? WHERE.CARRIER_VAR : ``}
`;

export const getContractShipmenstGQL = variables => {
  const whereGQL = whereClause(variables);
  return gql`
    query getContractShipments($limit: Int, $orderBy: [shipment_tender_order_by], $offset: Int, ${whereVar(
      variables,
    )}) {
      shipmentTender: shipment_tender(limit: $limit, order_by: $orderBy, offset: $offset, ${whereGQL}) {
        id
        destination {
          businessName: business_name
          city
          state
          street
          zip
        }
        origin {
          businessName: business_name
          city
          state
          street
          zip
        }
        shipmentExecutionStatus: shipment_execution_status
        equipmentType: equipment_type {
          name
        }
        executionPartnerCompany: execution_partner_company {
          name
        }
        carrierTripTender: carrier_trip_tender {
          tripId: trip_id
          company {
            id
            name
          }
        }
        poNumber: po_number
        legNumber: leg_number
        originAppointmentTime: origin_appointment_time
        originInitialAppointmentTime: origin_initial_appointment_time
        expectedShipmentDate: expected_shipment_date
      }
      shipmentTenderAggregate: shipment_tender_aggregate${whereGQL ? gql`( ${whereGQL} )` : ''} {
        aggregate {
          count
        }
      }
    }
  `;
};
