import React from 'react';
import classNames from 'classnames';
import './ClosablePill.scss';
import { Close } from '@mui/icons-material';
import styled from '@emotion/styled';

const CloseIcon = styled(Close)`
  height: ${({ theme }) => theme.spacing(2)};
  cursor: pointer;
`;

const ClosablePill = React.memo(({ text, onClose, className }) => (
  <div className={classNames('closable-pill', className)}>
    {text}
    <CloseIcon className="closable-pill__icon" name="close" onClick={onClose} />
  </div>
));

export default ClosablePill;
