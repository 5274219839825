import { toSnakeCase } from './format';

export const toServerSideParams = ({ filters, quickFilters, query, sort, ...rest }) => {
  const ps = { ...rest };

  if (query && query.trim()) {
    ps.query = query.trim();
  }

  if (sort && Object.keys(sort)) {
    ps.sort = toSnakeCase(sort.field || sort.name);

    if (sort.order) {
      ps.order = sort.order;
    }
  }

  const allFilters = { ...filters, ...quickFilters };
  const hasFilters = Boolean(allFilters && Object.keys(allFilters).length > 0);
  if (hasFilters) {
    ps.filters = Object.entries(allFilters).map(([fieldName, fieldValues]) => {
      const queryName = toSnakeCase(fieldName);
      return fieldValues.map(value => {
        if (Array.isArray(value)) {
          return value.map(v => `${queryName}=${v}`);
        }
        return `${queryName}=${value}`;
      });
    });
  }

  return new URLSearchParams(ps);
};
