import React from 'react';
import { pie, arc, scaleOrdinal, select, selectAll, format } from 'd3';
import _ from 'lodash';

const LaneVolume = ({ data }) => {
  const svg = React.useRef();

  React.useEffect(() => {
    selectAll('#lane-volume-chart > *').remove();

    const margin = { top: 20, right: 20, bottom: 50, left: 20 };
    const width = 780 - margin.left - margin.right;
    const height = 550 - margin.top - margin.bottom;
    const radius = Math.min(width, height) / 2 - 20;

    const lanesWihtVolume = data.filter(d => d.pct > 0.1);
    const otherPct = 1 - lanesWihtVolume.reduce((agg, v) => agg + v.pct, 0);
    if (otherPct >= 0.1) {
      lanesWihtVolume.push({ name: 'Other', origin: undefined, destination: undefined, pct: otherPct });
    }

    const colorRange = _.range(0.1, 0.8, 1 / (lanesWihtVolume.length + 1));
    const color = scaleOrdinal(colorRange.map(o => `rgba(0,128,0,${o}`));

    const detailPie = pie().value(d => d.pct);

    const slicePath = arc().outerRadius(radius).innerRadius(0);

    const sliceLabel = arc()
      .outerRadius(radius - 40)
      .innerRadius(radius - 40);

    const g = select(svg.current)
      .attr('width', width + margin.left + margin.right)
      .attr('height', height + margin.top + margin.bottom)
      .append('g')
      .attr('transform', `translate(${width / 2}, ${height / 2})`);

    const sliceArc = g.selectAll('.arc').data(detailPie(lanesWihtVolume)).enter().append('g').attr('class', 'arc');

    sliceArc
      .append('path')
      .attr('d', slicePath)
      .attr('fill', d => color(d.index));

    sliceArc
      .append('text')
      .attr('transform', d => `translate(${sliceLabel.centroid(d)})`)
      .attr('dy', '0')
      .attr('font-size', '10px')
      .text(d => (d.data.origin ? `From: ${d.data.origin}` : undefined));

    sliceArc
      .append('text')
      .attr('transform', d => `translate(${sliceLabel.centroid(d)})`)
      .attr('dy', '1.5em')
      .attr('font-size', '10px')
      .text(d => (d.data.destination ? `To: ${d.data.destination}` : d.data.name));

    sliceArc
      .append('text')
      .attr('transform', d => `translate(${sliceLabel.centroid(d)})`)
      .attr('dy', '2.5em')
      .attr('font-size', '14px')
      .text(d => `${format('.0%')(d.data.pct)}`);
  }, [data, svg]);

  return <svg id="lane-volume-chart" ref={svg} />;
};

export default LaneVolume;
