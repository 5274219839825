export const AWARD_STATUS = {
  FUTURE: 'FUTURE',
  CURRENT: 'CURRENT',
  PAST: 'PAST',
};

export const AWARD_STATUS_DISPLAY_NAMES = {
  FUTURE: 'Future',
  CURRENT: 'Current',
  PAST: 'Past',
};
