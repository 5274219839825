import React from 'react';
import classNames from 'classnames';

import './ChartLegend.scss';

export default ({ labels, isBuy }) => (
  <div className="chart-legend">
    {labels.map((label, index) => (
      <span key={index} className="chart-legend-label">
        <span
          className={classNames('chart-legend-square', {
            [`chart-legend-square--${label.toLowerCase()}-buy`]: isBuy,
            [`chart-legend-square--${label.toLowerCase()}-sell`]: !isBuy,
          })}
        />
        {label}
      </span>
    ))}
  </div>
);
