const AUTH0_METADATA_NAMESPACE = `https://${process.env.REACT_APP_AUTH0_DOMAIN}/metadata`;
export const IMPERSONATED_COMPANY_ID = 'impersonatedCompanyId';

export const getImpersonatedCompanyId = () => localStorage.getItem(IMPERSONATED_COMPANY_ID);
export const setImpersonatedCompanyId = companyId => localStorage.setItem(IMPERSONATED_COMPANY_ID, companyId);
export const removeImpersonatedCompanyId = () => localStorage.removeItem(IMPERSONATED_COMPANY_ID);

const parseJwt = token => {
  try {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace('-', '+').replace('_', '/');
    return JSON.parse(window.atob(base64));
  } catch (error) {
    return undefined;
  }
};

export const getCurrentUserMetadata = token => {
  const data = parseJwt(token);
  return data[AUTH0_METADATA_NAMESPACE]?.app_metadata ?? {}; // TODO change the way we read metadata, custom claims?
};
