import React from 'react';

export default ({ markerNumber, color, iconWidth = '37', iconHeight = '37' }) => (
  <svg
    width={iconWidth}
    height={iconHeight}
    viewBox="0 0 24 24"
    fill="white"
    strokeWidth="1"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <circle cx="12" cy="10" r="8" fill="white" />
    <circle cx="12" cy="10" r="7" fill={color} />

    <text x="35%" y="60%" fontSize={13} fontWeight={500}>
      {markerNumber}
    </text>
  </svg>
);
