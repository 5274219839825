import React from 'react';
import styled from '@emotion/styled';

import { Box, Chip, Typography } from '@mui/material';
import { AWARD_STATUS, AWARD_STATUS_DISPLAY_NAMES } from '../constants/AwardStatus';

const AwardChip = styled(Chip)`
  ${({ $bgColor }) => `
    background-color: ${$bgColor} !important;
    color: white !important;
  `}
`;

const COLORS = {
  [AWARD_STATUS.CURRENT]: '#4CAF50',
  [AWARD_STATUS.FUTURE]: '#4A90E2',
  [AWARD_STATUS.PAST]: '#BDBDBD',
};

/**
 * Componrnt: AwardStatus
 *
 * @status {string}
 *
 * @returns
 * Returns chip based on status parameter that user provide.
 */
const AwardStatus = ({ status }) => (
  <Box display="flex" alignItems="center">
    <AwardChip
      $bgColor={COLORS[status]}
      label={<Typography variant="body1">{AWARD_STATUS_DISPLAY_NAMES[status] || status}</Typography>}
    />
  </Box>
);

export default AwardStatus;
