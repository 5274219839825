/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect, useCallback } from 'react';
import styled from '@emotion/styled';
import {
  Tabs,
  BackButton,
  PageContentLayout,
  SpacedContainer,
  PageContainer,
  Lane,
  CONTRACT_STATUS_DISPLAY_NAMES,
  CompanyAvatar,
  RoutesOverview,
} from '@leaf/components';
import { withRouter } from 'react-router-dom';
import { Grid, Typography } from '@mui/material';
import useGQL from 'hooks/useGQL';
import Page from 'components/Page';
import { useSnackbar } from 'notistack';
import DetailsOverview from './DetailsOverview';
import { getContractDetailsLaneDescription, getContractRoutes } from './domain/contractDetailsModel';
import ShipmentsOverview from './shipments/ShipmentsOverview';
import TripsOverview from './shipments/TripsOverview';

const RouteHeader = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

const RouteItem = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
`;

const ContractGroupDetails = ({
  match: {
    params: { id },
  },
  history,
  location,
}) => {
  const [laneDescription, setLaneDescription] = useState();
  const [routes, setRoutes] = useState();
  const getGQLClient = useGQL();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    getContractDetailsLaneDescription(getGQLClient, id)
      .then(({ data }) => {
        setLaneDescription(data);
      })
      .catch(enqueueSnackbar);
    getContractRoutes(getGQLClient, id)
      .then(({ data }) => {
        setRoutes(data[0]);
      })
      .catch(enqueueSnackbar);
  }, []);

  const getTabs = useCallback(() => {
    const tabs = [
      { label: 'Details', content: <DetailsOverview contractId={id} /> },
      { label: 'Routes', content: <RoutesOverview routesData={routes} /> },
      ...(laneDescription && laneDescription.isBuyerContract
        ? [
            {
              label: 'Shipments',
              content: <ShipmentsOverview contractId={id} />,
            },
          ]
        : []),
      ...(laneDescription && !laneDescription.isBuyerContract
        ? [
            {
              label: 'Trips',
              content: <TripsOverview contractId={id} />,
            },
          ]
        : []),
    ];
    return tabs;
  }, [id, laneDescription, routes]);

  return (
    <Page
      ComponentTitleLeft={
        <RouteHeader>
          <BackButton history={history} path="/contracts" />
          {laneDescription && (
            <>
              <CompanyAvatar id={laneDescription?.buyerId || laneDescription?.sellerId} />
              <RouteItem>
                <Lane
                  origin={{ primaryContent: [laneDescription?.origin] }}
                  destination={{ primaryContent: [laneDescription?.destination] }}
                  fullWidth={false}
                />
                <Typography variant="body1">{`Contract ID #${id} - ${
                  CONTRACT_STATUS_DISPLAY_NAMES[laneDescription?.contractStatus]
                }`}</Typography>
              </RouteItem>
            </>
          )}
        </RouteHeader>
      }
      isUppercase={false}
    >
      <PageContainer>
        <SpacedContainer container spacing={2} autoWidth>
          <Grid item xs={12}>
            <PageContentLayout withoutSpacing fullHeight={false}>
              <Tabs location={location} history={history} mainUrlPath={`/contracts/${id}`} tabs={getTabs()} />
            </PageContentLayout>
          </Grid>
        </SpacedContainer>
      </PageContainer>
    </Page>
  );
};

export default withRouter(ContractGroupDetails);
