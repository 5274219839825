import React from 'react';
import { MultiRowInfo, ShipmentStatus, CompanyAvatar } from '@leaf/components';

export const getShipperColumns = filterOptions => [
  {
    name: 'id',
    label: 'ID',
    options: {
      sort: false,
      filter: false,
    },
  },
  {
    name: 'poNumber',
    field: 'po_number',
    label: 'Shipment references',
    options: {
      filter: false,
    },
  },
  {
    label: 'Execution partner name',
    name: 'executionPartnerName',
    options: { display: false, filter: false, sort: false },
  },
  {
    name: 'pickupDate',
    label: 'Pickup date',
    options: {
      sort: false,
      filter: false,
    },
  },
  {
    name: 'origin',
    field: 'origin_city',
    label: 'Origin',
    options: {
      filter: false,
      customBodyRender: origin => {
        const [primaryText, secondaryText] = origin;
        return <MultiRowInfo primaryContent={[primaryText]} secondaryContent={[secondaryText]} />;
      },
    },
  },
  {
    name: 'destination',
    field: 'destination_city',
    label: 'Destination',
    options: {
      filter: false,
      customBodyRender: destination => {
        const [primaryText, secondaryText] = destination;
        return <MultiRowInfo primaryContent={[primaryText]} secondaryContent={[secondaryText]} />;
      },
    },
  },
  {
    name: 'legNumber',
    field: 'leg_number',
    label: 'Leg',
    options: { filter: false },
  },
  {
    name: 'tripId',
    label: 'Trip ID',
    options: { filter: false, sort: false },
  },
  {
    name: 'carrier',
    label: 'Carrier',
    options: {
      ...filterOptions.carrier,
      sort: false,
      customBodyRender: carrier => (carrier ? <CompanyAvatar name={carrier.name} id={carrier.id} isChip /> : '-'),
    },
  },
  {
    name: 'equipmentTypeName',
    label: 'Equipment',
    options: {
      sort: false,
      filter: false,
    },
  },
  {
    name: 'status',
    field: 'shipment_execution_status',
    label: 'Status',
    options: {
      ...filterOptions.status,
      customBodyRender: status => <ShipmentStatus status={status} />,
    },
  },
];

export const getCarrierColumns = filterOptions => [
  {
    name: 'id',
    label: 'ID',
    options: {
      display: 'excluded',
      filter: false,
      sort: false,
    },
  },
  {
    label: 'Execution partner name',
    name: 'executionPartnerName',
    options: { display: false, filter: false, sort: false },
  },
  {
    name: 'tripId',
    field: 'trip_id',
    label: 'Trip ID',
    options: {
      filter: false,
      sort: false,
    },
  },
  {
    name: 'startDate',
    label: 'Start date',
    options: {
      filter: false,
      sort: false,
    },
  },
  {
    name: 'origin',
    field: 'origin_city',
    label: 'Origin',
    options: {
      filter: false,
      customBodyRender: origin => {
        const [primaryText, secondaryText] = origin;
        return <MultiRowInfo primaryContent={[primaryText]} secondaryContent={[secondaryText]} />;
      },
    },
  },
  {
    name: 'stops',
    label: 'Stops',
    options: {
      filter: false,
    },
  },
  {
    name: 'destination',
    field: 'destination_city',
    label: 'Destination',
    options: {
      filter: false,
      customBodyRender: destination => {
        const [primaryText, secondaryText] = destination;
        return <MultiRowInfo primaryContent={[primaryText]} secondaryContent={[secondaryText]} />;
      },
    },
  },
  {
    name: 'shipper',
    label: 'Shipper',
    options: {
      ...filterOptions.shipper,
      customBodyRender: shipper => (shipper ? <CompanyAvatar name={shipper.name} id={shipper.id} isChip /> : '-'),
      sort: false,
    },
  },
  {
    name: 'equipmentTypeName',
    label: 'Equipment',
    options: {
      filter: false,
      sort: false,
    },
  },
  {
    name: 'status',
    field: 'shipment_execution_status',
    label: 'Status',
    options: {
      ...filterOptions.status,
      customBodyRender: status => <ShipmentStatus status={status} />,
    },
  },
];
