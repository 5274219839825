import React from 'react';
import { CssBaseline } from '@mui/material';
import { ThemeProvider as MuiThemeProvider, StyledEngineProvider, ThemeProvider } from '@mui/material/styles';
import { Global, css } from '@emotion/react';

import { theme } from '@leaf/components';

import 'leaflet/dist/leaflet.css';

const GlobalStyle = css`
  @font-face {
    font-family: 'Avenir';
    src: url('/fonts/1475496/edefe737-dc78-4aa3-ad03-3c6f908330ed.eot?#iefix') format('eot'),
      url('/fonts/1475496/0078f486-8e52-42c0-ad81-3c8d3d43f48e.woff2') format('woff2'),
      url('/fonts/1475496/908c4810-64db-4b46-bb8e-823eb41f68c0.woff') format('woff'),
      url('/fonts/1475496/4577388c-510f-4366-addb-8b663bcc762a.ttf') format('truetype');
    font-weight: 300;
  }

  @font-face {
    font-family: 'Avenir';
    src: url('/fonts/1475532/e0542193-b2e6-4273-bc45-39d4dfd3c15b.eot?#iefix') format('eot'),
      url('/fonts/1475532/17b90ef5-b63f-457b-a981-503bb7afe3c0.woff2') format('woff2'),
      url('/fonts/1475532/c9aeeabd-dd65-491d-b4be-3e0db9ae47a0.woff') format('woff'),
      url('/fonts/1475532/25f994de-d13f-4a5d-a82b-bf925a1e054d.ttf') format('truetype');
    font-weight: 500;
  }

  html,
  body,
  #root {
    height: 100vh;
    width: 100%;
    overflow-y: hidden;
    letter-spacing: 0;
  }
`;

export default ({ children }) => (
  <StyledEngineProvider injectFirst>
    <MuiThemeProvider theme={theme}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Global styles={GlobalStyle} />
        {children}
      </ThemeProvider>
    </MuiThemeProvider>
  </StyledEngineProvider>
);
