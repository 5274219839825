import { gql } from 'graphql-request';

// NOTE: field names need to be snake case for correct mapping to be achieved
const WHERE = {
  EQUIPMENT_TYPE_IDS_VAR: gql`$equipment_type_ids: [bigint!]`,
  EQUIPMENT_TYPE_IDS_CLAUSE: gql`equipment_type_id: { _in: $equipment_type_ids }`,
  ACCESSORIAL_IDS_VAR: gql`$accessorial_ids: [bigint!]`,
  ACCESSORIAL_IDS_CLAUSE: gql`contract_accessorials: { accessorial_id: {_in: $accessorial_ids }}`,
  TRIP_TYPES_VAR: gql`$trip_types: [String!]`,
  TRIP_TYPES_CLAUSE: gql`contract_routes: { route: { trip_type: {_in: $trip_types }}}`,
  RANGE_START_DATE_VAR: gql`$range_start_date: date!`,
  RANGE_START_DATE_CLAUSE: gql`end_date: { _gte: $range_start_date }`,
  RANGE_END_DATE_VAR: gql`$range_end_date: date!`,
  RANGE_END_DATE_CLAUSE: gql`start_date: { _lte: $range_end_date }`,
};

const isUUID = uuid => uuid?.length === 36 && [...uuid.matchAll(/–|-/g)].length === 4;

const whereClause = ({ where, varsToRemap, gqlQuickFiltersMeta, search, company }) => {
  let whereGQL = gql``;
  const whereVarsKeys = Object.keys(where).filter(
    k => (Array.isArray(where[k]) && where[k].length) || typeof where[k] === 'string',
  );

  let nestedQueries = [];
  if (gqlQuickFiltersMeta) {
    nestedQueries =
      Object.keys(varsToRemap).filter(
        k => Array.isArray(varsToRemap[k]) && varsToRemap[k].length && gqlQuickFiltersMeta[k].isNestedQuery,
      ) ?? [];
  }

  const companyClause = company
    ? gql`{ 
    _or: [
      { buyer_id: { _eq: ${company.id}} }
      { seller_id: { _eq: ${company.id}} }
    ]
  }`
    : '';

  const varsToRemapKeys = Object.keys(varsToRemap)
    .filter(k => Array.isArray(varsToRemap[k]) && varsToRemap[k].length)
    .some(k => varsToRemap[k]?.length > 0)
    ? Object.keys(varsToRemap).filter(k => !nestedQueries.includes(k))
    : [];

  if (
    whereVarsKeys.length ||
    varsToRemapKeys.length ||
    (search !== undefined && search !== '') ||
    (companyClause !== undefined && companyClause !== '')
  ) {
    whereGQL = gql`
      where: {
        _and: [
          { contract_status: { _eq: "CONTRACTED" } }

          ${companyClause}

          ${whereVarsKeys.map(key =>
            where[key]
              ? gql`{
                  ${WHERE[`${key.toUpperCase()}_CLAUSE`]}
              }`
              : '',
          )}
          
          ${
            search !== undefined && search !== ''
              ? gql`{
            _or: [
              ${isUUID(search) ? gql`{ id: { _eq: "${search}" } }` : ''}
              {buyer: { name: { _ilike: "%${search}%" } }}
              {seller: { name: { _ilike: "%${search}%" } }}
              {contract_routes: {
                is_primary: { _eq: true },
                  route: {
                    legs: {
                      _or: [
                        {lane: {origin_name: { _ilike: "%${search}%" }}}
                        {lane: {destination_name: { _ilike: "%${search}%" }}}
                      ]
                    }
                  }
                }
              }
            ]}`
              : ''
          }
          
          ${
            varsToRemapKeys.length > 0
              ? gql`{
                _and: [
                  ${varsToRemapKeys.map(key =>
                    varsToRemap[key]
                      ? gql`{
                            _or: [
                                ${gqlQuickFiltersMeta[key].metaGenerator(varsToRemap[key]).map(
                                  operations => gql`{
                                      _and: [
                                        ${operations.map(
                                          operation =>
                                            gql`
                                              {${operation.field}: { ${operation.name}: ${
                                              operation.type === 'string' ? `"${operation.value}"` : operation.value
                                            }}
                                            }`,
                                        )}]
                                      }`,
                                )}
                              ]
                            }`
                      : '',
                  )}        
                  ]}`
              : ''
          }

          ${
            nestedQueries.length > 0
              ? gql`{
                _and: [${nestedQueries.map(key =>
                  varsToRemap[key]
                    ? gql`
                        ${gqlQuickFiltersMeta[key].metaGenerator(varsToRemap[key])}
                      `
                    : '',
                )}]
              }`
              : ''
          }
        ]
      }
  `;
  }
  return whereGQL;
};

const whereVar = ({ where }) => gql`
  ${where.equipment_type_ids ? WHERE.EQUIPMENT_TYPE_IDS_VAR : ``}
  ${where.accessorial_ids ? WHERE.ACCESSORIAL_IDS_VAR : ``}
  ${where.trip_types ? WHERE.TRIP_TYPES_VAR : ``}
  ${where.range_start_date ? WHERE.RANGE_START_DATE_VAR : ``}
  ${where.range_end_date ? WHERE.RANGE_END_DATE_VAR : ``}
`;

export const getContractsGQL = variables => {
  const whereGQL = whereClause(variables);
  return gql`
    query getContracts(
      $limit: Int
      $orderBy: [contract_order_by]
      $offset: Int
      ${whereVar(variables)}
    ) {
      contract(
        limit: $limit
        order_by: $orderBy
        offset: $offset
        ${whereGQL}
      ) {
        id
        actionGuid: id
        contractType: contract_type
        isFleet: is_fleet
        startDate: start_date
        endDate: end_date
        linehaulRpm: linehaul_rpm
        maxVolume: max_volume
        equipment_type {
          name
        }
        contract_accessorials {
          accessorial {
            name
          }
        }
        contract_routes_aggregate {
          aggregate {
            count
          }
        }
        contract_routes(where: { is_primary: { _eq: true } }) {
          route {
            tripType: trip_type
            legs(order_by: { sequence: asc }) {
              lane {
                origin: origin_name
                destination: destination_name
              }
            }
          }
        }
      }
      contract_aggregate${whereGQL ? gql`( ${whereGQL} )` : ''} {
        aggregate {
          count
        }
      }
    }
  `;
};
