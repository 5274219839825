import React from 'react';
import styled from '@emotion/styled';
import { Grid, Card, Typography } from '@mui/material';

const ItemCards = styled(Grid)`
  div:not(:first-child) {
    margin-top: 1em;
  }
`;

const ItemCard = styled(Card)`
  width: 100%;
  padding: 16px;
`;

const Item = styled(Grid)`
  justify-content: space-between;
`;

const List = styled(Grid)`
  display: block;
`;

const ListItem = styled(Typography)`
  margin-top: 0.75em;
`;

const ItemWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
`;

const SwitchLabel = styled(Typography)`
  display: flex;
  align-items: center;
`;

const makeList = (title, list) => {
  if (list?.length) {
    return (
      <ItemCard>
        <List container item xs={12}>
          <Typography>{title}</Typography>
          {list.map(item => (
            <ItemWrapper>
              <ListItem variant="h5">{item}</ListItem>
            </ItemWrapper>
          ))}
        </List>
      </ItemCard>
    );
  }
  return null;
};

const LaneData = ({ data, EquipmentClass, Uom, isAdmin }) => (
  <>
    <ItemCards container spacing={4}>
      <ItemCard>
        <Item container item xs={12}>
          <SwitchLabel>Equipment</SwitchLabel>
          <EquipmentClass />
        </Item>
      </ItemCard>

      {isAdmin && (
        <ItemCard>
          <Item container item xs={12}>
            <SwitchLabel>UOM basis</SwitchLabel>
            <Uom />
          </Item>
        </ItemCard>
      )}

      <ItemCard>
        <Item container item xs={12}>
          <Typography>Mileage</Typography>
          <Typography variant="h5">{data.miles}</Typography>
        </Item>
      </ItemCard>

      <ItemCard>
        <Item container item xs={12}>
          <Typography>Annualized Loads</Typography>
          <Typography variant="h5">{data.shipments}</Typography>
        </Item>
      </ItemCard>

      <ItemCard>
        <Item container item xs={12}>
          <Typography>Annualized linehaul spend</Typography>
          <Typography variant="h5">{data.spend}</Typography>
        </Item>
      </ItemCard>

      <ItemCard>
        <Item container item xs={12}>
          <Typography>Potential annual cost savings</Typography>
          <Typography variant="h5">{data.savings}</Typography>
        </Item>
      </ItemCard>

      {makeList('Adapt observations', data.observation)}

      {makeList('Adapt actions', data.actions)}
    </ItemCards>
  </>
);

export default LaneData;
