/* eslint-disable no-use-before-define */
/* eslint-disable no-restricted-globals */
export const toFixedNumber = (value, fractionDigits) => parseFloat(value.toFixed(fractionDigits));

export const toDecimalPercentage = percentage => percentage / 100;

export const toPercent = value => {
  if (value === null || value === undefined) {
    return '-';
  }
  let numberToFormat = value;
  if (typeof numberToFormat === 'string') {
    numberToFormat = parseFloat(value);
  }
  const sign = numberToFormat < 0 ? '-' : '';
  const percentage = Math.abs(numberToFormat);
  if (Math.abs(percentage) > 10) {
    return `${sign}${toFixedNumber(percentage, 0)}%`;
  }
  return `${sign}${toFixedNumber(percentage, 1)}%`;
};

export const formatNumberAsView = (number, digit = 0, showCurrency = true) => {
  if (typeof number === 'number') {
    return new Intl.NumberFormat('en-US', {
      minimumFractionDigits: digit,
      maximumFractionDigits: digit,
      ...(Math.abs(number) < 1 && { minimumIntegerDigits: 1 }),
      ...(showCurrency && {
        style: 'currency',
        currency: 'USD',
      }),
    }).format(number);
  }

  return '-';
};

const hasDecimals = number => number % 1 !== 0;

export const UNIT = {
  PERCENT: 'PERCENT',
  CURRENCY: 'USD',
  NONE: 'NONE',
  KTONSCO2: 'kTons CO2e',
};

const internationalNumberFormat = new Intl.NumberFormat('en-US');

export const numberWithCommas = value => value?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') ?? value;

export const calculateValue = (valueToFormat, unitSign = '', round, areOnlyThousandsRounded) => {
  let value = valueToFormat;
  let minus = '';
  if (value < 0) {
    minus = '-';
    value = Math.abs(valueToFormat);
  }

  if (value < 1e3) {
    if (round && areOnlyThousandsRounded) return `${minus}${unitSign}${Math.round(value)}`;
    const number = typeof value === 'string' ? parseFloat(value) : value;
    return `${minus}${unitSign}${number.toFixed(0)}`;
  }

  if (value < 1e6) {
    const number = value / 1e3;
    if (round && areOnlyThousandsRounded) return `${minus}${unitSign}${Math.round(number)}k`;
    return `${minus}${unitSign}${number.toFixed(hasDecimals(number) ? 1 : 0)}k`;
  }

  if (value < 1e9) {
    const number = value / 1e6;
    if (round && !areOnlyThousandsRounded) return `${minus}${unitSign}${Math.round(number)}M`;
    return `${minus}${unitSign}${number.toFixed(hasDecimals(number) ? 1 : 0)}M`;
  }

  if (value < 1e12) {
    const number = value / 1e9;
    if (round && !areOnlyThousandsRounded) return `${minus}${unitSign}${Math.round(number)}B`;
    return `${minus}${unitSign}${number.toFixed(hasDecimals(number) ? 1 : 0)}B`;
  }

  const number = value / 1e12;
  if (round && !areOnlyThousandsRounded) return `${minus}${unitSign}${Math.round(number)}T`;
  return `${minus}${unitSign}${number.toFixed(hasDecimals(number) ? 1 : 0)}T`;
};

export const formatNumber = (value, unit = UNIT.CURRENCY, round = false, areOnlyThousandsRounded = false) => {
  // TODO: Move these undefined checks in domain models when introduced
  if (value === undefined || value === null) {
    return '-';
  }

  switch (unit) {
    case UNIT.PERCENT:
      return `${(value * 100).toFixed()}%`;
    case UNIT.CURRENCY:
      return calculateValue(value, '$', round, areOnlyThousandsRounded);
    case UNIT.NONE:
    case UNIT.KTONSCO2:
      return internationalNumberFormat.format(toFixed(Number(value)));
    default:
      break;
  }

  if (!unit) {
    return calculateValue(value);
  }

  return internationalNumberFormat.format(value);
};

// ------ legacy numbers formatting

const commaPattern = /,/g; // One time creating regex state machine

export const toPercentage = decimal => decimal * 100;

export const numberAsStringWithCommaToFloat = string =>
  typeof string === 'string' ? parseFloat(string.replace(commaPattern, '')) : string;

export const toArrayOfFloatPercentsFromNumbers = numbers => {
  if (numbers.length === 1) {
    return [100];
  }

  const percents = [];
  const min = 0; // Math.min(...numbers);
  const max = Math.max(...numbers);
  const delta = max - min;

  // eslint-disable-next-line no-plusplus
  for (let step = 0; step < numbers.length; step++) {
    percents[step] = ((numbers[step] - min) / delta) * 100;
  }

  return percents;
};

export const precisionRound = (number, precision) => {
  const factor = 10 ** precision;
  return Math.round(number * factor) / factor;
};

export const toFixed = (number, numberOfDecimalsToFix) => {
  if (precisionRound(number, numberOfDecimalsToFix) !== number) {
    return Number(number.toFixed(numberOfDecimalsToFix));
  }

  return number;
};

export const humanizeToMillion = (number, numberOfDecimalsToFix = 2) =>
  `${formatNumberAsView(toFixed(number / 1000000, numberOfDecimalsToFix), 2, false)}M`;

export const humanizeToThousands = (number, numberOfDecimalsToFix = 2) =>
  `${formatNumberAsView(toFixed(number / 1000, numberOfDecimalsToFix), 2, false)}k`;

export const removeDecimals = numberAsString => {
  if (numberAsString.indexOf('.') === -1) {
    return numberAsString;
  }

  return typeof numberAsString === 'string' ? toFixed(parseFloat(numberAsString), 0) : numberAsString;
};

export const isNumber = value => !isNaN(+value);
