import { gql } from 'graphql-request';

export const GQL_COMPANIES = gql`
  query getCompanies {
    company {
      name
      id
    }
  }
`;

export const GQL_SHIPPERS = gql`
  query getShippers {
    company(where: { company_type: { _eq: "SHIPPER" } }, order_by: { name: asc }) {
      name
      id
    }
  }
`;

export const GQL_CARRIERS = gql`
  query getCarriers {
    company(where: { company_type: { _eq: "CARRIER" } }, order_by: { name: asc }) {
      name
      id
    }
  }
`;
