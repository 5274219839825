import React from 'react';
import classNames from 'classnames';

import { Icon, IconSizes } from './';

import './SortingSwitch.scss';

const ascendingIconName = 'up';
const descendingIconName = 'down-1';

export default class SortingSwitch extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      isAscending: this.props.isAscending,
      isHovered: false,
    };

    this.toggleSorting = this.toggleSorting.bind(this);
    this.handleMouseOver = this.handleMouseOver.bind(this);
    this.handleMouseOut = this.handleMouseOut.bind(this);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.isAscending !== this.props.isAscending) {
      this.setState({
        isAscending: nextProps.isAscending,
      });
    }
  }

  getIsAscending(prevState) {
    if (prevState.isAscending === undefined && this.props.isAscendingDefault) {
      return true;
    }

    if (prevState.isAscending === undefined && !this.props.isAscendingDefault) {
      return false;
    }

    return !prevState.isAscending;
  }

  handleMouseOver() {
    this.setState({
      isHovered: true,
    });
  }

  handleMouseOut() {
    this.setState({
      isHovered: false,
    });
  }

  toggleSorting() {
    this.setState(
      prevState => ({
        ...prevState,
        isHovered: false,
        isAscending: this.getIsAscending(prevState),
      }),
      () => this.props.onClick(this.state.isAscending),
    );
  }

  render() {
    const { isAscending, isHovered } = this.state;
    const { by, className, isAscendingDefault } = this.props;

    return (
      <div
        className={`sorting-switch ${className || ''}`}
        onKeyPress={this.toggleSorting}
        onClick={this.toggleSorting}
        onMouseOver={this.handleMouseOver}
        onFocus={this.handleMouseOver}
        onMouseOut={this.handleMouseOut}
        onBlur={this.handleMouseOut}
      >
        {isAscending === true && (
          <Icon
            className={classNames({
              'sorting-switch__icon': true,
              'sorting-switch__icon--active': true,
            })}
            tooltip="Ascending"
            name={isHovered ? descendingIconName : ascendingIconName}
            size={IconSizes.xs}
          />
        )}
        {isAscending === false && (
          <Icon
            className={classNames({
              'sorting-switch__icon': true,
              'sorting-switch__icon--active': true,
            })}
            tooltip="Descending"
            name={isHovered ? ascendingIconName : descendingIconName}
            size={IconSizes.xs}
          />
        )}
        {(isAscending === undefined || isAscending === null) && (
          <Icon
            className={classNames({
              'sorting-switch__icon': true,
              'sorting-switch__icon--hidden': true,
              'sorting-switch__icon--hovered': isHovered,
            })}
            name={isAscendingDefault ? ascendingIconName : descendingIconName}
            size={IconSizes.xs}
          />
        )}
        <span
          className={classNames({
            'sorting-switch__text': true,
            'sorting-switch__text--hovered': isHovered,
            'sorting-switch__text--active': isAscending === true || isAscending === false,
          })}
        >
          {by}
        </span>
      </div>
    );
  }
}

SortingSwitch.defaultProps = {
  isAscendingDefault: false,
};
