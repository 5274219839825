import { format, parse } from 'date-fns';

export const API_TIME_FORMAT = 'HH:mm:00';
export const VIEW_TIME_FORMAT = 'hh:mm aa';

export const intervalAsView = (interval, unit, precision = 2, placeholder = '-') => {
  if (!interval) {
    return placeholder;
  }
  if (unit === 'seconds') {
    return interval;
  }
  const zero = (0.0).toFixed(precision);

  const inMinutes = (interval / 60).toFixed(precision);
  if (unit === 'minutes') {
    return inMinutes === zero ? placeholder : inMinutes;
  }
  const inHours = (interval / 60 / 60).toFixed(precision);
  if (unit === 'hours') {
    return inHours === zero ? placeholder : inHours;
  }
  return undefined;
};

export const transformApiTime = time => {
  if (!time) {
    return null;
  }
  return format(parse(`1970-01-01 ${time}`, `yyyy-MM-dd ${VIEW_TIME_FORMAT}`, new Date()), API_TIME_FORMAT);
};

export const transformViewTime = time => {
  if (!time) {
    return null;
  }
  return format(new Date(`1970-01-01T${time}`), VIEW_TIME_FORMAT);
};

export const transformPickerTime = date => {
  if (!date) {
    return null;
  }
  return format(date, VIEW_TIME_FORMAT);
};

export const transformTime = time => {
  if (!time) {
    return null;
  }
  return parse(`1970-01-01 ${time}`, `yyyy-MM-dd ${VIEW_TIME_FORMAT}`, new Date());
};

export const transformViewTimeWithFormat = (time, timeFormat = VIEW_TIME_FORMAT) => {
  if (!time) {
    return null;
  }
  return format(new Date(`1970-01-01T${time}`), timeFormat);
};
