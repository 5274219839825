import React from 'react';
import { Typography, ListItem } from '@mui/material';

const OverviewListRow = React.memo(({ label, value, isSubHeader = false }) => (
  <ListItem disableGutters style={{ justifyContent: 'space-between' }}>
    {React.isValidElement(label) ? label : <Typography variant="caption">{label}</Typography>}
    {!isSubHeader && (React.isValidElement(value) ? value : <Typography variant="body1">{value ?? '-'}</Typography>)}
  </ListItem>
));

export default OverviewListRow;
