/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import _ from 'lodash';
import { Section } from '../AddEditWidgetModal.styled';
import Button from '../../../Button';
import Title from './Title';
import { Autocomplete, Checkbox, Input, Select } from '../../../form/library';
import { fnOptions, getSwapInputProps } from '../../utility';
import { fnDisplayNames } from '../../Constants/functions';
import { fontVariants } from '../../Constants';
import ColorsSection from './Colors';

export default ({
  arrayFields,
  control,
  errors,
  metricsOptions,
  setValue,
  hasFontVariantOption,
  isListInfoWidget,
  hasColorsSection,
}) => {
  const { fields, append, remove, swap } = arrayFields;
  return (
    <div>
      {fields.map((field, index) => (
        <Section boxShadow={2} p={2} mt={2} mb={2} key={field.id}>
          <Title title={`Metric #${index + 1}`} {...getSwapInputProps(fields, index, swap, remove)} />
          <Autocomplete
            name={`metrics[${index}].name`}
            label="Name"
            inputProps={{ error: _.get(errors, `metrics[${index}].name`) }}
            options={metricsOptions.map(({ name: key }) => key)}
            getOptionLabel={option => metricsOptions.find(({ name: key }) => option === key)?.displayName}
            isOptionEqualToValue={(option, value) => option === value}
            control={control}
            onBlur={e => {
              setValue(
                `metrics[${index}].numberFormat`,
                metricsOptions.find(m => m.displayName === e.target.value)?.numberFormat,
              );
            }}
            defaultValue={field.name}
          />
          <Autocomplete
            name={`metrics[${index}].fn`}
            label="Function"
            inputProps={{ error: _.get(errors, `metrics[${index}].fn`) }}
            options={fnOptions.map(({ key }) => key)}
            getOptionLabel={option => fnDisplayNames[option]}
            control={control}
            defaultValue={field.fn}
          />
          <Input
            type="text"
            name={`metrics[${index}].displayName`}
            label="Display name"
            control={control}
            error={_.get(errors, `metrics[${index}].displayName`)}
            defaultValue={field.displayName}
          />
          {hasFontVariantOption && (
            <Select
              label="Font variant"
              control={control}
              name={`metrics[${index}].fontVariant`}
              options={fontVariants}
              placeholder="Regular"
            />
          )}
          <Input
            name={`metrics[${index}].numberFormat`}
            label="Number format"
            control={control}
            style={{ display: 'none' }}
          />
          {isListInfoWidget && (
            <Checkbox name={`metrics[${index}].isDivider`} control={control} label="Display divider under the value" />
          )}
          {hasColorsSection && <ColorsSection control={control} errors={errors} metricIndex={index} />}
        </Section>
      ))}
      <Button onClick={() => append({})} color="primary">
        ADD METRIC
      </Button>
    </div>
  );
};
