const toCustomFilterOptions = (values, field) => {
  if (!Array.isArray(values)) {
    throw new Error('Values must be an array');
  }
  return values.map(value => ({
    value: field ? value[field] : value,
    label: field ? value[field] : value,
  }));
};

export default toCustomFilterOptions;
