import axios from 'axios';

import { utility } from '@leaf/components';

export default class AdaptLane {
  constructor(response) {
    Object.assign(this, {
      ...response,
      batchDate: utility.date.backendStringToViewString(response.batchDate, ''),
      batchDateLatest: utility.date.backendStringToViewString(response.batchDateLatest, ''),
    });

    this.view = {};
  }

  static getLocation = async searchTerm => axios.get(`geo/addresses/search?query=${searchTerm}&includeOnly=City,State`);
}
