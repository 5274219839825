import * as Sentry from '@sentry/react';

export default () => {
  if (process.env.REACT_APP_SENTRY_DSN) {
    Sentry.init({
      dsn: process.env.REACT_APP_SENTRY_DSN,
      environment: window.location.hostname,
    });
  }
};
