import React from 'react';
import { Typography } from '@mui/material';

import Card from 'company/components/Card';
import { H3, H6, Body1 } from 'company/components/Typography';
import {
  IMPACT_OBJECTIVE_COLORS,
  IMPACT_OBJECTIVE_STATUS,
  IMPACT_OBJECTIVE_STATUS_DISPLAY_NAMES,
} from '@leaf/components';
import ImpactObjectiveProgress from './ImpactObjectiveProgress';
import {
  DelayedIcon,
  DelayedStatus,
  DelayedValue,
  GoalDateValue,
  Kpi,
  KpiWrapper,
  OnTrackIcon,
  GoalValue,
  OnTrackStatus,
  OnTrackValue,
  Subtitle,
  CurrentValue,
} from './ImpactObjective.styled';

const Kpis = ({ potential, proposed, proposedDate }) => (
  <KpiWrapper>
    <Kpi>
      <H3>{potential}</H3>
      <Body1>Potential</Body1>
    </Kpi>

    <Kpi deEmphasize>
      <H3>{proposed}</H3>
      <Body1>{proposedDate}</Body1>
    </Kpi>
  </KpiWrapper>
);

const renderStatus = status => {
  switch (status) {
    case IMPACT_OBJECTIVE_STATUS.ON_TRACK:
      return (
        <OnTrackStatus
          label={IMPACT_OBJECTIVE_STATUS_DISPLAY_NAMES[IMPACT_OBJECTIVE_STATUS.ON_TRACK]}
          icon={<OnTrackIcon />}
          size="small"
        />
      );
    case IMPACT_OBJECTIVE_STATUS.NO_ACTUAL_GOAL:
      return null;
    default:
      return (
        <DelayedStatus
          label={IMPACT_OBJECTIVE_STATUS_DISPLAY_NAMES[IMPACT_OBJECTIVE_STATUS.CAUTION]}
          icon={<DelayedIcon />}
          size="small"
        />
      );
  }
};

const renderCurrentValue = (status, currentValue) => {
  if (status === IMPACT_OBJECTIVE_STATUS.ON_TRACK) {
    return <OnTrackValue>{currentValue}</OnTrackValue>;
  }
  return <DelayedValue>{currentValue}</DelayedValue>;
};

const rebalance = (status, currentValue, currentShouldValue, endValue) => {
  if (status === IMPACT_OBJECTIVE_STATUS.ON_TRACK) {
    return [
      {
        percentage: `${((100 * currentValue) / endValue).toFixed(2)}%`,
        color: IMPACT_OBJECTIVE_COLORS.ON_TRACK,
      },
    ];
  }
  return [
    {
      percentage: `${((100 * currentValue) / endValue).toFixed(2)}%`,
      color: IMPACT_OBJECTIVE_COLORS.DELAYED,
    },
    {
      percentage: `${100 - ((100 * currentShouldValue) / endValue).toFixed(2)}%`,
      color: 'grey',
    },
  ];
};

export default ({ data, title }) => {
  const Title = (
    <>
      <H6>{title || data.title}</H6>

      <Typography>{renderStatus(data.status)}</Typography>
    </>
  );

  const Top = (
    <>
      <CurrentValue>{renderCurrentValue(data.status, data.view.currentValue)}</CurrentValue>
      <GoalValue>{`Goal ${data.view.endValue}`}</GoalValue>
    </>
  );

  const Bottom = (
    <>
      <Typography />
      <GoalDateValue>{`by ${data.view.endYearMonth}`}</GoalDateValue>
    </>
  );

  return (
    <Card title={Title}>
      <Subtitle>{data.subtitle}</Subtitle>

      {data.status === IMPACT_OBJECTIVE_STATUS.NO_ACTUAL_GOAL ? (
        <Kpis
          potential={data.view.endValue}
          proposed={data.view.currentValue}
          proposedDate={data.view.currentYearMonth}
        />
      ) : (
        <ImpactObjectiveProgress
          top={Top}
          bottom={Bottom}
          data={rebalance(data.status, data.currentValue, data.currentShouldValue, data.endValue)}
        />
      )}
    </Card>
  );
};
