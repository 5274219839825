/* eslint-disable */
import React, { useContext } from 'react';
import leaflet from 'leaflet';
import styled from '@emotion/styled';
import { Map as LeafletMap, TileLayer } from 'react-leaflet';
import { Box, CircularProgress, Typography } from '@mui/material';
import { LocalShipping } from '@mui/icons-material';

import { useSnackbar } from 'notistack';
import axios from 'axios';
import { UserContext } from 'authentication/UserProvider';

const Map = styled(LeafletMap)`
  height: calc(100% - 60px);
  display: ${({ isLoading }) => (isLoading ? 'none' : 'block')};
  position: relative;
`;

const Loading = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const CircularProgressContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 75%;
  color: rgba(0, 0, 0, 0.38);
  .MuiSvgIcon-root {
    width: 40px;
    height: 40px;
  }
`;

const options = {
  center: [39.5, -98.35],
  maxBoundsViscosity: 1.0,
  maxBounds: leaflet.latLngBounds(leaflet.latLng(-90, -200), leaflet.latLng(90, 200)),
  zoom: 4,
  minZoom: 3,
  attributionControl: false,
};

const ShipperNetworkMap = () => {
  const mapRef = React.useRef();
  const { enqueueSnackbar } = useSnackbar();
  const { currentUser } = useContext(UserContext);

  const companyId = currentUser?.company?.id;

  const [shipperNetwork, setShipperNetwork] = React.useState(null);
  const [leafNetwork, setLeafNetwork] = React.useState(null);
  const [isLoading, setLoading] = React.useState(false);
  const [missingData, setMissingData] = React.useState(false);

  const fetchData = async () => {
    setLoading(true);

    axios
      .get(`view/adapt/network/leaf?shipper_id=${companyId}`)
      .then(leafNetwork => {
        setLeafNetwork(leafNetwork?.network);

        axios
          .get(`view/adapt/network/shippers/${companyId}`)
          .then(shipperNetwork => setShipperNetwork(shipperNetwork?.network));
      })
      .catch(err => {
        console.debug('Transportation grid: ' + err);
        setMissingData(true);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  React.useEffect(() => {
    fetchData();
  }, [companyId]);

  const shipperNetworkStyle = () => ({ weight: 1, color: '#4B8FE2' });
  const leafNetworkStyle = () => ({ weight: 6, color: '#4CAF50', opacity: 0.75 });
  const renderMap = () => {
    window.dispatchEvent(new Event('resize'));
    return (
      <Map {...options} ref={mapRef} preferCanvas>
        <TileLayer url={process.env.REACT_APP_MAPBOX} />
      </Map>
    );
  };

  React.useEffect(() => {
    const { current = {} } = mapRef;
    const { leafletElement: map } = current;

    if (map) {
      let sn;

      if (shipperNetwork) {
        sn = leaflet
          .geoJSON(
            {
              type: 'FeatureCollection',
              features: [shipperNetwork],
            },
            {
              style: shipperNetworkStyle,
            },
          )
          .addTo(map);
      }
      if (leafNetwork) {
        leaflet
          .geoJSON(
            {
              type: 'FeatureCollection',
              features: [leafNetwork],
            },
            {
              style: leafNetworkStyle,
            },
          )
          .addTo(map);

        if (sn) {
          sn.bringToFront();
        }
      }
      return () =>
        map.eachLayer(layer => {
          if (layer.feature) {
            layer.remove();
          }
        });
    }
  }, [shipperNetwork, leafNetwork]);

  return (
    <>
      {isLoading && (
        <Loading height={1}>
          <CircularProgress />
          <Typography>Analyzing your network…</Typography>
        </Loading>
      )}
      {missingData ? (
        <CircularProgressContainer>
          <LocalShipping />
          <Typography variant="caption">Data not yet available</Typography>
        </CircularProgressContainer>
      ) : (
        renderMap()
      )}
    </>
  );
};

export default ShipperNetworkMap;
