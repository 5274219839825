import { utility, toCustomFilterOptions, PHASE_TYPE } from '@leaf/components';
import axios from 'axios';

export default class Lanes {
  constructor(response) {
    Object.assign(this, {
      ...response,
    });

    this.view = {
      annualLoads: utility.numbers.numberWithCommas(response.annualLoads) ?? '-',
      annualSpend: utility.numbers.formatNumber(response.annualSpend) ?? '-',
      destination: response.destination ?? '-',
      laneId: response.laneId ?? '-',
      laneType: response.laneType || '-',
      origin: response.origin ?? '-',
      shipperGuid: response.shipperGuid ?? '-',
      shipperId: response.shipperId || '-',
      shipperName: response.shipperName ?? '-',
      status: response.status ?? '-',
      tenderAcceptance: '100%',
      annualCostSavings: utility.numbers.formatNumber(response?.savings) ?? '-',
      lane: [
        {
          origin: response.origin ?? '',
          destination: response.destination ?? '',
          laneType: response.laneType ?? '',
        },
      ],
      phases: {
        shipperId: response.shipperId,
        laneId: response.laneId,
        status: response.status,
        equipmentTypes: response.equipmentClass,
        startDate: response.startDate,
      },
    };
  }

  static getAll = async params =>
    axios.get(`view/adapt-board-lanes?${utility.pagination.toServerSideParams(params)}`).then(data => {
      const lanseWithDate = data.data.filter(item => item.startDate).sort((a, b) => new Date(a) - new Date(b));
      const lanesWithoutDate = data.data.filter(item => !item.startDate).sort((a, b) => a.phase - b.phase);
      return {
        ...data,
        data: [...lanseWithDate, ...lanesWithoutDate].map(adaptBoard => new Lanes(adaptBoard)),
      };
    });

  static getShipperSummary = () =>
    axios.get(`view/adapt-board/shipper-summary`).then(response => ({
      ...response,
    }));

  static getShipperSummaryPhases = shipperId =>
    axios.get(`view/adapt-board/shippers/${shipperId}/phase-split`).then(data => ({
      data: data.adaptBoardShipperPhaseSplit?.filter(item => item.phase !== PHASE_TYPE.OUT_OF_SCOPE),
    }));

  static patchPhaseStart = payload => axios.patch('analytics/adapt/board/lane/batch', payload);

  static getAllLanePhases = () =>
    axios.get(`view/adapt-board-lanes/shipper-dates`).then(response => ({
      ...response,
    }));

  static getEnums(type) {
    return axios.get(`view/adapt-board-lanes/filters?name=${type}`).then(({ values }) => toCustomFilterOptions(values));
  }
}
