// filter types are used for defining filters inside filter popup
const filterTypes = {
  ORIGINS: 'origin',
  DESTINATIONS: 'destination',
  LANES: 'lane',
  EQUIPMENT: 'equipment_type_id',
  CONTRACT_TYPES: 'contract_type',
  COUNTERPARTIES: 'counter_parties',
  SHIPPERS: 'shippers',
  CARRIERS: 'carriers',
  COHORTS: 'cohort_name',
  NETWORK_MOVES: 'network_move_id',
  REPORTING_LANES: 'reporting_lane',
  TRANSPORTATION_MODES: 'transportation_mode',
};

export default filterTypes;

export const filterTypeDisplayNames = userType => ({
  [filterTypes.ORIGINS]: 'Origins',
  [filterTypes.DESTINATIONS]: 'Destinations',
  [filterTypes.LANES]: 'Lanes',
  [filterTypes.EQUIPMENT]: 'Equipment',
  [filterTypes.CONTRACT_TYPES]: 'Contract types',
  [filterTypes.SHIPPERS]: 'Shippers',
  [filterTypes.CARRIERS]: 'Carriers',
  [filterTypes.COHORTS]: 'Cohorts',
  [filterTypes.TRANSPORTATION_MODES]: 'Transportation modes',
  [filterTypes.NETWORK_MOVES]: 'Network moves',
  [filterTypes.REPORTING_LANES]: 'Reporting lanes',
  [filterTypes.COUNTERPARTIES]: userType === 'carrier' ? 'Shippers' : 'Carriers',
});

// filter props are used for querying backend
export const filterProps = ['from', 'to', ...Object.values(filterTypes)];
