import Address from 'domain/location/Address';
import { utility } from '@leaf/components';
import { extractAddress } from 'shipments/utilities';
import axios from 'axios';
import ShipmentSimple from './ShipmentSimple';
import { formatAsViewReferenceNumbers } from './ShipmentMapper';
import ShipmentPickup from './ShipmentPickup';
import ShipmentDelivery from './ShipmentDelivery';

export default class Shipment {
  constructor({
    origin,
    destination,
    pickupStatus,
    pickupAppointment,
    pickupArrivalActual,
    pickupDepartureActual,
    originDelay,
    deliveryStatus,
    deliveryAppointment,
    deliveryArrivalActual,
    deliveryDepartureActual,
    destinationDelay,
    ...response
  }) {
    Object.assign(this, {
      ...response,
      laneDestination: new Address(destination),
      laneOrigin: new Address(origin),
    });

    const laneOrigin = extractAddress(origin);
    const laneDestination = extractAddress(destination);

    this.view = {
      equipmentName: this.equipmentTypeName || '-',
      status: this.status || '-',
      originAddress: `${laneOrigin.businessName}, ${laneOrigin.street}, ${laneOrigin.city} ${laneOrigin.state} ${laneOrigin.zipCode}`,
      destinationAddress: `${laneDestination.businessName}, ${laneDestination.street}, ${laneDestination.city} ${laneDestination.state} ${laneDestination.zipCode}`,
      carrierName: this.carrierName || '-',
      shipperName: this.shipperName || '-',
      executionPartnerName: this.executionPartnerName || '-',
      tripId: this.tripId || '-',
      referenceNumbers: formatAsViewReferenceNumbers(this.referenceNumbers),
      pickup:
        pickupAppointment &&
        new ShipmentPickup({
          origin,
          pickup: {
            arrivalActual: pickupArrivalActual,
            departureActual: pickupDepartureActual,
            appointmentTime: pickupAppointment,
            status: pickupStatus,
            delay: originDelay,
          },
        }),
      delivery:
        deliveryAppointment &&
        new ShipmentDelivery({
          destination,
          delivery: {
            arrivalActual: deliveryArrivalActual,
            departureActual: deliveryDepartureActual,
            appointmentTime: deliveryAppointment,
            status: deliveryStatus,
            delay: destinationDelay,
          },
        }),
    };
  }

  static getAll = async params =>
    axios.get(`view/shipment-tenders?${utility.pagination.toServerSideParams(params)}`).then(response => ({
      ...response,
      data: response.data.map(shipment => new ShipmentSimple(shipment)),
    }));

  static getById = async id => axios.get(`view/shipment-tenders/${id}`).then(shipment => new Shipment(shipment));

  // filter values
  static getExecutionPartners = async () =>
    axios.get('view/shipment-tenders/execution-partners').then(
      partners =>
        partners?.map(({ name }) => ({
          label: name,
          value: name,
        })) ?? [],
    );

  static getOrigins = async () =>
    axios.get('view/shipment-tenders/origins').then(
      origins =>
        origins?.map(origin => ({
          label: origin.split(';').join(', '),
          value: origin,
        })) ?? [],
    );

  static getDestinations = async () =>
    axios.get('view/shipment-tenders/destinations').then(
      destinations =>
        destinations?.map(destination => ({
          label: destination.split(';').join(', '),
          value: destination,
        })) ?? [],
    );

  static getCarriers = async () =>
    axios.get('view/shipment-tenders/carriers').then(
      carriers =>
        carriers?.map(({ name }) => ({
          label: name,
          value: name,
        })) ?? [],
    );

  static getTripIds = async () =>
    axios.get('view/shipment-tenders/trip-ids').then(
      tripIds =>
        tripIds?.map(tripId => ({
          label: tripId,
          value: tripId,
        })) ?? [],
    );

  static getLegNumbers = async () =>
    axios.get('view/shipment-tenders/leg-numbers').then(
      legNumbers =>
        legNumbers?.map(legNumber => ({
          label: legNumber.toString(),
          value: legNumber.toString(),
        })) ?? [],
    );
}
