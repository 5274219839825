import { Box, DialogContent as DialogContentMUI, IconButton as IconButtonMUI } from '@mui/material';
import styled from '@emotion/styled';

export const DialogContent = styled(DialogContentMUI)`
  .MuiButton-root {
    margin-bottom: ${({ theme }) => theme.spacing(1)};
  }
`;

export const IconButton = styled(IconButtonMUI)`
  padding: 0;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(2)};
`;

export const Section = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(2)};
  .section-title {
    display: flex;
    justify-content: space-between;
  }
`;
