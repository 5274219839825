import React from 'react';
import styled from '@emotion/styled';

const TooltipWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const tooltips = {
  Total_transportation_spend: (
    <TooltipWrapper>
      <div>The annualized total transportation spend (including linehaul, fuel and accessorials) on full</div>
      <div>truckload (including dry and/or reefer equipment types) based on the Adapt analysis for the</div>
      <div>specified historical time period. If the historical time period was more or less than a year, then the</div>
      <div>values were annualized to represent annual figures. Numbers are in USD.</div>
    </TooltipWrapper>
  ),
  Loads: (
    <TooltipWrapper>
      <div>The annualized total number of full truckload shipments (including dry and/or reefer equipment</div>
      <div>types) based on the Adapt analysis for the specified historical time period. If the historical time</div>
      <div>period was more or less than a year, then the values were annualized to represent annual figures.</div>
    </TooltipWrapper>
  ),
  On_time_delivery: (
    <TooltipWrapper>
      <div>The percentage of all truckload shipments (including dry and/or reefer equipment types) that</div>
      <div>were delivered on time based on the Adapt analysis for the specified historical time period. If the</div>
      <div>historical time period was more or less than a year, then the values were annualized to represent</div>
      <div>annual figures. If on-time data is not specifically provided, it is estimated based upon available</div>
      <div>information.</div>
    </TooltipWrapper>
  ),
  CO2_emissions: (
    <TooltipWrapper>
      <div>The annualized total amount of CO2 (in kilotons) directly produced by all truckload shipments</div>
      <div>(including dry and/or reefer equipment types) based on the Adapt analysis for the specified</div>
      <div>historical time period. If the historical time period was more or less than a year, then the values</div>
      <div>were annualized to represent annual figures.</div>
    </TooltipWrapper>
  ),
};

export const getTooltipValue = prop => {
  if (prop) {
    const objectProp = prop.replaceAll(' ', '_').replaceAll('-', '_');
    return tooltips[objectProp];
  }

  return null;
};
