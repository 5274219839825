import React from 'react';
import { Card, CardContent } from '@mui/material';
import styled from '@emotion/styled';
import { OverviewTable } from '@leaf/components';

const columns = [
  { label: 'Lat / Lon', name: 'view.latLon', options: { filter: false, sort: false } },
  { label: 'Event time', name: 'view.eventTime', options: { filter: false, sort: false } },
  { label: 'Address', name: 'view.address', options: { filter: false, sort: false } },
  { label: 'Tracking status', name: 'trackingStatus' },
  { label: 'Description', name: 'statusDescription', options: { filter: false, sort: false } },
];

const CardContentSC = styled(CardContent)`
  padding: 0;
  .MuiToolbar-gutters {
    padding-left: ${({ theme }) => theme.spacing(2)};
  }
`;

const ShipmentTrackingEvents = ({ trackingEvents }) => (
  <Card>
    <CardContentSC>
      <OverviewTable data={trackingEvents} columns={columns} title="Tracking events" />
    </CardContentSC>
  </Card>
);

export default ShipmentTrackingEvents;
