import * as yup from 'yup';
import { chartTypes } from '../Constants';

export default yup.object().shape({
  title: yup.string().required('Required'),
  subtitle: yup.string(),
  chartType: yup.string().required('Required'),
  isStacked: yup.string(),
  isPercentBased: yup.string(),
  order: yup.object().shape({
    name: yup.string(),
    direction: yup.string(),
  }),
  metrics: yup.array().of(
    yup.object({
      name: yup.string().required(),
      fn: yup.string().required(),
      numberFormat: yup.string(),
      displayName: yup.string(),
      fontVariant: yup.string(),
      isDivider: yup.boolean(),
      colors: yup.array().of(
        yup.object({
          color: yup.string().required(),
          from: yup.number(),
        }),
      ),
    }),
  ),
  dimensions: yup
    .array()
    .nullable()
    .of(
      yup.object({
        name: yup.string().required(),
        displayName: yup.string(),
        fontVariant: yup.string(),
      }),
    )
    .when(['chartType'], {
      is: chartType =>
        chartType &&
        chartType !== chartTypes.KPI &&
        chartType !== chartTypes.CIRCULAR_PROGRESS_BAR &&
        chartType !== chartTypes.DONUT &&
        chartType !== chartTypes.LIST &&
        chartType !== chartTypes.PIVOT_TABLE,
      then: yup
        .array()
        .of(
          yup.object({
            name: yup.string().required(),
            displayName: yup.string(),
            fontVariant: yup.string(),
          }),
        )
        .required('Required'),
    }),
  pivotRows: yup.array().of(
    yup.object({
      name: yup.string().required(),
      displayName: yup.string(),
      fontVariant: yup.string(),
    }),
  ),
  pivotColumns: yup.array().of(
    yup.object({
      name: yup.string().required(),
      displayName: yup.string(),
      fontVariant: yup.string(),
    }),
  ),
  filters: yup.array().of(
    yup.object({
      name: yup.string().required(),
      value: yup.array().of(yup.string()).required(),
    }),
  ),
});
