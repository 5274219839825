const chartTypes = {
  AREA: 'area',
  LINE: 'line',
  BAR: 'bar',
  PROGRESS_BAR: 'progress_bar',
  CIRCULAR_PROGRESS_BAR: 'circular_progress_bar',
  MAP: 'map',
  KPI: 'kpi',
  DONUT: 'donut',
  LIST: 'list',
  TABLE: 'table',
  PIVOT_TABLE: 'pivot_table',
};

export default chartTypes;

export const chartTypesDisplayNames = {
  [chartTypes.AREA]: 'Area',
  [chartTypes.LINE]: 'Line',
  [chartTypes.BAR]: 'Stacked vertical bars',
  [chartTypes.PROGRESS_BAR]: 'Horizontal bars',
  [chartTypes.CIRCULAR_PROGRESS_BAR]: 'Circular',
  [chartTypes.KPI]: 'Single value',
  [chartTypes.DONUT]: 'Donut',
  [chartTypes.LIST]: 'List value',
  [chartTypes.TABLE]: 'Table',
  [chartTypes.PIVOT_TABLE]: 'Pivot table',
};
