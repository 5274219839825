import React from 'react';
import { Dashboard } from '@leaf/components';
import * as DashboardDomain from 'domain/dashboard/Dashboard';
import { useSnackbar } from 'notistack';
import useDashboard from 'hooks/useDashboard';
import Page from '../components/Page';

const DashboardView = ({ name, companyType }) => {
  const { enqueueSnackbar } = useSnackbar();
  const { canEdit } = useDashboard();

  return (
    <Page title="Dashboard">
      <Dashboard
        name={name}
        canEdit={canEdit}
        api={DashboardDomain.default}
        userType={companyType?.toLowerCase()}
        onErrorHandler={enqueueSnackbar}
      />
    </Page>
  );
};

export default DashboardView;
