import React from 'react';

import { Charts } from '../../Common/Components';
import { dimensionToFunctionMap, applyIfNotNull, calcColorForValue } from './chartsHelper';
import { numbers as numbersService } from '../../../utility';
import { sharedChartPropTypes } from './types';

import './DashboardArea.scss';

const defaultOptions = {
  tooltips: {
    enabled: true,
    intersect: false,
    callbacks: {
      label: a => `$${numbersService.numberWithCommas(a.yLabel.toFixed(2))}`,
    },
  },
};

export default class DashboardArea extends React.PureComponent {
  render = () => {
    const { data, config, metricsOptions = [] } = this.props;
    const mapFn = dimensionToFunctionMap[data.dimensions[0].dimension] || (value => value);
    const transformedData = {
      labels: mapFn(data.dimensions[0].values),
      datasets: data.metrics.map((metric, index) => {
        const colors = config.metrics[index] && config.metrics[index].colors; // preventing application crash when removing metrics while editing. don't remove this. it should be detailed investigation why is this happening
        const color = calcColorForValue(0, colors);

        return {
          label: config.metrics[index]?.displayName || metricsOptions.find(m => m.name === metric.metric)?.displayName,
          borderWidth: 0,
          data: metric.values.map(v => applyIfNotNull(v, numbersService.numberAsStringWithCommaToFloat)),
          spanGaps: false, // If false, points with any NaN data will create a break in the line.
          backgroundColor: color,
          borderColor: color,
          pointRadius: 0,
          pointBorderWidth: 2,
          fill: true,
        };
      }),
    };

    return (
      <div className="padding-bottom-md padding-horizontal-md flex-column flex-grow">
        <div className="flex-grow">
          <Charts.Line
            height="100%"
            width="100%"
            data={transformedData}
            hasLegend
            options={defaultOptions}
            beginYAtZero
            stacked={config.isStacked}
            doHideXGridLines
            doHideYGridLines
            isUnitBased
            doShowOnlyFirstAndLastXAxisValue
            yAxisStepSize={10000000}
            padding={15}
            isWithRedraw={false}
            isTooltipEnabled
          />
        </div>
      </div>
    );
  };
}

DashboardArea.propTypes = sharedChartPropTypes;
