import Address from 'domain/location/Address';
import { utility } from '@leaf/components';
import { extractAddress } from 'shipments/utilities';
import axios from 'axios';
import TripSimple from './TripSimple';
import Leg from './Leg';

export default class Trip {
  constructor({ origin, destination, ...response }) {
    Object.assign(this, {
      ...response,
      laneDestination: new Address(destination),
      laneOrigin: new Address(origin),
    });

    const laneOrigin = extractAddress(origin);
    const laneDestination = extractAddress(destination);

    this.view = {
      equipmentName: this.equipmentTypeName || '-',
      status: this.status || '-',
      originAddress: `${laneOrigin.businessName}, ${laneOrigin.street}, ${laneOrigin.city} ${laneOrigin.state} ${laneOrigin.zipCode}`,
      destinationAddress: `${laneDestination.businessName}, ${laneDestination.street}, ${laneDestination.city} ${laneDestination.state} ${laneDestination.zipCode}`,
      carrierName: this.carrierName || '-',
      shipperName: this.shippers?.map(shipper => shipper.name).join(', ') || '-',
      executionPartnerName: this.executionPartnerName || '-',
    };
  }

  static getAll = async params =>
    axios.get(`view/carrier-trips?${utility.pagination.toServerSideParams(params)}`).then(response => ({
      ...response,
      data: response.data.map(shipment => new TripSimple(shipment)),
    }));

  static getById = async id => axios.get(`view/carrier-trips/${id}`).then(trip => new Trip(trip));

  static getLegs = async id => axios.get(`view/carrier-trips/${id}/legs`).then(legs => legs.map(leg => new Leg(leg)));

  // filter values
  static getExecutionPartners = async () =>
    axios.get('view/carrier-trips/execution-partners').then(
      partners =>
        partners?.map(({ name }) => ({
          label: name,
          value: name,
        })) ?? [],
    );

  static getOrigins = async () =>
    axios.get('view/carrier-trips/origins').then(
      origins =>
        origins?.map(origin => ({
          label: origin.split(';').join(', '),
          value: origin,
        })) ?? [],
    );

  static getDestinations = async () =>
    axios.get('view/carrier-trips/destinations').then(
      destinations =>
        destinations?.map(destination => ({
          label: destination.split(';').join(', '),
          value: destination,
        })) ?? [],
    );
}
