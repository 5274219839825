import React from 'react';
import Page from 'components/Page';
import { PageContainer } from '@leaf/components';
import { BoardOverview } from './tabs';

const AdaptBoardOverview = () => (
  <Page title="Adapt">
    <PageContainer>
      <BoardOverview />
    </PageContainer>
  </Page>
);

export default AdaptBoardOverview;
