import styled from '@emotion/styled';
import { Dialog as DialogMUI, DialogTitle, DialogActions, DialogContent } from '@mui/material';

export const Dialog = styled(DialogMUI)`
  .MuiDialog-paper {
    overflow-y: visible;
  }
`;

export const DialogTitleContainer = styled(DialogTitle)`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const DialogActionsContainer = styled(DialogActions)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 13px ${({ theme }) => theme.spacing(2)};
`;

export const DialogContentWrapper = styled(DialogContent)`
  overflow: auto;
`;
