/* eslint-disable react/jsx-props-no-spreading */
import { Box } from '@mui/material';
import React from 'react';
import styled from '@emotion/styled';

const pageHeaderHeight = 60;
const calculateOffset = (hasSubheader, heightOffsetMultiplier) => {
  let offsetHeight = pageHeaderHeight;
  if (hasSubheader) {
    offsetHeight = 2 * pageHeaderHeight;
  }
  if (heightOffsetMultiplier) {
    offsetHeight = heightOffsetMultiplier * pageHeaderHeight;
  }
  return offsetHeight;
};

const Layout = styled(Box, {
  shouldForwardProp: prop =>
    prop !== '$standaloneView' &&
    prop !== '$noStyling' &&
    prop !== '$heightOffsetMultiplier' &&
    prop !== '$hasSubheader' &&
    prop !== '$withoutSpacing' &&
    prop !== '$fullHeight',
})`
  height: ${({ theme, $hasSubheader, $heightOffsetMultiplier, $fullHeight }) =>
    `calc(100vh - ${calculateOffset($hasSubheader, $heightOffsetMultiplier)}px - ${
      $fullHeight ? '0px' : theme.spacing(4)
    })`};
  background-color: ${({ theme, $noStyling }) => !$noStyling && `${theme.palette.common.white}`};
  overflow-x: auto;
  padding: ${({ theme, $standaloneView }) => $standaloneView && theme.spacing(2)};
  // targeting muidatatable root container. Can't target MUIDataTable class since for prod build, that class is omitted
  .MuiPaper-root {
    box-shadow: none;
    display: flex;
    flex-direction: column;
    height: 100%;
    > .MuiPaper-root {
      flex-direction: row;
      max-height: 64px;
    }
  }
  .MuiGrid-root > .MuiBox-root {
    background-color: white;
  }
`;

export const PageContent = ({ children, withPadding = false, fullHeight = false, ...otherProps }) => {
  const props = {
    ...(fullHeight && { height: 1 }),
  };
  return (
    <Box boxShadow={2} padding={withPadding ? 2 : 0} {...props} {...otherProps}>
      {children}
    </Box>
  );
};

export default ({
  children,
  withoutSpacing = false,
  hasSubheader = false,
  heightOffsetMultiplier,
  fullHeight = false,
  noStyling = false,
  standaloneView = false,
  ...otherProps
}) => {
  const defaultProps = {
    ...(!noStyling && { boxShadow: 2 }),
  };
  const props = !withoutSpacing
    ? {
        m: fullHeight ? 0 : 2,
        ...defaultProps,
      }
    : {
        m: 0,
        ...defaultProps,
      };
  return (
    <Layout
      $hasSubheader={hasSubheader}
      $fullHeight={fullHeight}
      $withoutSpacing={withoutSpacing}
      $noStyling={noStyling}
      $standaloneView={standaloneView}
      $heightOffsetMultiplier={heightOffsetMultiplier}
      {...props}
      {...otherProps}
    >
      {children}
    </Layout>
  );
};
