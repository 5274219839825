import React from 'react';
import classNames from 'classnames';
import { Close } from '@mui/icons-material';
import { IconButton } from '@mui/material';

import './HeaderPanel.scss';

export default React.memo(({ children, className, hasTopBorder }) => (
  <div className={classNames('header-panel', className, { 'header-panel--top-border': hasTopBorder })}>{children}</div>
));

export const HeaderPanelLeft = React.memo(({ children, className, hasLargeMargin }) => (
  <div className={classNames('header-panel-left', className, { 'header-panel-left--large-padding': hasLargeMargin })}>
    {children}
  </div>
));

export const HeaderPanelRight = React.memo(({ children, className, hasCloseButton = false, onCloseButtonClick }) => (
  <div className={`header-panel-right ${className || ''}`}>
    {children}
    {hasCloseButton && (
      <IconButton onClick={onCloseButtonClick} size="large">
        <Close title="Close" />
      </IconButton>
    )}
  </div>
));
