/* eslint-disable no-param-reassign */
import React, { useState } from 'react';
import styled from '@emotion/styled';
import { Bar } from 'react-chartjs-2';

import { utility, DataNotAvailable } from '@leaf/components';
import Card from 'company/components/Card';
import { Typography } from '@mui/material';

const LineWrapper = styled.div`
  position: relative;
  padding: 25px 24px;
  width: ${({ width }) => (width ? `${width}px` : '100%')};
  height: ${({ height }) => (height ? `${height}px` : '95%')};
  .no-data {
    position: absolute;
    top: calc(50% - 40px);
    left: 50%;
    transform: translate(-50%, -50%);
  }
  #phase-start-date-label {
    color: ${({ theme }) => theme.palette.deEmphasize};
  }
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  label {
    display: flex;
    align-items: center;
    gap: 8px;
  }
`;

const DEFAULT_BAR_COLOR = '#83C1FF';

const getData =
  (datasets = [], shouldShowTiers) =>
  () => {
    const mapAccountPlanPhases = values => {
      const lineChartData = values.map(({ benefitToCustomer }) => benefitToCustomer);
      if (shouldShowTiers) {
        const barChartDataGreen = values.map(({ greenPercTotal }) => greenPercTotal * 100);
        const barChartDataBlue = values.map(({ bluePercTotal }) => bluePercTotal * 100);
        const barChartDataYellow = values.map(({ yellowPercTotal }) => yellowPercTotal * 100);
        return [
          { color: '#5000FA', label: 'Savings', data: lineChartData },
          { color: '#4CAF50', label: 'Forward Contracted', data: barChartDataGreen },
          { color: '#4A90E2', label: 'Routing Guide', data: barChartDataBlue },
          { color: '#FFC300', label: 'Spot', data: barChartDataYellow },
        ];
      }
      return [
        { color: '#5000FA', label: 'Savings', data: lineChartData },
        { color: '#5000FA', label: 'Annual Spend', data: values.map(({ percTotal }) => percTotal * 100) },
      ];
    };
    const lineChartValues = datasets.map(({ benefitToCustomer }) => benefitToCustomer);
    return {
      labels: datasets.map(({ start }) => utility.date.to3LetterMonthAndShortYear(start)),
      datasets: mapAccountPlanPhases(datasets).map(({ color, label, data }, index) => {
        const isLineChart = index === 0;
        if (isLineChart) {
          return {
            data: lineChartValues,
            type: 'line',
            borderColor: color,
            backgroundColor: color,
            yAxisID: 'y2',
            fill: false,
            label,
          };
        }
        return {
          data,
          borderColor: shouldShowTiers ? color : DEFAULT_BAR_COLOR,
          backgroundColor: shouldShowTiers ? color : DEFAULT_BAR_COLOR,
          label,
          lineTension: 0.1,
          fill: 'origin',
          pointRadius: 0.8,
        };
      }),
    };
  };

const BudgetPortfolio = ({ data, options, height }) => {
  const [shouldShowTiers, setShouldShowTiers] = useState(false);
  return (
    <Card
      title={
        <Header>
          <Typography variant="h6">Transportation Portfolio</Typography>
          <label>
            <input
              type="checkbox"
              checked={shouldShowTiers}
              onChange={() => setShouldShowTiers(currentState => !currentState)}
            />
            Show tiers
          </label>
        </Header>
      }
    >
      <LineWrapper height={height}>
        <Bar
          data={getData(data, shouldShowTiers)}
          options={{
            ...options,
            legend: shouldShowTiers
              ? options.legend
              : {
                  position: options.legend?.position,
                  labels: {
                    boxWidth: 12,
                    padding: 30,
                    filter: item => item.text?.includes('Savings'),
                  },
                },
          }}
          redraw
        />
        {(!Array.isArray(data) || data.length === 0) && <DataNotAvailable />}
      </LineWrapper>
    </Card>
  );
};

export default BudgetPortfolio;
