const fns = {
  SUM: 'SUM',
  MAX: 'MAX',
  MIN: 'MIN',
  AVG: 'AVG',
};

export default fns;

export const fnDisplayNames = {
  [fns.SUM]: 'Sum',
  [fns.MAX]: 'Maximum',
  [fns.MIN]: 'Minimum',
  [fns.AVG]: 'Average',
};
