import React from 'react';
import styled from '@emotion/styled';
import { Box, Chip, Typography } from '@mui/material';

import { USER_TYPE, USER_TYPE_DISPLAY_NAMES } from '../constants/UserType';

const ChipSC = styled(Chip, {
  shouldForwardProp: prop => prop !== '$textColor' && prop !== '$bgColor',
})`
  ${({ $bgColor, $textColor }) => `
    background-color: ${$bgColor} !important;
    color: ${$textColor} !important;
  `}
`;

export const USER_TYPE_COLORS = {
  [USER_TYPE.CARRIER]: {
    bgColor: '#001530',
    textColor: 'white',
  },
  [USER_TYPE.SHIPPER]: {
    bgColor: '#4A90E2',
    textColor: 'white',
  },
  [USER_TYPE.BROKER]: {
    bgColor: '#EEEEEE',
    textColor: '#757575',
  },
  [USER_TYPE.LE_ADMIN]: {
    bgColor: '#4CAF50',
    textColor: 'white',
  },
};

/**
 * Componrnt: UserType
 *
 * @type {string}
 *
 * @returns
 * Returns chip based on type parameter that user provide.
 */
const UserType = ({ type }) => (
  <Box display="flex" alignItems="center">
    <ChipSC
      $bgColor={USER_TYPE_COLORS[type]?.bgColor}
      $textColor={USER_TYPE_COLORS[type]?.textColor}
      label={<Typography variant="body1">{USER_TYPE_DISPLAY_NAMES[type] || type}</Typography>}
    />
  </Box>
);

export default UserType;
