import React, { useContext } from 'react';
import { useSnackbar } from 'notistack';

import { ServerSideTable, useTableCustomFilters, SHIPMENT_STATUS_DISPLAY_NAMES } from '@leaf/components';
import { StateContext } from 'state/StateProvider';
import { SET_CONTRACT_SHIPMENTS_TABLE_STATE } from 'state/stateReducer';
import useGQL from 'hooks/useGQL';
import { getShipperColumns } from './table-config';
import { getCarriers, getContractShipments } from './domain/contractShipmentsModel';

export default ({ contractId }) => {
  const { enqueueSnackbar } = useSnackbar();
  const getGQLClient = useGQL();

  const {
    state: {
      tables: { contractShipmentsOverview: tableState },
    },
    dispatch,
  } = useContext(StateContext);

  const [filterOptions] = useTableCustomFilters([
    {
      inputType: 'autocomplete',
      columnName: 'carrier',
      filterOptionsGetter: () => getCarriers(getGQLClient),
    },
    {
      inputType: 'autocomplete',
      columnName: 'status',
      filterOptionsGetter: () =>
        Promise.resolve(
          Object.keys(SHIPMENT_STATUS_DISPLAY_NAMES).map(key => ({
            label: SHIPMENT_STATUS_DISPLAY_NAMES[key],
            value: key,
          })),
        ),
    },
  ]);

  return (
    <>
      <ServerSideTable
        options={{ filter: true }}
        columns={getShipperColumns(filterOptions)}
        fn={() => getContractShipments({ getGQLClient, tableState, contractId })}
        tableState={tableState}
        dispatch={dispatch}
        stateSetterAction={SET_CONTRACT_SHIPMENTS_TABLE_STATE}
        errorHandler={enqueueSnackbar}
      />
    </>
  );
};
