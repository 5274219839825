import React from 'react';
import { ArrowDownward, ArrowUpward, Delete } from '@mui/icons-material';
import { Typography } from '@mui/material';
import { IconButton } from '../AddEditWidgetModal.styled';

export default ({
  onDelete,
  title,
  onArrowUp,
  onArrowDown,
  hasDelete = true,
  hasArrowUp = false,
  hasArrowDown = false,
}) => (
  <div className="section-title">
    <Typography variant="h6">{title}</Typography>
    <div>
      {hasDelete && (
        <IconButton onClick={onDelete} size="large">
          <Delete color="disabled" />
        </IconButton>
      )}
      {hasArrowUp && (
        <IconButton onClick={onArrowUp} size="large">
          <ArrowUpward color="disabled" />
        </IconButton>
      )}
      {hasArrowDown && (
        <IconButton onClick={onArrowDown} size="large">
          <ArrowDownward color="disabled" />
        </IconButton>
      )}
    </div>
  </div>
);
