import React from 'react';
import axios from 'axios';
import styled from '@emotion/styled';
import { Box } from '@mui/material';
import { withRouter } from 'react-router-dom';

import { Explorer, Page, BackButton } from '@leaf/components';

const ExplorerWrapper = styled.div`
  height: 100vh;
  overflow-y: auto;
  padding: 16px;
`;

const ExplorerView = ({
  companyId,
  history,
  match: {
    params: { laneId },
  },
}) => {
  if (!companyId) {
    return <Page title="Adapt Lane Explorer" />;
  }

  return (
    <Page
      title="Adapt Lane Explorer"
      ComponentTitleLeft={
        <Box display="flex">
          <BackButton history={history} />
        </Box>
      }
      isUppercase={false}
    >
      <ExplorerWrapper>
        <Explorer client={axios} companyId={companyId} laneId={laneId} mapboxUrl={`${process.env.REACT_APP_MAPBOX}`} />
      </ExplorerWrapper>
    </Page>
  );
};

export default withRouter(ExplorerView);
