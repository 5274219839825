/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Typography } from '@mui/material';
import styled from '@emotion/styled';

import { v4 } from 'uuid';
import OverviewListRow from '../OverviewListRow';
import OverviewListCol from '../OverviewListCol';
import Completed from '../Completed';
import Divider from '../Divider';
import Overview from '../Overview';
import OverviewMultiColumn from '../OverviewMultiColumn';

const CompletedWrapper = styled.div`
  padding-top: ${({ theme }) => `${theme.spacing(2)}`};
  padding-bottom: ${({ theme }) => `${theme.spacing(2)}`};
`;

const SubtitleWrapper = styled.div`
  padding-top: ${({ theme }) => `${theme.spacing(1)}`};
  padding-bottom: ${({ theme }) => `${theme.spacing(3)}`};
`;

const Info = styled(Typography)`
  color: rgba(0, 0, 0, 0.54);
`;

export default React.memo(({ title, data, titleBgColor, titleBadge }) => (
  <Overview title={title} titleBgColor={titleBgColor} titleBadge={titleBadge}>
    {data.map(row => {
      switch (row.type) {
        case 'row': {
          const props = { ...row.data };
          return <OverviewListRow {...props} key={v4()} />;
        }
        case 'info': {
          const { value } = row.data;
          return (
            <Info variant="body1" key={v4()}>
              {value}
            </Info>
          );
        }
        case 'completed': {
          const { value } = row.data;
          return (
            typeof value === 'number' && (
              <CompletedWrapper key={v4()}>
                <Completed value={value} />
              </CompletedWrapper>
            )
          );
        }
        case 'divider': {
          return <Divider key={v4()} />;
        }
        case 'subtitle': {
          const { label } = row.data;
          return (
            <SubtitleWrapper key={v4()}>
              <Typography variant="h6">{label}</Typography>
            </SubtitleWrapper>
          );
        }
        case 'col': {
          const props = { ...row.data };
          return <OverviewListCol {...props} key={v4()} />;
        }
        case 'multiColumn': {
          return <OverviewMultiColumn data={row.data} />;
        }
        default: {
          return null;
        }
      }
    })}
  </Overview>
));
