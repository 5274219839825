import styled from '@emotion/styled';
import { Typography, Chip, Grid } from '@mui/material';
import { CheckCircle, Error } from '@mui/icons-material';
import { theme, IMPACT_OBJECTIVE_COLORS } from '@leaf/components';

export const OnTrackStatus = styled(Chip)`
  color: white;
  background-color: ${IMPACT_OBJECTIVE_COLORS.ON_TRACK};
  display: flex;
  align-items: center;
  font-size: 0.8em;
  font-weight: 500;
`;

export const OnTrackIcon = styled(CheckCircle)`
  & {
    color: white !important;
  }
`;

export const OnTrackValue = styled(Typography)`
  color: ${IMPACT_OBJECTIVE_COLORS.ON_TRACK};
  font-size: 1.5em;
  font-weight: 500;
`;

export const DelayedStatus = styled(Chip)`
  color: white;
  background-color: ${IMPACT_OBJECTIVE_COLORS.DELAYED};
  font-size: 0.8em;
  font-weight: 500;
`;

export const DelayedIcon = styled(Error)`
  color: white;
`;

export const DelayedValue = styled(Typography)`
  color: ${IMPACT_OBJECTIVE_COLORS.DELAYED};
  font-size: 1.5em;
  font-weight: 500;
`;

export const GoalValue = styled(Typography)`
  display: flex;
  align-items: center;
  color: ${theme.palette.deEmphasize};
  font-size: 1.5em;
  font-weight: 500;
`;

export const CurrentValue = styled(Typography)`
  display: flex;
  align-items: center;
  font-size: 1.1em;
  font-weight: 500;
`;

export const GoalDateValue = styled(Typography)`
  color: ${theme.palette.deEmphasize};
  font-size: 1.25em;
  font-weight: 500;
`;

export const Subtitle = styled(Typography)`
  margin-bottom: 0.5em;
`;

export const KpiWrapper = styled(Grid)`
  display: flex;
  justify-content: space-between;
  margin-right: 1em;
`;

export const Kpi = styled.div`
  color: ${({ deEmphasize }) => deEmphasize && theme.palette.deEmphasize};
`;
