import React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography } from '@mui/material';

import styled from '@emotion/styled';

const Circle = styled(Box)`
  height: 40px;
  width: 40px;
  background-color: ${({ theme }) => `${theme.palette.primary.light}`};
  color: ${({ theme }) => `${theme.palette.common.white}`};
  border-radius: 50%;
`;

const Day = React.memo(({ day }) => {
  const shortenDayLabel = () => day?.substr(0, 2).toUpperCase();

  return (
    <Circle display="flex" justifyContent="center" alignItems="center">
      <Typography variant="body1">{shortenDayLabel()}</Typography>
    </Circle>
  );
});

const Blank = styled(Box)`
  .MuiBox-root {
    background-color: #e5e5e5;
  }
`;

const FixedDay = ({ earliest }) => <Day day={earliest} />;

const FixedTiming = ({ earliest }) => <Typography variant="body1">{earliest}</Typography>;

const VariableDay = ({ earliest, latest }) => {
  if (earliest === latest) {
    return <Day day={earliest} />;
  }

  return (
    <>
      <Day day={earliest} />
      {latest && (
        <>
          <Box pl={1} pr={1}>
            -
          </Box>
          <Day day={latest} />
        </>
      )}
    </>
  );
};

const VariableTiming = ({ earliest, latest }) => {
  if (earliest === latest) {
    return <Typography variant="body1">{earliest}</Typography>;
  }
  if (!earliest && latest) {
    return <Typography variant="body1">{`Until ${latest}`}</Typography>;
  }
  if (earliest && !latest) {
    return <Typography variant="body1">{`From ${earliest}`}</Typography>;
  }

  return (
    <>
      <Typography variant="body1">{earliest}</Typography>
      <>
        <Box pl={1} pr={1}>
          -
        </Box>
        <Typography variant="body1">{latest}</Typography>
      </>
    </>
  );
};

const ScheduleTiming = React.memo(
  ({ earliestDayOfWeek, latestDayOfWeek, earliestTime, latestTime, type, pickupDeliveryType, isBlank }) => {
    if (isBlank) {
      return (
        <Blank display="flex" alignItems="center" justifyContent="center" mb={1}>
          <Day />
        </Blank>
      );
    }
    const isFixedType = type === 'FIXED'; // !isFixedType means it's either VARIABLE or NO_APPOINTMENT_REQUIRED

    return (
      <>
        <Box display="flex" alignItems="center" justifyContent="center" mb={1}>
          {isFixedType && <FixedDay earliest={earliestDayOfWeek} latest={latestDayOfWeek} />}
          {!isFixedType && <VariableDay earliest={earliestDayOfWeek} latest={latestDayOfWeek} />}
        </Box>
        <Box display="flex" alignItems="center" justifyContent="center" mb={1}>
          {isFixedType && <FixedTiming earliest={earliestTime} latest={latestTime} />}
          {!isFixedType && <VariableTiming earliest={earliestTime} latest={latestTime} />}
        </Box>
        <Box>
          <Typography variant="caption">{pickupDeliveryType}</Typography>
        </Box>
        {type === 'NO_APPOINTMENT_REQUIRED' && <Typography variant="caption">(No appt. required)</Typography>}
      </>
    );
  },
);

const WeekPropType = ['MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY', 'SATURDAY', 'SUNDAY'];

ScheduleTiming.propTypes = {
  earliestDayOfWeek: PropTypes.oneOf(WeekPropType).isRequired,
  latestDayOfWeek: PropTypes.oneOf(WeekPropType).isRequired,
  earliestTime: PropTypes.string,
  latestTime: PropTypes.string,
  type: PropTypes.oneOf(['FIXED', 'VARIABLE', 'NO_APPOINTMENT_REQUIRED']).isRequired,
};

ScheduleTiming.defaultProps = {
  earliestTime: null,
  latestTime: null,
};

export default ScheduleTiming;
