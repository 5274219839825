import React from 'react';
import { Global, css } from '@emotion/react';

import Explorer from './views/Explorer';

const GlobalStyle = css`
  .leaflet-container {
    width: 100%;
    height: 640px;
  }

  .leaflet-control-attribution {
    display: none;
  }
`;

const defaultOptions = {
  view: 'SHIPPER',
};
export default ({ client, companyId, laneId, mapboxUrl, options }) => (
  <>
    <Global styles={GlobalStyle} />
    <Explorer
      client={client}
      companyId={companyId}
      laneId={laneId}
      mapboxUrl={mapboxUrl}
      options={{ ...defaultOptions, ...options }}
    />
  </>
);
