import { v4 } from 'uuid';
import metrics from '../../metrics';

import chartTypes from '../../chartTypes';
import dimensions from '../../dimensions';
import functions from '../../functions';
import chartLayoutRestrictions from '../../chartLayoutRestrictions';
import { formattingTypes } from '../../../../utility/formattingHelper';
import order from '../../order';

export default [
  // row 1
  {
    title: 'Volume by contract tier',
    subtitle: 'Executed shipments',
    chartType: chartTypes.DONUT,
    metrics: [
      {
        name: metrics.SHIPMENTS_EXECUTED_DAY_OF,
        displayName: 'Day of',
        numberFormat: formattingTypes.INTEGER,
        fn: functions.SUM,
        colors: [{ from: 0, color: '#3B873E' }],
      },
      {
        name: metrics.SHIPMENTS_EXECUTED_WEEK_OF,
        displayName: 'Week of',
        numberFormat: formattingTypes.INTEGER,
        fn: functions.SUM,
        colors: [{ from: 0, color: '#7BC67E' }],
      },
      {
        name: metrics.SHIPMENTS_EXECUTED_UNCOMMITTED,
        displayName: 'Uncommited',
        numberFormat: formattingTypes.INTEGER,
        fn: functions.SUM,
        colors: [{ from: 0, color: '#4A90E2' }],
      },
      {
        name: metrics.SHIPMENTS_EXECUTED_SPOT,
        displayName: 'Spot',
        numberFormat: formattingTypes.INTEGER,
        fn: functions.SUM,
        colors: [{ from: 0, color: '#FFB547' }],
      },
    ],
    order: {
      name: metrics.SHIPMENTS_EXECUTED_DAY_OF,
      direction: order.DESCENDING,
    },
    layout: {
      x: 0,
      y: 0,
      i: v4(),
      ...chartLayoutRestrictions[chartTypes.DONUT],
    },
  },
  {
    title: 'Volume',
    chartType: chartTypes.LIST,
    metrics: [
      {
        name: metrics.TENDERS,
        displayName: 'TOTAL',
        numberFormat: formattingTypes.INTEGER,
        fn: functions.SUM,
        isDivider: true,
      },
      {
        name: metrics.SHIPMENTS_TODO,
        displayName: 'To do',
        numberFormat: formattingTypes.INTEGER,
        fn: functions.SUM,
        fontVariant: 'body1',
      },
      {
        name: metrics.EXECUTED_SHIPMENTS,
        displayName: 'Executed',
        numberFormat: formattingTypes.INTEGER,
        fn: functions.SUM,
        fontVariant: 'body1',
      },
      {
        name: metrics.SHIPMENTS_SHIPPER_FAILED,
        displayName: 'Shipper missed',
        numberFormat: formattingTypes.INTEGER,
        fn: functions.SUM,
        fontVariant: 'body1',
      },
      {
        name: metrics.SHIPMENTS_CARRIER_FAILED,
        displayName: 'Carrier missed',
        numberFormat: formattingTypes.INTEGER,
        fn: functions.SUM,
        fontVariant: 'body1',
      },
      {
        name: metrics.TENDERS_DECLINED,
        displayName: 'Declined',
        numberFormat: formattingTypes.INTEGER,
        fn: functions.SUM,
        fontVariant: 'body1',
      },
      {
        name: metrics.TENDERS_CANCELED,
        displayName: 'Canceled',
        numberFormat: formattingTypes.INTEGER,
        fn: functions.SUM,
        fontVariant: 'body1',
      },
    ],
    layout: {
      x: 3,
      y: 0,
      i: v4(),
      ...chartLayoutRestrictions[chartTypes.LIST],
    },
  },
  {
    title: 'Service',
    chartType: chartTypes.LIST,
    metrics: [
      {
        name: metrics.TENDER_ACCEPTANCE,
        numberFormat: formattingTypes.PERCENT,
        fn: functions.SUM,
      },
      {
        name: metrics.TENDERS_ACCEPTED,
        displayName: 'Shipments',
        numberFormat: formattingTypes.INTEGER,
        fn: functions.SUM,
        fontVariant: 'body1',
        isDivider: true,
      },
      {
        name: metrics.ON_TIME_PICKUP_PERFORMANCE,
        displayName: 'Pickup performance',
        numberFormat: formattingTypes.PERCENT,
        fn: functions.SUM,
      },
      {
        name: metrics.ON_TIME_PICKUP_TOTAL_SHIPMENTS,
        displayName: 'Shipments',
        numberFormat: formattingTypes.INTEGER,
        fn: functions.SUM,
        fontVariant: 'body1',
        isDivider: true,
      },
      {
        name: metrics.ON_TIME_DELIVERY_PERFORMANCE,
        displayName: 'Delivery performance',
        numberFormat: formattingTypes.PERCENT,
        fn: functions.SUM,
      },
      {
        name: metrics.ON_TIME_DELIVERY_TOTAL_SHIPMENTS,
        displayName: 'Shipments',
        numberFormat: formattingTypes.INTEGER,
        fn: functions.SUM,
        fontVariant: 'body1',
      },
    ],
    layout: {
      x: 6,
      y: 0,
      i: v4(),
      ...chartLayoutRestrictions[chartTypes.LIST],
    },
  },
  {
    title: 'Expected savings',
    chartType: chartTypes.LIST,
    metrics: [
      {
        name: metrics.EXPECTED_SHIPPER_SAVINGS,
        displayName: 'Shipper savings',
        numberFormat: formattingTypes.DOLLAR,
        fn: functions.SUM,
      },
      {
        name: metrics.EXPECTED_SHIPPER_SAVINGS_SHIPMENTS,
        displayName: 'Shipments',
        numberFormat: formattingTypes.INTEGER,
        fn: functions.SUM,
        isDivider: true,
        fontVariant: 'body1',
      },
      {
        name: metrics.EXPECTED_MARGIN,
        displayName: 'Leaf margin',
        numberFormat: formattingTypes.DOLLAR,
        fn: functions.SUM,
      },
      {
        name: metrics.EXPECTED_MARGIN_SHIPMENTS,
        displayName: 'Shipments',
        numberFormat: formattingTypes.INTEGER,
        fn: functions.SUM,
        fontVariant: 'body1',
      },
      {
        name: metrics.EXPECTED_MARGIN_SHIPPER_SPEND,
        displayName: 'Shipper spend',
        numberFormat: formattingTypes.DOLLAR,
        fn: functions.SUM,
        fontVariant: 'body1',
      },
      {
        name: metrics.EXPECTED_MARGIN_CARRIER_SPEND,
        displayName: 'Carrier spend',
        numberFormat: formattingTypes.DOLLAR,
        fn: functions.SUM,
        fontVariant: 'body1',
      },
    ],
    layout: {
      x: 9,
      y: 0,
      i: v4(),
      ...chartLayoutRestrictions[chartTypes.LIST],
    },
  },
  // row 2
  {
    title: 'Performance by contract tier',
    chartType: chartTypes.TABLE,
    metrics: [
      {
        name: metrics.CONTRACTED_LH_RPM_SHIPPER,
        displayName: 'Avg. Shipper LH RPM',
        numberFormat: formattingTypes.RATE_PER_MILE,
        fn: functions.AVG,
      },
      {
        name: metrics.SHIPMENTS_SHIPPER_FAILED,
        displayName: 'Shipper missed',
        numberFormat: formattingTypes.INTEGER,
        fn: functions.SUM,
      },
      {
        name: metrics.SHIPMENTS_CARRIER_FAILED,
        displayName: 'Carrier missed',
        numberFormat: formattingTypes.INTEGER,
        fn: functions.SUM,
      },
      {
        name: metrics.TENDER_ACCEPTANCE,
        numberFormat: formattingTypes.PERCENT,
        fn: functions.SUM,
      },
      {
        name: metrics.ON_TIME_DELIVERY_PERFORMANCE,
        displayName: 'On-time delivery',
        numberFormat: formattingTypes.PERCENT,
        fn: functions.SUM,
      },
      {
        name: metrics.EXECUTED_SHIPMENTS,
        numberFormat: formattingTypes.INTEGER,
        fn: functions.SUM,
      },
      {
        name: metrics.ACTUAL_SHIPPER_SAVINGS,
        displayName: 'Actual shipper savings',
        numberFormat: formattingTypes.DOLLAR,
        fn: functions.SUM,
      },
      {
        name: metrics.ACTUAL_MARGIN_SHIPPER_SPEND,
        displayName: 'Actual shipper costs',
        numberFormat: formattingTypes.DOLLAR,
        fn: functions.SUM,
      },
      {
        name: metrics.ACTUAL_MARGIN_CARRIER_SPEND,
        displayName: 'Actual carrier costs',
        numberFormat: formattingTypes.DOLLAR,
        fn: functions.SUM,
      },
      {
        name: metrics.ACTUAL_MARGIN,
        displayName: 'Actual Leaf margin',
        numberFormat: formattingTypes.DOLLAR,
        fn: functions.SUM,
      },
    ],
    dimensions: [
      {
        name: dimensions.CONTRACT_TYPE,
      },
    ],
    order: {
      name: metrics.CONTRACTED_LH_RPM_SHIPPER,
      direction: order.DESCENDING,
    },
    layout: {
      x: 0,
      y: 1,
      i: v4(),
      ...chartLayoutRestrictions[chartTypes.TABLE],
      w: 12,
      h: 3,
    },
  },
  // row 3
  {
    title: 'Executed shipments by contract tier',
    chartType: chartTypes.BAR,
    metrics: [
      {
        name: metrics.SHIPMENTS_EXECUTED_DAY_OF,
        displayName: 'Day of',
        numberFormat: formattingTypes.INTEGER,
        fn: functions.SUM,
        colors: [{ from: 0, color: '#3B873E' }],
      },
      {
        name: metrics.SHIPMENTS_EXECUTED_WEEK_OF,
        displayName: 'Week of',
        numberFormat: formattingTypes.INTEGER,
        fn: functions.SUM,
        colors: [{ from: 0, color: '#7BC67E' }],
      },
      {
        name: metrics.SHIPMENTS_EXECUTED_UNCOMMITTED,
        displayName: 'Uncommitted',
        numberFormat: formattingTypes.INTEGER,
        fn: functions.SUM,
        colors: [{ from: 0, color: '#4A90E2' }],
      },
      {
        name: metrics.SHIPMENTS_EXECUTED_SPOT,
        displayName: 'Spot',
        numberFormat: formattingTypes.INTEGER,
        fn: functions.SUM,
        colors: [{ from: 0, color: '#FFB547' }],
      },
      {
        name: metrics.SHIPMENTS_IN_PROGRESS,
        displayName: 'In progress',
        numberFormat: formattingTypes.INTEGER,
        fn: functions.SUM,
        colors: [{ from: 0, color: '#E0E0E0' }],
      },
      {
        name: metrics.CONTRACTS_NOT_TENDERED,
        displayName: 'Expected',
        numberFormat: formattingTypes.INTEGER,
        fn: functions.SUM,
        colors: [{ from: 0, color: '#757575' }],
      },
    ],
    dimensions: [
      {
        name: dimensions.SHIPMENT_WEEK,
      },
    ],
    order: {
      name: dimensions.SHIPMENT_WEEK,
      direction: order.ASCENDING,
    },
    configurableDimensions: {
      [dimensions.SHIPMENT_WEEK]: [
        {
          name: 'Shipment year',
          id: dimensions.SHIPMENT_YEAR,
        },
        {
          name: 'Shipment month',
          id: dimensions.SHIPMENT_MONTH,
        },
        {
          name: 'Shipment week',
          id: dimensions.SHIPMENT_WEEK,
        },
        {
          name: 'Shipment date',
          id: dimensions.SHIPMENT_DATE,
        },
      ],
    },
    layout: {
      i: v4(),
      x: 0,
      y: 2,
      ...chartLayoutRestrictions[chartTypes.BAR],
      w: 12,
    },
  },
  // row 4
  {
    title: 'Performance by carrier',
    chartType: chartTypes.TABLE,
    metrics: [
      {
        name: metrics.EXECUTED_SHIPMENTS,
        numberFormat: formattingTypes.INTEGER,
        fn: functions.SUM,
      },
      {
        name: metrics.SHIPMENTS_SHIPPER_FAILED,
        displayName: 'Shipper missed shipments',
        numberFormat: formattingTypes.INTEGER,
        fn: functions.SUM,
      },
      {
        name: metrics.SHIPMENTS_CARRIER_FAILED,
        displayName: 'Carrier missed shipments',
        numberFormat: formattingTypes.INTEGER,
        fn: functions.SUM,
      },
      {
        name: metrics.ON_TIME_PICKUP_PERFORMANCE,
        displayName: 'Pickup performance',
        numberFormat: formattingTypes.PERCENT,
        fn: functions.SUM,
      },
      {
        name: metrics.ON_TIME_DELIVERY_PERFORMANCE,
        displayName: 'Delivery performance',
        numberFormat: formattingTypes.PERCENT,
        fn: functions.SUM,
      },
      {
        name: metrics.ACTUAL_SHIPPER_SAVINGS,
        displayName: 'Actual shipper savings',
        numberFormat: formattingTypes.DOLLAR,
        fn: functions.SUM,
      },
      {
        name: metrics.ACTUAL_MARGIN_CARRIER_SPEND,
        displayName: 'Actual shipper costs',
        numberFormat: formattingTypes.DOLLAR,
        fn: functions.SUM,
      },
      {
        name: metrics.ACTUAL_MARGIN_CARRIER_SPEND,
        displayName: 'Actual carrier costs',
        numberFormat: formattingTypes.DOLLAR,
        fn: functions.SUM,
      },
      {
        name: metrics.ACTUAL_MARGIN,
        displayName: 'Actual Leaf margin',
        numberFormat: formattingTypes.DOLLAR,
        fn: functions.SUM,
      },
    ],
    dimensions: [
      {
        name: dimensions.CARRIER_NAME,
      },
    ],
    order: {
      name: metrics.EXECUTED_SHIPMENTS,
      direction: order.DESCENDING,
    },
    layout: {
      x: 0,
      y: 3,
      i: v4(),
      ...chartLayoutRestrictions[chartTypes.TABLE],
      w: 12,
      h: 3,
    },
  },
  // row 5
  {
    title: 'Performance by lane',
    chartType: chartTypes.TABLE,
    metrics: [
      {
        name: metrics.EXECUTED_SHIPMENTS,
        numberFormat: formattingTypes.INTEGER,
        fn: functions.SUM,
      },
      {
        name: metrics.SHIPMENTS_SHIPPER_FAILED,
        displayName: 'Shipper missed shipments',
        numberFormat: formattingTypes.INTEGER,
        fn: functions.SUM,
      },
      {
        name: metrics.SHIPMENTS_CARRIER_FAILED,
        displayName: 'Carrier missed shipments',
        numberFormat: formattingTypes.INTEGER,
        fn: functions.SUM,
      },
      {
        name: metrics.ON_TIME_PICKUP_PERFORMANCE,
        displayName: 'Pickup performance',
        numberFormat: formattingTypes.PERCENT,
        fn: functions.SUM,
      },
      {
        name: metrics.ON_TIME_DELIVERY_PERFORMANCE,
        displayName: 'Delivery performance',
        numberFormat: formattingTypes.PERCENT,
        fn: functions.SUM,
      },
      {
        name: metrics.ACTUAL_SHIPPER_SAVINGS,
        displayName: 'Actual shipper savings',
        numberFormat: formattingTypes.DOLLAR,
        fn: functions.SUM,
      },
      {
        name: metrics.ACTUAL_MARGIN_CARRIER_SPEND,
        displayName: 'Actual shipper costs',
        numberFormat: formattingTypes.DOLLAR,
        fn: functions.SUM,
      },
      {
        name: metrics.ACTUAL_MARGIN_CARRIER_SPEND,
        displayName: 'Actual carrier costs',
        numberFormat: formattingTypes.DOLLAR,
        fn: functions.SUM,
      },
      {
        name: metrics.ACTUAL_MARGIN,
        displayName: 'Actual Leaf margin',
        numberFormat: formattingTypes.DOLLAR,
        fn: functions.SUM,
      },
    ],
    dimensions: [
      {
        name: dimensions.LANE,
      },
    ],
    order: {
      name: metrics.EXECUTED_SHIPMENTS,
      direction: order.DESCENDING,
    },
    layout: {
      x: 0,
      y: 4,
      i: v4(),
      ...chartLayoutRestrictions[chartTypes.TABLE],
      w: 12,
      h: 3,
    },
  },
];
