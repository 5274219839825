/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import memoize from 'memoize-one';

import DashboardContext from '../DashboardContext';
import { chartTypes, LOCAL_FILTERS_QUERY_PARAM_SEPARATOR, ORDER_QUERY_PARAM_SEPARATOR } from '../Constants';
import { date as dateTimeService } from '../../utility';
import { EmptyPage, withSafeSetState } from '../Common/Components';
import { Charts } from '.';
import ChartHeader from './Charts/ChartHeader';
import { calcConfigurableDimension } from './Charts/chartsHelper';
import { widgetConfigPropType } from './Charts/types';
import './Charts/shared.scss';
import { pickActiveFilters } from '../paramsHelper';
import { timeDimensions } from '../Constants/dimensions';
import ErrorBoundary from '../../ErrorBoundary';

const chartTypeToComponentMap = {
  [chartTypes.AREA]: Charts.DashboardArea,
  [chartTypes.PROGRESS_BAR]: Charts.DashboardProgressBars,
  [chartTypes.MAP]: Charts.DashboardMap,
  [chartTypes.KPI]: Charts.DashboardKpi,
  [chartTypes.CIRCULAR_PROGRESS_BAR]: Charts.DashboardCircularProgressBar,
  [chartTypes.LINE]: Charts.DashboardLine,
  [chartTypes.BAR]: Charts.DashboardBar,
  [chartTypes.DONUT]: Charts.DashboardDonut,
  [chartTypes.LIST]: Charts.DashboardListInfo,
  [chartTypes.TABLE]: Charts.DashboardTable,
  [chartTypes.PIVOT_TABLE]: Charts.DashboardPivotTable,
};

class Widget extends React.PureComponent {
  static wrapperStyle = { width: '100%', height: '100%' };

  api = this.context.api;

  state = {
    isLoading: true,
    failedToLoadData: false,
    chartData: undefined,
    prevConfig: undefined,
  };

  static getDerivedStateFromProps = (props, state) => {
    const { config } = props;
    return state.prevConfig !== config
      ? {
          selectedDimensionId: config.configurableDimensions
            ? calcConfigurableDimension(config.configurableDimensions)
            : undefined,
          selectedOrderId: config.order ? config.order.direction : undefined,
          prevConfig: config,
        }
      : null;
  };

  lastQueryString = '';

  calcQueryStringMemoized = memoize((filters, config, selectedOrderId, selectedDimensionId) => {
    const { metrics, order, chartType, filters: localFilters = [] } = config;
    const { dimensionOptions } = this.context.state;
    const metricsNames = metrics.map(m => m.name);
    const metricsFunctions = metrics.map(m => m.fn);
    const { from, to, ...nonDateFilters } = filters;
    const dimensions =
      chartType === chartTypes.PIVOT_TABLE
        ? [
            ...(config.pivotColumns?.map(({ name }) => name) ?? []),
            ...(config.pivotRows?.map(({ name }) => name) ?? []),
          ]
        : this.calcDimensions(config, selectedDimensionId);
    const orderName = order?.name && timeDimensions.includes(selectedDimensionId) ? selectedDimensionId : order?.name;

    // orderName can be either a metric name or a dimension name
    const [userSpecifiedOrderName, metricFunction = ''] = orderName?.split(ORDER_QUERY_PARAM_SEPARATOR) ?? [orderName];

    let orderMetricFunction = metricFunction ? `${ORDER_QUERY_PARAM_SEPARATOR}${metricFunction}` : '';

    const isSelectedOrderADimension =
      dimensionOptions.length > 0 ? dimensionOptions.map(({ name }) => name).includes(userSpecifiedOrderName) : true;
    if (userSpecifiedOrderName && !isSelectedOrderADimension && !metricFunction) {
      // fallback to first metrics function, for old user defined dashboard configs
      orderMetricFunction = `${ORDER_QUERY_PARAM_SEPARATOR}${metrics.find(m => m.name === userSpecifiedOrderName)?.fn}`;
    }

    const localFiltersRequest = {};
    localFilters?.forEach(({ name, value }) => {
      localFiltersRequest[name] = value;
    });
    const params = {
      global_filters: queryString.stringify(
        {
          ...nonDateFilters,
          from_date: dateTimeService.transformApiDate(filters.from),
          to_date: dateTimeService.transformApiDate(filters.to),
        },
        { encode: false },
      ),
      ...(localFilters?.length > 0 && {
        local_filters: queryString.stringify(localFiltersRequest, {
          encode: false,
        }),
      }),
      ...(dimensions?.length && { dimensions: [...dimensions] }),
      metrics: metricsNames.map((name, index) => `${name}:${metricsFunctions[index]}`),
      ...(userSpecifiedOrderName &&
        order?.direction && {
          order: `${userSpecifiedOrderName}${orderMetricFunction}:${selectedOrderId || order.direction}`,
        }),
    };
    return queryString.stringify(params);
  });

  componentDidMount() {
    const qs = this.calcQueryString();
    this.fetchChartData(qs);
  }

  componentDidUpdate() {
    const qs = this.calcQueryString();

    if (this.lastQueryString !== qs) {
      this.forceRefetchChartData(qs);
    }
  }

  calcDimensions = (config, selectedDimensionId) => {
    if (!config.dimensions || (config.dimensions.length === 1 && !config.dimensions[0])) return [];
    let finalDimensions = config.dimensions.map(dimension => dimension.name);
    if (config.configurableDimensions) {
      const configurableDimension = calcConfigurableDimension(config.configurableDimensions);
      finalDimensions = finalDimensions.map(d => (d === configurableDimension ? selectedDimensionId : d));
    }
    return finalDimensions;
  };

  calcQueryString = () => {
    const { selectedDimensionId, selectedOrderId } = this.state;
    const { config } = this.props;
    const filters = pickActiveFilters(this.context.state);
    return this.calcQueryStringMemoized(filters, config, selectedOrderId, selectedDimensionId);
  };

  fetchChartData = qs => {
    this.lastQueryString = qs;
    this.api
      .getWidgetData(qs)
      .then(response => {
        this.setState({
          chartData: response.data ?? response, // backward compatible
          isLoading: false,
          failedToLoadData: false,
        });
      })
      .catch(response => {
        this.context.onErrorHandler(response);
        this.setState({
          chartData: undefined,
          failedToLoadData: true,
          isLoading: false,
        });
        if (!response.response) {
          throw response;
        }
      });
  };

  renderSpinner = () => (
    <EmptyPage className="margin-top-md" hasSpinner textLine1="Loading..." textLine2="(Please wait)" />
  );

  handleSelectDimension = id => this.setState({ selectedDimensionId: id });

  handleSelectOrder = id => this.setState({ selectedOrderId: id });

  forceRefetchChartData = queryString => {
    let qs = queryString;
    if (typeof qs !== 'string') {
      qs = this.calcQueryString();
    }
    this.setState(
      {
        isLoading: true,
        failedToLoadData: false,
        chartData: undefined,
      },
      () => this.fetchChartData(qs),
    );
  };

  renderErrorMessage = () => (
    <EmptyPage
      className="margin-top-md"
      textLine1="Failed to load data for this widget"
      hasActionLink
      actionLinkText="Retry"
      onActionLinkClick={this.forceRefetchChartData}
    />
  );

  renderWidget = () => {
    const { failedToLoadData, selectedOrderId, selectedDimensionId, chartData } = this.state;
    if (failedToLoadData) {
      return this.renderErrorMessage();
    }

    const { config } = this.props;
    const { metricsOptions, dimensionOptions } = this.context.state;

    const Comp = chartTypeToComponentMap[config.chartType];

    return (
      <Comp
        config={config}
        data={chartData}
        metricsOptions={metricsOptions}
        dimensionOptions={dimensionOptions}
        selectedDimensionId={selectedDimensionId}
        onDimensionSelect={this.handleSelectDimension}
        selectedOrderId={selectedOrderId}
        onOrderSelect={this.handleSelectOrder}
      />
    );
  };

  render() {
    const { config, onWidgetEdit, onWidgetDelete, canEdit } = this.props;
    const { isLoading, selectedOrderId, selectedDimensionId } = this.state;
    return (
      <div style={Widget.wrapperStyle} className="flex-column">
        <header className="dashboard-header flex-shrink">
          <ChartHeader
            config={config}
            onEdit={onWidgetEdit}
            onDelete={onWidgetDelete}
            canEdit={canEdit}
            selectedDimensionId={selectedDimensionId}
            onDimensionSelect={this.handleSelectDimension}
            selectedOrderId={selectedOrderId}
            onOrderSelect={this.handleSelectOrder}
          />
        </header>
        <ErrorBoundary message="Widget failed to load">
          {isLoading ? this.renderSpinner() : this.renderWidget()}
        </ErrorBoundary>
      </div>
    );
  }
}

Widget.contextType = DashboardContext;

Widget.propTypes = {
  filters: PropTypes.shape({}).isRequired,
  config: widgetConfigPropType.isRequired,
  onWidgetEdit: PropTypes.func.isRequired,
  onWidgetDelete: PropTypes.func.isRequired,
  canEdit: PropTypes.boolean,
};

Widget.defaultProps = {
  canEdit: false,
};

export default withSafeSetState(Widget);
