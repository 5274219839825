// colors: [{from: 0, color: "#E82100"}, {from: 33, color: "#FFC300"}, {from: 66, color: "#1DD264"}]

import { date as dateTimeService } from '../../../utility';
import dimensions from '../../Constants/dimensions';

const colorsCompareFn = (a, b) => a.from - b.from;

export const calcColorForValue = (value, colorsArr) => {
  if (!colorsArr) {
    return '#000000';
    // throw new Error('You need to define colors for metric!');
  }
  colorsArr.sort(colorsCompareFn);
  let currentColor = colorsArr[0].color;
  for (let i = 0; i < colorsArr.length; i += 1) {
    if (value > colorsArr[i].from) {
      currentColor = colorsArr[i].color;
    } else break;
  }
  return currentColor;
};

export const mapMonthDimensions = values => values.map(v => dateTimeService.to3LetterMonthAndYear(new Date(v)));
export const mapWeekDimensions = values => values.map(v => `Week ${v.substring(5)} \`${v.substring(2, 4)}`);

const identityFunction = v => v;

export const dimensionToFunctionMap = {
  [dimensions.SHIPMENT_MONTH]: mapMonthDimensions,
  [dimensions.SHIPMENT_WEEK]: mapWeekDimensions,
  [dimensions.SHIPMENT_DATE]: identityFunction,
  [dimensions.SHIPMENT_YEAR]: identityFunction,
  [dimensions.ORIGIN]: identityFunction,
  [dimensions.DESTINATION]: identityFunction,
  [dimensions.LANE]: identityFunction,
  [dimensions.CARRIER_NAME]: identityFunction,
  [dimensions.SHIPPER_NAME]: identityFunction,
};

export const getLabels = dimension => {
  const mapFn = dimensionToFunctionMap[dimension.dimension] || identityFunction;
  return mapFn(dimension.values);
};

export const calcConfigurableDimension = configurableDimensions => Object.keys(configurableDimensions)[0];

export const applyIfNotNull = (v, func) => {
  if (v !== null && v !== 'null') {
    return func(v);
  }

  return v;
};
