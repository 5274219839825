import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { Grid } from '@mui/material';

export const SpacedContainer = styled(Grid, {
  shouldForwardProp: prop => prop !== 'autoWidth',
})`
  margin: ${({ theme }) => `0px ${theme.spacing(2)} ${theme.spacing(1)} 0px`};
  ${({ autoWidth }) =>
    autoWidth &&
    css`
      width: auto;
    `}
`;

const GridItem = styled(Grid)`
  width: calc(100vw - 60px - 32px);
`;

export default ({ children }) => (
  <Grid container>
    <GridItem xs={12} item>
      {children}
    </GridItem>
  </Grid>
);
