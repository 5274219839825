/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import _ from 'lodash';
import { Section } from '../AddEditWidgetModal.styled';
import Button from '../../../Button';
import Title from './Title';
import { Autocomplete, Input, Select } from '../../../form/library';
import { getPivotDimensionOptions, getSwapInputProps } from '../../utility';
import { chartTypes, fontVariants } from '../../Constants';

export default ({
  arrayFields,
  control,
  errors,
  dimensionOptions,
  hasFontVariantOption,
  chartType,
  dimensionRows,
  dimensionColumns,
  name,
}) => {
  const { fields, append, remove, swap } = arrayFields;
  const fieldsName = name ?? 'dimensions';
  return (
    <div>
      {fields.map((field, index) => (
        <Section boxShadow={2} p={2} mt={2} mb={2} key={field.id}>
          <Title title={`Dimension #${index + 1}`} {...getSwapInputProps(fields, index, swap, remove)} />
          <Autocomplete
            name={`${fieldsName}[${index}].name`}
            label="Name"
            inputProps={{ error: _.get(errors, `${fieldsName}[${index}].name`) }}
            options={
              chartType === chartTypes.PIVOT_TABLE
                ? getPivotDimensionOptions(dimensionRows, dimensionColumns, dimensionOptions, fields[index]).map(
                    ({ key }) => key,
                  )
                : dimensionOptions.map(({ key }) => key)
            }
            getOptionLabel={option => dimensionOptions.find(({ key }) => option === key)?.text}
            isOptionEqualToValue={(option, value) => option === value}
            control={control}
            defaultValue={field.name}
          />
          <Input
            type="text"
            name={`${fieldsName}[${index}].displayName`}
            label="Display name"
            control={control}
            error={_.get(errors, `${fieldsName}[${index}].displayName`)}
            defaultValue={field.displayName}
          />
          {hasFontVariantOption && (
            <Select
              label="Font variant"
              control={control}
              name={`${fieldsName}[${index}].fontVariant`}
              options={fontVariants}
              placeholder="Regular"
            />
          )}
        </Section>
      ))}
      <Button onClick={() => append({})} color="primary">
        ADD DIMENSION
      </Button>
    </div>
  );
};
