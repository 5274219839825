import React, { useCallback } from 'react';
import { Box, Tab as MuiTab, Tabs as MuiTabs } from '@mui/material';
import styled from '@emotion/styled';
import { css } from '@emotion/react';

import { TAB_HEADER_HEIGHT } from '../constants';

const TabDefault = styled(MuiTab, {
  shouldForwardProp: prop => prop !== '$tabWidth',
})`
  height: ${TAB_HEADER_HEIGHT}px;
  min-height: ${TAB_HEADER_HEIGHT}px;
  ${({ $tabWidth }) =>
    $tabWidth &&
    css`
      min-width: ${$tabWidth}px;
    `}
`;

const TabsDefault = styled(MuiTabs)`
  height: ${TAB_HEADER_HEIGHT}px;
  min-height: ${TAB_HEADER_HEIGHT}px;
  background-color: white;
  box-sizing: border-box;
`;

const TabsWithColor = styled(TabsDefault, {
  shouldForwardProp: prop => prop !== '$isSecondary' && prop !== '$hasPadding' && prop !== '$isStickyHeader',
})`
  background-color: ${({ $isSecondary, theme }) => ($isSecondary ? theme.palette.background.default : 'white')};
  padding-left: ${({ $hasPadding, theme }) => ($hasPadding ? theme.spacing(2) : 0)};
  padding-right: ${({ $hasPadding, theme }) => ($hasPadding ? theme.spacing(2) : 0)};
  ${({ $isStickyHeader }) =>
    $isStickyHeader &&
    css`
      position: sticky;
      top: 0;
      z-index: 1;
    `};
`;

const Panel = styled(Box)`
  flex-grow: 1;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
`;

const PanelSC = styled(Panel, {
  shouldForwardProp: prop => prop !== '$isStickyHeader',
})`
  background-color: ${({ theme }) => `${theme.palette.background.default}`};
  ${({ $isStickyHeader }) =>
    $isStickyHeader &&
    css`
      overflow: visible;
    `};
`;

const TabsWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const TabPanel = ({ children, value, index, $isStickyHeader }) =>
  value === index ? <PanelSC $isStickyHeader={$isStickyHeader}>{children}</PanelSC> : null;

const splitJoin = (value, splitSeparator = ' ', joinSeparator = '_') =>
  value.split(splitSeparator).join(joinSeparator).toLowerCase();

const getDefaultQueryTabValue = tabs => ({ label: tabs[0].label, value: splitJoin(tabs[0].label), index: 0 });
const Tabs = ({
  tabs,
  history,
  location,
  mainUrlPath,
  queryTabKey = 'tab',
  isSecondary = false,
  hasPadding = false,
  isStickyHeader = false,
  tabWidth,
}) => {
  const setInitialValueFromQuery = () => {
    if (location.search && location.search.includes(`?${queryTabKey}=`)) {
      const queryTabValue = location.search.split('=').pop();
      const value = splitJoin(queryTabValue, '_', ' ');
      const index = tabs.findIndex(t => t.label.toLowerCase() === value);
      const tab = {
        ...(tabs.find(t => t.label.toLowerCase() === value) ?? tabs[0]),
        index: index !== -1 ? index : 0,
      };
      return {
        label: tab.label,
        value: splitJoin(tab.label),
        index: tab.index,
      };
    }
    const defaultContext = getDefaultQueryTabValue(tabs);
    return {
      label: tabs.find(t => t.label === defaultContext.label).label,
      value: defaultContext.value,
      index: tabs.findIndex(t => t.label === defaultContext.label),
    };
  };

  const [tabContext, setTabContext] = React.useState(setInitialValueFromQuery());
  const handleChange = useCallback(
    (_event, newValue) => {
      setTabContext({
        label: tabs[newValue].label,
        value: splitJoin(tabs[newValue].label),
        index: newValue,
      });
    },
    [tabs],
  );

  React.useEffect(() => {
    if (mainUrlPath) {
      history.replace(`${mainUrlPath}?${queryTabKey}=${tabContext.value}`);
    }
  }, [tabContext]);

  React.useEffect(() => {
    if (tabContext.index < tabs.length) {
      setTabContext(tabContext);
    } else {
      setTabContext(getDefaultQueryTabValue(tabs));
    }
  }, [location.search]);

  return (
    <TabsWrapper>
      <TabsWithColor
        onChange={handleChange}
        value={tabContext.index}
        $isSecondary={isSecondary}
        $hasPadding={hasPadding}
        $isStickyHeader={isStickyHeader}
      >
        {tabs.map(({ label }) => (
          <TabDefault $tabWidth={tabWidth} key={label} label={label} />
        ))}
      </TabsWithColor>
      {tabs.map(({ content }, index) => (
        <TabPanel value={tabContext.index} index={index} key={index} $isStickyHeader={isStickyHeader}>
          {content}
        </TabPanel>
      ))}
    </TabsWrapper>
  );
};

export default Tabs;
