export const TRIP_TYPE = {
  ONE_WAY: 'ONE_WAY',
  ROUND_TRIP: 'ROUND_TRIP',
  CONTINUOUS_MOVE: 'CONTINUOUS_MOVE',
  CIRCUIT: 'CIRCUIT',
  SHORTY: 'SHORTY',
};

export const TRIP_TYPE_DISPLAY_NAMES = {
  ONE_WAY: 'One way',
  ROUND_TRIP: 'Round trip',
  CONTINUOUS_MOVE: 'Continous move',
  CIRCUIT: 'Circuit',
  SHORTY: 'Shorty continuous move',
};
