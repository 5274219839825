import React from 'react';
import { ReportProblemOutlined as MissingIcon } from '@mui/icons-material';
import { Grid, Typography } from '@mui/material';
import styled from '@emotion/styled';

import Card from 'company/components/Card';

const Missing = styled(Grid)`
  display: flex;
  align-items: center;

  & :first-child: {
    padding-right: 1em;
  }
`;

const Metric = ({ data }) => {
  if (data.missing) {
    const Title = (
      <>
        <Typography variant="body1">{data.name}</Typography>

        <Missing>
          <MissingIcon />
        </Missing>
      </>
    );
    return <Card title={Title} disabled />;
  }
  const Title = (
    <>
      <Typography variant="body1">{data.name}</Typography>

      <Typography variant="h5">{data.view.value}</Typography>
    </>
  );
  return <Card title={Title} />;
};

export default Metric;
