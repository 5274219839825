/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { FormControlLabel } from '@mui/material';
import { Controller } from 'react-hook-form';
import { Checkbox } from '../generic';

export default ({ name, label, defaultValue, control, ...rest }) => (
  <FormControlLabel
    label={label}
    labelPlacement="end"
    control={
      <Controller
        name={name}
        render={props => (
          <Checkbox {...props} onChange={e => props.onChange(e.target.checked)} checked={props.value} {...rest} />
        )}
        defaultValue={defaultValue}
        control={control}
      />
    }
  />
);
