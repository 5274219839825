import React from 'react';
import { Chip, IconButton, Tooltip } from '@mui/material';
import styled from '@emotion/styled';
import { v4 } from 'uuid';
import { ContractType, NewTabLink, utility, Lane, TripType, LocationFilter } from '@leaf/components';
import { Link as LinkIcon } from '@mui/icons-material';
import AdaptLane from 'domain/adapt/AdaptLane';

const { ActionButtons } = utility.table;

const RouteChip = styled(Chip)`
  border-radius: 20%;
  background-color: #001530;
  color: white;
  font-weight: 500;
`;

export const LaneWrapper = styled.div`
  margin-bottom: ${({ isSingleRow, theme }) => `${!isSingleRow ? theme.spacing(1) : 0}`};
  .MuiTypography-body2 {
    text-overflow: ellipsis;
    overflow: hidden;
    width: 220px;
    white-space: nowrap;
  }
`;

export const getQuickFilters = () => [
  {
    label: 'Time',
    name: 'timeStatuses',
    values: ['PAST', 'ONGOING', 'FUTURE'],
    inputType: 'toggle-button',
  },
];

export const getOptions = history => ({
  filter: true,
  onRowClick: rowData => {
    const id = rowData[0];
    history.push(`/contracts/${id}`);
  },
  ...utility.table.getRowHoverActionProps('contract'),
});

export const getColumns = (filterOptions, companyType, copy) => [
  {
    label: 'ID',
    name: 'id',
    options: {
      filter: false,
      sort: false,
      display: false,
    },
  },
  {
    label: 'Contract Type',
    name: 'contractType',
    field: 'contract_type',
    options: {
      sort: true,
      display: companyType === 'CARRIER',
      ...filterOptions.contractType,
      customBodyRender: type => <ContractType type={type} />,
    },
  },
  {
    label: 'Contract Weekly Volume',
    name: 'maxVolume',
    options: {
      filter: false,
      sort: false,
      ...utility.table.rightAlignColumnProps(),
    },
  },
  {
    label: 'Equipment Type',
    name: 'equipmentType',
    options: {
      ...filterOptions.equipmentType,
      display: false,
      sort: false,
    },
  },
  {
    label: 'Move Type',
    name: 'tripTypes',
    options: {
      ...filterOptions.tripTypes,
      sort: false,
      display: companyType === 'CARRIER',
      customBodyRender: type => <TripType type={type} />,
    },
  },
  {
    label: 'Accessorials',
    name: 'accessorials',
    options: {
      ...filterOptions.accessorials,
      sort: false,
      display: false,
      customBodyRender: accessorials => accessorials.join(', '),
    },
  },
  {
    label: 'Routes',
    name: 'routes',
    options: {
      display: false,
      filter: false,
      sort: false,
      customBodyRender: routes => <RouteChip size="small" label={routes} />,
    },
  },
  {
    label: 'Display Route',
    name: 'contractLanes',
    options: {
      filter: false,
      sort: false,
      customBodyRender: lanes =>
        lanes?.map(({ lane: { origin, destination, laneType, shipper } }) => (
          <LaneWrapper key={v4()} isSingleRow={lanes.length === 1}>
            <Lane
              displayAsGrid
              type={laneType}
              origin={{ primaryContent: [origin] }}
              destination={{ primaryContent: [destination] }}
              shipper={shipper}
            />
          </LaneWrapper>
        )),
    },
  },
  {
    label: 'Origin',
    name: 'origin',
    options: {
      sort: false,
      display: 'excluded',
      filterType: 'custom',
      customFilterListOptions: {
        render: v => {
          if (v?.length === 4) return `Origin: ${v[3]} (${v[2]} miles)`;
          return undefined;
        },
      },
      filterOptions: {
        display: (filterList, onChange, index, column) => (
          <LocationFilter
            filterList={filterList}
            onChange={onChange}
            index={index}
            column={column}
            getLocations={AdaptLane.getLocation}
            defaultRadius={100}
          />
        ),
      },
    },
  },
  {
    label: 'Destination',
    name: 'destination',
    options: {
      sort: false,
      display: 'excluded',
      filterType: 'custom',
      customFilterListOptions: {
        render: v => {
          if (v?.length === 4) return `Destination: ${v[3]} (${v[2]} miles)`;
          return undefined;
        },
      },
      filterOptions: {
        display: (filterList, onChange, index, column) => (
          <LocationFilter
            filterList={filterList}
            onChange={onChange}
            index={index}
            column={column}
            getLocations={AdaptLane.getLocation}
            defaultRadius={100}
          />
        ),
      },
    },
  },
  {
    label: 'Range Date',
    name: 'rangeDate',
    options: {
      filter: false,
      sort: false,
      display: 'excluded',
      ...filterOptions.rangeDate,
      customFilterListOptions: {
        render: v => v.map(l => l).join(' - '),
      },
    },
  },
  {
    label: 'Start Date',
    name: 'startDate',
    field: 'start_date',
    options: {
      filter: false,
    },
  },
  {
    label: 'End Date',
    name: 'endDate',
    field: 'end_date',
    options: {
      filter: false,
    },
  },
  {
    label: 'Linehaul RPM',
    name: 'linehaulRpm',
    field: 'linehaul_rpm',
    options: {
      filter: false,
      ...utility.table.rightAlignColumnProps(),
    },
  },
  {
    label: ' ',
    name: 'actionsData',
    options: {
      sort: false,
      filter: false,
      viewColumns: false,
      ...utility.table.rightStickyColumnProps,
      customBodyRender: actionsData => {
        const { id } = actionsData;
        const text = `${window.location.host}/contracts/${id}`;
        return (
          <ActionButtons id={`Actions-contract-${id}`}>
            <Tooltip title="Copy Contract Link">
              <IconButton
                onClick={event => {
                  event.stopPropagation();
                  copy(text);
                }}
              >
                <LinkIcon />
              </IconButton>
            </Tooltip>
            <NewTabLink url={`/contracts/${id}`} />
          </ActionButtons>
        );
      },
    },
  },
];
