import React from 'react';
import styled from '@emotion/styled';
import { ArrowRightAlt } from '@mui/icons-material';

const IconWrapper = styled(ArrowRightAlt, {
  shouldForwardProp: prop => prop !== '$iconColor' && prop !== '$iconSize',
})`
  ${({ $iconSize, $iconColor }) => `
    &&& {
      height: ${$iconSize}px;
      width: ${$iconSize}px;
      color: ${$iconColor};
    }
  `}
`;

const PointToPointIcon = ({ iconColor = 'rgba(0,0,0,0.54)', iconSize = 24 }) => (
  <IconWrapper $iconColor={iconColor} $iconSize={iconSize} />
);

export default PointToPointIcon;
