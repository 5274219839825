import React from 'react';

const PowerlaneIcon = ({ color = 'currentColor', size = 24 }) => (
  <svg width={size} height={size} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      opacity="0.8"
      d="M12.6737 7.33325H4.66699V8.66659H12.6737V10.6666L15.3337 7.99992L12.6737 5.33325V7.33325Z"
      fill={color}
      fillOpacity="0.87"
    />
    <path
      opacity="0.3"
      d="M9.67366 2.66675H1.66699V4.00008H9.67366V6.00008L12.3337 3.33341L9.67366 0.666748V2.66675Z"
      fill={color}
      fillOpacity="0.87"
    />
    <path
      opacity="0.3"
      d="M9.67366 12H1.66699V13.3333H9.67366V15.3333L12.3337 12.6667L9.67366 10V12Z"
      fill={color}
      fillOpacity="0.87"
    />
  </svg>
);

export default PowerlaneIcon;
