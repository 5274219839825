import React, { useContext } from 'react';
import { Page, BackButton } from '@leaf/components';
import { withRouter } from 'react-router-dom';
import { Box, Typography } from '@mui/material';
import styled from '@emotion/styled';
import { UserContext } from 'authentication/UserProvider';
import { getTitles } from '../utilities';
import ShipmentDetailsContainer from './ShipmentDetailsContainer';

const ShipmentDetailsWrapper = styled.div`
  ${({ theme }) => `
		padding: ${theme.spacing(2)};
		background-color: ${theme.palette.background.default};
		height: calc(100vh - 60px);
		overflow-y: auto;
		width: 100%;
	`}
`;

const Title = styled(Typography)`
  text-transform: uppercase;
`;

const ShipmentDetails = ({
  match: {
    params: { id },
  },
  history,
}) => {
  const { currentUser } = useContext(UserContext);
  const companyType = currentUser?.company?.type;
  return (
    <Page
      isUppercase={false}
      ComponentTitleLeft={
        <Box display="flex">
          <BackButton history={history} path="/shipments" />
          {companyType && (
            <Box display="flex" flexDirection="column" justifyContent="center">
              <Title variant="h6">{getTitles(companyType, 'detailsTitle')}</Title>
              <Typography variant="body1">ID: #{id}</Typography>
            </Box>
          )}
        </Box>
      }
    >
      <ShipmentDetailsWrapper>
        <ShipmentDetailsContainer companyType={companyType} />
      </ShipmentDetailsWrapper>
    </Page>
  );
};

export default withRouter(ShipmentDetails);
