export const CONTRACT_TYPE = {
  SPOT: 'SPOT',
  UNCOMMITTED: 'UNCOMMITTED',
  DAY_OF: 'DAY_OF',
  WEEK_OF: 'WEEK_OF',
  NETWORK: 'NETWORK',
  DEDICATED: 'DEDICATED',
  FLEET: 'FLEET',
  OTHER: 'OTHER',
};

export const CONTRACT_TYPE_DISPLAY_NAMES = {
  [CONTRACT_TYPE.SPOT]: 'Spot',
  [CONTRACT_TYPE.UNCOMMITTED]: 'Uncommitted',
  [CONTRACT_TYPE.DAY_OF]: 'Day of',
  [CONTRACT_TYPE.WEEK_OF]: 'Week of',
  [CONTRACT_TYPE.NETWORK]: 'Network',
  [CONTRACT_TYPE.DEDICATED]: 'Dedicated',
  [CONTRACT_TYPE.FLEET]: 'Fleet',
  [CONTRACT_TYPE.OTHER]: 'Other',
};

export const CONTRACT_TYPE_COLORS = {
  [CONTRACT_TYPE.SPOT]: '#FFB547',
  [CONTRACT_TYPE.UNCOMMITTED]: '#4A90E2',
  [CONTRACT_TYPE.DAY_OF]: '#3B873E',
  [CONTRACT_TYPE.WEEK_OF]: '#7BC67E',
  [CONTRACT_TYPE.NETWORK]: '#FFB547',
  [CONTRACT_TYPE.DEDICATED]: '#0000007F',
  [CONTRACT_TYPE.FLEET]: '#E0E0E0',
  [CONTRACT_TYPE.OTHER]: '#BDBDBD',
};
