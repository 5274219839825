import { date } from '../../utility/format';

class Meta {
  constructor({ batchDate, batchDateLatest, earliestShipment, latestShipment, ...rest }) {
    Object.assign(this, {
      ...rest,
      batchDate: date(batchDate),
      batchDateLatest: date(batchDateLatest),
      earliestShipment: date(earliestShipment),
      latestShipment: date(latestShipment),
    });
  }

  static get = async (client, companyId, laneId) =>
    client.get(`/view/adapt/explorer/shippers/${companyId}/lanes/${laneId}/meta`).then(response => new Meta(response));
}

export default Meta;
