import { useState, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { isUserAllowedToEditDashboard } from 'features';

export default () => {
  const { getAccessTokenSilently } = useAuth0();
  const [canEdit, setCanEdit] = useState(false);

  useEffect(() => {
    getAccessTokenSilently().then(isUserAllowedToEditDashboard).then(setCanEdit);
  }, []);

  return { canEdit };
};
