import { v4 } from 'uuid';
import metrics from '../../metrics';

import chartTypes from '../../chartTypes';
import dimensions from '../../dimensions';
import functions from '../../functions';
import chartLayoutRestrictions from '../../chartLayoutRestrictions';
import { formattingTypes } from '../../../../utility/formattingHelper';
import order from '../../order';

export default [
  // row 1
  {
    title: 'Shipments executed',
    chartType: chartTypes.KPI,
    metrics: [
      {
        name: metrics.EXECUTED_SHIPMENTS,
        numberFormat: formattingTypes.INTEGER,
        fn: functions.SUM,
      },
    ],
    layout: {
      x: 0,
      y: 0,
      i: v4(),
      ...chartLayoutRestrictions[chartTypes.KPI],
    },
  },
  {
    title: 'Expected spend',
    chartType: chartTypes.KPI,
    formattingType: formattingTypes.DOLLARS_IN_THOUSANDS,
    metrics: [
      {
        name: metrics.EXPECTED_SHIPPER_SPEND,
        numberFormat: formattingTypes.DOLLAR,
        fn: functions.SUM,
      },
    ],
    layout: {
      x: 3,
      y: 0,
      i: v4(),
      ...chartLayoutRestrictions[chartTypes.KPI],
    },
  },
  {
    title: 'Expected savings',
    chartType: chartTypes.KPI,
    formattingType: formattingTypes.DOLLARS_IN_THOUSANDS,
    metrics: [
      {
        name: metrics.EXPECTED_SHIPPER_SAVINGS,
        numberFormat: formattingTypes.DOLLAR,
        fn: functions.SUM,
      },
    ],
    layout: {
      x: 6,
      y: 0,
      i: v4(),
      ...chartLayoutRestrictions[chartTypes.KPI],
    },
  },
  {
    title: 'Expected CO2 reduction',
    chartType: chartTypes.KPI,
    formattingType: formattingTypes.TONNES_IN_THOUSANDS,
    metrics: [
      {
        name: metrics.EXECUTED_CO2_REDUCTION_TONS,
        numberFormat: formattingTypes.TONS,
        fn: functions.SUM,
      },
    ],
    layout: {
      x: 9,
      y: 0,
      i: v4(),
      ...chartLayoutRestrictions[chartTypes.KPI],
    },
  },
  // row 2
  {
    chartType: chartTypes.CIRCULAR_PROGRESS_BAR,
    title: 'On-time delivery',
    metrics: [
      {
        name: metrics.ON_TIME_DELIVERY_PERFORMANCE,
        numberFormat: formattingTypes.PERCENT,
        fn: functions.SUM,
        colors: [
          { from: 0, color: '#E82100' },
          { from: 80, color: '#FFC300' },
          { from: 90, color: '#1DD264' },
        ],
      },
    ],
    layout: {
      i: v4(),
      x: 0,
      y: 1,
      ...chartLayoutRestrictions[chartTypes.CIRCULAR_PROGRESS_BAR],
    },
  },
  {
    chartType: chartTypes.CIRCULAR_PROGRESS_BAR,
    title: 'Tender acceptance',
    metrics: [
      {
        name: metrics.TENDER_ACCEPTANCE,
        numberFormat: formattingTypes.PERCENT,
        fn: functions.SUM,
        colors: [
          { from: 0, color: '#E82100' },
          { from: 80, color: '#FFC300' },
          { from: 90, color: '#1DD264' },
        ],
      },
    ],
    layout: {
      i: v4(),
      x: 3,
      y: 1,
      ...chartLayoutRestrictions[chartTypes.CIRCULAR_PROGRESS_BAR],
    },
  },
  {
    title: 'Shipper missed',
    chartType: chartTypes.KPI,
    metrics: [
      {
        name: metrics.SHIPMENTS_SHIPPER_FAILED,
        numberFormat: formattingTypes.INTEGER,
        fn: functions.SUM,
      },
    ],
    layout: {
      x: 6,
      y: 1,
      i: v4(),
      ...chartLayoutRestrictions[chartTypes.KPI],
    },
  },
  {
    title: 'Carrier missed',
    chartType: chartTypes.KPI,
    metrics: [
      {
        name: metrics.SHIPMENTS_CARRIER_FAILED,
        numberFormat: formattingTypes.INTEGER,
        fn: functions.SUM,
      },
    ],
    layout: {
      x: 9,
      y: 1,
      i: v4(),
      ...chartLayoutRestrictions[chartTypes.KPI],
    },
  },
  // row 3
  {
    chartType: chartTypes.BAR,
    title: 'Shipments by tier',
    metrics: [
      {
        name: metrics.SHIPMENTS_EXECUTED_DAY_OF,
        numberFormat: formattingTypes.INTEGER,
        fn: functions.SUM,
        colors: [{ from: 0, color: '#3B873E' }],
      },
      {
        name: metrics.SHIPMENTS_EXECUTED_WEEK_OF,
        numberFormat: formattingTypes.INTEGER,
        fn: functions.SUM,
        colors: [{ from: 0, color: '#7BC67E' }],
      },
      {
        name: metrics.SHIPMENTS_EXECUTED_UNCOMMITTED,
        numberFormat: formattingTypes.INTEGER,
        fn: functions.SUM,
        colors: [{ from: 0, color: '#4A90E2' }],
      },
      {
        name: metrics.SHIPMENTS_EXECUTED_SPOT,
        numberFormat: formattingTypes.INTEGER,
        fn: functions.SUM,
        colors: [{ from: 0, color: '#FFB547' }],
      },
    ],
    dimensions: [
      {
        name: dimensions.SHIPMENT_WEEK,
      },
    ],
    order: {
      name: dimensions.SHIPMENT_WEEK,
      direction: order.DESCENDING,
    },
    configurableDimensions: {
      [dimensions.SHIPMENT_WEEK]: [
        {
          name: 'Shipment year',
          id: dimensions.SHIPMENT_YEAR,
        },
        {
          name: 'Shipment month',
          id: dimensions.SHIPMENT_MONTH,
        },
        {
          name: 'Shipment week',
          id: dimensions.SHIPMENT_WEEK,
        },
        {
          name: 'Shipment date',
          id: dimensions.SHIPMENT_DATE,
        },
      ],
    },
    layout: {
      i: v4(),
      x: 0,
      y: 2,
      ...chartLayoutRestrictions[chartTypes.BAR],
    },
  },
  {
    chartType: chartTypes.LINE,
    title: 'On-time performance',
    metrics: [
      {
        name: metrics.ON_TIME_PICKUP_PERFORMANCE,
        numberFormat: formattingTypes.PERCENT,
        fn: functions.SUM,
        colors: [{ from: 0, color: '#4A90E2' }],
      },
      {
        name: metrics.ON_TIME_DELIVERY_PERFORMANCE,
        numberFormat: formattingTypes.PERCENT,
        fn: functions.SUM,
        colors: [{ from: 0, color: '#183450' }],
      },
    ],
    dimensions: [
      {
        name: dimensions.SHIPMENT_WEEK,
      },
    ],
    order: {
      name: dimensions.SHIPMENT_WEEK,
      direction: order.DESCENDING,
    },
    configurableDimensions: {
      [dimensions.SHIPMENT_WEEK]: [
        {
          name: 'Shipment year',
          id: dimensions.SHIPMENT_YEAR,
        },
        {
          name: 'Shipment month',
          id: dimensions.SHIPMENT_MONTH,
        },
        {
          name: 'Shipment week',
          id: dimensions.SHIPMENT_WEEK,
        },
        {
          name: 'Shipment date',
          id: dimensions.SHIPMENT_DATE,
        },
      ],
    },
    layout: {
      i: v4(),
      x: 6,
      y: 2,
      ...chartLayoutRestrictions[chartTypes.LINE],
    },
  },
  // row 4
  {
    chartType: chartTypes.PROGRESS_BAR,
    title: 'Delivery performance by carrier',
    metrics: [
      {
        name: metrics.ON_TIME_DELIVERY_PERFORMANCE,
        numberFormat: formattingTypes.PERCENT,
        fn: functions.SUM,
        colors: [
          { from: 0, color: '#E82100' },
          { from: 80, color: '#FFC300' },
          { from: 90, color: '#1DD264' },
        ],
      },
    ],
    isPercentBased: true,
    dimensions: [
      {
        name: dimensions.CARRIER_NAME,
      },
    ],
    order: {
      name: metrics.ON_TIME_DELIVERY_PERFORMANCE,
      direction: order.DESCENDING,
    },
    layout: {
      i: v4(),
      x: 0,
      y: 4,
      ...chartLayoutRestrictions[chartTypes.PROGRESS_BAR],
    },
  },
  {
    chartType: chartTypes.PROGRESS_BAR,
    title: 'Delivery performance by destination',
    metrics: [
      {
        name: metrics.ON_TIME_DELIVERY_PERFORMANCE,
        numberFormat: formattingTypes.PERCENT,
        fn: functions.SUM,
        colors: [
          { from: 0, color: '#E82100' },
          { from: 80, color: '#FFC300' },
          { from: 90, color: '#1DD264' },
        ],
      },
    ],
    isPercentBased: true,
    dimensions: [
      {
        name: dimensions.DESTINATION,
      },
    ],
    order: {
      name: metrics.ON_TIME_DELIVERY_PERFORMANCE,
      direction: order.DESCENDING,
    },
    layout: {
      i: v4(),
      x: 6,
      y: 4,
      ...chartLayoutRestrictions[chartTypes.PROGRESS_BAR],
    },
  },
];
