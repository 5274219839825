import React from 'react';
import { Doughnut as ChartJSDoughnut } from 'react-chartjs-2';
import styled from '@emotion/styled';

const Centered = styled.span`
  position: absolute;
  font-size: 20px;
  font-weight: 500;
  color: #333;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -120%);
`;

const defaultDonutChartColors = [
  '#4A90E2',
  '#FFC300',
  '#1DD264',
  '#183b5a',
  '#f49389',
  '#3f4eaf',
  '#ba1b23',
  '#2d6363',
  '#fc9ff9',
  '#c7cafc',
  '#8c8c8c',
  '#cccccc',
  '#080614',
];

const transformToDonutChartData = ({ labels, values, colors }, labelFormatter) => ({
  labels: labels.map((label, index) => labelFormatter(label, values[index])),
  datasets: [
    {
      data: values.map(value => (typeof value === 'number' ? value : parseFloat(value))),
      backgroundColor: colors || defaultDonutChartColors,
      borderColor: colors || defaultDonutChartColors,
    },
  ],
});

export default props => {
  const {
    hideLegend = false,
    legend = {},
    displayTooltipPercentage = false,
    centeredContent,
    data,
    cutoutPercentage = 80,
    width = '100%',
    height = '100%',
    labelFormatter = (label, value) => `${label} - ${value}`,
    shouldTransformData = true,
  } = props;

  const options = {
    elements: {
      arc: {
        borderWidth: 0,
      },
    },
    cutoutPercentage,
    rotation: 0,
    tooltips: {
      enabled: true,
      ...(displayTooltipPercentage && {
        callbacks: {
          label: (tooltipItem, labelData) => {
            const dataset = labelData.datasets[tooltipItem.datasetIndex];
            // eslint-disable-next-line no-underscore-dangle
            const meta = dataset._meta[Object.keys(dataset._meta)[0]];
            const total = { meta };
            const currentValue = dataset.data[tooltipItem.index];
            const percentage = parseFloat(((currentValue / total) * 100).toFixed(1));
            return `${currentValue} (${percentage}%)`;
          },
          title: (tooltipItem, titleData) => titleData.labels[tooltipItem[0].index],
        },
      }),
    },
    maintainAspectRatio: false,
    legend: !hideLegend
      ? {
          ...{
            position: 'bottom',
            labels: {
              boxWidth: 10,
              padding: 15,
              fontSize: 10,
              fontColor: '#333333',
              fontFamily: "'Avenir', 'sans-serif'",
            },
          },
          ...legend,
        }
      : undefined,
  };

  const dataToDisplay = shouldTransformData ? transformToDonutChartData(data, labelFormatter) : data;

  return (
    <div style={{ position: 'relative', width, height }}>
      <ChartJSDoughnut data={dataToDisplay} options={options} />
      {React.isValidElement(centeredContent) ? centeredContent : <Centered style={{}}>{centeredContent}</Centered>}
    </div>
  );
};
