import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
import { Today } from '@mui/icons-material';
import { Button, Popover as PopoverMUI, Typography } from '@mui/material';

import styled from '@emotion/styled';
import { ActionLink, DateRangePicker } from '../Common/Components';
import { date as dateTimeService } from '../../utility';

import './DateRangePickerPopup.scss';

const Popover = styled(PopoverMUI)`
  .MuiPopover-paper {
    overflow: hidden;
    box-shadow: 0 1px 5px rgb(0, 0, 0, 0.12);
  }
`;

const DateRangePickerPopup = memo(({ onChange, isLoading, from, to, presets, disabledDays = {}, name }) => {
  const [popupAnchorEl, setPopupAnchorEl] = useState(null);

  const closePopup = () => setPopupAnchorEl(null);

  const handlePresetClick = (fromValue, toValue) => {
    onChange(fromValue, toValue);
    closePopup();
  };

  const handleChange = newValue => {
    if (newValue.to) {
      onChange(newValue.from, newValue.to);
      setTimeout(closePopup, 250);
    }
  };

  const renderPresetsSection = () => (
    <div className="date-picker-popup__preset-section">
      <div>
        {presets.map((p, i) => (
          <p key={i}>
            <ActionLink hasNoPadding onClick={() => handlePresetClick(p.from, p.to)}>
              {p.name}
            </ActionLink>
          </p>
        ))}
      </div>
    </div>
  );

  const renderDateRangePicker = () => {
    const leftMonth = dateTimeService.subtractMonthsFromDate(new Date(), 1);

    return (
      <div className="date-picker-popup__picker-section">
        <DateRangePicker
          name={name}
          hideHeader
          dayPickerProps={{
            disabledDays,
            selectedDays: {},
            month: leftMonth,
          }}
          onChange={handleChange}
          numberOfMonths={2}
        />
      </div>
    );
  };

  const handleTriggerClick = event => {
    setPopupAnchorEl(event.currentTarget);
  };

  const handlePopupClose = () => {
    setPopupAnchorEl(null);
  };

  return (
    <>
      <Button variant="outlined" size="small" onClick={handleTriggerClick} disabled={isLoading}>
        <Today />
        &nbsp;
        <Typography variant="caption">{`${dateTimeService.to3LetterMonthDayAndYear(
          from,
        )} - ${dateTimeService.to3LetterMonthDayAndYear(to)}`}</Typography>
      </Button>
      <Popover
        open={Boolean(popupAnchorEl)}
        onClose={handlePopupClose}
        anchorEl={popupAnchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
      >
        <div className="date-picker-popup">
          {renderPresetsSection()}
          {renderDateRangePicker()}
        </div>
      </Popover>
    </>
  );
});

DateRangePickerPopup.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  from: PropTypes.instanceOf(Date).isRequired,
  to: PropTypes.instanceOf(Date).isRequired,
  presets: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      from: PropTypes.instanceOf(Date).isRequired,
      to: PropTypes.instanceOf(Date).isRequired,
    }),
  ).isRequired,
};

export default DateRangePickerPopup;
