import { getCurrentUserMetadata } from 'authentication';

// TODO: LEAF-989 LEAF-4469
export const isUserAllowedToEditDashboard = token => {
  if (process.env.REACT_APP_ENV !== 'PROD') {
    return true;
  }
  if (!token) {
    return false;
  }
  const WHITELIST = [1, 7762, 1000, 53314, 51414, 54960, 49683];
  const { uid } = getCurrentUserMetadata(token);
  return WHITELIST.includes(Number(uid));
};
