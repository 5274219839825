import React, { useEffect } from 'react';
import { withRouter } from 'react-router-dom';

import { LazySpinner } from '@leaf/components';

import { setImpersonatedCompanyId, removeImpersonatedCompanyId } from 'authentication/token';

const LoginImpersonatedUserView = ({
  match: {
    params: { companyId },
  },
}) => {
  useEffect(() => {
    removeImpersonatedCompanyId();
    if (companyId) {
      setImpersonatedCompanyId(companyId);
    }
    window.location.replace(window.location.origin);
  }, []);

  return <LazySpinner />;
};

export default withRouter(LoginImpersonatedUserView);
