import React, { memo } from 'react';
import styled from '@emotion/styled';

const List = styled.ul`
  list-style-type: none;
  padding: ${({ theme }) => theme.spacing(2)};
  margin: 0;
`;

const ListItem = styled.li`
  color: ${({ $isDisabled, $isSelected, theme }) => {
    if ($isDisabled) {
      return theme.palette.disabled;
    }
    if ($isSelected) {
      return 'black';
    }
    return theme.palette.primary.main;
  }};
  margin-top: ${({ theme }) => theme.spacing(2)};
  font-size: 14px;
  cursor: pointer;
`;

export default memo(({ changeDisplayedContent, selectedItem }) => (
  <div>
    <List>
      <ListItem $isDisabled>Documentation</ListItem>

      <ListItem
        $isSelected={selectedItem === 'trading_rules'}
        onClick={() => changeDisplayedContent('trading_rules', 'Trading Rules')}
      >
        Trading Rules
      </ListItem>
      <ListItem
        $isSelected={selectedItem === 'accessorials'}
        onClick={() => changeDisplayedContent('accessorials', 'Accessorials')}
      >
        Accessorials
      </ListItem>
      <ListItem
        $isSelected={selectedItem === 'fuel_surcharge'}
        onClick={() => changeDisplayedContent('fuel_surcharge', 'Fuel Surcharge')}
      >
        Fuel Surcharge
      </ListItem>
      <ListItem
        $isSelected={selectedItem === 'leaf_forward_agreement'}
        onClick={() => changeDisplayedContent('leaf_forward_agreement', 'The Leaf Forward Agreement')}
      >
        The Leaf Forward Agreement
      </ListItem>
      <ListItem $isDisabled>Legal</ListItem>
      <ListItem
        $isSelected={selectedItem === 'privacy_policy'}
        onClick={() => changeDisplayedContent('privacy_policy', 'Privacy Policy')}
      >
        Privacy Policy
      </ListItem>
      <ListItem
        $isSelected={selectedItem === 'terms_and_conditions'}
        onClick={() => changeDisplayedContent('terms_and_conditions', 'Terms and conditions')}
      >
        Terms and conditions
      </ListItem>
      <ListItem
        $isSelected={selectedItem === 'release_notes'}
        onClick={() => changeDisplayedContent('release_notes', 'Release notes')}
      >
        Release notes
      </ListItem>
    </List>
  </div>
));
