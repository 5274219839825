import React from 'react';
import styled from '@emotion/styled';
import { v4 } from 'uuid';
import { Tooltip, Avatar as AvatarMUI } from '@mui/material';

const AvatarGroup = styled.div`
  display: flex;
  justify-content: center;
  gap: ${({ theme }) => `${theme.spacing(1)}`};
  align-items: center;
`;

const Avatar = styled(AvatarMUI, {
  shouldForwardProp: prop => prop !== '$size',
})`
  font-size: ${({ $size }) => `${$size * 0.45}px`};
  height: ${({ $size }) => `${$size}px`};
  width: ${({ $size }) => `${$size}px`};
  border: 1px solid rgba(0, 0, 0, 0.15);
  background-color: ${({ color }) => `${color ?? '#4a6b8c'}`};
`;

const HiddenAvatar = styled(AvatarMUI)`
  font-size: ${({ $size }) => `${$size * 0.45}px`};
  height: ${({ $size }) => `${$size}px`};
  width: ${({ $size }) => `${$size}px`};
  border: 1px solid rgba(0, 0, 0, 0.15);
  background-color: white;
  color: rgba(0, 0, 0, 0.87);
  font-weight: 400;
`;

const COLORS = ['#4a6b8c', '#183b5b', '#498edf'];

export default ({
  avatars,
  avatarLabelFormatter = label => label,
  tooltipLabelFormatter = label => label,
  maxShown = 3,
  avatarSize = 32,
}) => {
  if (!Array.isArray(avatars)) {
    return null;
  }
  const displayedAvatars = avatars.slice(0, maxShown);
  const hiddenAvatars = avatars.slice(maxShown);
  return (
    <AvatarGroup>
      {displayedAvatars.map((avatar, index) => (
        <Tooltip title={tooltipLabelFormatter(avatar)}>
          <Avatar color={COLORS[index]} $size={avatarSize}>
            {avatarLabelFormatter(avatar)}
          </Avatar>
        </Tooltip>
      ))}
      {hiddenAvatars.length > 0 && (
        <Tooltip
          title={
            <>
              {hiddenAvatars.map(avatar => (
                <div key={v4()}>{tooltipLabelFormatter(avatar)}</div>
              ))}
            </>
          }
        >
          <HiddenAvatar $size={avatarSize}>+{hiddenAvatars.length}</HiddenAvatar>
        </Tooltip>
      )}
    </AvatarGroup>
  );
};
