import { LANE_COLORS } from '../constants';

export const calculateLaneColor = (index, order) => {
  if (order === 'second') return '#fff';
  if (order === 'outer') return '#001530';
  let colorIndex = index;
  while (colorIndex > 5) {
    colorIndex -= 5;
  }

  return LANE_COLORS[colorIndex];
};

export const calculateLaneWeight = order => {
  switch (order) {
    case 'first':
      return 9;
    case 'second':
      return 6;
    case 'third':
      return 2;
    case 'outer':
      return 8;
    case 'inner':
      return 5;
    default:
      return 8;
  }
};

export const calculateLaneOpacity = order => {
  switch (order) {
    case 'first':
      return 1;
    case 'second':
      return 0.2;
    case 'third':
      return 0.8;
    case 'outer':
      return 0.2;
    case 'inner':
      return 1;
    default:
      return 1;
  }
};

export const getLatLangValues = item => [item[1], item[0]];

export const getPathClass = index => {
  switch (index) {
    case 1:
      return 'polyline-offset-positive-first';
    case 2:
      return 'polyline-offset-negative-first';
    case 3:
      return 'polyline-offset-positive-second';
    case 4:
      return 'polyline-offset-negative-second';
    default:
      return '';
  }
};

export const getMarkerClass = index => {
  if (index === 0) return 'map-marker-icon-wrapper-first';
  if (index % 2 === 0) {
    return 'map-marker-icon-wrapper-second';
  }

  return 'map-marker-icon-wrapper-third';
};

export const calculateLaneOffset = index => {
  if (index % 2 === 0) {
    return index * 3;
  }
  return index * -3;
};

const getPowerLane = (index, geoLocation) => {
  const retVal = [
    {
      type: 'Feature',
      ...geoLocation,
      properties: { index, order: 'first' },
    },
    {
      type: 'Feature',
      ...geoLocation,
      properties: { index, order: 'second' },
    },
    {
      type: 'Feature',
      ...geoLocation,
      properties: { index, order: 'third' },
    },
  ];

  return retVal;
};

const getOutlinedLane = (index, geoLocation) => {
  const retVal = [
    {
      type: 'Feature',
      ...geoLocation,
      properties: { index, order: 'outer' },
    },
    {
      type: 'Feature',
      ...geoLocation,
      properties: { index, order: 'inner' },
    },
  ];

  return retVal;
};

const getStandardLane = (index, geoLocation) => ({
  type: 'Feature',
  ...geoLocation,
  properties: { index },
});

export const getGeoJSONData = (dataList, isOutlinedLine, isLaneReversOrder) => {
  let geoJsonObjects = [];
  dataList.forEach((item, index) => {
    if (item.laneType === 'POWER') {
      geoJsonObjects = isLaneReversOrder
        ? [...getPowerLane(index, item.geo), ...geoJsonObjects]
        : [...geoJsonObjects, ...getPowerLane(index, item.geo)];
    } else if (isOutlinedLine) {
      geoJsonObjects = isLaneReversOrder
        ? [...getOutlinedLane(index, item.geo), ...geoJsonObjects]
        : [...geoJsonObjects, ...getOutlinedLane(index, item.geo)];
    } else {
      geoJsonObjects = isLaneReversOrder
        ? [getStandardLane(index, item.geo), ...geoJsonObjects]
        : [...geoJsonObjects, getStandardLane(index, item.geo)];
    }
  });

  return geoJsonObjects;
};
