import styled from '@emotion/styled';
import { Dialog as DialogMUI, DialogActions, Typography, TableCell, DialogContent } from '@mui/material';
import { Cancel, CheckCircle } from '@mui/icons-material';

export const Dialog = styled(DialogMUI)`
  .MuiDialog-paper {
    overflow-y: visible;
  }
  .MuiDialogContent-root {
    margin-top: ${({ theme, isLoading }) => `${!isLoading ? theme.spacing(7) : 0}`};
  }
`;

export const CancelIcon = styled(Cancel)`
  color: #f88078;
  width: 33px;
  height: 33px;
`;

export const CheckIcon = styled(CheckCircle)`
  color: #4a90e2;
  width: 33px;
  height: 33px;
`;

export const CircleContainer = styled.div`
  width: 88px;
  height: 88px;
  position: absolute;
  top: 0%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  background: #ffffff;
  box-shadow: 0px 0px 8px rgba(37, 40, 43, 0.22);
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Title = styled(Typography)`
  color: ${props => (props.type ? '#F88078' : 'blue')};
`;

export const SubTitle = styled(Typography)`
  color: #183b5a;
`;

export const MessageContainer = styled(Typography)`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Message = styled(Typography)`
  width: 240px;
  white-space: normal;
  color: #183b5a;
`;

export const DialogActionsContainer = styled(DialogActions)`
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fafafa;
  padding: 13px ${({ theme }) => theme.spacing(2)};
`;

export const TableHeaderWrapepr = styled(TableCell)`
  font-weight: bold;
  background-color: #fff;
`;

export const TableCellWrapepr = styled(TableCell)`
  font-weight: bold;
`;

export const TableWrapper = styled.div`
  overflow: auto;
  min-height: 200px;
  max-height: 350px;
`;

export const DialogContentWrapper = styled(DialogContent)`
  overflow: hidden;
`;
