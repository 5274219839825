export default class DetailVolume {
  constructor(response) {
    Object.assign(this, response);

    this.view = {
      pct: `${(parseFloat(response.pct) * 100).toFixed(0)}%`,
    };
  }

  static get = async (client, companyId, laneId) =>
    client
      .get(`/analytics/explorer/detail-volumes/companies/${companyId}/lanes/${laneId}?agg=shipmentsByLane`)
      .then(({ detail_volumes }) => {
        const equipmentClasses = Object.keys(detail_volumes);

        return equipmentClasses.reduce(
          (acc, equipmentClass) => {
            acc[equipmentClass] = detail_volumes[equipmentClass].map(f => new DetailVolume(f));
            return acc;
          },
          { equipmentClasses },
        );
      });
}
