import React, { useContext, useMemo } from 'react';
import PropTypes from 'prop-types';

import { Box, Tooltip, Typography } from '@mui/material';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { InfoOutlined as InfoIcon } from '@mui/icons-material';
import { widgetConfigPropType } from './types';

import './ChartHeader.scss';
import DropdownMenu from '../../../DropdownMenu';
import ConfigurableDimensionRow from './ConfigurableDimensionRow';
import ConfigurableOrderRow from './ConfigurableOrderRow';
import order, { orderDisplayNames } from '../../Constants/order';
import { chartTypes } from '../../Constants';
import DashboardContext from '../../DashboardContext';

const DropdownMenuWrapper = styled.div`
  ${({ isPlaceholder }) =>
    isPlaceholder &&
    css`
      height: 31px;
    `}
  ${({ hasConfigurableOptions }) =>
    hasConfigurableOptions &&
    css`
      margin: ${({ theme }) => `calc(${theme.spacing(1)} / 2) 0 0 ${theme.spacing(2)}`};
    `}
`;

const DescriptionsWrapper = styled.div`
  min-height: 150px;
  max-height: 300px;
  overflow-y: auto;
  padding: 16px;
`;

const ChartHeaderWrapper = styled(Box)`
  display: flex;
  align-items: start;
  margin-bottom: ${({ theme }) => theme.spacing(1)};
  .info-icon {
    color: ${({ theme }) => `${theme.palette.primary.main}`};
  }
`;

const Title = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
`;

const shouldShowDescriptions = (metrics, dimensions) =>
  metrics.some(({ description }) => description) || dimensions.some(({ description }) => description);

const Descriptions = ({ config, metrics = [], dimensions = [] }) => (
  <DescriptionsWrapper>
    {config.metrics?.length > 0 && (
      <>
        <Typography variant="body2">Metrics</Typography>
        <br />
        {metrics.map(metric => (
          <Box mb={1}>
            <Typography variant="caption">
              {config.metrics.find(({ name }) => name === metric.name).displayName ?? metric.displayName}
            </Typography>
            <Typography variant="body1">{metric.description}</Typography>
          </Box>
        ))}
      </>
    )}
    <br />
    {config.dimensions?.length > 0 && (
      <>
        <Typography variant="body2">Dimensions</Typography>
        <br />
        {dimensions.map(dimension => (
          <Box mb={1}>
            <Typography variant="caption">
              {config.dimensions.find(({ name }) => name === dimension.name).displayName ?? dimension.displayName}
            </Typography>
            <Typography variant="body1">{dimension.description}</Typography>
          </Box>
        ))}
      </>
    )}
  </DescriptionsWrapper>
);

const ChartHeader = React.memo(
  ({ config, onEdit, onDelete, canEdit, onDimensionSelect, onOrderSelect, selectedDimensionId, selectedOrderId }) => {
    const hasConfigurableOptions =
      config.configurableDimensions || (config.order?.direction && !config.configurableDimensions);
    const shouldRestrictOptions = config.chartType === chartTypes.TABLE;

    const {
      state: { metricsOptions, dimensionOptions },
    } = useContext(DashboardContext);

    const metrics = useMemo(
      () =>
        metricsOptions.filter(
          ({ name }) => !!config.metrics?.find(({ name: configMetricName }) => name === configMetricName),
        ),
      [config, metricsOptions],
    );

    const dimensions = useMemo(
      () =>
        dimensionOptions.filter(
          ({ name }) =>
            !!config.dimensions?.filter(d => d).find(({ name: configDimensionName }) => name === configDimensionName),
        ),
      [config, dimensionOptions],
    );

    return (
      <ChartHeaderWrapper>
        <div className="flex-column flex-grow">
          <Title>
            <span className="chart-header__title">{config.title}</span>
            {shouldShowDescriptions(metrics, dimensions) && (
              <Tooltip interactive title={<Descriptions config={config} metrics={metrics} dimensions={dimensions} />}>
                <InfoIcon fontSize="small" className="info-icon" />
              </Tooltip>
            )}
          </Title>
          <span className="chart-header__subtitle">{config.subtitle}</span>
        </div>

        {!shouldRestrictOptions && config.configurableDimensions && (
          <ConfigurableDimensionRow
            configurableDimensions={config.configurableDimensions}
            onDimensionSelect={onDimensionSelect}
            selectedDimensionId={selectedDimensionId}
          />
        )}
        {!shouldRestrictOptions && config.order?.direction && !config.configurableDimensions && (
          <ConfigurableOrderRow
            options={[
              {
                name: orderDisplayNames[order.DESCENDING],
                id: order.DESCENDING,
              },
              {
                name: orderDisplayNames[order.ASCENDING],
                id: order.ASCENDING,
              },
            ]}
            onOrderSelect={onOrderSelect}
            selectedOrderId={selectedOrderId}
          />
        )}
        {canEdit ? (
          <DropdownMenuWrapper hasConfigurableOptions={hasConfigurableOptions}>
            <DropdownMenu
              options={[
                { label: 'Edit', onClick: () => onEdit(config) },
                { label: 'Delete', onClick: () => onDelete(config) },
              ]}
            />
          </DropdownMenuWrapper>
        ) : (
          <DropdownMenuWrapper isPlaceholder />
        )}
      </ChartHeaderWrapper>
    );
  },
);

ChartHeader.propTypes = {
  config: widgetConfigPropType.isRequired,
  onEdit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  canEdit: PropTypes.boolean,
};

ChartHeader.defaultProps = {
  canEdit: false,
};

export default ChartHeader;
