import React from 'react';
import styled from '@emotion/styled';
import { Paper, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';

const PtpLanesTooltipTitleWrapper = styled.div`
  align-items: center;
  border-bottom: 1px solid #e5e5e5;
  color: #333;
  display: flex;
  height: 60px;
  justify-content: space-between;
  padding-left: ${({ theme }) => theme.spacing(2)};
`;

const PtpLaneTableCell = styled(TableCell)`
  border: none;
`;

export default ({ ptpLanes }) => (
  <Paper>
    <PtpLanesTooltipTitleWrapper>
      <Typography>Powerlane lanes</Typography>
    </PtpLanesTooltipTitleWrapper>
    <Table>
      <TableHead>
        <TableRow>
          <PtpLaneTableCell>Origin</PtpLaneTableCell>
          <PtpLaneTableCell>Destination</PtpLaneTableCell>
          {ptpLanes.find(item => item.distance) && <PtpLaneTableCell>Distance</PtpLaneTableCell>}
          {ptpLanes.find(item => item.shipperDistance && item.leafDistance) && (
            <>
              <PtpLaneTableCell>Shipper distance</PtpLaneTableCell>
              <PtpLaneTableCell>Leaf distance</PtpLaneTableCell>
            </>
          )}
          <PtpLaneTableCell>Est. volume</PtpLaneTableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {ptpLanes.map(ptpLane => (
          <TableRow>
            <PtpLaneTableCell>{ptpLane.view?.originName || ''}</PtpLaneTableCell>
            <PtpLaneTableCell>{ptpLane.view?.destinationName || ''}</PtpLaneTableCell>
            {ptpLane.distance && <PtpLaneTableCell>{ptpLane.distance || ''}</PtpLaneTableCell>}
            {ptpLane.shipperDistance && <PtpLaneTableCell>{ptpLane.shipperDistance || ''}</PtpLaneTableCell>}
            {ptpLane.leafDistance && <PtpLaneTableCell>{ptpLane.leafDistance || ''}</PtpLaneTableCell>}
            <PtpLaneTableCell>{`${ptpLane.volumeShare * 100} %` || ''}</PtpLaneTableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  </Paper>
);
