/* eslint-disable react/jsx-props-no-spreading */
import React, { memo } from 'react';
import PropTypes from 'prop-types';
import GridLayout, { WidthProvider } from 'react-grid-layout';

import Widget from './Widget';
import { widgetConfigPropType } from './Charts/types';

import '../../../../../node_modules/react-grid-layout/css/styles.css';
import '../../../../../node_modules/react-resizable/css/styles.css';
import './DashboardGrid.scss';

const spaceBetweenWidgets = [16, 16]; // x, y - keep in sync with constants.scss, $le-margin-md: 16px;

const DashboardGrid = ({
  filters,
  widgetsConfig,
  width,
  onWidgetEdit,
  onWidgetDelete,
  canEdit,
  ...gridLayoutProps
}) => (
  <GridLayout
    className="layout"
    layout={widgetsConfig.map(w => w.layout)}
    cols={12}
    width={width}
    rowHeight={150} // per design
    compactType="vertical"
    margin={spaceBetweenWidgets} // Margin between items [x, y] in px.
    containerPadding={spaceBetweenWidgets} // Padding inside the container [x, y] in px
    isResizable={canEdit}
    isDraggable={canEdit}
    {...gridLayoutProps}
  >
    {widgetsConfig.map(c => (
      <div className="dashboard-grid__widget" key={c.layout.i}>
        <Widget
          filters={filters}
          config={c}
          canEdit={canEdit}
          onWidgetEdit={onWidgetEdit}
          onWidgetDelete={onWidgetDelete}
        />
      </div>
    ))}
  </GridLayout>
);

DashboardGrid.propTypes = {
  widgetsConfig: PropTypes.arrayOf(widgetConfigPropType).isRequired,
  filters: PropTypes.shape({}).isRequired,
  onWidgetEdit: PropTypes.func.isRequired,
  onWidgetDelete: PropTypes.func.isRequired,
  canEdit: PropTypes.bool,
};

DashboardGrid.defaultProps = {
  canEdit: false,
};

export default WidthProvider(memo(DashboardGrid));
