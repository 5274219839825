import React from 'react';
import { Typography } from '@mui/material';

export const termsAndConditions = (
  <div>
    <Typography variant="h4">The Leaf Logistics Platform</Typography>
    <Typography variant="body1">
      Leaf Logistics (Leaf) operates a digital platform (the &quot;Platform&quot;) that matches supply of, and demand
      for, trucking capacity. Carriers and shippers listed on the Platform may post orders to buy or sell such trucking
      capacity, and the matching engine of the Platform will &quot;match&quot; orders, i.e. the matching engine will
      present to the party that wishes to buy trucking capacity with the sell order that matches the pick-up dates and
      pick-up and delivery locations specified by the buying party and contains the most favorable terms for the buying
      party. Upon acceptance by the buyer party of such a sell order, the two parties will be deemed to have entered
      into a contract in the form posted on the Platform (an &quot;Leafå Forward Contract&quot;). The Platform will
      present each of the parties with a PDF of the Leaf Forward Contract. The Trading Rules posted on the Platform set
      forth these procedures in greater detail.
    </Typography>
    <Typography variant="h4">Terms and Conditions of Use</Typography>
    <Typography variant="body1">
      You agree to be bound by the procedures set forth above and the Trading Rules posed on as well as the obligations
      of any Leaf Forward Contracts entered on the Platform. You also agree to be bound by the following terms and
      conditions regarding your access to and use of the Leaf Logistics app and website (the &quot;Website&quot;).{' '}
    </Typography>
    <ol>
      <li>
        <Typography variant="body1">Dealings with Third Parties</Typography>
        <Typography variant="body1">
          Your business dealings with any third parties as a result of your visit to and participation in the Website,
          including, but not limited to, business dealings with any person as a customer for your services, is solely
          between you and such third party, and Leaf Logistics is not responsible in any manner for such business
          relationship.{' '}
        </Typography>
        <Typography variant="body1">
          Specifically, You agree that Leaf Logistics shall not be responsible or liable for any loss or damage of any
          nature incurred by you as the result of any such dealings through or with the Website including but not
          limited to your use of the content of any database on the Website and that only the customer with whom you
          transact business shall be responsible to you for payment for such services. Leaf Logistics is not responsible
          under any circumstance for payment to you for service rendered by you to any customer.
        </Typography>
      </li>
      <li>
        <Typography variant="body1">Modification to or Termination of Service </Typography>
        <Typography variant="body1">
          Leaf Logistics reserves the right at any time and from time to time to modify or discontinue, temporarily or
          permanently, the services provided on the Website (or any part thereof) with or without notice. You understand
          and agree that Leaf Logistics shall not be liable to you or to any third party for any modification,
          suspension or discontinuance of the Service (or any part thereof).{' '}
        </Typography>
      </li>
      <li>
        <Typography variant="body1">No Warranty </Typography>
        <Typography variant="body1">
          THIS WEBSITE, THE WEBSITE CONTENT AND THE SOFTWARE ARE PROVIDED ON AN &quot;AS IS&quot; BASIS WITHOUT ANY
          WARRANTIES OF ANY KIND. WE AND OUR AFFILIATES, SUPPLIERS AND LICENSORS, TO THE FULLEST EXTENT PERMITTED BY
          LAW, DISCLAIM ALL WARRANTIES, INCLUDING THE WARRANTIES OF TITLE, MERCHANTABILITY, NON-INFRINGEMENT OF THIRD
          PARTIES RIGHTS, AND FITNESS FOR A PARTICULAR PURPOSE.
        </Typography>
      </li>
    </ol>
  </div>
);
