import { useState } from 'react';

const useLoading = (action, initial = false) => {
  const [loading, setLoading] = useState(initial);
  const doAction = (...args) => {
    setLoading(true);
    return action(...args).finally(() => setLoading(false));
  };

  return [doAction, loading];
};

export default useLoading;
