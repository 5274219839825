export const CONTRACT_STATUS = {
  CONTRACTED: 'CONTRACTED',
  CANCELED: 'CANCELED',
  EXECUTED: 'EXECUTED',
  FAILED: 'FAILED',
  DRAFT: 'DRAFT',
};

export const CONTRACT_STATUS_DISPLAY_NAMES = {
  EXECUTED: 'Executed',
  CONTRACTED: 'Contracted',
  CANCELED: 'Canceled',
  FAILED: 'Failed',
  DRAFT: 'Draft',
};
