import React from 'react';
import { scaleLinear, bin, extent, max, select, selectAll, axisLeft, axisBottom } from 'd3';

const magic = {
  width: 780,
  height: 550,
  margins: { top: 20, right: 20, bottom: 50, left: 40 },
  barColor: 'rgba(0, 128, 0, 0.5)',
  avgStrokeWidth: 2,
  avgBarColor: 'steelblue',
  avgTextColor: 'black',
  avgTextSize: '1em',
  xAxisColor: 'black',
  yAxisColor: 'black',
};

const DailyDistribution = ({ data }) => {
  const svg = React.useRef();

  React.useEffect(() => {
    selectAll('#daily-distribution-chart > *').remove();

    const width = magic.width - magic.margins.left - magic.margins.right;
    const height = magic.height - magic.margins.top - magic.margins.bottom;

    const shipments = data.map(d => d.shipments);

    const x = scaleLinear().domain(extent(shipments)).nice().range([0, width]);

    const histogram = bin()
      .domain(x.domain())
      .thresholds([...Array(max(shipments)).keys()])(shipments);

    const y = scaleLinear()
      .domain([0, max(histogram, h => h.length)])
      .nice()
      .range([height, 0]);

    const g = select(svg.current)
      .attr('width', magic.width + magic.margins.left)
      .attr('height', magic.height + magic.margins.bottom)
      .append('g')
      .attr('transform', `translate(${magic.margins.left}, ${magic.margins.top})`);

    g.append('g')
      .attr('fill', magic.barColor)
      .selectAll('rect')
      .data(histogram)
      .enter()
      .append('rect')
      .attr('x', d => x(d.x0) + 1)
      .attr('width', d => max([0, x(d.x1) - (x(d.x0) + 1)]))
      .attr('y', d => y(d.length))
      .attr('height', d => y(0) - y(d.length));

    const avgVolume = shipments.reduce((a, b) => a + b) / shipments.length;

    g.append('line')
      .attr('x1', x(avgVolume))
      .attr('y1', 0)
      .attr('x2', x(avgVolume))
      .attr('y2', height)
      .style('stroke-width', magic.avgStrokeWidth)
      .style('stroke', magic.avgBarColor);

    g.append('text')
      .attr('fill', magic.avgTextColor)
      .attr('text-anchor', 'start')
      .attr('font-size', magic.avgTextSize)
      .attr('x', x(avgVolume) + 5)
      .attr('y', 6)
      .text(`Mean (${avgVolume.toFixed(1)} loads / day)`);

    g.append('g')
      .attr('transform', `translate(0,${height})`)
      .call(axisBottom(x).tickSizeOuter(0))
      .append('text')
      .attr('fill', magic.xAxisColor)
      .attr('x', width)
      .attr('y', -10)
      .attr('text-anchor', 'end')
      .text('Loads');

    g.append('g')
      .call(axisLeft(y))
      .append('text')
      .attr('fill', magic.yAxisColor)
      .attr('transform', 'rotate(-90)')
      .attr('y', 6)
      .attr('dy', '0.71em')
      .attr('text-anchor', 'end')
      .text('Days');
  }, [data, svg]);

  return <svg id="daily-distribution-chart" ref={svg} />;
};

export default DailyDistribution;
