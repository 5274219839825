/* eslint-disable no-unused-vars */
/* eslint-disable import/named */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useContext, useEffect, useState } from 'react';
import CurrentUserInfo from 'components/CurrentUserInfo';
import { Page, Form, USER_TYPE, LazySpinner } from '@leaf/components';
import {
  getCurrentUserMetadata,
  getImpersonatedCompanyId,
  IMPERSONATED_COMPANY_ID,
  setImpersonatedCompanyId,
} from 'authentication';
import { Box } from '@mui/material';
import { StateContext } from 'state/StateProvider';
import styled from '@emotion/styled';
import { useAuth0 } from '@auth0/auth0-react';

const InputWrapper = styled.div`
  min-width: 200px;
  .MuiAutocomplete-inputRoot {
    padding: 0 9px;
  }
`;

const ClientAppPage = ({ title, children, ...rest }) => {
  const { getAccessTokenSilently } = useAuth0();
  const impersonatedCompanyId = getImpersonatedCompanyId();
  const [companyId, setCompanyId] = useState(impersonatedCompanyId);
  const [isLeafAdmin, setIsLeafAdmin] = useState();
  const {
    state: { companies },
  } = useContext(StateContext);

  useEffect(() => {
    getAccessTokenSilently?.().then(res => {
      const userMetadata = getCurrentUserMetadata(res);
      setIsLeafAdmin(userMetadata.role === USER_TYPE.LE_ADMIN);
    });
  }, []);

  useEffect(() => {
    if (companyId && companyId !== impersonatedCompanyId) {
      setImpersonatedCompanyId(companyId);
      window.location.replace(window.location.origin);
    }
  }, [companyId]);

  const companyIds = companies?.map(c => c.id);

  const CompanyPicker = () => {
    if (!isLeafAdmin) {
      return null;
    }

    return companyIds?.length ? (
      <InputWrapper>
        <Form.Generic.Autocomplete
          id="impersonatedCompanyId"
          name="impersonatedCompanyId"
          label="Impersonated Company"
          options={companyIds}
          renderInput={params => <Form.Generic.Input {...params} />}
          getOptionLabel={selectedCompanyId =>
            companies.find(({ id }) => id.toString() === selectedCompanyId?.toString())?.label
          }
          isOptionEqualToValue={option => option === companyId}
          onChange={(_, value) => {
            if (value) {
              // prevent user from not selecting anything
              setCompanyId(value);
            }
          }}
          value={companyId}
        />
      </InputWrapper>
    ) : (
      <LazySpinner />
    );
  };

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <Page
      title={title}
      UserDescription={
        <Box display="flex" alignItems="center" marginLeft="auto" gap={2}>
          <CompanyPicker />
          <CurrentUserInfo />
        </Box>
      }
      {...rest}
    >
      {children}
    </Page>
  );
};

export default ClientAppPage;
