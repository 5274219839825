export default class Volume {
  constructor(response) {
    Object.assign(this, {
      ...response,
      period: new Date(response.period),
    });
  }

  static get = async (client, companyId, laneId) =>
    client.get(`/analytics/explorer/volumes/companies/${companyId}/lanes/${laneId}`).then(({ volumes }) => {
      const equipmentClasses = Object.keys(volumes);
      return equipmentClasses.reduce(
        (acc, key) => {
          acc[key] = volumes[key].map(volume => new Volume(volume));
          return acc;
        },
        { equipmentClasses },
      );
    });
}
