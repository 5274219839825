import React from 'react';
import classNames from 'classnames';

import './CircularProgressBar.scss';

const MIN_PERCENTAGE = 0;
const MAX_PERCENTAGE = 100;

export default class CircularProgressBar extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      truncatedPercentage: props.initialAnimation
        ? 0
        : Math.min(Math.max(props.percentage, MIN_PERCENTAGE), MAX_PERCENTAGE) || 0,
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({
      truncatedPercentage: Math.min(Math.max(nextProps.percentage, MIN_PERCENTAGE), MAX_PERCENTAGE) || 0,
    });
  }

  componentWillUnmount() {
    clearTimeout(this.initialTimeout);
    window.cancelAnimationFrame(this.requestAnimationFrame);
  }

  getBackgroundPadding() {
    const { backgroundPadding, background, strokeWidth } = this.props;
    if (background) {
      return backgroundPadding;
    }
    if (background && backgroundPadding === null) {
      return strokeWidth;
    }
    return 0;
  }

  getPathDescription(innerPath) {
    const radius = this.getPathRadius();
    return `M ${innerPath ? '-50' : '50'},${innerPath ? '50' : '50'} m 0,-${radius}
      			a ${radius},${radius} 0 1 1 0,${2 * radius}
      			a ${radius},${radius} 0 1 1 0,-${2 * radius}`;
  }

  getProgressStyle() {
    const { strokeColor } = this.props;
    const { truncatedPercentage } = this.state;
    const diameter = Math.PI * 2 * this.getPathRadius();
    return {
      strokeDasharray: `${diameter}px ${diameter}`,
      strokeDashoffset: `${((100 - truncatedPercentage) / 100) * diameter}`,
      stroke: strokeColor,
    };
  }

  getProgressRotation() {
    return 'rotate(270)';
  }

  getPathRadius() {
    const { strokeWidth } = this.props;
    // the radius of the path is defined to be in the middle, so in order for the path to
    // fit perfectly inside the 100x100 viewBox, need to subtract half the strokeWidth
    return 30 - strokeWidth / 2 - this.getBackgroundPadding();
  }

  render() {
    const {
      percentage,
      textForPercentage,
      className,
      classForPath,
      strokeWidth,
      status = undefined,
      isBuy = undefined,
      classForPercentageFn,
      width,
      height,
      background,
    } = this.props;
    const classForPercentage = classForPercentageFn ? classForPercentageFn(percentage) : '';
    const pathDescription = this.getPathDescription();
    const innerPathDescription = this.getPathDescription(true);
    const text = textForPercentage ? textForPercentage(percentage) : null;
    const sideAsString = '100px';

    return (
      <div
        style={{
          position: 'relative',
          top: '-25px',
          width: width ? `${width}` : sideAsString,
          height: width ? `${height}` : sideAsString,
        }}
      >
        <svg className={`circular-progress-bar ${className} ${classForPercentage}`} viewBox="0 0 100 100">
          {background ? <circle className="circular-progress-bar__background" cx={50} cy={50} r={50} /> : null}
          <path
            className="circular-progress-bar__trail"
            d={pathDescription}
            strokeWidth={strokeWidth}
            fillOpacity={0}
          />
          <path
            className={`circular-progress-bar__path ${classForPath}`}
            d={innerPathDescription}
            strokeWidth={strokeWidth}
            fillOpacity={0}
            style={this.getProgressStyle()}
            transform={this.getProgressRotation()}
          />
          {text ? (
            <text
              className={classNames({
                'circular-progress-bar__text': true,
                [`circular-progress-bar__text--${typeof status !== 'undefined' && status.toLowerCase()}-buy`]:
                  typeof isBuy !== 'undefined' && isBuy,
                [`circular-progress-bar__text--${typeof status !== 'undefined' && status.toLowerCase()}-sell`]:
                  typeof isBuy !== 'undefined' && !isBuy,
              })}
              x={50}
              y={56}
            >
              {text}
            </text>
          ) : null}
        </svg>
      </div>
    );
  }
}
