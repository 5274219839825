import chartTypes from './chartTypes';

export const initialRestrinctions = {
  w: undefined,
  h: undefined,
  minH: undefined,
  maxH: undefined,
  minW: undefined,
  maxW: undefined,
};

export default {
  [chartTypes.AREA]: {
    w: 6,
    h: 2,
    maxH: 2,
    minH: 2,
    maxW: 12,
    minW: 4,
  },
  [chartTypes.LINE]: {
    w: 6,
    h: 2,
    maxH: 2,
    minH: 2,
    maxW: 12,
    minW: 4,
  },
  [chartTypes.BAR]: {
    w: 6,
    h: 2,
    maxH: 2,
    minH: 2,
    maxW: 12,
    minW: 4,
  },
  [chartTypes.KPI]: {
    w: 3,
    h: 1,
    maxH: 1,
    minH: 1,
    maxW: 3,
    minW: 2,
  },
  [chartTypes.CIRCULAR_PROGRESS_BAR]: {
    w: 3,
    h: 1,
    maxH: 1,
    minH: 1,
    maxW: 3,
    minW: 2,
    isResizable: true,
  },
  [chartTypes.PROGRESS_BAR]: {
    w: 6,
    h: 2,
    maxH: 2,
    minH: 2,
    maxW: 12,
    minW: 3,
  },
  [chartTypes.DONUT]: {
    w: 3,
    h: 2,
    maxH: 2,
    minH: 2,
    maxW: 4,
    minW: 3,
  },
  [chartTypes.LIST]: {
    w: 3,
    h: 2,
    maxH: 4,
    minH: 2,
    maxW: 4,
    minW: 3,
  },
  [chartTypes.TABLE]: {
    w: 4,
    h: 2,
    minW: 3,
    minH: 2,
  },
  [chartTypes.PIVOT_TABLE]: {
    w: 4,
    h: 2,
    minW: 3,
    minH: 2,
  },
};
