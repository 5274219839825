import React from 'react';
import { SvgIcon, IconButton, Typography, Tooltip } from '@mui/material';
import { Link as LinkIcon } from '@mui/icons-material';
import { DELIVERY, DELIVERY_DISPLAY_NAMES, TRIP_TYPE_DISPLAY_NAMES } from './..';
import { ForkLift, PointToPointIcon } from './../icons';
import { Body, Dot, Description, IconContainer, RouteCardItem, LaneWrapper, IconWrapper } from './RouteCardBody.styled';

export default ({ route, copyRoute }) => {
  const getLoadingType = legs => {
    if (legs.find(leg => leg.pickupType === DELIVERY.ANY || leg.deliveryType === DELIVERY.ANY))
      return DELIVERY_DISPLAY_NAMES.ANY;
    if (legs.find(leg => leg.pickupType === DELIVERY.DROP || leg.deliveryType === DELIVERY.DROP))
      return DELIVERY_DISPLAY_NAMES.DROP;
    if (legs.find(leg => leg.pickupType === DELIVERY.UNKNOWN || leg.deliveryType === DELIVERY.UNKNOWN))
      return DELIVERY_DISPLAY_NAMES.UNKNOWN;

    return DELIVERY_DISPLAY_NAMES.LIVE;
  };

  const showTripTypeValue = (tripType, legNumber) => {
    if (tripType === TRIP_TYPE_DISPLAY_NAMES.ONE_WAY || tripType === TRIP_TYPE_DISPLAY_NAMES.ROUND_TRIP) {
      return tripType;
    }

    return `${tripType} (${legNumber === 1 ? `1 leg` : `${legNumber} legs`})`;
  };

  return (
    <>
      <Body>
        <RouteCardItem>
          <LaneWrapper>
            <Tooltip title={route.displayOriginName}>
              <Typography variant="body2">{route.displayOriginName}</Typography>
            </Tooltip>
            <IconWrapper>
              <PointToPointIcon />
            </IconWrapper>
            <Tooltip title={route.displayDestinationName}>
              <Typography variant="body2">{route.displayDestinationName}</Typography>
            </Tooltip>
          </LaneWrapper>
        </RouteCardItem>
      </Body>
      <Body>
        <Description variant="body1" $fontSize="12">
          {showTripTypeValue(route?.tripType, route.legs.length)}
        </Description>
        {copyRoute && (
          <IconButton
            size="small"
            onClick={event => {
              event.stopPropagation();
              copyRoute(route.id);
            }}
          >
            <LinkIcon />
          </IconButton>
        )}
      </Body>
      <Body>
        <Description variant="body1" $fontSize="10">
          {`Distance: ${route.totalMiles}`}
        </Description>
        <Dot />
        <Description variant="body1" $fontSize="10">
          {`Deadhead: ${route.deadheadMiles}`}
        </Description>
        <Dot />
        <IconContainer>
          <SvgIcon component={ForkLift} />
          <Description variant="body1" $fontSize="10">
            {getLoadingType(route?.legs)}
          </Description>
        </IconContainer>
      </Body>
    </>
  );
};
