import React from 'react';
import styled from '@emotion/styled';
import { Card, CardHeader, CardContent } from '@mui/material';

const FullHeightCard = styled(Card)`
  height: 100%;
`;

const FullHeightCardContent = styled(CardContent)`
  height: 100%;
  ${({ centerContent }) => {
    if (centerContent) {
      return 'display: flex; justify-content: center;';
    }
    return '';
  }}
`;

const ChartCard = ({ title, children, centerContent }) => (
  <FullHeightCard>
    <CardHeader title={title} />
    <FullHeightCardContent centerContent={centerContent}>{children}</FullHeightCardContent>
  </FullHeightCard>
);

export default ChartCard;
