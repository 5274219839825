import React from 'react';
import styled from '@emotion/styled';
import { Typography, ListItem } from '@mui/material';

const ListItemWrapper = styled(ListItem)`
  justify-content: space-between;
`;

const OverviewListCol = React.memo(({ label, value, isSubHeader = false }) => (
  <>
    <ListItemWrapper disableGutters>
      {React.isValidElement(label) ? label : <Typography variant="caption">{label}</Typography>}
    </ListItemWrapper>
    <ListItemWrapper disableGutters>
      {!isSubHeader && (React.isValidElement(value) ? value : <Typography variant="body1">{value || '-'}</Typography>)}
    </ListItemWrapper>
  </>
));

export default OverviewListCol;
