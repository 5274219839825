import React from 'react';
import { ListItem, ListItemIcon, ListItemText } from '@mui/material';
import styled from '@emotion/styled';

export const NavigationItemWrapper = styled(ListItem)`
  &&& {
    .MuiListItemIcon-root {
      min-width: 45px;
    }
  }
`;

export const NavigationItemText = ({ primary }) => (
  <ListItemText primary={primary} primaryTypographyProps={{ variant: 'body2' }} />
);

export default ({ text, to, onClick, children, link }) =>
  to ? (
    <NavigationItemWrapper component={link} to={to} button activeClassName="active-link">
      <ListItemIcon>{children}</ListItemIcon>

      <NavigationItemText primary={text} />
    </NavigationItemWrapper>
  ) : (
    <NavigationItemWrapper onClick={onClick} button>
      <ListItemIcon>{children}</ListItemIcon>

      <NavigationItemText primary={text} />
    </NavigationItemWrapper>
  );
