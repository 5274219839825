/* eslint-disable import/named */
/* eslint-disable import/no-extraneous-dependencies */
import React, { useContext, useEffect } from 'react';
import { Switch, Route, Redirect, withRouter } from 'react-router-dom';

import PrivateRoute from 'navigation/PrivateRoute';
import NotFound from 'navigation/NotFound';
import { UserContext } from 'authentication/UserProvider';
import { GoogleAnalytics, USER_TYPE, FullStory, LazySpinner } from '@leaf/components';
import DashboardView from 'dashboard/DashboardView';
import Shipments from 'shipments/ShipmentsOverview';
import HelpView from 'help/HelpView';
import AdaptBoardOverview from 'adaptBoard/AdaptBoardOverview';
import AdaptNetworkMoveDetails from 'adaptBoard/tabs/tables/details/NetworkMovesDetails';
import CompanyOverview from 'company/CompanyOverview';
import LoginImpersonatedUserView from 'authentication/LoginImpersonatedUserView';
import ShipmentsDetails from 'shipments/details';
import LaneExplorer from 'LaneExplorer';
import { ContractsOverview } from 'contracts';
import { ContractDetails } from 'contracts/details';
import { StateContext } from 'state/StateProvider';
import SharedOpportunityView from 'shared-views/SharedOpportunityView';
import SharedFleetOpportunitiesView from 'shared-views/SharedFleetOpportunitiesView';
import { isRoutePublic } from './publicRouteUtils';

const getDefaultRoute = (companyType, adapt = false) => {
  if (companyType === USER_TYPE.SHIPPER && adapt) {
    return '/company';
  }
  return '/dashboard';
};

const Continue = ({ companyType }) => {
  const { state } = useContext(StateContext);

  const pathname = getDefaultRoute(companyType, state.adapt);

  return window.history.state?.returnTo && window.history.state?.returnTo !== '/' ? (
    <Redirect
      to={{
        pathname: window.history.state.returnTo,
      }}
    />
  ) : (
    <Redirect
      to={{
        pathname,
      }}
    />
  );
};

export default withRouter(({ history }) => {
  const { state } = useContext(StateContext);
  const { currentUser } = useContext(UserContext);

  const companyType = currentUser?.company?.type;
  const companyId = currentUser?.company?.id;
  const userRole = currentUser?.role;
  const defaultRoute = getDefaultRoute(companyType, state.adapt);

  useEffect(() => {
    FullStory.initialize('client', currentUser?.email);
  }, [currentUser]);

  const shouldRedirectAfterLogin = userRole || companyType;

  if (state.adapt === null && !isRoutePublic()) {
    return null;
  }

  return (
    <GoogleAnalytics.Listener
      trackingId={process.env.REACT_APP_GA_TRACKING_ID}
      userType={companyType}
      history={history}
    >
      <Switch>
        <Redirect exact from="/" to={defaultRoute} />

        {shouldRedirectAfterLogin && (
          <PrivateRoute exact path="/continue">
            <Continue companyType={companyType} />
          </PrivateRoute>
        )}

        <PrivateRoute path="/impersonate/:companyId">
          <LoginImpersonatedUserView />
        </PrivateRoute>

        <PrivateRoute exact path="/dashboard">
          {companyType || userRole ? <DashboardView name="performance" companyType={companyType} /> : <LazySpinner />}
        </PrivateRoute>

        <PrivateRoute exact path="/shipments">
          {companyType ? <Shipments companyType={companyType} /> : <LazySpinner />}
        </PrivateRoute>

        <PrivateRoute exact path="/shipments/:id">
          <ShipmentsDetails />
        </PrivateRoute>

        <PrivateRoute exact path="/adapt-board">
          <AdaptBoardOverview />
        </PrivateRoute>

        <PrivateRoute exact path="/adapt-board-network-moves/:id">
          <AdaptNetworkMoveDetails />
        </PrivateRoute>

        <PrivateRoute exact path="/adapt/explorer/lanes/:laneId">
          <LaneExplorer companyId={companyId} />
        </PrivateRoute>

        <PrivateRoute exact path="/contracts">
          <ContractsOverview />
        </PrivateRoute>

        <PrivateRoute exact path="/contracts/:id">
          <ContractDetails />
        </PrivateRoute>

        <PrivateRoute exact path="/company">
          <CompanyOverview
            data={{
              company: {
                id: companyId,
                name: currentUser?.company?.name,
                logo: currentUser?.company?.logoUrl,
              },
            }}
          />
        </PrivateRoute>

        <Route exact path="/share/opportunities/:contractId">
          <SharedOpportunityView />
        </Route>

        <Route exact path="/share/fleet-opportunities/:fleetId">
          <SharedFleetOpportunitiesView />
        </Route>

        <PrivateRoute path="/help">
          <HelpView />
        </PrivateRoute>

        <PrivateRoute path="*">
          <NotFound />
        </PrivateRoute>
      </Switch>
    </GoogleAnalytics.Listener>
  );
});
