import { Box, Typography, Tooltip } from '@mui/material';
import React from 'react';
import styled from '@emotion/styled';
import { InfoOutlined } from '@mui/icons-material';
import { v4 } from 'uuid';
import { isNumber } from 'lodash';
import { LANE_TYPE } from '../constants';
import { PowerlaneIcon, PickupIcon, DropOffIcon } from '../icons';
import TooltipDialog from '../PolylineMap/TooltipDialog';
import {
  Lane as LaneSC,
  Deadhead,
  Distance,
  HorizontalLine,
  Powerlane,
  Route,
  RouteCard,
  Wrapper,
  TooltipWrapper,
} from './RouteSchedule.styled';
import CompanyAvatar from '../CompanyAvatar';

const InfoIcon = styled(InfoOutlined)`
  &&& {
    width: 24px;
    height: 24px;
  }
`;

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  color: rgba(0, 0, 0, 0.54);
`;

const Origin = ({ data }) => (
  <div>
    <Typography variant="body2">{data.origin}</Typography>
    <Typography variant="caption">
      <IconWrapper>
        <PickupIcon size={20} />
        {data.pickupType || 'Loading'}
      </IconWrapper>
    </Typography>
  </div>
);

const Destination = ({ data }) => (
  <div>
    <Typography variant="body2">{data.destination}</Typography>
    <Typography variant="caption">
      <IconWrapper>
        <DropOffIcon size={20} />
        {data.deliveryType || 'Unloading'}
      </IconWrapper>
    </Typography>
  </div>
);

const Lane = ({ data, meta }) => {
  const shouldUseLeafMiles = data.miles === '-';
  return (
    <>
      {data.type === LANE_TYPE.PTP ? (
        <Route position={meta.position} color={meta.color} />
      ) : (
        <Powerlane position={meta.position} color={meta.color}>
          <span className="powerlane-1" />
          <span className="powerlane-2">
            <PowerlaneIcon size={30} />
          </span>
          <span className="powerlane-3" />
        </Powerlane>
      )}
      <LaneSC>
        <Origin data={data} />
        <div>
          <Distance>
            <Box display="flex" gap={8}>
              {shouldUseLeafMiles ? (
                <Box display="flex" alignItems="center" gap={4}>
                  <Typography variant="caption">{data.leafMiles}</Typography>
                  <Tooltip title="Showing Leaf distance based on PC*Miler Web API. Shipper mileage is not known.">
                    <InfoIcon />
                  </Tooltip>
                </Box>
              ) : (
                <Typography variant="caption">{data.miles}</Typography>
              )}
              {data.type === LANE_TYPE.POWER && (
                <TooltipWrapper>
                  <Tooltip title={<TooltipDialog ptpLanes={data.ptpLanes} />}>
                    <Typography variant="body1">Power</Typography>
                  </Tooltip>
                </TooltipWrapper>
              )}
            </Box>
            <HorizontalLine />
            <Box mr={2} ml={2}>
              {data.shipperId && (
                <CompanyAvatar id={!meta.isDisabled ? data.shipperId : null} tooltip={data.shipperName} />
              )}
            </Box>
          </Distance>
        </div>
        <Destination data={data} />
      </LaneSC>
    </>
  );
};

export default ({
  route = [],
  shouldFitContainerHeight = true,
  shouldFitContainerWidth = false,
  showHorizontalLine = true,
  offsetCount = 1,
  onCardClick,
}) => (
  <Wrapper
    shouldFitContainerHeight={shouldFitContainerHeight}
    offsetCount={offsetCount}
    shouldFitContainerWidth={shouldFitContainerWidth}
    showHorizontalLine={showHorizontalLine}
  >
    {route.map(({ data, meta }, index) =>
      index % 2 === 0 ? (
        <RouteCard
          boxShadow={2}
          $isDisabled={meta?.isDisabled}
          $hasExplorer={data.hasExplorer}
          key={v4()}
          onClick={onCardClick ? () => onCardClick(data, meta) : null}
        >
          <Lane data={data} meta={meta} />
        </RouteCard>
      ) : (
        <Deadhead key={v4()}>
          {isNumber(data.deadheadMiles) && <Typography variant="caption">{data.deadheadMiles} - Deadhead</Typography>}
        </Deadhead>
      ),
    )}
  </Wrapper>
);
