import React, { useState, useContext, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import {
  ServerSideTable,
  PhaseSelect,
  useTableCustomFilters,
  toCustomFilterOptions,
  PHASE_STATUS_DISPLAY_NAMES,
  PHASE_TYPE,
} from '@leaf/components';
import { StateContext } from 'state/StateProvider';
import { default as LanesDomain } from 'domain/adaptBoard/Lanes';
import { SET_ADAPT_BOARD_LANES_TABLE_STATE, SET_ADAPT_BOARD_LANE_PHASES_STATE } from 'state/stateReducer';
import styled from '@emotion/styled';

import { getColumns, getExplorerUrl } from './lanesColumns';

const Actions = styled.div`
  justify-content: center;
  margin-right: 15px;
`;

const Lanes = ({ history, setPhasePanelData, companyId }) => {
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedPageData, setSelectedPageData] = useState(null);
  const { enqueueSnackbar } = useSnackbar();

  const {
    state: {
      tables: { adaptBoardLanes: tableState },
      lanePhases: { lanePhasesByShipper: lanePhases },
    },
    dispatch,
  } = useContext(StateContext);

  const [filterOptions, addFilterOption] = useTableCustomFilters([
    {
      inputType: 'autocomplete',
      columnName: 'status',
      filterOptionsGetter: () =>
        Promise.resolve(
          Object.keys(PHASE_STATUS_DISPLAY_NAMES).map(key => ({
            label: PHASE_STATUS_DISPLAY_NAMES[key],
            value: key,
          })),
        ),
    },
    {
      inputType: 'autocomplete',
      columnName: 'phase',
      filterOptionsGetter: () =>
        Promise.resolve(
          lanePhases.map(item => ({
            label: item.phase,
            value: item.phase,
          })),
        ),
    },
    {
      inputType: 'autocomplete',
      columnName: 'equipmentClass',
      filterOptionsGetter: () => LanesDomain.getEnums('equipment_class'),
    },
  ]);

  useEffect(() => {
    addFilterOption('phase', 'autocomplete', toCustomFilterOptions(lanePhases, 'phase'));
  }, [lanePhases]);

  const updateLanePhaseList = () => {
    LanesDomain.getAllLanePhases()
      .then(data => {
        dispatch({
          type: SET_ADAPT_BOARD_LANE_PHASES_STATE,
          payload: data.shipperPhaseDates
            .filter(item => item.phase !== PHASE_TYPE.OUT_OF_SCOPE)
            .sort((a, b) => a.phase.localeCompare(b.phase)),
        });

        dispatch({ type: SET_ADAPT_BOARD_LANES_TABLE_STATE, payload: { ...tableState } });
        LanesDomain.getShipperSummaryPhases(companyId).then(setPhasePanelData);
        enqueueSnackbar(`Phase successfully updated`, { variant: 'success' });
      })
      .catch(enqueueSnackbar);
  };

  const filterAllLanePhases = () =>
    lanePhases.filter(
      (item, index) =>
        item.phase &&
        item.phase !== PHASE_TYPE.UNASSIGNED &&
        item.phase !== PHASE_TYPE.OUT_OF_SCOPE &&
        lanePhases.findIndex(innerItem => innerItem.startDate === item.startDate) === index,
    );

  const getFilteredPageData = () => {
    if (selectedPageData?.length && selectedRows?.length) {
      let selectedLaneIds = [];
      let selectedEquipmentTypes = [];
      selectedRows.forEach(item => {
        selectedLaneIds = [...selectedLaneIds, selectedPageData[item].laneId];
        selectedEquipmentTypes = [...selectedEquipmentTypes, selectedPageData[item].equipmentClass];
      });
      return { laneIds: selectedLaneIds, equipmentTypes: selectedEquipmentTypes };
    }

    return [];
  };

  const renderCustomToolbarSelect = () => {
    const { laneIds, equipmentTypes } = getFilteredPageData();
    return (
      <Actions>
        <PhaseSelect
          data={filterAllLanePhases()}
          patchData={LanesDomain.patchPhaseStart}
          updateLanePhases={updateLanePhaseList}
          laneIds={laneIds}
          equipmentTypes={equipmentTypes}
          displayEmpty
          variant="outlined"
        />
      </Actions>
    );
  };

  const options = {
    filter: true,
    download: false,
    selectableRows: 'multiple',
    onRowSelectionChange: (_, __, rowsSelected) => setSelectedRows(rowsSelected),
    customToolbarSelect: renderCustomToolbarSelect,
    onRowClick: row => {
      const explorerUrl = getExplorerUrl(row);
      if (explorerUrl) {
        history.push(explorerUrl);
      }
    },
    textLabels: {
      body: {
        noMatch: 'Data not yet available',
      },
    },
  };

  const handleChange = setTableState =>
    dispatch({ type: SET_ADAPT_BOARD_LANES_TABLE_STATE, payload: setTableState(tableState) });

  return (
    <ServerSideTable
      options={options}
      columns={getColumns(tableState, filterOptions, lanePhases, updateLanePhaseList)}
      fn={LanesDomain.getAll}
      tableState={tableState}
      setTableState={handleChange}
      onPageRowsLoadedCb={setSelectedPageData}
    />
  );
};

export default withRouter(Lanes);
