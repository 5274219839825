import React from 'react';
import styled from '@emotion/styled';
import { Box, Typography } from '@mui/material';
import { ArrowRightAlt, Autorenew, Timeline, DoubleArrow } from '@mui/icons-material';

import { TRIP_TYPE, TRIP_TYPE_DISPLAY_NAMES } from '../constants/TripType';

const IconWrapperSC = styled.div`
  margin-right: ${({ theme }) => `${theme.spacing(1)}`};
  svg {
    vertical-align: middle;
  }
`;

const TripTypeIcons = {
  [TRIP_TYPE.ONE_WAY]: <ArrowRightAlt color="disabled" />,
  [TRIP_TYPE.CIRCUIT]: <DoubleArrow color="disabled" />,
  [TRIP_TYPE.CONTINUOUS_MOVE]: <Timeline color="disabled" />,
  [TRIP_TYPE.ROUND_TRIP]: <Autorenew color="disabled" />,
  [TRIP_TYPE.SHORTY]: <Timeline color="disabled" />,
};

const TripType = ({ type }) => {
  const icon = TripTypeIcons[type];
  return (
    <Box display="flex" alignItems="center">
      {icon && <IconWrapperSC>{icon}</IconWrapperSC>}
      <Typography variant="body1">{TRIP_TYPE_DISPLAY_NAMES[type] || type}</Typography>
    </Box>
  );
};

export default TripType;
