import React from 'react';
import { Tooltip } from '@mui/material';

const ConditionalTooltip = ({ children, tooltipText, tooltipPosition = 'top', tooltipArrow = false }) =>
  tooltipText ? (
    <Tooltip title={tooltipText} placement={tooltipPosition} arrow={tooltipArrow}>
      {children}
    </Tooltip>
  ) : (
    <>{children}</>
  );

export default ConditionalTooltip;
