import React, { PureComponent } from 'react';
import { Line as ChartJSLine } from 'react-chartjs-2';
import { merge } from 'lodash';

import { showOnlyFirstAndLast, showAll, addPercentSign, addUnitSign } from './chartsHelper';
import ChartsDefaults from './chartsDefaults';

export default class LineChart extends PureComponent {
  constructor(props) {
    super(props);
    ChartsDefaults.init();
    ChartsDefaults.lineChartInit();
  }

  calcOptions = (rest, optionsOverride) => {
    const optionsGeneratedFromProps = this.calcOptionsFromProps(rest);
    return merge(optionsGeneratedFromProps, optionsOverride || {});
  };

  calcOptionsFromProps(rest) {
    const legend = rest.hasLegend
      ? {
          position: 'bottom',
          labels: {
            boxWidth: 10,
            padding: typeof rest.padding === 'number' ? rest.padding : 30,
            fontColor: '#333333',
          },
          onClick: false,
        }
      : { display: false };

    const title = rest.title
      ? {
          display: true,
          position: 'left',
          text: rest.title,
        }
      : { display: false };

    return {
      title,
      tooltips: { enabled: rest.isTooltipEnabled || false, intersect: false },
      maintainAspectRatio: false,
      legend,
      scales: {
        yAxes: [
          {
            id: 'y',
            stacked: rest.stacked,
            gridLines: {
              display: !rest.doHideYGridLines,
            },
            ticks: {
              beginAtZero: rest.beginYAtZero,
              fontColor: rest.yAxisFontColor,
              stepSize: rest.yAxisStepSize,
              padding: rest.yAxisTicksPadding || 20,
              callback: rest.isPercentBased ? addPercentSign : rest.isUnitBased ? addUnitSign : showAll,
            },
          },
        ],
        xAxes: [
          {
            id: 'x',
            gridLines: {
              display: !rest.doHideXGridLines,
            },
            ticks: {
              fontColor: rest.xAxisFontColor,
              callback: rest.doShowOnlyFirstAndLastXAxisValue ? showOnlyFirstAndLast : showAll,
            },
          },
        ],
      },
    };
  }

  render() {
    const { data, options, isWithRedraw = true, ...rest } = this.props;
    return (
      <div style={{ position: 'relative', width: rest.width || '100%', height: rest.height || '100%' }}>
        <ChartJSLine
          width={rest.width ? rest.width : undefined}
          height={rest.height ? rest.height : undefined}
          data={data}
          options={this.calcOptions(rest, options)}
          redraw={isWithRedraw}
        />
      </div>
    );
  }
}
