import React from 'react';
import { Card, CardContent, CardHeader, Grid } from '@mui/material';
import styled from '@emotion/styled';
import { ShipmentStatus, USER_TYPE, Form } from '@leaf/components';
import PropTypes from 'prop-types';

const CardHeaderSC = styled(CardHeader)`
  .MuiCardHeader-title {
    font-size: 1rem;
  }
`;

const GridItem = styled(Grid)`
  .MuiTextField-root {
    width: 100%;
  }
`;

const ShipmentOverview = ({ shipment, companyType }) => (
  <Card>
    <CardHeaderSC title="Overview" />
    <CardContent>
      <Grid container spacing={2}>
        <GridItem item xs={6}>
          <Form.Generic.Input name="shipperName" label="Shipper" value={shipment.view.shipperName} readOnly />
        </GridItem>
        <GridItem item xs={6}>
          <Form.Generic.Input name="carrierName" label="Carrier" value={shipment.view.carrierName} readOnly />
        </GridItem>
        <GridItem item xs={6}>
          <Form.Generic.Input name="origin" label="Origin" value={shipment.view.originAddress} readOnly />
        </GridItem>
        <GridItem item xs={6}>
          <Form.Generic.Input
            name="destination"
            label="Destination"
            value={shipment.view.destinationAddress}
            readOnly
          />
        </GridItem>
        {companyType === USER_TYPE.SHIPPER && (
          <GridItem item xs={6}>
            <Form.Generic.Input
              name="referenceNumbers"
              label="Shipment references"
              value={shipment.view.referenceNumbers}
              readOnly
            />
          </GridItem>
        )}
        <GridItem item xs={6}>
          <Form.Generic.Input
            name="equipmentName"
            label="Equipment type"
            value={shipment.view.equipmentName}
            readOnly
          />
        </GridItem>
        <GridItem item xs={6}>
          <Form.Generic.Input
            name="executionPartnerShipmentId"
            label="Execution partner ship #"
            value={shipment.executionPartnerShipmentId}
            readOnly
          />
        </GridItem>
        <GridItem item xs={6}>
          <Form.Generic.Input
            name="executionPartner"
            label="Execution partner"
            value={shipment.view.executionPartnerName}
            readOnly
          />
        </GridItem>
        <GridItem item xs={6}>
          <Form.Generic.Input
            name="status"
            label="Status"
            adornment={<ShipmentStatus status={shipment.status} />}
            adornmentPosition="start"
            readOnly
          />
        </GridItem>
      </Grid>
    </CardContent>
  </Card>
);

ShipmentOverview.propTypes = {
  shipment: PropTypes.objectOf(PropTypes.object).isRequired,
  companyType: PropTypes.string.isRequired,
};

export default ShipmentOverview;
