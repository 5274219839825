import React from 'react';
import styled from '@emotion/styled';

const Wrapper = styled.div`
  .MuiTypography-h4,
  .MuiTypography-h5 {
    margin: 16px 0;
  }
  table {
    margin: 16px 0;
    width: 100%;
    border: 1px solid #ddd;
  }
  thead {
    font-weight: bold;
    td {
      padding-bottom: 16px;
    }
  }
  li {
    font-size: 14px;
  }
`;

export default ({ children }) => <Wrapper>{children}</Wrapper>;
