/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import styled from '@emotion/styled';

const ScrollableWrapper = styled.div`
  height: 100%;
  width: 100%;
  overflow-y: auto;
`;

export default React.forwardRef((props, ref) => <ScrollableWrapper ref={ref} {...props} />);
