import React from 'react';

import { Charts } from '../../Common/Components';
import { getLabels, applyIfNotNull, calcColorForValue } from './chartsHelper';
import { sharedChartPropTypes } from './types';
import './shared.scss';
import { numbers } from '../../../utility';
import { formattingTypes } from '../../Constants';

const getDefaultOptions = config => ({
  tooltips: {
    enabled: true,
    intersect: false,
  },
  scales: {
    yAxes: [
      {
        ticks: {
          beginAtZero: config.metrics[0].numberFormat === formattingTypes.PERCENT,
          callback: value => numbers.formatNumberAsView(value, null, false),
        },
      },
    ],
  },
});
const chartWrapperStyle = { width: '100%', height: '100%' };

const DashboardLine = React.memo(({ data, config, metricsOptions = [] }) => {
  const newData = {
    labels: getLabels(data.dimensions[0]),
    datasets: data.metrics.map((metric, index) => {
      const colors = config.metrics[index] && config.metrics[index].colors; // preventing application crash when removing metrics while editing. don't remove this. it should be detailed investigation why is this happening
      const color = calcColorForValue(0, colors);

      return {
        label: config.metrics[index]?.displayName || metricsOptions.find(m => m.name === metric.metric)?.displayName,
        data: metric.values.map(v => applyIfNotNull(v, parseFloat)),
        spanGaps: false, // If false, points with any NaN data will create a break in the line.
        borderColor: color,
        backgroundColor: color,
        pointRadius: 0,
        pointBorderWidth: 2,
        fill: false,
        borderWidth: 2,
      };
    }),
  };
  return (
    <div className="padding-bottom-md padding-horizontal-md flex-column flex-grow">
      <div className="flex-grow">
        <div style={chartWrapperStyle}>
          <Charts.Line
            height="100%"
            width="100%"
            data={newData}
            options={getDefaultOptions(config)}
            hasLegend
            doHideXGridLines
            padding={15}
            isWithRedraw={false}
            isTooltipEnabled
          />
        </div>
      </div>
    </div>
  );
});

DashboardLine.propTypes = sharedChartPropTypes;

export default DashboardLine;
