import React, { useRef, forwardRef, useState } from 'react';
import MUIDataTable from 'mui-datatables';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { Skeleton } from '@mui/material';
import _ from 'lodash';
import { debounceCustomSearchRender } from '../CustomSearchField';
import './OverviewTable.scss';

export const ROWS_PER_PAGE = 50;

const scrollToTop = tableRef => tableRef?.current?.tableRef?.scrollIntoView?.({ block: 'start' });

export const onTableChange =
  ({ setTableState, tableRef, columns }) =>
  (action, newTableState) => {
    switch (action) {
      case 'changeRowsPerPage': {
        setTableState(previousTableState => ({
          ...previousTableState,
          rowsPerPage: newTableState.rowsPerPage,
        }));
        break;
      }
      case 'viewColumnsChange': {
        const viewColumns = newTableState.columns.reduce((acc, column) => {
          acc[column.name] = column.display;
          return acc;
        }, {});
        setTableState(previousTableState => ({
          ...previousTableState,
          display: viewColumns,
        }));
        break;
      }
      case 'changePage': {
        setTableState(previousTableState => ({
          ...previousTableState,
          page: newTableState.page,
        }));
        break;
      }
      case 'search': {
        const searchText = newTableState.searchText?.trim();
        scrollToTop(tableRef);
        setTableState(previousTableState => ({ ...previousTableState, search: searchText, page: 0 }));
        break;
      }
      case 'sort': {
        const { name, direction } = newTableState.sortOrder;
        scrollToTop(tableRef);
        // 'name' can be different from the backend, therefore we can use 'field'
        const field = _.find(columns, { name })?.field;
        setTableState(previousTableState => ({
          ...previousTableState,
          sort: { name, field, order: direction },
          page: 0,
        }));
        break;
      }
      case 'filterChange': {
        scrollToTop(tableRef);
        break;
      }
      default: {
        break;
      }
    }
  };

const OverviewTable = styled(MUIDataTable)`
  width: 100%;
  .center-aligned {
    & > span {
      justify-content: center;
    }
    & > div {
      text-align: center;
    }
  }

  .right-aligned {
    & > span {
      justify-content: flex-end;
    }
    & > div {
      text-align: right;
    }
  }

  .right-sticky-column {
    position: sticky;
    right: 0;
    padding: 0 ${({ theme }) => theme.spacing(1)};
    &:hover {
      background-color: #eeeeee;
    }
  }

  ${props =>
    props?.options?.onRowClick &&
    css`
      .MuiTableRow-root {
        cursor: pointer;
      }
    `}
`;

const SkeletonCell = styled(Skeleton)`
  width: 200px;
`;

const getOptions = ({ tableRef, customSearch, searchAutoFocus, columns, tableState, setTableState, isServerSide }) => ({
  searchAlwaysOpen: customSearch,
  customSearchRender: customSearch ? debounceCustomSearchRender(500, searchAutoFocus) : null,
  enableNestedDataAccess: '.',
  filterArrayFullMatch: false,
  print: false,
  download: true,
  rowsPerPage: ROWS_PER_PAGE,
  rowsPerPageOptions: [],
  selectableRows: 'none',
  tableBodyMaxHeight: '100%',
  responsive: 'standard',
  searchPlaceholder: 'Search',
  onChangePage: () => scrollToTop(tableRef),
  ...(!isServerSide && {
    page: tableState?.page ?? 0,
    onTableChange: onTableChange({ setTableState, tableRef, columns }),
  }),
});

const generateSkeleton = columns => {
  const ds = columns.map(column => ({
    [column.name]: 'Skeleton',
  }));
  const cs = columns.map(column => ({
    label: column.label,
    name: column.name,
    options: {
      customBodyRenderLite: () => <SkeletonCell variant="text" />,
    },
  }));

  return [ds, cs];
};

export const getColumns = (columns, tableState) => {
  if (tableState) {
    return columns.map(column => {
      const columnFilterName = column.field ?? column.name;
      return {
        ...column,
        options: {
          ...column.options,
          display: tableState.display?.[column.name] ?? column.options?.display ?? 'true',
          ...(tableState.filters?.[columnFilterName] && {
            filterList: tableState.filters?.[columnFilterName]?.flat(1),
          }),
        },
      };
    });
  }
  return columns;
};

/**
 * Known bugs and improvements:
 *
 * Search bar does not respect fullWidth
 */
export default forwardRef(
  (
    { isLoading, options: propsOptions, customSearch = true, searchAutoFocus = false, columns: propColumns, ...props },
    ref,
  ) => {
    const [tableState, setTableState] = useState(null);
    const tableRef = useRef(null);
    const refToUse = ref ?? tableRef;

    const { serverSide } = { serverSide: propsOptions || undefined };

    if (isLoading) {
      const [data, columns] = generateSkeleton(propColumns);

      if (serverSide) {
        return (
          <OverviewTable
            title={props.title}
            data={data}
            columns={columns}
            options={{
              onRowClick: null,
            }}
            components={{ TableFilterList: props.components?.TableFilterList }}
            className="skeleton-table"
          />
        );
      }
      return <OverviewTable data={data} columns={columns} className="skeleton-table" />;
    }
    return (
      <OverviewTable
        ref={refToUse}
        options={{
          ...getOptions({
            tableRef: refToUse,
            customSearch,
            searchAutoFocus,
            columns: propColumns,
            tableState,
            setTableState,
            isServerSide: serverSide,
          }),
          ...propsOptions,
        }}
        columns={getColumns(propColumns, tableState)}
        {...props}
      />
    );
  },
);
