import React from 'react';
import { DetailsOverview } from '@leaf/components';

export default React.memo(({ opportunity }) => (
  <DetailsOverview
    title="FLEET OPPORTUNITY DETAILS"
    data={[
      {
        type: 'row',
        data: {
          label: 'Contract',
          value: opportunity?.contract,
        },
      },
      {
        type: 'row',
        data: {
          label: 'Trucks',
          value: opportunity?.trucks,
        },
      },
      {
        type: 'row',
        data: {
          label: 'Average Weekly Mileage',
          value: opportunity?.avgWeeklyTotalMiles,
        },
      },
    ]}
  />
));
