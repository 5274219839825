import React from 'react';
import { Controller } from 'react-hook-form';
import Weekpicker from '../generic/Weekpicker';

export default ({ name, startLabel, endLabel, control, variant = 'outlined', fullWidth = false, error }) => (
  <Controller
    as={<Weekpicker startText={startLabel} endText={endLabel} variant={variant} fullWidth={fullWidth} error={error} />}
    name={name}
    control={control}
  />
);
