import React from 'react';
import { IconButton, Tooltip } from '@mui/material';
import { Launch as LaunchIcon } from '@mui/icons-material';

const NewTabLink = React.memo(({ url }) => {
  const handleOpenLink = event => {
    event.stopPropagation();
    window.open(url, '_blank').focus();
  };

  return (
    <Tooltip title="Open details in new tab">
      <IconButton onClick={handleOpenLink}>
        <LaunchIcon />
      </IconButton>
    </Tooltip>
  );
});

export default NewTabLink;
