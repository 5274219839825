/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import MUIDataTable from 'mui-datatables';

const defaultOptions = {
  print: false,
  download: false,
  filter: false,
  viewColumns: false,
  search: false,
  pagination: false,
  selectableRows: 'none',
  enableNestedDataAccess: '.',
  filterArrayFullMatch: false,
};

export default ({ options, ...rest }) => {
  const mergedOptions = options ? Object.assign(defaultOptions, options) : defaultOptions;

  return <MUIDataTable {...rest} options={mergedOptions} />;
};
