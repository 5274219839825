import { Chart } from 'react-chartjs-2';

const LINE_COLOR = '#4a90e2';

export const showVerticalLineOnTooltipHover = () => {
  Chart.pluginService.register({
    afterDraw: chart => {
      // eslint-disable-next-line no-underscore-dangle
      const activePoints = chart.tooltip._active;

      if (activePoints && activePoints.length) {
        const activePoint = activePoints[0];
        const { ctx } = chart;
        const { x } = activePoint.tooltipPosition();
        const topY = chart.scales['y-axis-0']?.top;
        const bottomY = chart.scales['y-axis-0']?.bottom;

        ctx.save();
        ctx.beginPath();
        ctx.moveTo(x, topY);
        ctx.lineTo(x, bottomY);
        ctx.lineWidth = 2;
        ctx.strokeStyle = LINE_COLOR;
        ctx.stroke();
        ctx.restore();
      }
    },
  });
};
