import React, { useContext, useEffect } from 'react';
import styled from '@emotion/styled';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import memoize from 'memoize-one';
import { FilterList } from '@mui/icons-material';
import { Popover, Button, Typography } from '@mui/material';
import DashboardContext from '../DashboardContext';
import { ActionLink, Scrollbar, SearchBox, Badge, Checkbox } from '../Common/Components';
import { companyDashboardNames, filterTypes } from '../Constants';
import { makeEmptySelectedByTypeObj, getActiveFilters, setActiveFilters } from '../paramsHelper';

import './FiltersPopup.scss';

const PopoverWrapper = styled(Popover)`
  .MuiPaper-root {
    box-shadow: 0 1px 5px rgb(0, 0, 0, 0.12);
    font-size: 14px;
    font-weight: 500;
  }
`;

const getConditionalFilters = (setFilters, userType, state) => {
  const filters = [];
  if (userType !== 'admin') {
    filters.push({
      type: filterTypes.COUNTERPARTIES,
      displayName: userType === 'carrier' ? 'Shippers' : 'Carriers',
      onClick: () => setFilters(filterTypes.COUNTERPARTIES),
    });
  } else if ([companyDashboardNames.CARRIER, companyDashboardNames.SHIPPER].includes(state.name)) {
    filters.push(
      ...(state.name === companyDashboardNames.SHIPPER
        ? [
            {
              type: filterTypes.CARRIERS,
              displayName: 'Carriers',
              onClick: () => setFilters(filterTypes.CARRIERS),
            },
          ]
        : []),
      ...(state.name === companyDashboardNames.CARRIER
        ? [
            {
              type: filterTypes.SHIPPERS,
              displayName: 'Shippers',
              onClick: () => setFilters(filterTypes.SHIPPERS),
            },
          ]
        : []),
    );
  } else {
    filters.push(
      {
        type: filterTypes.CARRIERS,
        displayName: 'Carriers',
        onClick: () => setFilters(filterTypes.CARRIERS),
      },
      {
        type: filterTypes.SHIPPERS,
        displayName: 'Shippers',
        onClick: () => setFilters(filterTypes.SHIPPERS),
      },
      {
        type: filterTypes.COHORTS,
        displayName: 'Cohorts',
        onClick: () => setFilters(filterTypes.COHORTS),
      },
      {
        type: filterTypes.TRANSPORTATION_MODES,
        displayName: 'Transportation modes',
        onClick: () => setFilters(filterTypes.TRANSPORTATION_MODES),
      },
    );
  }
  return filters;
};

const getFilters = (setFilters, userType, state) => [
  {
    type: filterTypes.LANES,
    displayName: 'Lanes',
    onClick: () => setFilters(filterTypes.LANES),
  },
  {
    type: filterTypes.ORIGINS,
    displayName: 'Origin',
    onClick: () => setFilters(filterTypes.ORIGINS),
  },
  {
    type: filterTypes.DESTINATIONS,
    displayName: 'Destination',
    onClick: () => setFilters(filterTypes.DESTINATIONS),
  },
  {
    type: filterTypes.EQUIPMENT,
    displayName: 'Equipment',
    onClick: () => setFilters(filterTypes.EQUIPMENT),
  },
  {
    type: filterTypes.CONTRACT_TYPES,
    displayName: 'Contract type',
    onClick: () => setFilters(filterTypes.CONTRACT_TYPES),
  },
  {
    type: filterTypes.NETWORK_MOVES,
    displayName: 'Network moves',
    onClick: () => setFilters(filterTypes.NETWORK_MOVES),
  },
  {
    type: filterTypes.REPORTING_LANES,
    displayName: 'Reporting lanes',
    onClick: () => setFilters(filterTypes.REPORTING_LANES),
  },
  ...getConditionalFilters(setFilters, userType, state),
];

const FiltersPopup = ({
  isLoading,
  userType,
  lanes,
  destinations,
  origins,
  contractTypes,
  equipment,
  counterparties,
  shippers,
  carriers,
  cohorts,
  transportationModes,
  networkMoves,
  reportingLanes,
}) => {
  const { state, setState } = useContext(DashboardContext);
  const [popupAnchorEl, setPopupAnchorEl] = React.useState(null);

  const isLaneDisabled = counts => counts[filterTypes.ORIGINS] > 0 || counts[filterTypes.DESTINATIONS] > 0;

  const calcFilterCount = (type, selectedByType) => {
    const selected = selectedByType?.[type] || [];
    let count = 0;
    Object.keys(selected).forEach(id => {
      count += selected[id] === true ? 1 : 0;
    });
    return count;
  };

  const setActiveList = listType =>
    setState(currentState => ({
      filtersPopup: { ...currentState.filtersPopup, activeFilterList: listType, searchTerm: '' },
    }));

  const calcAllFilterCounts = memoize(selectedByType => {
    const counts = {};
    getFilters(setActiveList, userType, state).forEach(f => {
      counts[f.type] = calcFilterCount(f.type, selectedByType);
    });
    return counts;
  });
  const getInitialState = () => {
    const initialState = {
      searchTerm: '',
      activeFilterList: filterTypes.LANES,
      selectedByType: makeEmptySelectedByTypeObj(),
    };
    initialState.selectedByType = Object.assign(initialState.selectedByType, getActiveFilters(state));
    const counts = calcAllFilterCounts(initialState.selectedByType);
    if (isLaneDisabled(counts)) {
      initialState.activeFilterList = filterTypes.ORIGINS;
    }
    return initialState;
  };

  const setInitialState = () => setState({ filtersPopup: { ...getInitialState() } });

  useEffect(() => {
    setInitialState();
  }, []);

  const renderFilterTypeOption = ({ displayName, isActive, count, onClick, index, isDisabled }) => (
    <div
      key={index}
      className={classNames('filters-popup__filter-type-item', {
        'filters-popup__filter-type-item--active': isActive,
        'filters-popup__filter-type-item--disabled': isDisabled,
      })}
      onClick={isDisabled ? undefined : onClick}
    >
      {displayName}
      {count > 0 && <Badge count={count} />}
    </div>
  );

  const renderFilterTypes = () => {
    const counts = calcAllFilterCounts(state.filtersPopup.selectedByType);
    const isOriginDestinationDisabled = counts[filterTypes.LANES] > 0;
    return (
      <div className="filters-popup__filter-types-section">
        {getFilters(setActiveList, userType, state).map((f, i) =>
          renderFilterTypeOption({
            ...f,
            isActive: state.filtersPopup.activeFilterList === f.type,
            count: counts[f.type],
            index: i,
            isDisabled:
              (f.type === filterTypes.LANES && isLaneDisabled(counts)) ||
              (f.type === filterTypes.ORIGINS && isOriginDestinationDisabled) ||
              (f.type === filterTypes.DESTINATIONS && isOriginDestinationDisabled),
          }),
        )}
      </div>
    );
  };

  const toggleSearchableListOption = (filterType, id) => () =>
    setState(currentState => ({
      ...currentState,
      filtersPopup: {
        ...currentState.filtersPopup,
        selectedByType: {
          ...currentState.filtersPopup.selectedByType,
          [filterType]: {
            ...currentState.filtersPopup.selectedByType[filterType],
            [id]: !currentState.filtersPopup.selectedByType[filterType]?.[id],
          },
        },
      },
    }));

  const renderListOption = (text, isChecked, onClick, index) => (
    <div
      key={index}
      role="button"
      tabIndex={0}
      className={classNames('filters-popup__list-option', { 'filters-popup__list-option--even': index % 2 })}
      onClick={onClick}
    >
      <Checkbox isChecked={isChecked} onChangeHandler={onClick} />
      {text}
    </div>
  );

  const renderNameOnlyItem = (items, selected) => {
    const {
      filtersPopup: { activeFilterList },
    } = state;
    return items.map((item, index) =>
      renderListOption(item, selected[item] === true, toggleSearchableListOption(activeFilterList, item), index),
    );
  };

  const renderIdNameItem = (items, selected) => {
    const {
      filtersPopup: { activeFilterList },
    } = state;
    return items.map((item, index) =>
      renderListOption(
        item.name,
        selected[item.guid] === true,
        toggleSearchableListOption(activeFilterList, item.guid),
        index,
      ),
    );
  };

  const filterStringsArr = (items = []) => {
    const searchTerm = state.filtersPopup.searchTerm.toLowerCase();
    return items.filter(i => i && i.toLowerCase().indexOf(searchTerm) !== -1);
  };

  const filterItems = (items = []) => {
    const searchTerm = state.filtersPopup.searchTerm.toLowerCase();
    return items.filter(i => i?.name && i.name.toLowerCase().indexOf(searchTerm) !== -1);
  };

  const renderSearchableListOptions = () => {
    const {
      filtersPopup: { selectedByType, activeFilterList },
    } = state;
    const selected = selectedByType?.[activeFilterList] || [];

    switch (activeFilterList) {
      case filterTypes.LANES: {
        const lanesFiltered = filterStringsArr(lanes);
        return renderNameOnlyItem(lanesFiltered, selected);
      }
      case filterTypes.DESTINATIONS: {
        const destinationsFiltered = filterStringsArr(destinations);
        return renderNameOnlyItem(destinationsFiltered, selected);
      }
      case filterTypes.ORIGINS: {
        const originsFiltered = filterStringsArr(origins);
        return renderNameOnlyItem(originsFiltered, selected);
      }
      case filterTypes.EQUIPMENT: {
        const equipmentFiltered = filterItems(equipment);
        return renderIdNameItem(equipmentFiltered, selected);
      }
      case filterTypes.CONTRACT_TYPES: {
        const contractTypesFiltered = filterItems(contractTypes);
        return renderIdNameItem(contractTypesFiltered, selected);
      }
      case filterTypes.COUNTERPARTIES: {
        const counterpartiesFiltered = filterItems(counterparties);
        return renderIdNameItem(counterpartiesFiltered, selected);
      }
      case filterTypes.SHIPPERS: {
        const shippersFiltered = filterItems(shippers);
        return renderIdNameItem(shippersFiltered, selected);
      }
      case filterTypes.CARRIERS: {
        const carriersFiltered = filterItems(carriers);
        return renderIdNameItem(carriersFiltered, selected);
      }
      case filterTypes.COHORTS: {
        const cohortsFiltered = filterStringsArr(cohorts);
        return renderNameOnlyItem(cohortsFiltered, selected);
      }
      case filterTypes.TRANSPORTATION_MODES: {
        const transportationModesFiltered = filterStringsArr(transportationModes);
        return renderNameOnlyItem(transportationModesFiltered, selected);
      }
      case filterTypes.NETWORK_MOVES: {
        const networkMovesFiltered = filterItems(networkMoves);
        return renderIdNameItem(networkMovesFiltered, selected);
      }
      case filterTypes.REPORTING_LANES: {
        const reportingLanesFiltered = filterStringsArr(reportingLanes);
        return renderNameOnlyItem(reportingLanesFiltered, selected);
      }
      default:
        return null;
    }
  };

  const handleSearchTermChange = text =>
    setState(currentState => ({ ...currentState, filtersPopup: { ...currentState.filtersPopup, searchTerm: text } }));

  const closePopup = () => setPopupAnchorEl(null);

  const resetDefault = () => {
    const newState = {
      filtersPopup: {
        searchTerm: '',
        activeFilterList: filterTypes.LANES,
        selectedByType: makeEmptySelectedByTypeObj(),
      },
    };
    setActiveFilters(
      {
        ...newState.filtersPopup.selectedByType,
        from: state.activeFilters.from,
        to: state.activeFilters.to,
      },
      setState,
      state,
    );
    closePopup();
  };

  const applyFilters = () => {
    setActiveFilters(state.filtersPopup.selectedByType, setState, state);
    closePopup();
  };

  const renderSearchableList = () => (
    <div className="filters-popup__searchable-list">
      <SearchBox
        style={{ width: '100%' }}
        key={state.filtersPopup.activeFilterList}
        initialSearchText=""
        onTextChange={handleSearchTermChange}
      />
      <Scrollbar className="filters-popup__search-results">{renderSearchableListOptions()}</Scrollbar>
      <div className="filters-popup__searchable-list-button-row">
        <ActionLink onClick={resetDefault}>Remove all filters</ActionLink>
        <Button variant="contained" color="secondary" onClick={applyFilters}>
          Apply
        </Button>
      </div>
    </div>
  );

  const handleTriggerClick = event => {
    setInitialState();
    setPopupAnchorEl(event.currentTarget);
  };

  const handlePopupClose = () => {
    setPopupAnchorEl(null);
  };

  return (
    <>
      <Button variant="outlined" size="small" onClick={handleTriggerClick} disabled={isLoading}>
        <FilterList />
        &nbsp;<Typography variant="caption">Filter</Typography>
      </Button>
      <PopoverWrapper
        open={Boolean(popupAnchorEl)}
        onClose={handlePopupClose}
        anchorEl={popupAnchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <div className="filters-popup">
          {renderFilterTypes()}
          {renderSearchableList()}
        </div>
      </PopoverWrapper>
    </>
  );
};
const ItemType = PropTypes.shape({
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  name: PropTypes.string,
});

FiltersPopup.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  equipment: PropTypes.arrayOf(ItemType).isRequired,
  lanes: PropTypes.arrayOf(PropTypes.string).isRequired,
  origins: PropTypes.arrayOf(PropTypes.string).isRequired,
  destinations: PropTypes.arrayOf(PropTypes.string).isRequired,
  contractTypes: PropTypes.arrayOf(ItemType).isRequired,
  counterparties: PropTypes.arrayOf(ItemType),
  shippers: PropTypes.arrayOf(ItemType),
  carriers: PropTypes.arrayOf(ItemType),
  cohorts: PropTypes.arrayOf(PropTypes.string),
  transportationModes: PropTypes.arrayOf(PropTypes.string),
  networkMoves: PropTypes.arrayOf(PropTypes.string).isRequired,
  reportingLanes: PropTypes.arrayOf(ItemType),
  userType: PropTypes.string.isRequired,
};

FiltersPopup.defaultProps = {
  shippers: [],
  carriers: [],
  transportationModes: [],
  counterparties: [],
  cohorts: [],
  reportingLanes: [],
};

export default FiltersPopup;
