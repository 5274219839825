import Address from 'domain/location/Address';
import { utility } from '@leaf/components';
import { extractAddress } from 'shipments/utilities';

export default class TripSimple {
  constructor({ origin, destination, ...response }) {
    Object.assign(this, {
      laneDestination: new Address(destination),
      laneOrigin: new Address(origin),
      ...response,
    });

    const laneOrigin = extractAddress(origin);
    const laneDestination = extractAddress(destination);

    this.view = {
      originAddress: [
        `${laneOrigin.city}, ${laneOrigin.state} ${laneOrigin.zipCode}`,
        `${laneOrigin.businessName}, ${laneOrigin.street}`,
      ],
      destinationAddress: [
        `${laneDestination.city}, ${laneDestination.state} ${laneDestination.zipCode}`,
        `${laneDestination.businessName}, ${laneDestination.street}`,
      ],
      stops: this.stops || '-',
      tripId: this.tripId || '-',
      shipper: Array.isArray(this.shippers) && this.shippers.join(', '),
      equipment: this.equipmentTypeName || '-',
      status: this.status || '-',
      startDate: utility.date.stringTo3LetterMonthDayAndYear(this.startDate),
    };
  }
}
