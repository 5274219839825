/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Switch } from '@mui/material';

export default ({ label, name, ...rest }) => (
  <label htmlFor={name}>
    <Switch name={name} {...rest} />
    {label}
  </label>
);
