import React from 'react';
import styled from '@emotion/styled';
import { Box, Typography } from '@mui/material';
import { grey } from '@mui/material/colors';
import {
  CheckCircle,
  Error,
  CancelRounded,
  Label,
  LocalShipping,
  LabelOff,
  MoneyOff,
  MonetizationOn,
} from '@mui/icons-material';
import { Tonu } from '../icons';

import { SHIPMENT_STATUS, SHIPMENT_STATUS_DISPLAY_NAMES } from '../constants/ShipmentStatus';

const LabelOffIcon = styled(LabelOff)`
  color: ${grey[400]};
`;
const LabelIcon = styled(Label)`
  color: #4a90e2;
`;
const DeliveredIcon = styled(CheckCircle)`
  color: #4caf50;
`;
const TenderedIcon = styled(CheckCircle)`
  color: ${grey[400]};
`;
const CanceledIcon = styled(CancelRounded)`
  color: ${grey[400]};
`;
const FailedIcon = styled(CancelRounded)`
  color: #f88078;
`;
const MonetizationOnIcon = styled(MonetizationOn)`
  color: #4a90e2;
`;
const InTransitIcon = styled(LocalShipping)`
  color: #4a90e2;
`;
const DispatchedIcon = styled(LocalShipping)`
  color: ${grey[400]};
`;
const MoneyOffIcon = styled(MoneyOff)`
  color: ${grey[400]};
`;
const UnknownIcon = styled(Error)`
  color: #ccc;
`;

const IconWrapperSC = styled.div`
  margin-right: ${({ theme }) => `${theme.spacing(1)}`};
  svg {
    vertical-align: middle;
  }
`;

const ShipmentStatusIcons = {
  [SHIPMENT_STATUS.DECLINED]: <CanceledIcon />,
  [SHIPMENT_STATUS.QUOTES_RECEIVED]: <MonetizationOnIcon />,
  [SHIPMENT_STATUS.QUOTES_REQUESTED]: <MoneyOffIcon />,
  [SHIPMENT_STATUS.UNASSIGNED]: <LabelOffIcon />,
  [SHIPMENT_STATUS.ASSIGNED]: <LabelIcon />,
  [SHIPMENT_STATUS.NOT_TENDERED]: <CanceledIcon />,
  [SHIPMENT_STATUS.DISPATCHED]: <DispatchedIcon />,
  [SHIPMENT_STATUS.DELIVERED]: <DeliveredIcon />,
  [SHIPMENT_STATUS.TENDERED]: <TenderedIcon />,
  [SHIPMENT_STATUS.CANCELED]: <CanceledIcon />,
  [SHIPMENT_STATUS.FAILED]: <FailedIcon />,
  [SHIPMENT_STATUS.IN_TRANSIT]: <InTransitIcon />,
  [SHIPMENT_STATUS.UNKNOWN]: <UnknownIcon />,
  [SHIPMENT_STATUS.COMPLETED]: <DeliveredIcon />,
  [SHIPMENT_STATUS.TONU]: <Tonu />,
};

const ShipmentStatus = ({ status = SHIPMENT_STATUS.UNKNOWN }) => (
  <Box display="flex" alignItems="center">
    <IconWrapperSC>{ShipmentStatusIcons[status]}</IconWrapperSC>
    <Typography variant="body1">
      {SHIPMENT_STATUS_DISPLAY_NAMES[status] || SHIPMENT_STATUS_DISPLAY_NAMES[SHIPMENT_STATUS.UNKNOWN]}
    </Typography>
  </Box>
);

export default ShipmentStatus;
