import React from 'react';
import { Grid, Typography } from '@mui/material';
import styled from '@emotion/styled';
import axios from 'axios';

import { theme, ConditionalTooltip, DataNotAvailable, CompanyAvatar } from '@leaf/components';

import { H5, Body2, Caption } from 'company/components/Typography';
import { getTooltipValue } from './companyMetricsTooltips';

import CompanyMetric from './CompanyMetric';

export const FullHeightGrid = styled(Grid)`
  height: 100vh;
  overflow-y: hidden;
`;

const CompanyName = styled(H5)`
  margin-bottom: 0.5em;
`;

export const LabelPair = styled(Grid)`
  display: flex;
  justify-content: space-between;
  padding-bottom: 1em;
  padding-top: 1em;
`;

const CompanyWrapper = styled(Grid)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 200;
  box-sizing: content-box;
  margin-top: 3em;
  padding-bottom: 1em;
  border-bottom: 1px solid ${theme.palette.divider};
`;

const CompanyMetricWrapper = styled(Grid)`
  margin-top: 1.5em;
  margin-bottom: 1.5em;
`;

const DescriptionWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: rgba(0, 0, 0, 0.38);
  margin-top: 6em;
  font-weight: 500;
`;

const GridContainer = styled(Grid)`
  background-color: rgb(250, 250, 250);
  margin-left: ${({ theme: styleTheme }) => styleTheme.spacing(2)};
  padding-right: ${({ theme: styleTheme }) => styleTheme.spacing(2)};
  height: 100vh;
`;

export default ({ company, adapt }) => {
  const hasAdaptStats = adapt.runDate && adapt.windowStartDate && adapt.windowEndDate;

  return (
    <FullHeightGrid container spacing={4}>
      <Grid item xs={12}>
        <CompanyWrapper item xs={12}>
          <CompanyAvatar name={company.name} id={company.id} client={axios} width="100px" height="100px" />

          <CompanyName>{company.name}</CompanyName>

          <Body2>Business Overview</Body2>
        </CompanyWrapper>
      </Grid>

      <GridContainer item xs={12}>
        <Grid item xs={12}>
          {adapt.metrics && adapt.metrics?.length ? (
            <>
              <Grid item xs={12}>
                <LabelPair>
                  <Caption>Metric</Caption>
                  <Caption>Historical annual value</Caption>
                </LabelPair>
              </Grid>

              <Grid item xs={12}>
                {adapt.metrics.map(metric => (
                  <ConditionalTooltip
                    key={metric.name}
                    tooltipText={getTooltipValue(metric.name)}
                    tooltipPosition="top"
                    tooltipArrow
                  >
                    <CompanyMetricWrapper key={metric.name} item xs={12}>
                      <CompanyMetric data={metric} />
                    </CompanyMetricWrapper>
                  </ConditionalTooltip>
                ))}
              </Grid>
            </>
          ) : (
            <DataNotAvailable />
          )}
        </Grid>

        {hasAdaptStats && (
          <Grid item xs={12}>
            <DescriptionWrapper>
              <Typography variant="body1">Analyzed via Adapt on {adapt.runDate}</Typography>
              <Typography variant="body1">Based on historical shipments from</Typography>
              <Typography variant="body1">
                {adapt.windowStartDate} to {adapt.windowEndDate}
              </Typography>
            </DescriptionWrapper>
          </Grid>
        )}
      </GridContainer>
    </FullHeightGrid>
  );
};
