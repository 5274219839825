import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import Select from './Select';

import './ConfigurableOrderRow.scss';

const ConfigurableOrderRow = ({ options, onOrderSelect, selectedOrderId, className }) =>
  options ? (
    <div className={classNames('dashboard-chart-configurable-order-row margin-bottom-base', className)}>
      <Select options={options} onSelect={onOrderSelect} selectedId={selectedOrderId} />
    </div>
  ) : null;

ConfigurableOrderRow.propTypes = {
  options: PropTypes.array.isRequired,
  onOrderSelect: PropTypes.func.isRequired,
  selectedOrderId: PropTypes.any.isRequired,
  className: PropTypes.string,
};

export default React.memo(ConfigurableOrderRow);
