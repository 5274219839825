const REFERENCE_NUMBER_TYPES = {
  shipmentId: 'Ship#',
  poNumber: 'PO#',
};

const addTypeToReferenceNumber = referenceNumbers => {
  const type = REFERENCE_NUMBER_TYPES[referenceNumbers.type];

  return referenceNumbers.values.map(referenceNumber => {
    return `${referenceNumber} (${type})`;
  });
};

export const formatAsViewReferenceNumbers = referenceNumbers => {
  return (
    referenceNumbers?.reduce((a, b) => {
      return addTypeToReferenceNumber(b).join(', ');
    }, '') || '-'
  );
};
