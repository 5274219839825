import { useAuth0 } from '@auth0/auth0-react';
import { GraphQLClient } from 'graphql-request';
import React from 'react';
import { useHistory } from 'react-router-dom';

export default () => {
  const history = useHistory();
  const { getAccessTokenSilently } = useAuth0();

  return () =>
    getAccessTokenSilently()
      .then(
        token =>
          new GraphQLClient(process.env.REACT_APP_HASURA_GQL_ENDPOINT, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }),
      )
      .catch(() => {
        history.push('/continue');
      });
};

const gqlClient = new GraphQLClient(process.env.REACT_APP_HASURA_GQL_ENDPOINT);

/**
 *
 * @returns gqlClient with Authorization token
 */
export const useGQLClientWithAuth = () => {
  const history = useHistory();
  const { getAccessTokenSilently } = useAuth0();

  const [clientReady, setClientReady] = React.useState(null);
  const [loading, setLoading] = React.useState(true);
  const setClientAuthHeader = async () => {
    try {
      const accessToken = await getAccessTokenSilently();
      gqlClient.setHeader('Authorization', `Bearer ${accessToken}`);
      setClientReady(gqlClient);
      setLoading(false);
    } catch (e) {
      history.push('/continue');
    }
  };
  setClientAuthHeader();
  return [clientReady, loading];
};

export const useSecureQuery = (QUERY, variables) => {
  const [secureClient] = useGQLClientWithAuth();
  const [data, setData] = React.useState();
  const [loading, setLoading] = React.useState(true);
  const [error, setError] = React.useState();
  const needToFetch = React.useRef(true);

  React.useEffect(() => {
    if (needToFetch.current && secureClient) {
      secureClient
        .request(QUERY, variables)
        .then(res => setData(res))
        .catch(err => setError(err))
        .finally(() => {
          needToFetch.current = false;
          setLoading(false);
        });
    }
  }, [QUERY, variables, secureClient]);

  return [data, loading, error];
};
