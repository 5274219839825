import { ORDER_STATUS } from '@leaf/components';
import ShipmentSimple from 'domain/shipments/ShipmentSimple';

export default {
  tables: {
    shipmentsOverview: {
      page: 0,
      rowsPerPage: 50,
      search: '',
      display: {},
      filters: {},
      onDownloadHandler: ShipmentSimple.download,
    },
    contractShipmentsOverview: {
      page: 0,
      rowsPerPage: 50,
      search: '',
      display: {},
      filters: {},
    },
    contractTripsOverview: {
      page: 0,
      rowsPerPage: 50,
      search: '',
      display: {},
      filters: {},
    },
    contractsOverview: {
      page: 0,
      rowsPerPage: 50,
      search: '',
      display: {},
      filters: {},
      quickFilters: {},
    },
    ordersByLaneOverview: {
      page: 0,
      rowsPerPage: 50,
      search: '',
      display: {},
      filters: {},
    },
    ordersByMoveOverview: {
      page: 0,
      rowsPerPage: 50,
      search: '',
      display: {},
      sort: { name: 'orderLanes', field: 'origin', order: 'asc' },
      filters: { status: [[ORDER_STATUS.DRAFT, ORDER_STATUS.IN_PLANNING, ORDER_STATUS.READY_TO_CONTRACT]] },
    },
    adaptBoardLanes: {
      page: 0,
      rowsPerPage: 50,
      search: '',
      sort: { name: 'view.phases', field: 'phase', order: 'asc' },
      display: {},
      filters: {},
    },
    adaptBoardNetworkMoves: {
      page: 0,
      rowsPerPage: 50,
      search: '',
      display: {},
      filters: {},
      sort: { name: 'view.minTrips', field: 'minTrips', order: 'desc' },
    },
  },
  lanePhases: {
    lanePhasesByShipper: [],
  },
  interests: {
    destinationStates: {},
    originStates: {},
    userInterestId: '',
  },
  companies: [],
  adapt: null,
};
