/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { TextField } from '@mui/material';
import enLocale from 'date-fns/locale/en-US';
import { LocalizationProvider, MobileTimePicker } from '@mui/x-date-pickers-pro';
import { AdapterDateFns as DateFnsAdapter } from '@mui/x-date-pickers-pro/AdapterDateFns';

export default React.memo(({ inputVariant = 'outlined', ...props }) => (
  <LocalizationProvider dateAdapter={DateFnsAdapter} locale={enLocale}>
    <MobileTimePicker
      renderInput={inputProps => <TextField fullWidth variant={inputVariant} {...inputProps} error={props.required} />}
      minutesStep={5}
      autoOk
      ampmInClock
      {...props}
    />
  </LocalizationProvider>
));
