import React from 'react';
import { scaleLinear, scaleTime, scaleSqrt, extent, select, selectAll, axisLeft, axisBottom, timeFormat } from 'd3';

const magic = {
  margins: { top: 20, right: 20, bottom: 80, left: 40 },
};

const DAYS = { 0: 'Sun', 1: 'Mon', 2: 'Tue', 3: 'Wed', 4: 'Thu', 5: 'Fri', 6: 'Sat' };

const WeeklyVolumeHeatmap = ({ data }) => {
  const svg = React.useRef();

  React.useEffect(() => {
    selectAll('#weekly-volume-heatmap > *').remove();

    const RECT = 20;
    const N_WEEKS = 52;
    const N_DAYS = 7;
    const GAP = RECT - 4;

    const width = RECT * N_WEEKS;
    const height = RECT * N_DAYS;

    const x = scaleLinear().range([0, width]);
    x.domain(extent(data, d => d.index));

    const g = select(svg.current)
      .attr('width', width + magic.margins.left + magic.margins.right)
      .attr('height', height + magic.margins.top + magic.margins.bottom)
      .append('g')
      .attr('transform', `translate(${magic.margins.left}, ${magic.margins.top})`);

    const y = {
      Sun: 0,
      Mon: 20,
      Tue: 40,
      Wed: 60,
      Thu: 80,
      Fri: 100,
      Sat: 120,
    };
    const opacity = scaleSqrt().domain([0, 100]).range([0, 1]);

    const col = g
      .selectAll('.column')
      .data(data)
      .enter()
      .append('g')
      .attr('class', 'column')
      .attr('transform', d => `translate(${x(d.index)}, 0)`);

    col
      .selectAll('.bin')
      .data(d => d.volume)
      .enter()
      .append('rect')
      .attr('class', 'bin')
      .style('fill', 'steelblue')
      .style('fill-opacity', d => opacity(d[1]))
      .attr('width', GAP)
      .attr('height', GAP)
      .attr('x', 0)
      .attr('y', d => y[d[0]]);

    g.append('g')
      .attr('transform', `translate(0,${height})`)
      .call(
        axisBottom(
          scaleTime()
            .range([0, width])
            .domain(extent(data, d => d.start_date)),
        ).tickFormat(timeFormat("%b %d '%y")),
      )
      .selectAll('text')
      .attr('transform', 'rotate(-45)')
      .style('text-anchor', 'end');

    g.append('g').call(axisLeft(scaleLinear().range([0, height]).domain([0, 6])).tickFormat(d => DAYS[d]));
  }, [data, svg]);

  return <svg id="weekly-volume-heatmap" ref={svg} />;
};

export default WeeklyVolumeHeatmap;
