import { utility } from '@leaf/components';
import ShipmentDelivery from '../ShipmentDelivery';
import ShipmentPickup from '../ShipmentPickup';

export default class Leg {
  constructor({
    destination,
    origin,
    originDelay,
    pickupStatus,
    pickupAppointment,
    pickupArrivalActual,
    pickupDepartureActual,
    deliveryStatus,
    deliveryAppointment,
    deliveryArrivalActual,
    deliveryDepartureActual,
    destinationDelay,
    ...response
  }) {
    Object.assign(this, {
      ...response,
    });

    this.view = {
      originAddress: [
        `${this.originCity}, ${this.originState} ${this.originZip}`,
        `${this.originBusinessName}, ${this.originAddressName}`,
      ],
      destinationAddress: [
        `${this.destinationCity}, ${this.destinationState} ${this.destinationZip}`,
        `${this.destinationBusinessName}, ${this.destinationAddressName}`,
      ],
      pickupEarliest: utility.date.formatStringDate(pickupAppointment, utility.date.VIEW_DATE_TIME_FORMAT),
      deliveryEarliest: utility.date.formatStringDate(deliveryAppointment, utility.date.VIEW_DATE_TIME_FORMAT),
      details: {
        originAddress: `${this.originBusinessName}, ${this.originAddressName}, ${this.originCity} ${this.originState} ${this.originZip}`,
        destinationAddress: `${this.destinationBusinessName}, ${this.destinationAddressName}, ${this.destinationCity} ${this.destinationState} ${this.destinationZip}`,
        pickup:
          pickupAppointment &&
          new ShipmentPickup({
            origin,
            pickup: {
              arrivalActual: pickupArrivalActual,
              departureActual: pickupDepartureActual,
              appointmentTime: pickupAppointment,
              status: pickupStatus,
              delay: originDelay,
            },
          }),
        delivery:
          deliveryAppointment &&
          new ShipmentDelivery({
            destination,
            delivery: {
              arrivalActual: deliveryArrivalActual,
              departureActual: deliveryDepartureActual,
              appointmentTime: deliveryAppointment,
              status: deliveryStatus,
              delay: destinationDelay,
            },
          }),
      },
      shipperName: this.shipperName || '-',
      legNumber: this.legNumber ?? '-',
    };
  }
}
