import React from 'react';
import { Box, Typography } from '@mui/material';

import { ORDER_STATUS_DISPLAY_NAMES } from '../constants/OrderStatus';

const OrderStatus = ({ status }) => (
  <Box display="flex" alignItems="center">
    <Typography variant="body1">{ORDER_STATUS_DISPLAY_NAMES[status] || status}</Typography>
  </Box>
);

export default OrderStatus;
