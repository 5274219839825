import { utility } from '@leaf/components';

export default class NetworkMovesDetails {
  constructor(response) {
    Object.assign(this, {
      ...response,
    });

    this.view = {
      id: response.id,
      l0Miles: utility.format.miles(response.l0Miles),
      l1Miles: utility.format.miles(response.l1Miles),
      l2Name: response.l2Name || '-',
      l2OwnerName: response.l2OwnerName || '-',
      l2Miles: utility.format.miles(response.l2Miles),
      deadheadL0L1: utility.format.miles(response.deadheadL0L1),
      deadheadL1L2: utility.format.miles(response.deadheadL1L2),
      deadheadFinal: utility.format.miles(response.deadheadFinal),
      l0MeanRpm: utility.numbers.formatNumberAsView(response.l0MeanRpm, 2),
      l1MeanRpm: utility.numbers.formatNumberAsView(response.l1MeanRpm, 2),
      l2MeanRpm: utility.numbers.formatNumberAsView(response.l2MeanRpm, 2),
      l0AnnualLoads: response.l0AnnualLoads || '-',
      l1AnnualLoads: response.l1AnnualLoads || '-',
      l2AnnualLoads: response.l2AnnualLoads || '-',
      totalMiles: utility.format.miles(response.totalMiles),
      supplyTargetDayRateLsp: utility.numbers.formatNumberAsView(response.supplyTargetDayRateLsp, 2),
      supplyTargetLinehaulLsp: utility.numbers.formatNumberAsView(response.supplyTargetLinehaulLsp, 2),
      leafDailyLinehaulLsp: utility.numbers.formatNumberAsView(response.leafDailyLinehaulLsp, 2),
      shipperTriggerLinehaulLsp: utility.numbers.formatNumberAsView(response.shipperTriggerLinehaulLsp, 2),
      startDate: response.startDate ? response.startDate.toLocaleDateString() : '-',
      runningDate: response.runningDate ? response.runningDate.toLocaleDateString() : '-',
      benefit: utility.numbers.formatNumberAsView(response.benefit, 2),
      margin: utility.numbers.formatNumberAsView(response.margin, 2),
      lanes: [
        {
          lane: response.l0Name,
          owner: response.l0OwnerName,
          shipper: { name: response.l0OwnerName, image: response.l0OwnerLogo },
        },
        {
          lane: response.l1Name,
          owner: response.l1OwnerName,
          shipper: { name: response.l1OwnerName, image: response.l1OwnerLogo },
        },
        {
          lane: response.l2Name,
          owner: response.l2OwnerName,
          shipper: { name: response.l2OwnerName, image: response.l2OwnerLogo },
        },
      ],
      minTrips: utility.numbers.formatNumberAsView(response.minTrips, 0, false),
      totalMilesWithoutDeadhead: this.calculateTotalMilesWithoutFinalDeadhead(
        response.moveType,
        response.totalMiles,
        response.deadheadFinal,
      ),
      totalLoadedMiles: this.calculateTotalLoadedMiles(response.l0Miles, response.l1Miles, response.l2Miles),
    };

    const [l0Origin, l0Destination] = response.l0Name.split(' - ');
    this.view.l0Origin = l0Origin;
    this.view.l0Destination = l0Destination;
    this.view.l0ShipperName = response.l0OwnerName;
    this.view.l0ShipperId = response.l0OwnerLogo;

    const [l1Origin, l1Destination] = response.l1Name.split(' - ');
    this.view.l1Origin = l1Origin;
    this.view.l1Destination = l1Destination;
    this.view.l1ShipperName = response.l1OwnerName;
    this.view.l1ShipperId = response.l1OwnerLogo;

    if (response.l2Name) {
      const [l2Origin, l2Destination] = response.l2Name.split(' - ');
      this.view.l2Origin = l2Origin;
      this.view.l2Destination = l2Destination;
      this.view.l2ShipperName = response.l2OwnerName;
      this.view.l2ShipperId = response.l2OwnerLogo;
    }
  }

  toMapFormat = () => {
    const paths = [this.l0PathWkt.coordinates, this.l1PathWkt.coordinates];

    if (this.l2PathWkt) {
      paths.push(this.l2PathWkt.coordinates);
    }

    return paths.map(coordinates => ({
      laneType: 'PTP',
      geo: {
        type: 'Feature',
        geometry: {
          type: 'MultiLineString',
          coordinates: [coordinates],
        },
      },
    }));
  };

  toRouteFormat = shipperId => {
    const routes = [
      {
        data: {
          origin: this.view.l0Origin,
          destination: this.view.l0Destination,
          miles: this.view.l0Miles,
          shipperName: this.l0OwnerName,
          shipperId: this.l0OwnerId,
          laneId: this.l0Id,
          hasExplorer: this.l0HasExplorer,
          type: 'PTP',
        },
        meta: {
          position: 1,
          color: '#8397B1',
          isDisabled: this.l0OwnerId !== Number(shipperId),
        },
      },
      {
        data: {
          deadheadMiles: this.view.deadheadL0L1,
        },
      },
      {
        data: {
          origin: this.view.l1Origin,
          destination: this.view.l1Destination,
          miles: this.view.l1Miles,
          shipperName: this.l1OwnerName,
          shipperId: this.l1OwnerId,
          laneId: this.l1Id,
          hasExplorer: this.l1HasExplorer,
          type: 'PTP',
        },
        meta: {
          position: 3,
          color: '#56CCF2',
          isDisabled: this.l1OwnerId !== Number(shipperId),
        },
      },
      {
        data: {
          deadheadMiles: this.view.l2Origin ? this.view.deadheadL1L2 : this.view.deadheadFinal,
        },
      },
    ];

    if (this.l2Name) {
      routes.push(
        {
          data: {
            origin: this.view.l2Origin,
            destination: this.view.l2Destination,
            miles: this.view.l2Miles,
            shipperName: this.l2OwnerName,
            shipperId: this.l2OwnerId,
            laneId: this.l2Id,
            hasExplorer: this.l2HasExplorer,
            type: 'PTP',
          },
          meta: {
            position: 5,
            color: '#A695FF',
            isDisabled: this.l2OwnerId !== Number(shipperId),
          },
        },
        {
          data: {
            deadheadMiles: this.view.deadheadFinal,
          },
        },
      );
    }

    return routes;
  };

  toRouteDataTableFormat = shipperId => [
    // row 1
    [
      this.l0OwnerId === Number(shipperId) ? this.view.l0MeanRpm : '-',
      this.l0OwnerId === Number(shipperId) ? this.view.l0AnnualLoads : '-',
    ],
    // row 2
    [
      this.l1OwnerId === Number(shipperId) ? this.view.l1MeanRpm : '-',
      this.l1OwnerId === Number(shipperId) ? this.view.l1AnnualLoads : '-',
    ],
    // row 3
    ...(this.l2Name
      ? [
          [
            this.l2OwnerId === Number(shipperId) ? this.view.l2MeanRpm : '-',
            this.l2OwnerId === Number(shipperId) ? this.view.l2AnnualLoads : '-',
          ],
        ]
      : [[]]),
  ];

  calculateTotalMilesWithoutFinalDeadhead = (moveType, totalMiles, deadheadFinal) => {
    if (moveType?.toLowerCase() === 'triangular circuit' || moveType?.toLowerCase() === 'round trip') {
      if (totalMiles && deadheadFinal) return utility.format.miles(totalMiles - deadheadFinal);
    }

    return '-';
  };

  calculateTotalLoadedMiles = (l0Miles, l1Miles, l2Miles) => {
    let totalLoadedMiles = 0;
    if (l0Miles && typeof l0Miles === 'number') totalLoadedMiles += l0Miles;
    if (l1Miles && typeof l1Miles === 'number') totalLoadedMiles += l1Miles;
    if (l2Miles && typeof l2Miles === 'number') totalLoadedMiles += l2Miles;

    return utility.format.miles(totalLoadedMiles);
  };
}
