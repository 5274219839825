import { utility } from '@leaf/components';

class Metric {
  constructor(response) {
    Object.assign(this, {
      ...response,
    });

    this.view = {
      value: response.missing ? '-' : utility.numbers.formatNumber(this.value, this.unit, this.unit === 'PERCENT'),
    };
  }
}

export default Metric;
