/* eslint-disable no-restricted-globals */
import { gql } from 'graphql-request';

const WHERE = {
  STATUS_VAR: gql`$status: [String!]`,
  STATUS_CLAUSE: gql`shipment_execution_status: { _in: $status }`,
  SHIPPER_VAR: gql`$shipper: [bigint!]`,
  SHIPPER_CLAUSE: gql`shipment_tenders: { company: { id: { _in: $shipper } }}`,
};

const whereClause = ({ where, contractId, search }) => {
  let whereGQL = gql``;
  const whereVarsKeys = Object.keys(where).filter(k => Array.isArray(where[k]) && where[k].length);

  if (contractId || whereVarsKeys.length || (search !== undefined && search !== '')) {
    whereGQL = gql`
    where: {
        _and: [
          { sell_contract_id: { _eq: "${contractId}" } }

          ${whereVarsKeys.map(key =>
            where[key]
              ? gql`{
                  ${WHERE[`${key.toUpperCase()}_CLAUSE`]}
                }`
              : '',
          )}

          ${
            search !== undefined && search !== ''
              ? gql`{
              _or: [
                ${!isNaN(search) ? gql`{ id: { _eq: "${search}" } }` : ''}
                {origin_address_name: { _ilike: "%${search}%" }}
                {origin_business_name: { _ilike: "%${search}%" }}
                {origin_city: { _ilike: "%${search}%" }}
                {origin_country: { _ilike: "%${search}%" }}
                {origin_state: { _ilike: "%${search}%" }}
                {origin_zip: { _ilike: "%${search}%" }}
                {destination_address_name: { _ilike: "%${search}%" }}
                {destination_business_name: { _ilike: "%${search}%" }}
                {destination_city: { _ilike: "%${search}%" }}
                {destination_country: { _ilike: "%${search}%" }}
                {destination_state: { _ilike: "%${search}%" }}
                {destination_zip: { _ilike: "%${search}%" }}
                {equipment_type: { name: { _ilike: "%${search}%" }}}
              ]}`
              : ''
          }
        ]
      }
    `;
  }
  return whereGQL;
};

const whereVar = ({ where }) => gql`
  ${where.status ? WHERE.STATUS_VAR : ``}
  ${where.shipper ? WHERE.SHIPPER_VAR : ``}
`;

export const getContractTripsGQL = variables => {
  const whereGQL = whereClause(variables);
  return gql`
    query getContractTrips($limit: Int, $orderBy: [carrier_trip_tender_order_by], $offset: Int, ${whereVar(
      variables,
    )}) {
      carrierTripTender: carrier_trip_tender(limit: $limit, order_by: $orderBy, offset: $offset, ${whereGQL}) {
        id
        destinationZip: destination_zip
        destinationState: destination_state
        destinationCountry: destination_country
        destinationCity: destination_city
        destinationBusinessName: destination_business_name
        destinationStreet: destination_address_name
        originZip: origin_zip
        originState: origin_state
        originCountry: origin_country
        originCity: origin_city
        originBusinessName: origin_business_name
        originStreet: origin_address_name
        shipmentExecutionStatus: shipment_execution_status
        shipmentTenders: shipment_tenders(where: { leg_number: {_eq: 0}}) {
          originAppointmentTime: origin_appointment_time
          originInitialAppointmentTime: origin_initial_appointment_time
          expectedShipmentDate: expected_shipment_date
          company {
            id
            name
          }
        }
        equipmentType: equipment_type {
          name
        }
        executionPartnerCompany: execution_partner_company {
          name
        }
        tripId: trip_id
        stops
      }
      carrierTripTenderAggregate: carrier_trip_tender_aggregate${whereGQL ? gql`( ${whereGQL} )` : ''} {
        aggregate {
          count
        }
      }
    }
  `;
};
