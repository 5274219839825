import axios from 'axios';

import { utility } from '@leaf/components';

import AdaptCompanyDataMetric from 'domain/adapt/AdaptCompanyDataMetric';
import AdaptCompanyDataObjective from 'domain/adapt/AdaptCompanyDataObjective';

export default class AdaptCompanyData {
  constructor(response) {
    Object.assign(this, {
      runDate: utility.date.backendStringToViewString(response.runDate, null),
      windowStartDate: utility.date.backendStringToViewString(response.windowStartDate, null),
      windowEndDate: utility.date.backendStringToViewString(response.windowEndDate, null),
      metrics: response.data?.metrics?.map(metric => new AdaptCompanyDataMetric(metric)) || [],
      objectives: response.data?.objectives?.map(objective => new AdaptCompanyDataObjective(objective)) || [],
    });
  }

  static read = id => axios.get(`analytics/adapt/companies/${id}`).then(data => new AdaptCompanyData(data));
}
