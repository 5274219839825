import { utility } from '@leaf/components';
import { extractAddress } from 'shipments/utilities';

export default class ShipmentPickup {
  constructor({ origin, pickup, ...response }) {
    Object.assign(this, {
      ...response,
    });

    const laneOrigin = extractAddress(origin);

    this.view = {
      originAddress: [
        `${laneOrigin.city}, ${laneOrigin.state} ${laneOrigin.zipCode}`,
        `${laneOrigin.businessName}, ${laneOrigin.street}`,
      ],
      status: pickup.status,
      inOutTime: [
        pickup.arrivalActual
          ? utility.date.formatStringDate(pickup.arrivalActual, utility.date.VIEW_DATE_TIME_FORMAT)
          : 'N/A',
        pickup.departureActual
          ? utility.date.formatStringDate(pickup.departureActual, utility.date.VIEW_DATE_TIME_FORMAT)
          : 'N/A',
      ],
      delay: pickup.delay || '-',
      appointmentTime: pickup.appointmentTime
        ? utility.date.formatStringDate(pickup.appointmentTime, utility.date.VIEW_DATE_TIME_FORMAT)
        : '-',
    };
  }
}
