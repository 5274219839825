import React from 'react';
import { PhaseSelect, Lane, utility, PHASE_TYPE } from '@leaf/components';
import Lanes from 'domain/adaptBoard/Lanes';
import styled from '@emotion/styled';
import { v4 } from 'uuid';

const LaneWrapper = styled.div`
  margin-bottom: ${({ isSingleRow, theme }) => `${!isSingleRow ? theme.spacing(1) : 0}`};
  .MuiTypography-body2 {
    text-overflow: ellipsis;
    overflow: hidden;
    width: 220px;
    white-space: nowrap;
  }
`;

// No check here because we only add lanes with explorers for now
export const getExplorerUrl = row => `/adapt/explorer/lanes/${row[0]}`;

export const getColumns = (tableState, filterOptions, lanePhases, updateLanePhaseList) => {
  // Before modifying anything here think about the side-effects.
  const columns = [];

  // ALWAYS INDEX 0
  columns.push({
    label: 'Lane ID',
    name: 'laneId',
    options: { filter: false, sort: false, display: false },
  });

  // Rest in any order
  columns.push(
    {
      label: 'Origin',
      name: 'origin',
      options: { display: false, sort: false, filter: false },
    },
    {
      label: 'Destination',
      name: 'destination',
      options: { display: false, sort: false, filter: false },
    },
    {
      label: 'Lane',
      name: 'view.lane',
      options: {
        filter: false,
        sort: false,
        customBodyRender: lanes =>
          lanes?.map(({ origin, destination, laneType }) => (
            <LaneWrapper key={v4()} isSingleRow={lanes.length === 1}>
              <Lane
                displayAsGrid
                type={laneType}
                origin={{ primaryContent: [origin] }}
                destination={{ primaryContent: [destination] }}
              />
            </LaneWrapper>
          )),
      },
    },
    {
      label: 'Annual Loads',
      name: 'view.annualLoads',
      field: 'annualLoads',
      options: {
        filter: false,
        ...utility.table.rightAlignColumnProps(),
      },
    },
    {
      label: 'Annual Spend',
      name: 'view.annualSpend',
      field: 'annualSpend',
      options: {
        filter: false,
        ...utility.table.rightAlignColumnProps(),
      },
    },
    {
      label: 'Potential Leaf Tender Acceptance',
      name: 'view.tenderAcceptance',
      options: { display: true, filter: false, ...utility.table.rightAlignColumnProps() },
    },
    {
      label: 'Potential Annual Cost Savings',
      name: 'view.annualCostSavings',
      field: 'savings',
      options: { display: true, filter: false, ...utility.table.rightAlignColumnProps() },
    },
    {
      label: 'Equipment Type',
      name: 'equipmentClass',
      options: { display: true, ...filterOptions.equipmentClass },
    },
    {
      label: 'Phases',
      name: 'view.phases',
      field: 'phase',
      options: {
        display: false,
        sort: false,
        ...filterOptions.phase,
        customBodyRender: ({ shipperId, laneId, status, equipmentTypes, startDate }) => {
          let filteredLanePhases = [];
          if (lanePhases.length) {
            filteredLanePhases = lanePhases
              .map(item => {
                if (item.shipperId === shipperId) {
                  return {
                    shipperId,
                    startDate: item.startDate,
                    phase: item.phase,
                    laneId,
                  };
                }

                return null;
              })
              .filter(item => item);

            filteredLanePhases = filteredLanePhases.filter(
              (item, index) =>
                item.phase &&
                item.phase !== PHASE_TYPE.UNASSIGNED &&
                filteredLanePhases.findIndex(innerItem => innerItem.startDate === item.startDate) === index,
            );
          }

          const handleStopPropagation = event => {
            event.stopPropagation();
          };

          return (
            /* eslint-disable-next-line jsx-a11y/no-static-element-interactions */
            <div onClick={handleStopPropagation}>
              <PhaseSelect
                startDate={startDate}
                shipperId={shipperId}
                laneIds={[laneId]}
                status={status}
                data={filteredLanePhases}
                equipmentTypes={[equipmentTypes]}
                patchData={Lanes.patchPhaseStart}
                updateLanePhases={updateLanePhaseList}
                variant="outlined"
              />
            </div>
          );
        },
      },
    },
    {
      label: 'Status',
      name: 'status',
      options: {
        display: false,
        ...filterOptions.status,
      },
    },
  );

  return columns;
};
