import React from 'react';
import { Typography } from '@mui/material';

export const privacyPolicy = (
  <div>
    <Typography variant="body1">Last Updated: November 29, 2017</Typography>
    <Typography variant="h4">Scope and Application</Typography>
    <Typography variant="body1">
      This Privacy Policy (&quot;Policy&quot;) describes how Leaf Logistics (&quot;Leaf&quot;, &quot;our&quot;,
      &quot;we&quot;, &quot;us&quot;,) collects, uses, and discloses information when you use our web and mobile
      applications, and other online products and services we provide (collectively, the &quot;Services&quot;) or when
      you otherwise interact with us.
    </Typography>
    <Typography variant="body1">
      The Policy applies to any users of the Services (collectively &quot;Users&quot; or &quot;Participants,&quot; or
      individually &quot;you&quot;), including freight shippers, Logistics Service Providers (&quot;LSP&quot;), and
      carriers. It does not apply to other websites to which we may link.
    </Typography>
    <Typography variant="h4">Collection of Information</Typography>
    <Typography variant="body1">
      Our Services connect Participants to match transportation demand with capacity. Participants can submit requests
      (&quot;Order&quot;) to buy or sell trucking capacity (&quot;Shipment&quot;). The platform identifies buy and sell
      Orders with matching properties (&quot;Match&quot;), including the origin/destination, equipment type, and
      accessorials. A buyer can then accept such a Match based on the pricing and other information specified in the
      matched sell Order, and upon accepting a Match a binding and enforceable contract (&quot;Contract&quot;) is
      entered between both parties.
    </Typography>
    <Typography variant="h5">Information You Provide to Us</Typography>
    <Typography variant="body1">
      We collect information you provide directly to us. For example, we collect information when you fill out a form,
      request to become a Participant on the platform, log into your Leaf account, share your Shipment data with us,
      place an Order on the platform, request customer support, or otherwise communicate with us. This information may
      include your name, company name, email address, phone number, payment information, shipment information, insurance
      information, service ratings and reviews, and any other information you choose to provide.
    </Typography>
    <Typography variant="h5">Information We Collect When You Use the Services</Typography>
    <Typography variant="body1">
      Depending on how you use the Services, we may collect information about you in the following general categories:
    </Typography>
    <Typography variant="body1">
      <em>Transaction Information</em>: We collect transaction details related to your use of the Services, including
      the type of Service, the date and time you used the Service, payment amounts, and other related transaction
      details.
    </Typography>
    <Typography variant="body1">
      <em>Log Information</em>: We collect log information about your use of the Services, including the type of browser
      or app version you use, access times, pages viewed, your IP address, and the page you visited before navigating to
      our Services.
    </Typography>
    <Typography variant="body1">
      <em>Device Information</em>: We collect information about the computer or mobile device you use to access our
      Services, including the hardware model, operating system and version, device and app identifiers, and mobile
      network information.
    </Typography>
    <Typography variant="body1">
      <em>Information Collected by Cookies and Other Tracking Technologies</em>: We use various technologies to collect
      information, including cookies and web beacons. Cookies are small data files stored on your hard drive or in
      device memory that help us improve our Services and your experience, see which areas and features of our Services
      are popular, and count visits. Web beacons are electronic images that may be used in our Services or emails and
      help deliver cookies, count visits, and understand usage and campaign effectiveness.
    </Typography>
    <Typography variant="h5">Information We Collect From Other Sources</Typography>
    <Typography variant="body1">
      We may also obtain information from other sources and combine that with information we collect directly. These
      third parties include, but are not limited to, verification services (for instance, to confirm your insurance or
      regulatory compliance status), credit bureaus, and publicly available sources. If you choose to link, create, or
      log in to your Leaf account with a payment provider (e.g., Google Wallet) or social media service (e.g.,
      Facebook), or if you engage with a separate app or website that uses our API (or whose API we use), we may receive
      information about you or your connections from that site or app, as well as information from connected services
      you choose to use.
    </Typography>
    <Typography variant="h4">Use of Information</Typography>
    <Typography variant="body1">
      We use the information we collect in order to provide and improve our Services, including to connect Participants,
      match Orders, facilitate entering and trading contracts, process payments, and provide arbitration services. We
      use your email and phone number to call you and send you messages about potential and current Shipments, depending
      on the notification preferences you can set on the platform. We may also use information about you for other
      purposes, including to:
    </Typography>
    <ul>
      <li>Send you technical notices, updates, security alerts and support, and administrative messages;</li>
      <li>Respond to your comments, questions, and requests and provide customer service;</li>
      <li>
        Communicate with you about products, services, offers, opportunities, promotions, rewards, and events offered by
        Leaf and others, and provide news and information we think will be of interest to you;
      </li>
      <li>Monitor and analyze trends, usage, and activities in connection with our Services;</li>
      <li>
        Detect, investigate, and prevent fraudulent transactions and other illegal activities and protect the rights and
        property of Leaf and others;
      </li>
      <li>
        Personalize and improve the Services and provide advertisements, content, or features that match user profiles
        or interests;
      </li>
      <li>
        Link or combine with information we get from others to help understand your needs and provide you with better
        service; and
      </li>
      <li>Carry out any other purpose described to you at the time the information was collected.</li>
    </ul>
    <Typography variant="h5">Sharing of Information</Typography>
    <Typography variant="body1">
      We share information we collect about you as described in this Privacy Policy or at the time of collection or
      sharing, including sharing with other users of our Services and various third-parties as described below.
    </Typography>
    <Typography variant="h5">Sharing with Other Users of the Services</Typography>
    <Typography variant="body1">
      Depending on how you use our Services, we may share information about you with other users of the Services. For
      example, when you place an Order on the platform, we share certain order information including the origin and
      destination, the shipment dates, the equipment type and the requested shipment/pickup dates with other
      participants.{' '}
    </Typography>
    <Typography variant="body1">
      If you are a carrier, we will share your company name, SCAC code, and certain contact information (including your
      contact email address and phone number) with those shippers you entered contracts with on the platform.{' '}
    </Typography>
    <Typography variant="body1">
      If you are a shipper, your name or company name and your instructions, address, and contact information will be
      shared with carriers you entered a contract with.{' '}
    </Typography>
    <Typography variant="body1">
      Certain documents and information that you submit to our Services in connection with a Shipment request, such as
      feedback, may be shared with other users. If you submit ratings and reviews, this information may be shared with
      other users of our Services.
    </Typography>
    <Typography variant="h5">Sharing with Third Parties</Typography>
    <Typography variant="body1">We may also share information about you with other third parties, such as:</Typography>
    <ul>
      <li>
        With vendors, consultants, and other service providers who need access to such information to carry out work on
        our behalf;
      </li>
      <li>
        In response to a request for information if we believe disclosure is in accordance with, or required by, any
        applicable law, regulation or legal process;
      </li>
      <li>
        If we believe your actions are inconsistent with our user agreements or policies, or to protect the rights,
        property, and safety of Leaf or others;
      </li>
      <li>
        In connection with, or during negotiations of any merger, sale of company assets, financing, or acquisition of
        all or a portion of our business by another company;
      </li>
      <li>
        Between and among Leaf and our current and future parents, affiliates, subsidiaries, and other companies under
        common control and ownership; and
      </li>
      <li>Aggregated or de-identified information, which cannot reasonably be used to identify you. </li>
      <li>With your consent or at your direction.</li>
    </ul>
    <Typography variant="h5">Advertising and Analytics Services Provided by Others</Typography>
    <Typography variant="body1">
      We may also share aggregated or de-identified information, which cannot reasonably be used to identify you.
    </Typography>
    <Typography variant="body1">
      We may allow others to provide analytics services and serve advertisements on our behalf across the Internet and
      in applications. These entities may use cookies, web beacons, device identifiers, and other technologies to
      collect information about your use of the Services and other websites and applications, including your IP address,
      web browser, mobile network information, pages viewed, time spent on pages or in apps, links clicked, and
      conversion information. This information may be used by Leaf and others to, among other things, analyze and track
      data, determine the popularity of certain content, deliver advertising and content targeted to your interests on
      our Services and other websites, and better understand your online activity. For more information about
      interest-based ads, or to opt out of having your web browsing information used for behavioral advertising
      purposes, please visit www.aboutads.info/choices. Your device may also include a feature (&quot;Limit Ad
      Tracking&quot; on iOS or &quot;Opt Out of Interest-Based Ads&quot; on Android) that allows you to opt out of
      having certain information collected through apps used for behavioral advertising purposes.
    </Typography>
    <Typography variant="h4">Security</Typography>
    <Typography variant="body1">
      Leaf takes reasonable measures to help protect information about you from loss, theft, misuse and unauthorized
      access, disclosure, alteration, and destruction.
    </Typography>
    <Typography variant="h4">Changes to the Policy</Typography>
    <Typography variant="body1">
      We may change this Policy from time to time. If we make changes, we will update the date at the top of the policy.
      In some cases, we may provide you with additional notice (such as adding a statement to our homepage or sending
      you a notification). Your continued use of the Services after any such change constitutes your consent to the
      changes. We encourage you to periodically review this Policy for the latest information on our privacy practices.
    </Typography>
    <Typography variant="h4">Contact Us</Typography>
    <Typography variant="body1">
      If you have any questions about this Privacy Policy, please contact us at: support@leaflogistics.com
    </Typography>
  </div>
);
