import { Typography } from '@mui/material';
import { ErrorOutline as ErrorIcon } from '@mui/icons-material';
import React from 'react';
import styled from '@emotion/styled';

const Error = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: ${({ theme }) => theme.spacing(1)};
  height: 100%;
  svg {
    color: #f88078;
  }
`;

export default class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  // eslint-disable-next-line no-unused-vars
  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  // eslint-disable-next-line no-unused-vars
  componentDidCatch(error, errorInfo) {
    // Log the error to an error reporting service if needed
  }

  render() {
    const { hasError } = this.state;
    const { message, fallbackComponent, children } = this.props;
    if (hasError) {
      return !fallbackComponent ? (
        <Error>
          <ErrorIcon />
          <Typography variant="h5">{message}</Typography>
        </Error>
      ) : (
        fallbackComponent
      );
    }

    return children;
  }
}
