import React from 'react';
import styled from '@emotion/styled';
import { Grid, Card, CardHeader, CardContent, Typography } from '@mui/material';

import { LaneData, LaneMap } from '../features/lane';
import CompanyAvatar from '../../CompanyAvatar';

const LaneName = styled(Grid)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 200;
  box-sizing: content-box;
`;

const LaneNameCard = styled(Card)`
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 16px 0 16px 0;
`;

const LaneNameCardHeader = styled(CardHeader)`
  width: 100%;
`;

const LaneNameTitleWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-around;
  align-items: center;
`;

const FullHeightWrapper = styled.div`
  height: 100%;
  background-color: #fafafa;
`;

const DateInfo = styled.div`
  display: grid;
`;

const GeneralData = ({ data, mapboxUrl, EquipmentClass, Uom, isAdmin }) => (
  <>
    <LaneName item xs={12}>
      <LaneNameCard>
        <LaneNameCardHeader
          title={
            <>
              <LaneNameTitleWrapper>
                <CompanyAvatar id={data.shipperId} name={data.shipperName} width="3em" height="3em" />

                <Typography variant="h4">{data.name}</Typography>

                <DateInfo>
                  <Typography variant="h6">
                    Adapt analysis generated on {data.runDate || data.batchDateLatest}
                  </Typography>

                  <Typography variant="h6">
                    Based on historical shipments from {data.earliestShipment} to {data.latestShipment}
                  </Typography>
                </DateInfo>
              </LaneNameTitleWrapper>
            </>
          }
        />
      </LaneNameCard>
    </LaneName>

    <Grid item xs={6}>
      <FullHeightWrapper>
        <CardContent>
          <LaneData data={data.general} EquipmentClass={EquipmentClass} Uom={Uom} isAdmin={isAdmin} />
        </CardContent>
      </FullHeightWrapper>
    </Grid>

    <Grid item xs={6}>
      <LaneMap data={data.geo} mapboxUrl={mapboxUrl} />
    </Grid>
  </>
);

export default GeneralData;
