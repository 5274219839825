import React, { useState, useEffect, useContext } from 'react';
import { withRouter } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { SpacedContainer, PageContentLayout, Tabs, PHASE_TYPE } from '@leaf/components';
import { Grid } from '@mui/material';
import { default as LanesDomain } from 'domain/adaptBoard/Lanes';
import { StateContext } from 'state/StateProvider';
import { SET_ADAPT_BOARD_LANE_PHASES_STATE } from 'state/stateReducer';
// import styled from '@emotion/styled';
import { UserContext } from 'authentication/UserProvider';
// import BoardPhasePanel from './BoardPhasePanel';
import { Lanes, NetworkMoves } from './tables';

// const GridWrapper = styled(Grid)`
//   min-width: 300px;
// `;

const BoardOverview = ({ location, history }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [phasePanelData, setPhasePanelData] = useState([]);
  const [isLoadingPhasePanelData, setIsLoadingPhasePanelData] = useState(false);

  const { dispatch } = useContext(StateContext);
  const { currentUser } = useContext(UserContext);

  const companyId = currentUser?.company?.id;

  useEffect(() => {
    setIsLoadingPhasePanelData(true);
    LanesDomain.getAllLanePhases()
      .then(data => {
        dispatch({
          type: SET_ADAPT_BOARD_LANE_PHASES_STATE,
          payload: data.shipperPhaseDates
            .filter(item => item.phase !== PHASE_TYPE.OUT_OF_SCOPE)
            .sort((a, b) => a.phase.localeCompare(b.phase)),
        });
      })
      .catch(enqueueSnackbar);

    // LanesDomain.getShipperSummaryPhases(companyId).then(data => {
    //   setIsLoadingPhasePanelData(false);
    //   setPhasePanelData(data);
    // });
  }, []);

  const tabs = [
    {
      label: 'Lanes',
      content: <Lanes setPhasePanelData={setPhasePanelData} companyId={companyId} />,
    },
    {
      label: 'Network moves',
      content: <NetworkMoves />,
    },
  ];

  return (
    <SpacedContainer container spacing={2} autoWidth>
      {/* <GridWrapper item xs={3}>
        <PageContentLayout withoutSpacing fullHeight={false}>
          <BoardPhasePanel phasePanelData={phasePanelData?.data} isDataLoading={isLoadingPhasePanelData} />
        </PageContentLayout>
      </GridWrapper> */}
      <Grid item xs={12}>
        <PageContentLayout withoutSpacing fullHeight={false}>
          <Tabs hasPadding={false} location={location} history={history} mainUrlPath="/adapt-board" tabs={tabs} />
        </PageContentLayout>
      </Grid>
    </SpacedContainer>
  );
};

export default withRouter(BoardOverview);
