import axios from 'axios';

import { TRIP_TYPE_DISPLAY_NAMES, utility, LANE_COLORS, CONTRACT_TYPE, TRIP_TYPE } from '@leaf/components';
import {
  getContractRoutesGQL,
  getContractDetailsGQL,
  getContractDetailsLaneDescriptionGQL,
} from './GQL_CONTRACT_DETAILS';

const mapContractRoutes = contracts =>
  contracts.map(contract => ({
    routes: contract?.contractRoutes.map(contractRoute => ({
      deadheadMiles: utility.format.miles(contractRoute?.route.avgDeadheadMiles),
      totalMiles: utility.format.miles(contractRoute?.route.avgTotalMiles),
      tripType: TRIP_TYPE_DISPLAY_NAMES[contractRoute?.route.tripType],
      isPrimary: contractRoute?.isPrimary,
      displayOriginName: contractRoute.route.legs[0].lane?.originName,
      displayDestinationName:
        contractRoute?.route.tripType === TRIP_TYPE.ROUND_TRIP
          ? contractRoute.route.legs[0].lane?.destinationName
          : contractRoute.route.legs[contractRoute.route.legs.length - 1].lane?.destinationName,
      id: contractRoute?.route.routeId,
      legs: contractRoute.route.legs.map(leg => ({
        origin: leg?.lane?.originName,
        pickupType: leg?.lane?.pickupType,
        destination: leg?.lane?.destinationName,
        deliveryType: leg?.lane?.deliveryType,
        miles: utility.format.miles(leg?.lane?.leafMiles),
        laneId: leg?.lane?.laneId,
        deadheadMiles: utility.format.miles(leg?.deadheadMiles) ?? '',
        originGeo: leg?.lane?.originGeo,
        destinationGeo: leg?.lane?.destinationGeo,
        pathGeo: leg?.lane?.path,
      })),
      editLegs: contractRoute.route.legs,
    })),
  }));

export const mapDataToRouteSchedule = legs => {
  const retVal = [];
  legs.forEach((leg, index) => {
    retVal.push({
      data: {
        origin: leg.origin,
        pickupType: leg.pickupType,
        destination: leg.destination,
        deliveryType: leg.deliveryType,
        miles: leg.miles,
        laneId: leg.leniId,
        type: 'PTP',
      },
      meta: {
        position: index * 2 + 1,
        color: LANE_COLORS[index],
      },
    });
    retVal.push({
      data: {
        deadheadMiles: leg.deadheadMiles,
      },
    });
  });

  return retVal;
};

const mapVolumePattern = volumePattern => {
  let retVal = {};
  if (volumePattern) {
    const patternArray = volumePattern.split(',');
    patternArray.forEach(item => {
      const dayArray = item.split('=');
      retVal = {
        ...retVal,
        [dayArray[0]]: dayArray[1],
      };
    });
  }

  return retVal;
};

const mapContractDetails = contracts => {
  let retVal = {};
  contracts.forEach(contract => {
    retVal = {
      contractType:
        contract.contract_type === CONTRACT_TYPE.DEDICATED && contract.is_fleet
          ? CONTRACT_TYPE.FLEET
          : contract.contract_type ?? '-',
      contractParty: contract.contract_party ?? '-',
      contractStatus: contract.contract_status ?? '-',
      equipmentType: contract?.equipment_type?.name ?? '-',
      accessorials: '-',
      incumbentContract: '-',
      notes: contract.notes ?? '-',

      startDate: utility.date.backendStringToViewString(contract.start_date) ?? '-',
      endDate: utility.date.backendStringToViewString(contract.end_date) ?? '-',
      volumeType: contract.volume_type ?? '-',
      volumeFrequency: contract.volume_frequency ?? '-',
      minVolume: contract.min_volume ?? '-',
      maxVolume: contract.max_volume ?? '-',
      includeWeekends: contract.include_weekends ? 'Yes' : 'No',

      pricingMechanism: contract.pricing_mechanism ?? '-',
      linehaulRPM: contract.linehaul_rpm ?? '-',
      pricingIndex: contract.pricing_index ?? '-',
      pricingIndexPremium: contract.pricing_index_premium ?? '-',
      fuelSurchargeSchedule: contract.fuel_surcharge_schedule?.name ?? '-',
      ratePerAsset: contract.asset_pricing ?? '-',
      assetPricingFrequency: contract.asset_pricing_frequency ?? '-',
      minChargeableMiles: contract.min_chargeable_miles ?? '-',
      minChargeableMilesFrequency: contract.min_chargeable_miles_frequency ?? '-',
      volumePattern: mapVolumePattern(contract.volume_pattern),
    };
  });

  return retVal;
};

const mapContractDetailsLaneDescription = contracts => {
  let retVal = {};
  contracts.forEach(contractItem => ({
    route: contractItem.contractRoutes.forEach(({ route }) => {
      retVal = {
        isBuyerContract: !!contractItem.buyerId,
        buyerId: contractItem.buyerId,
        sellerId: contractItem.sellerId,
        contractStatus: contractItem.contractStatus ?? '',
        origin: route.legs[0]?.lane?.originName ?? '',
        destination: route.legs[route.legs.length - 1]?.lane?.destinationName ?? '',
      };
    }),
  }));
  return retVal;
};

/**
 * Use by RoutesOverview
 * @param {*} getGQLClient
 * @param {*} id
 * @returns
 */
export const getContractRoutes = (getGQLClient, id) => {
  const GQL = getContractRoutesGQL(id);
  return getGQLClient().then(client =>
    client.request(GQL).then(res => ({
      data: mapContractRoutes(res.contract),
    })),
  );
};

export const getContractDetails = (getGQLClient, id) => {
  const GQL = getContractDetailsGQL(id);
  return getGQLClient().then(client =>
    client.request(GQL).then(res => ({
      data: mapContractDetails(res.contract),
    })),
  );
};

export const getContractDetailsLaneDescription = (getGQLClient, id) => {
  const GQL = getContractDetailsLaneDescriptionGQL(id);
  return getGQLClient().then(client =>
    client.request(GQL).then(res => ({
      data: mapContractDetailsLaneDescription(res.contract),
    })),
  );
};

export const deleteContract = id => axios.delete(`contracting/contracts/${id}`).then(response => response);
