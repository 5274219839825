import {
  primaryMain,
  secondaryMain,
  background,
  redPrimary,
  greenPrimary,
  greenLeaf,
  orangePrimary,
  blueLeaf,
  divider,
  disabled,
  deEmphasize,
} from './colors';

const createPalette = () => ({
  primary: {
    main: primaryMain,
  },
  secondary: {
    main: secondaryMain,
  },
  background: {
    default: background,
  },
  red: {
    primary: redPrimary,
  },
  blue: {
    leaf: blueLeaf,
  },
  green: {
    primary: greenPrimary,
    leaf: greenLeaf,
  },
  orange: {
    primary: orangePrimary,
  },
  divider,
  disabled,
  deEmphasize,
});

export default createPalette;
