const createShadow = opacity => `0 0 8px  rgba(37, 40, 43, ${opacity})`;

const shadows = [
  'none',
  createShadow(0.07),
  createShadow(0.12),
  createShadow(0.22),
  createShadow(0.32),
  createShadow(0.42),
];

export default shadows;
