import React from 'react';

import { Charts } from '../../Common/Components';
import { getLabels, calcColorForValue } from './chartsHelper';
import { sharedChartPropTypes } from './types';

import './shared.scss';
import { numbers } from '../../../utility';
import { formattingTypes } from '../../../utility/formattingHelper';

const calculateHighestValue = metrics => {
  const arr = [];
  metrics.forEach(m => arr.push(...m.values));
  return Math.max(...arr);
};

const getDefaultOptions = shouldShowUnits => ({
  tooltips: {
    enabled: true,
    intersect: false,
    callbacks: {
      label: tooltipItem => numbers.formatNumberAsView(tooltipItem.yLabel, null, shouldShowUnits),
    },
  },
  scales: {
    yAxes: [
      {
        ticks: {
          callback: value => numbers.formatNumberAsView(value, null, shouldShowUnits),
        },
      },
    ],
  },
});

const DashboardBar = React.memo(({ data, config, metricsOptions = [] }) => {
  const metrics = [];
  const indexesToRemove = [];
  data.metrics.forEach((el, index) => {
    if (el.values.every(v => v === '0')) {
      indexesToRemove.push(index);
    } else {
      metrics.push(el);
    }
  });
  const configMetrics = config.metrics.filter((el, index) => indexesToRemove.indexOf(index) === -1);
  const newData = {
    labels: getLabels(data.dimensions[0]),
    datasets: metrics.map((metric, index) => {
      const colors = configMetrics[index] && configMetrics[index].colors; // preventing application crash when removing metrics while editing. don't remove this. it should be detailed investigation why is this happening
      const color = calcColorForValue(0, colors);

      return {
        label: configMetrics[index]?.displayName || metricsOptions.find(m => m.name === metric.metric)?.displayName,
        borderWidth: 1,
        data: metric.values.map(v => parseInt(v, 10)),
        backgroundColor: color,
        borderColor: color,
        pointRadius: 0,
        pointBorderWidth: 2,
      };
    }),
  };
  const maxValue = calculateHighestValue(metrics);
  const shouldShowUnits = config.metrics[0].numberFormat === formattingTypes.DOLLAR;
  return (
    <div className="padding-bottom-md padding-horizontal-md flex-column flex-grow">
      <div className="flex-grow">
        <Charts.Bar
          data={newData}
          options={getDefaultOptions(shouldShowUnits)}
          isStacked
          hasLegend
          shouldShowUnits={shouldShowUnits}
          yAxisSuggestedMax={maxValue}
          isTooltipEnabled
        />
      </div>
    </div>
  );
});

DashboardBar.propTypes = sharedChartPropTypes;

export default DashboardBar;
