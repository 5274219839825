import React from 'react';

import { EmbeddedTable } from '../../../EmbeddedTable';

const columns = data => [
  {
    label: 'Origin',
    name: 'origin',
  },
  {
    label: 'Destination',
    name: 'destination',
  },
  {
    label: 'Percentage',
    name: 'pct',
    options: {
      customBodyRenderLite: i => data[i].view.pct,
    },
  },
  {
    label: 'Shipments',
    name: 'shipments',
  },
];

const options = {
  pagination: true,
  rowsPerPage: 7,
  sortOrder: { name: 'shipments', direction: 'desc' },
};

export default ({ data }) => <EmbeddedTable data={data} columns={columns(data)} options={options} />;
