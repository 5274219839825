const order = {
  ASCENDING: 'ASC',
  DESCENDING: 'DESC',
};

export default order;

export const orderDisplayNames = {
  [order.ASCENDING]: 'Ascending',
  [order.DESCENDING]: 'Descending',
};
