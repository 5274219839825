import React from 'react';
import classNames from 'classnames';

import { Icon, IconSizes } from '../../Components';

import './ActionLink.scss';

export default ({
  onClick,
  children,
  className,
  isDisabled = false,
  isDisabledAndPointable = false,
  isWarning = false,
  isInverted = false,
  isApiActionLink = false,
  hasIcon = false,
  iconName,
  isIconSmall = false,
  hasNoPadding = false,
  isSelected = false,
  doOpenInNewWindow = false,
  href,
}) => (
  <a
    onClick={onClick}
    onKeyPress={onClick}
    className={classNames({
      'action-link': !isApiActionLink,
      'action-link--disabled': isDisabled,
      'action-link--disabled-and-pointable': isDisabledAndPointable,
      'action-link--warning': isWarning,
      'action-link--inverted': isInverted,
      'action-link-api': isApiActionLink,
      'action-link-with-icon': hasIcon,
      'action-link--padding': !hasNoPadding,
      'action-link--selected': isSelected,
      [className]: className,
    })}
    target={doOpenInNewWindow ? '_blank' : '_self'}
    href={href ? href : undefined}
  >
    {hasIcon && (
      <Icon name={iconName} size={!isIconSmall ? IconSizes.lg : IconSizes.base} className="action-link__icon" />
    )}
    {children}
  </a>
);
