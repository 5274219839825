import React from 'react';

export default () => (
  <svg
    width="120px"
    height="120px"
    viewBox="0 0 24 24"
    fill="white"
    strokeWidth="1"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <circle cx="12" cy="10" r="8" fill="rgba(0, 21, 48, 0.24)" />
  </svg>
);
