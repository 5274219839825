export default class CarrierSpend {
  constructor(response) {
    Object.assign(this, {
      ...response,
      lsp: {
        rpm: response.linehaul_lsp_basis_rpm,
      },
      shipper: {
        rpm: response.linehaul_shipper_basis_rpm,
      },
    });
  }

  static get = async (client, companyId, laneId) =>
    client
      .get(`/analytics/explorer/carrier-spends/companies/${companyId}/lanes/${laneId}`)
      .then(({ carrier_spends }) => {
        const equipmentClasses = Object.keys(carrier_spends);

        return equipmentClasses.reduce(
          (acc, key) => {
            acc[key] = {
              ...carrier_spends[key],
              data: carrier_spends[key].data.map(volume => new CarrierSpend(volume)),
            };
            return acc;
          },
          { equipmentClasses },
        );
      });
}
