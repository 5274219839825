const dimensions = {
  SHIPMENT_DATE: 'shipment_date',
  SHIPMENT_WEEK: 'shipment_week',
  SHIPMENT_YEAR: 'shipment_year',
  SHIPMENT_MONTH: 'shipment_month',
  REPORTING_LANE: 'reporting_lane',
  REPORTING_LANE_ORIGIN: 'reporting_lane_origin',
  REPORTING_LANE_DESTINATION: 'reporting_lane_destination',
  LANE: 'lane',
  ORIGIN: 'origin',
  DESTINATION: 'destination',
  CARRIER_NAME: 'carrier_name',
  SHIPPER_NAME: 'shipper_name',
  CONTRACT_TYPE: 'contract_type',
  REPORTING_LANE_ID: 'reporting_lane_id',
  CONTRACT_LANE: 'contract_lane',
  CONTRACTED_LANE_ID: 'contracted_lane_id',
  CONTRACT_LANE_TYPE: 'contract_lane_type',
  CONTRACT_LANE_ORIGIN: 'contract_lane_origin',
  CONTRACT_LANE_DESTINATION: 'contract_lane_destination',
  CONTRACT_LEG_NUMBER: 'contract_leg_number',
  LANE_SHIPPER_MILEAGE: 'lane_shipper_mileage',
  LANE_LEAF_MILEAGE: 'lane_leaf_mileage',
  ORIGIN_BUSINESS_NAME: 'origin_business_name',
  ORIGIN_ADDRESS: 'origin_address',
  ORIGIN_CITY: 'origin_city',
  ORIGIN_STATE: 'origin_state',
  ORIGIN_ZIP: 'origin_zip',
  DESTINATION_BUSINESS_NAME: 'destination_business_name',
  DESTINATION_ADDRESS: 'destination_address',
  DESTINATION_CITY: 'destination_city',
  DESTINATION_STATE: 'destination_state',
  DESTINATION_ZIP: 'destination_zip',
  CONTRACTED_LEG_ID: 'contracted_leg_id',
  MATCHED_PTP_LANE_ID: 'matched_ptp_lane_id',
  MATCHED_PTP_LANE_ORIGIN: 'matched_ptp_lane_origin',
  MATCHED_PTP_LANE_DESTINATION: 'matched_ptp_lane_destination',
  SHIPPER_ID: 'shipper_id',
  CARRIER_ID: 'carrier_id',
  EQUIPMENT_TYPE_ID: 'equipment_type_id',
  EQUIPMENT_TYPE_NAME: 'equipment_type_name',
  STATUS: 'status',
  COHORT_MONTH: 'cohort_month',
  COHORT_WEEK: 'cohort_week',
  COHORT_NAME: 'cohort_name',
};

export default dimensions;

export const timeDimensions = [
  dimensions.SHIPMENT_DATE,
  dimensions.SHIPMENT_WEEK,
  dimensions.SHIPMENT_MONTH,
  dimensions.SHIPMENT_YEAR,
];
