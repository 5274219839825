import React from 'react';
import styled from '@emotion/styled';

const Label = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
`;

const Visual = styled.div`
  display: flex;
  height: 0.5em;
  background-color: ${({ color }) => color};
  border-radius: 0.5em;
  margin-top: 0.25em;
  margin-bottom: 0.25em;
`;

const VisualProgress = styled.div`
  transition: width 2s;
  width: ${({ width }) => width};
  background-color: ${({ color }) => color};
  border-top-left-radius: ${({ index }) => `${index === 0 ? '0.5em' : 0}`};
  border-bottom-left-radius: ${({ index }) => `${index === 0 ? '0.5em' : 0}`};
`;

export default ({ top, bottom, data, color = 'lightgrey' }) => {
  const [widths, setWidths] = React.useState(data.map(() => 0));

  React.useEffect(() => {
    requestAnimationFrame(() => {
      setWidths(data.map(item => item.percentage));
    });
  }, [data]);

  return (
    <>
      <Label>{top}</Label>

      <Visual color={color}>
        {data.map((item, i) => (
          <VisualProgress key={i} width={widths[i]} color={item.color} index={i} />
        ))}
      </Visual>

      <Label>{bottom}</Label>
    </>
  );
};
