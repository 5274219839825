import React from 'react';
import styled from '@emotion/styled';

import { CircularProgress, Grid } from '@mui/material';

const Loading = styled(Grid, {
  shouldForwardProp: prop => prop !== '$offset',
})`
  height: ${({ $offset }) => `calc(100vh - ${$offset})`};
  align-content: center;
  justify-content: center;
`;

const LazySpinner = ({ offset = '0px' }) => (
  <Loading container $offset={offset}>
    <CircularProgress />
  </Loading>
);

export default LazySpinner;
