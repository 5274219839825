const ROUTE_OVERVIEW_LANE_COLORS: Array<string> = ['#476587', '#83C1FF', '#7B61FF', '#4A90E2', '#BDBDBD', '#0064B0'];

export const getRouteOverviewColor = (index: number): string => {
  let colorIndex = index;
  if (colorIndex >= ROUTE_OVERVIEW_LANE_COLORS.length) {
    colorIndex -= ROUTE_OVERVIEW_LANE_COLORS.length;
  }

  return ROUTE_OVERVIEW_LANE_COLORS[colorIndex];
};
