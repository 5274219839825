import React from 'react';
import { Grid, Typography } from '@mui/material';
import styled from '@emotion/styled';

import LazySpinner from '../LazySpinner';

const PageContainer = styled(Grid)`
  background-color: ${({ theme }) => theme.palette.background.default};
  height: auto;
`;

const Content = styled(Grid)`
  @media (max-width: 1280px) {
    // breakpoint is same as grids "md" width from MUI
    height: calc(100vh - 60px);
    overflow-y: auto;
  }
`;

const PageTitle = styled(Grid)`
  align-items: center;
  border-bottom: ${({ theme }) => `1px solid ${theme.palette.divider}`};
  display: flex;
  height: 60px;
  padding: ${({ theme }) => `${theme.spacing(2)}`};
`;

const PageTitleWithColor = styled(PageTitle, {
  shouldForwardProp: prop => prop !== '$isSecondary' && prop !== '$isUppercase' && prop !== '$hasMargin',
})`
  background-color: ${({ $isSecondary, theme }) => ($isSecondary ? theme.palette.background.default : 'white')};
  margin-left: ${({ $hasMargin, theme }) => ($hasMargin ? theme.spacing(2) : 0)};
  margin-right: ${({ $hasMargin, theme }) => ($hasMargin ? theme.spacing(2) : 0)};
  ${({ $isUppercase }) => $isUppercase && 'text-transform: uppercase;'}
  border-left: 1px solid #E5E5E5;
`;

const Page = ({
  title = '',
  ComponentTitleLeft,
  ComponentTitleRight,
  isSecondary = false,
  hasMargin = false,
  isUppercase = true,
  lazySpinner = false,
  children,
  UserDescription,
}) => (
  <PageContainer container>
    <PageTitleWithColor item xs={12} $isSecondary={isSecondary} $hasMargin={hasMargin} $isUppercase={isUppercase}>
      {ComponentTitleLeft}
      <Typography variant="h6">{title}</Typography>
      {ComponentTitleRight}
      {UserDescription}
    </PageTitleWithColor>
    <Content container item xs={12}>
      {lazySpinner ? <LazySpinner /> : children}
    </Content>
  </PageContainer>
);

export default Page;
