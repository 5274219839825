const primaryMain = '#4A90E2';
const secondaryMain = '#183B5A';
const redPrimary = '#F88078';
const blueLeaf = '#4A90E2';
const greenPrimary = '#4CAF50';
const greenLeaf = '#1DD264';
const orangePrimary = '#FF9800';
const background = '#fafafa';
const divider = '#E5E5E5';
const disabled = 'rgba(0, 0, 0, 0.26)';
const deEmphasize = 'rgba(0, 0, 0, 0.38)';

export {
  primaryMain,
  secondaryMain,
  background,
  divider,
  redPrimary,
  greenPrimary,
  greenLeaf,
  blueLeaf,
  orangePrimary,
  disabled,
  deEmphasize,
};
