/* eslint-disable no-underscore-dangle */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable no-param-reassign */
import React from 'react';
import memoize from 'memoize-one';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { v4 } from 'uuid';
import { Button, IconButton, Tooltip } from '@mui/material';
import { Add } from '@mui/icons-material';
import DashboardContext from './DashboardContext';
import DateRangePickerPopup from './Components/DateRangePickerPopup';
import FiltersPopup from './Components/FiltersPopup';
import DashboardGrid from './Components/DashboardGrid';
import {
  HeaderPanel,
  HeaderPanelLeft,
  HeaderPanelRight,
  Scrollbar,
  ClosablePill,
  withSafeSetState,
} from './Common/Components';
import { date as dateTimeService } from '../utility';
import { CONTRACT_TYPE_DISPLAY_NAMES } from '../constants/ContractType';
import { platformSettings } from './Common/Config';
import {
  filterTypes,
  filterTypeDisplayNames,
  widgetsConfig as defaultWidgetsConfig,
  chartLayoutRestrictions,
  formattingTypes,
  defaultColorOption,
  date,
  draftWidgetTypes,
} from './Constants';
import { getActiveFilters, makeEmptySelectedByTypeObj, pickActiveFilters, setActiveFilters } from './paramsHelper';
import AddEditWidgetModal from './Components/AddEditWidgetModal';

import './Dashboard.scss';
import { timeDimensions } from './Constants/dimensions';
import { getFilters } from './filters';
import LazySpinner from '../LazySpinner';

const mapInitialFilters = (initialFilters = []) =>
  Object.keys(initialFilters).reduce((acc, key) => {
    acc[key] = {
      [initialFilters[key]]: true,
    };
    return acc;
  }, {});
class DashboardComponent extends React.PureComponent {
  _lastLayout = {};

  mergeLastLayoutWithWidgetConfigs = memoize((lastLayouts, widgetConfigs, hasUserChangedLayout) =>
    widgetConfigs.map(c => {
      if (lastLayouts[c.layout.i]) {
        return {
          ...c,
          layout: {
            ...(hasUserChangedLayout
              ? {
                  ...c.layout,
                  ...lastLayouts[c.layout.i],
                }
              : {
                  ...lastLayouts[c.layout.i],
                  ...c.layout,
                }),
          },
        };
      }
      return c;
    }),
  );

  saveWidgetsConfig = _.debounce((hasUserChangedLayout = false) => {
    const { configResponse } = this.state;
    const id = configResponse[this.name] && configResponse[this.name].id;
    const widgetConfigs = this.calcConfigsWithLatestLayout(hasUserChangedLayout);
    const widgetConfigsStringified = JSON.stringify(widgetConfigs);
    if (
      configResponse[this.name] &&
      configResponse[this.name].config === widgetConfigsStringified &&
      this.name === configResponse[this.name].tab
    ) {
      return; // nothing changed, no need to save anything
    }
    const content = { ...configResponse[this.name], tab: this.name, config: widgetConfigsStringified };
    let promise;
    if (id) {
      // PUT
      promise = this.api.updateDashboardConfig(this.name, content);
    } else {
      // POST
      promise = this.api.saveDashboardConfig(content).then(response =>
        this.setState({
          configResponse: {
            ...configResponse,
            [this.name]: response.data,
          },
        }),
      );
    }
    promise.catch(this.onErrorHandler);
  }, 200);

  constructor(props) {
    super();
    const { initialFilters, name, api, onErrorHandler } = props;
    this.name = name;
    this.api = api;
    this.onErrorHandler = error => {
      const { message } = error;
      onErrorHandler(message ?? error);
    };
    this.state = {
      canEdit: false,
      metricsOptions: [],
      dimensionOptions: [],
      filterOptions: [],
      name,
      isLoading: true,
      // AddEditWidgetModal
      isAddEditWidgetModalVisible: false,
      draftWidgetConfig: {},
      draftWidgetType: null,
      activeFilters: {
        from: dateTimeService.subtractMonthsFromDate(new Date(), 1),
        to: new Date(),
      },
      initialFilters: mapInitialFilters(initialFilters),
      // FiltersPopup
      filtersPopup: {
        searchTerm: '',
        activeFilterList: filterTypes.LANES,
        selectedByType: makeEmptySelectedByTypeObj(),
      },
      areFiltersLoading: true,
      hasFailedToLoadFilters: false,
      lanes: [],
      reportingLanes: [],
      origins: [],
      destinations: [],
      equipment: [],
      networkMoves: [],
      // client only filter
      counterparties: [],
      // admin only filter
      carriers: [],
      shippers: [],
      cohorts: [],
      transportationModes: [],
      contractTypes: Object.keys(_.omit(CONTRACT_TYPE_DISPLAY_NAMES, 'NETWORK')).map(contractType => ({
        name: CONTRACT_TYPE_DISPLAY_NAMES[contractType],
        guid: contractType,
      })),
      config: [],
      configResponse: {
        // needed for PUT operations
        [this.name]: undefined,
      },
    };
  }

  componentDidMount() {
    const { userType } = this.props;
    const { initialFilters } = this.state;
    if (initialFilters) {
      const activeFilters = getActiveFilters(this.state) || [];
      setActiveFilters(activeFilters, this.setState.bind(this), this.state);
    }
    this.loadWidgetConfigs(() => {
      getFilters.apply(this, [userType, this.onErrorHandler]);
      Promise.all([this.api.getMetrics(), this.api.getDimensions(), this.api.getFilterOptions()])
        .then(([metricsOptions, dimensionOptions, filterOptions]) =>
          this.setState({
            isLoading: false,
            metricsOptions,
            dimensionOptions,
            filterOptions,
          }),
        )
        .catch(this.onErrorHandler);
    });
  }

  componentDidUpdate(prevProps) {
    const { canEdit } = this.props;
    if (canEdit && canEdit !== prevProps.canEdit) {
      this.setState({
        canEdit,
      });
    }
  }

  removeFilter = (filterType, id) => {
    const activeFilters = getActiveFilters(this.state);
    activeFilters[filterType][id] = false;
    setActiveFilters(activeFilters, this.setState.bind(this), this.state);
  };

  removeAllParamFromUrlOfType = filterType => {
    const activeFilters = getActiveFilters(this.state);
    activeFilters[filterType] = {};
    setActiveFilters(activeFilters, this.setState.bind(this), this.state);
  };

  isFilterWithNameAsId = filterType =>
    filterType === filterTypes.ORIGINS ||
    filterType === filterTypes.DESTINATIONS ||
    filterType === filterTypes.LANES ||
    filterType === filterTypes.REPORTING_LANES ||
    filterType === filterTypes.COHORTS ||
    filterType === filterTypes.TRANSPORTATION_MODES;

  calcPillName = (filterType, id) => {
    const { contractTypes, equipment, counterparties, shippers, carriers, networkMoves } = this.state;
    if (this.isFilterWithNameAsId(filterType)) {
      return id;
    }
    if (filterType === filterTypes.CONTRACT_TYPES) {
      return this.calcItemName(contractTypes, id);
    }
    if (filterType === filterTypes.EQUIPMENT) {
      return this.calcItemName(equipment, id);
    }
    if (filterType === filterTypes.COUNTERPARTIES) {
      return this.calcItemName(counterparties, id);
    }
    if (filterType === filterTypes.SHIPPERS) {
      return this.calcItemName(shippers, id);
    }
    if (filterType === filterTypes.CARRIERS) {
      return this.calcItemName(carriers, id);
    }
    if (filterType === filterTypes.NETWORK_MOVES) {
      return this.calcItemName(networkMoves, id);
    }
    return '';
  };

  calcItemName = (items, id) => {
    const item = this.findItemWithId(items, id) || items.find(i => i.guid === id);
    return item ? item.name : platformSettings.notAvailableDataString;
  };

  findItemWithId = (items, id) => items.find(el => el.id === (typeof id === 'number' ? id.toString() : id));

  renderFilterPillsMemoized = memoize(params => {
    const pills = [];
    Object.keys(params).forEach(filterType => {
      const filterIds = Object.keys(params[filterType]);
      if (filterIds.length === 1) {
        pills.push({
          name: this.calcPillName(filterType, filterIds[0]),
          onClose: () => this.removeFilter(filterType, filterIds[0]),
        });
      } else if (filterIds.length > 1) {
        const { userType } = this.props;
        pills.push({
          name: `${filterTypeDisplayNames(userType)[filterType]}: ${filterIds.length}`,
          onClose: () => this.removeAllParamFromUrlOfType(filterType),
        });
      }
    });

    return pills.map(p => (
      <ClosablePill key={v4()} text={p.name} onClose={p.onClose} className="dashboard__filters-pill" />
    ));
  });

  renderFilterPills = () => {
    const params = getActiveFilters(this.state);
    return this.renderFilterPillsMemoized(params);
  };

  handleLayoutChange = layouts => {
    const layoutMap = {};
    layouts.forEach(l => {
      layoutMap[l.i] = l;
    });
    this._lastLayout = layoutMap;
    this.saveWidgetsConfig(true);
  };

  calcConfigsWithLatestLayout = hasUserChangedLayout => {
    // used only when rendering and saving to BE
    const { config: widgetConfigs } = this.state;
    return this.mergeLastLayoutWithWidgetConfigs(this._lastLayout, widgetConfigs, hasUserChangedLayout);
  };

  getActiveFilters = () => pickActiveFilters(this.state);

  handleDateRangeChange = (from, to) => {
    const activeFilters = this.getActiveFilters();
    if (activeFilters.from.getTime() !== from.getTime() || activeFilters.to.getTime() !== to.getTime()) {
      this.setState({
        activeFilters: {
          ...activeFilters,
          from,
          to,
        },
      });
    }
  };

  openAddEditWidgetModal = (config, draftWidgetType = draftWidgetTypes.EDIT) => {
    const defaultConfig = {
      formattingType: formattingTypes.NO_FORMATTING,
    };
    this.setState({
      isAddEditWidgetModalVisible: true,
      draftWidgetConfig: Object.assign(defaultConfig, config),
      draftWidgetType,
    });
  };

  deleteWidget = config => {
    const { config: allConfigs } = this.state;
    const updatedConfigs = allConfigs.filter(c => c.layout.i !== config.layout.i);
    this.updateWidgetsConfig(updatedConfigs);
  };

  handleWidgetAddOrEdit = config => {
    const { dimensionOptions } = this.state;
    if (config.dimensions && config.dimensions.length === 1) {
      const { name: dimension } = config.dimensions?.[0] || {};

      // add time dimensions to be configurable
      config.configurableDimensions = timeDimensions.includes(dimension)
        ? {
            [dimension]: timeDimensions.map(timeDimension => ({
              name: dimensionOptions.find(({ name }) => name === timeDimension)?.displayName,
              id: timeDimension,
            })),
          }
        : {};
    } else {
      config.configurableDimensions = undefined;
    }

    config.layout = {
      // add default w, h, and maxH, maxW, minH, minW restrictions
      ...config.layout,
      ...chartLayoutRestrictions[config.chartType],
    };

    const { config: allConfigs } = this.state;
    let shouldUnshift = true;
    const widgetId = config.layout.i;

    const updatedConfigs = allConfigs.map(c => {
      if (c.layout.i !== widgetId) {
        return c;
      }
      shouldUnshift = false;
      return config;
    });
    if (shouldUnshift) {
      updatedConfigs.unshift(config);
    }
    this.updateWidgetsConfig(updatedConfigs);
  };

  updateWidgetsConfig = updatedConfigs => {
    this.setState(
      {
        config: updatedConfigs,

        // reset state
        isAddEditWidgetModalVisible: false,
        draftWidgetConfig: {},
        draftWidgetType: null,
      },
      () => this.saveWidgetsConfig(),
    );
  };

  setDefaultWidgetConfig = () => {
    const { userType } = this.props;
    const defaultConfig = defaultWidgetsConfig[userType]?.[this.name] || [];
    this.updateWidgetsConfig(defaultConfig);
  };

  loadWidgetConfigs = onConfigSuccess => {
    const { configResponse } = this.state;
    this.api
      .getDashboardConfig(this.name)
      .then(response => {
        const config = JSON.parse(response.config);
        this.setState({
          config,
          configResponse: {
            configResponse,
            [this.name]: response,
          },
        });
        onConfigSuccess?.();
      })
      .catch(error => {
        this.onErrorHandler(error);
        if (
          [404, 400].includes(error.response?.status) ||
          [404, 400].includes(error.status) ||
          error.toLowerCase().includes('not found') ||
          error.toLowerCase().includes('config could not be found')
        ) {
          this.setState({
            isLoading: false,
            configResponse: {
              ...configResponse,
              [this.name]: undefined,
            },
          });
          this.setDefaultWidgetConfig();
        }
      });
  };

  closeAddEditWidgetModalAndResetState = () =>
    this.setState({
      isAddEditWidgetModalVisible: false,
      draftWidgetConfig: {},
      draftWidgetType: null,
    });

  handleAddNewWidgetButtonClick = () => {
    this.openAddEditWidgetModal(
      {
        metrics: [
          {
            colors: [defaultColorOption],
          },
        ],
        dimensions: [],
        filters: [],
        pivotRows: [],
        pivotColumns: [],
        order: {},
        layout: { i: v4(), x: 0, y: 0 },
      },
      draftWidgetTypes.NEW,
    );
  };

  renderFiltersRow() {
    const {
      areFiltersLoading,
      hasFailedToLoadFilters,
      lanes,
      origins,
      destinations,
      equipment,
      contractTypes,
      counterparties,
      shippers,
      carriers,
      cohorts,
      transportationModes,
      networkMoves,
      reportingLanes,
      canEdit,
      activeFilters: { from, to },
    } = this.state;
    const { userType } = this.props;
    return (
      <HeaderPanel>
        <HeaderPanelLeft hasLargeMargin>
          {!hasFailedToLoadFilters && (
            <div className="dashboard__filter-pills-row">
              <FiltersPopup
                isLoading={areFiltersLoading}
                lanes={lanes}
                origins={origins}
                destinations={destinations}
                equipment={equipment}
                contractTypes={contractTypes}
                counterparties={counterparties}
                shippers={shippers}
                carriers={carriers}
                cohorts={cohorts}
                transportationModes={transportationModes}
                networkMoves={networkMoves}
                reportingLanes={reportingLanes}
                userType={userType}
              />
              {this.renderFilterPills()}
            </div>
          )}
        </HeaderPanelLeft>
        <HeaderPanelRight>
          <DateRangePickerPopup
            presets={date.datePickerPresets[this.name] || date.datePickerPresets.performance}
            from={from}
            to={to}
            onChange={this.handleDateRangeChange} // needed to set value on preset click
            disabledDays={date.getDisabledDays(this.name)}
          />
          <div className="margin-left-sm" style={{ lineHeight: 1 }}>
            {canEdit && (
              <Tooltip title="Add New Widget">
                <IconButton onClick={this.handleAddNewWidgetButtonClick} size="large">
                  <Add />
                </IconButton>
              </Tooltip>
            )}
          </div>
        </HeaderPanelRight>
      </HeaderPanel>
    );
  }

  render() {
    const filters = this.getActiveFilters();
    const {
      configResponse,
      metricsOptions,
      dimensionOptions,
      filterOptions,
      draftWidgetType,
      draftWidgetConfig,
      isAddEditWidgetModalVisible,
      canEdit,
      isLoading,
    } = this.state;
    const { userType } = this.props;
    const showAddNewWidgetButton = canEdit && !(configResponse[this.name] === undefined);

    return (
      <DashboardContext.Provider
        value={{
          state: this.state,
          setState: this.setState.bind(this),
          onErrorHandler: this.onErrorHandler,
          api: this.api,
        }}
      >
        <div className="dashboard">
          {this.renderFiltersRow()}
          {isLoading ? (
            <LazySpinner offset="120px" />
          ) : (
            <Scrollbar className="dashboard__content">
              {dimensionOptions?.length > 0 && metricsOptions?.length > 0 && (
                <DashboardGrid
                  filters={filters}
                  widgetsConfig={this.calcConfigsWithLatestLayout()}
                  onLayoutChange={this.handleLayoutChange}
                  onWidgetEdit={this.openAddEditWidgetModal}
                  onWidgetDelete={this.deleteWidget}
                  canEdit={canEdit}
                />
              )}
              {showAddNewWidgetButton && (
                <div className="flex-row justify-center margin-bottom-md">
                  <Button variant="contained" color="primary" onClick={this.handleAddNewWidgetButtonClick}>
                    Add new widget
                  </Button>
                </div>
              )}
              {isAddEditWidgetModalVisible && (
                <AddEditWidgetModal
                  isCompanyTypeShipper={userType === 'shipper'}
                  modalTitle={`${draftWidgetType === draftWidgetTypes.EDIT ? 'Edit' : 'Add new'} widget`}
                  onCancel={this.closeAddEditWidgetModalAndResetState}
                  onSubmit={this.handleWidgetAddOrEdit}
                  metricsOptions={metricsOptions}
                  dimensionOptions={dimensionOptions
                    .map(({ name, displayName }) => ({
                      key: name,
                      value: name,
                      text: displayName,
                    }))
                    .sort((a, b) => (a.text > b.text ? 1 : -1))}
                  filterOptions={filterOptions
                    .map(({ name, displayName }) => ({
                      key: name,
                      value: name,
                      text: displayName,
                    }))
                    .sort((a, b) => (a.text > b.text ? 1 : -1))}
                  initialValues={draftWidgetConfig}
                />
              )}
            </Scrollbar>
          )}
        </div>
      </DashboardContext.Provider>
    );
  }
}

DashboardComponent.propTypes = {
  /* name prop is used to identify the dashboard and that same value is used for
		retrieving the dashboard config file name from widgetsConfig folder */
  name: PropTypes.string.isRequired,
  onErrorHandler: PropTypes.func,
  userType: PropTypes.string.isRequired,
  api: PropTypes.object.isRequired,
  initialFilters: PropTypes.object,
};

DashboardComponent.defaultProps = {
  initialFilters: {},
  onErrorHandler: () => {},
};

export default withSafeSetState(DashboardComponent);
