import React from 'react';
import { LinearProgress, Box } from '@mui/material';
import styled from '@emotion/styled';

const LinearProgressWrapper = styled(LinearProgress)`
  .MuiLinearProgress-dashed {
    top: 0;
    left: 0;
    width: 100%;
    bottom: 0;
    position: absolute;
    transition: transform 0.2s linear;
    transform-origin: left;
    animation: unset;
  }
  .MuiLinearProgress-dashedColorPrimary {
    background-image: unset;
    background-color: #f5f5f5;
  }
  .MuiLinearProgress-barColorPrimary {
    background-color: ${({ $primaryColor }) => ($primaryColor ? `${$primaryColor}` : '#4a90e2')};
  }
  .MuiLinearProgress-colorPrimary {
    background-color: ${({ $secondaryColor }) => ($secondaryColor ? `${$secondaryColor}` : '#e0e0e0')};
  }
`;

const DoubleValuesProgressBar = ({
  variant = 'buffer',
  primaryValue,
  secondaryValue,
  primaryColor,
  secondaryColor,
}) => (
  <Box>
    <LinearProgressWrapper
      variant={variant}
      value={primaryValue}
      valueBuffer={secondaryValue}
      $primaryColor={primaryColor}
      $secondaryColor={secondaryColor}
    />
  </Box>
);

export default DoubleValuesProgressBar;
