const overrideMuiTable = (typography, palette) => ({
  MUIDataTableBodyCell: {
    root: { whiteSpace: 'nowrap', ...typography.body1 },
  },
  MUIDataTableHeadCell: {
    root: { borderRight: 'none', ...typography.caption },
  },
  MuiToolbar: {
    root: { ...typography.body1 },
  },
  MuiTooltip: {
    tooltip: {
      maxWidth: 'none',
      backgroundColor: '#757575',
    },
  },
  MuiTabs: {
    scroller: {
      borderBottom: `1px solid #e0e0e0`,
    },
  },
  MuiToggleButton: {
    root: {
      'color': palette.primary.main,
      'border': `1px solid ${palette.primary.main}`,
      '&.Mui-selected': {
        'color': 'white',
        'backgroundColor': palette.primary.main,
        '&:hover': {
          backgroundColor: palette.primary.main,
          opacity: 0.9,
        },
      },
    },
  },
  MUIDataTableToolbar: {
    root: { borderBottom: `1px solid ${palette.divider}` },
    filterPaper: { minWidth: '630px' },
  },
  MuiTypography: {
    body2: { letterSpacing: 0 },
  },
  MuiChip: {
    root: { marginRight: '8px', border: 'none' },
    label: { letterSpacing: 0 },
  },
  MuiTableRow: {
    root: {
      '&:hover': { cursor: 'pointer', backgroundColor: `#eeeeee !important` },
      '&:nth-child(odd)': { backgroundColor: `#fafafa` },
    },
    head: { backgroundColor: `#fff` },
    footer: {
      '&:hover': { cursor: 'default !important', backgroundColor: `#fff !important` },
      'borderTop': `1px solid #e0e0e0`,
      'backgroundColor': `#fff !important`,
    },
  },
  MuiTableCell: {
    root: {
      'borderBottom': `1px solid ${palette.divider}`,
      'borderRight': `1px solid ${palette.divider}`,
      '&:first-child': {
        borderLeft: `1px solid ${palette.divider}`,
      },
      ...typography.caption,
    },
    paddingCheckbox: {
      backgroundColor: 'white',
    },
    head: {
      'borderRight': 'none',
      '&:first-child': {
        borderLeft: 'none',
      },
      ...typography.caption,
    },
  },
  MUIDataTableFilter: {
    root: { backgroundColor: '#FFF' },
  },
  MuiInputBase: {
    root: { padding: '0px !important' },
  },
  MuiAutocomplete: {
    inputRoot: { padding: '8px !important' },
  },
  MUIDataTable: {
    responsiveBase: {
      flex: 1,
      zIndex: 1,
    },
    tableRoot: {
      borderCollapse: 'separate',
    },
  },
  MuiTablePagination: {
    selectLabel: {
      letterSpacing: 0,
    },
    displayedRows: {
      letterSpacing: 0,
    },
  },
  MuiAlert: {
    root: {
      flexDirection: 'row !important',
      alignItems: 'flex-end !important',
      ...typography.body1,
    },
  },
  MuiDialogContent: {
    root: {
      paddingTop: '16px !important',
    },
  },
});

const createOverrides = (typography, palette, shadows) => ({
  ...overrideMuiTable(typography, palette, shadows),
});

export default createOverrides;
