/* eslint-disable no-restricted-globals */
import React, { useEffect, useState } from 'react';
import MUIDataTable from 'mui-datatables';
import { Box, Typography } from '@mui/material';
import styled from '@emotion/styled';
import { sharedChartPropTypes } from './types';
import dimensions from '../../Constants/dimensions';
import ContractType from '../../../ContractType';
import { formattingFns } from '../../../utility/formattingHelper';
import AlignText from '../../../AlignText';
import { ORDER_QUERY_PARAM_SEPARATOR } from '../../Constants';

const DATA_SEPARATOR = '||';

const TableWrapper = styled(Box)`
  height: calc(100% - 55px); // where 55px is the height of widget header
  .right-aligned {
    padding: 0;
    & > span {
      justify-content: flex-end;
    }
    & > div {
      text-align: right;
    }
  }
  .MuiPaper-root {
    border: none;
    box-shadow: none;
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow-y: scroll;
  }
`;

const generateDimensionColumns = (dimensionsData, dimensionOptions, config) =>
  (dimensionsData || [])
    .filter((_, idx) => config.dimensions[idx])
    .map(({ dimension }, index) => ({
      label:
        config.dimensions[index].displayName || dimensionOptions.find(({ name }) => name === dimension)?.displayName,
      name: dimension,
      options: {
        customBodyRender: cellValue => {
          const [value] = cellValue?.split(DATA_SEPARATOR) ?? [];
          if (config.dimensions[index].name === dimensions.CONTRACT_TYPE) {
            return <ContractType type={value} />;
          }
          return <Typography variant={config.dimensions[index].fontVariant || 'body1'}>{value || '-'}</Typography>;
        },
        sortCompare:
          order =>
          ({ data: data1 }, { data: data2 }) => {
            const [value1] = data1?.split(DATA_SEPARATOR) ?? [];
            const [value2] = data2?.split(DATA_SEPARATOR) ?? [];
            return (value1 < value2 ? -1 : 1) * (order === 'desc' ? -1 : 1);
          },
      },
    }));

const generateMetricColumns = (metricsData, config, metricsOptions) =>
  (metricsData || [])
    .filter((_, idx) => config.metrics[idx])
    .map(({ metric }, index) => ({
      label: config.metrics[index]?.displayName || metricsOptions.find(m => m.name === metric)?.displayName,
      name: `${metric}${ORDER_QUERY_PARAM_SEPARATOR}${config.metrics[index].fn}`,
      options: {
        customBodyRender: cellValue => {
          const [value] = cellValue?.split(DATA_SEPARATOR) ?? [];
          return (
            <AlignText position="right">
              <Typography variant={config.metrics[index].fontVariant || 'body1'}>{value || '-'}</Typography>
            </AlignText>
          );
        },
        setCellHeaderProps: () => ({
          className: 'right-aligned',
        }),
        sortCompare:
          order =>
          ({ data: data1 }, { data: data2 }) => {
            const [, value1] = data1?.split(DATA_SEPARATOR) ?? [];
            const [, value2] = data2?.split(DATA_SEPARATOR) ?? [];
            const formattedValue1 = value1 && !isNaN(value1) ? parseFloat(value1) : null;
            const formattedValue2 = value2 && !isNaN(value2) ? parseFloat(value2) : null;
            return (formattedValue1 < formattedValue2 ? -1 : 1) * (order === 'desc' ? -1 : 1);
          },
      },
    }));

const extractValues = (data, fieldName, config, index) =>
  (data || [])
    .map(object => object[fieldName])
    .reduce((result, object, idx) => {
      let valueToDisplay = data[idx].values[index];
      let objectProperty = object;
      let value = [valueToDisplay, data[idx].values[index]].join(DATA_SEPARATOR);
      if (fieldName === 'metric' && config.metrics[idx]) {
        const fieldFormat = config.metrics[idx].numberFormat;
        const formattingFn = formattingFns[fieldFormat] || (v => v);
        valueToDisplay = fieldFormat ? formattingFn(valueToDisplay) : valueToDisplay;
        objectProperty = `${objectProperty}${ORDER_QUERY_PARAM_SEPARATOR}${config.metrics[idx].fn}`;
        value = [valueToDisplay, data[idx].values[index]].join(DATA_SEPARATOR);
      } else if (fieldName !== 'dimension') {
        value = null;
      }
      if (value) {
        // eslint-disable-next-line no-param-reassign
        result[objectProperty] = value;
      }
      return result;
    }, {});

const DashboardTableWidget = ({ data, config, metricsOptions = [], dimensionOptions = [] }) => {
  const [options, setOptions] = useState({
    enableNestedDataAccess: '.',
    filterArrayFullMatch: false,
    print: false,
    download: true,
    rowsPerPage: 10,
    rowsPerPageOptions: [],
    selectableRows: 'none',
    searchPlaceholder: 'Search',
    tableBodyMaxHeight: '100%',
    responsive: 'standard',
    filter: false,
    viewColumns: false,
    ...(config.order?.name &&
      config.order?.direction && {
        sortOrder: {
          name: config.order.name,
          direction: config.order.direction.toLowerCase(),
        },
      }),
    onDownload: (buildHead, buildBody, tableColumns, tableData) => {
      const formattedData = tableData.map(({ data: rowData, index }) => {
        const formattedRowData = [...rowData.map(row => row.split(DATA_SEPARATOR)?.[1])];
        return { data: formattedRowData, index };
      });
      return buildHead(tableColumns) + buildBody(formattedData);
    },
    onColumnSortChange: () => {
      setOptions(currentState => ({
        ...currentState,
        sortOrder: undefined,
        page: 0,
      }));
    },
  });

  const tableData = [];
  for (let i = 0; i < data.metrics[0].values.length; i += 1) {
    tableData.push({
      ...extractValues(data?.dimensions, 'dimension', config, i),
      ...extractValues(data?.metrics, 'metric', config, i),
    });
  }
  const columns = [
    ...generateDimensionColumns(data.dimensions, dimensionOptions, config),
    ...generateMetricColumns(data.metrics, config, metricsOptions),
  ];

  useEffect(() => {
    if (config.order?.name) {
      setOptions(currentState => ({
        ...currentState,
        sortOrder: {
          name: config.order.name,
          direction: config.order.direction?.toLowerCase(),
        },
      }));
    }
  }, [config.order]);

  return (
    <TableWrapper height={1}>
      <MUIDataTable data={tableData} columns={columns} options={options} />
    </TableWrapper>
  );
};

DashboardTableWidget.propTypes = sharedChartPropTypes;

export default DashboardTableWidget;
