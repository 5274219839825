import React from 'react';
import classNames from 'classnames';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import { Typography } from '@mui/material';
import { v4 } from 'uuid';

export const useStyles = makeStyles(
  createStyles(theme => ({
    primaryText: {
      fontWeight: 'bold',
      verticalAlign: 'top',
    },
    secondaryText: {
      color: theme.palette.text.secondary,
    },
  })),
);

const MultiRowInfo = ({ primaryContent, secondaryContent, hasNoWrap = false }) => {
  const classes = useStyles();

  return (
    <div>
      <div>
        {!!primaryContent?.length &&
          primaryContent.map(content => (
            <div className={classNames(classes.primaryText)} key={v4()}>
              <Typography variant="body2" noWrap={hasNoWrap}>
                {content}
              </Typography>
            </div>
          ))}
      </div>
      <div>
        {!!secondaryContent?.length &&
          secondaryContent.map(content => (
            <div className={classNames(classes.secondaryText)} key={v4()}>
              <Typography variant="body1" noWrap={hasNoWrap}>
                {content}
              </Typography>
            </div>
          ))}
      </div>
    </div>
  );
};

export default MultiRowInfo;
