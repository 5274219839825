import React from 'react';
import { Typography, Chip } from '@mui/material';
import styled from '@emotion/styled';
import { ON_TIME_STATUS_DISPLAY_NAMES, ON_TIME_STATUS } from '../constants/OnTimeStatus';

const Badge = styled(Chip)`
  margin-left: ${({ theme }) => theme.spacing(2)};
  background-color: ${({ bgColor }) => bgColor};
`;

// eslint-disable-next-line consistent-return
const getStatusColor = status => {
  switch (status) {
    case ON_TIME_STATUS.LATE:
      return '#FF7C33';
    case ON_TIME_STATUS.ON_TIME:
      return '#4caf50';
    case ON_TIME_STATUS.NOT_AVAILABLE:
      return '#adadad';
    default:
      break;
  }
};

export default ({ status }) => (
  <Badge
    label={<Typography variant="caption">{ON_TIME_STATUS_DISPLAY_NAMES[status]}</Typography>}
    color="primary"
    size="small"
    bgColor={getStatusColor(status)}
  />
);
