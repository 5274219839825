/* eslint-disable react/forbid-prop-types */
import PropTypes from 'prop-types';

import chartTypes from '../../Constants/chartTypes';

export const widgetConfigPropType = PropTypes.shape({
  chartType: PropTypes.oneOf(Object.values(chartTypes)).isRequired,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  metrics: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      fn: PropTypes.string,
      displayName: PropTypes.string,
      fontVariant: PropTypes.string,
    }),
  ).isRequired,
  dimensions: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      displayName: PropTypes.string,
      fontVariant: PropTypes.string,
    }),
  ),
  configurableDimensions: PropTypes.object,
  layout: PropTypes.shape({
    i: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    x: PropTypes.number.isRequired,
    y: PropTypes.number.isRequired,
    w: PropTypes.number.isRequired,
    h: PropTypes.number.isRequired,
    minW: PropTypes.number,
    minH: PropTypes.number,
    maxH: PropTypes.number,
    maxW: PropTypes.number,
    isResizable: PropTypes.bool,
  }),
}).isRequired;

export const sharedChartPropTypes = {
  config: widgetConfigPropType,
  data: PropTypes.shape({
    dimensions: PropTypes.arrayOf(PropTypes.shape({})),
    metrics: PropTypes.arrayOf(
      PropTypes.shape({
        metric: PropTypes.string.isRequired,
      }),
    ).isRequired,
  }).isRequired,
  selectedOrderId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  onOrderSelect: PropTypes.func,
  selectedDimensionId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  onDimensionSelect: PropTypes.func,
};
