import React from 'react';
import { Box, Grid } from '@mui/material';
import ImpactObjective from 'company/components/impact-objective/ImpactObjective';

export default ({ data }) => (
  <Grid container>
    {data.map(o => (
      <Grid key={o.type} item xs={4}>
        <Box boxShadow={2} m={1} height={180}>
          <ImpactObjective data={o} />
        </Box>
      </Grid>
    ))}
  </Grid>
);
