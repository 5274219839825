/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Controller } from 'react-hook-form';
import Switch from '../generic/Switch';

export default ({ name, control, label, ...rest }) => (
  <Controller
    control={control}
    name={name}
    render={props => (
      <Switch
        label={label}
        {...props}
        {...(props.onChange && { onChange: e => props.onChange(e.target.checked) })}
        checked={props.value}
        {...rest}
      />
    )}
  />
);
