import React from 'react';
import _ from 'lodash';

const outerDivStyle = { display: 'flex', flex: 1, flexGrow: 1 };
const innerDivStyle = { width: '100%', height: '100%', display: 'block' };

export default Comp => {
  return class HeightProviderHOC extends React.Component {
    state = {
      height: null,
    };

    componentDidMount() {
      this.calcHeight();
      window.addEventListener('resize', this.handleResize);
    }

    componentWillUnmount() {
      window.removeEventListener('resize', this.handleResize);
    }

    calcHeight = () => {
      const { height } = this.divRef.getBoundingClientRect();
      this.setState({ height });
    };

    handleResize = _.debounce(this.calcHeight, 200);

    saveRef = r => (this.divRef = r);

    render() {
      return (
        <div style={outerDivStyle} ref={this.saveRef}>
          {this.state.height !== null && (
            <div style={innerDivStyle}>
              <Comp {...this.props} height={this.state.height} />
            </div>
          )}
        </div>
      );
    }
  };
};
