/* eslint-disable react/jsx-props-no-spreading */
import React, { memo, useState } from 'react';

import { Box } from '@mui/material';
import styled from '@emotion/styled';
import Page from 'components/Page';
import { useAuth0 } from '@auth0/auth0-react';
import Help from './Components/Help';
import Content from './Components/Content';
import { getHelpContent } from './Pages';
import Wrapper from './Pages/Wrapper';

const RightSection = styled(Box)`
  flex-basis: 100%;
  background-color: white;
  border-left: 1px solid #e5e5e5;
`;

const LeftSection = styled(Box)`
  margin-right: ${({ theme }) => theme.spacing(4)};
`;

const HelpContent = styled(Box)`
  height: calc(100vh - 60px);
`;

export default memo(() => {
  const [helpPageContent, setHelpPageContent] = useState(null);
  const { getAccessTokenSilently } = useAuth0();

  const changeDisplayedContent = (fileName, title) => {
    setHelpPageContent({
      markdown: <Wrapper>{getHelpContent(fileName)}</Wrapper>,
      title,
      pageName: fileName,
    });
  };

  return (
    <Page title="Help">
      <HelpContent display="flex" flexGrow={1}>
        <LeftSection>
          <Help changeDisplayedContent={changeDisplayedContent} selectedItem={helpPageContent?.pageName} />
        </LeftSection>
        <RightSection>
          <Content
            pageName={helpPageContent?.pageName}
            markdown={helpPageContent?.markdown}
            title={helpPageContent?.title}
            getAccessTokenSilently={getAccessTokenSilently}
          />
        </RightSection>
      </HelpContent>
    </Page>
  );
});
