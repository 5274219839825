import React from 'react';
import { Typography } from '@mui/material';

export const releaseNotes = (
  <div>
    <Typography variant="h4">09/2022</Typography>

    <Typography variant="h6">New Features</Typography>

    <Typography variant="body1">
      <ul>
        <li>Ability to download opportunities as CSV export</li>
        <li>Sharing release notes</li>
      </ul>
    </Typography>
  </div>
);
