import { isEmpty } from 'lodash';
import { formatNumberAsView } from './numbers';
import { intervalAsView } from './time';
import { backendStringToViewString } from './date';
import { LANE_TYPE } from '../constants/LaneType';
import { TRIP_TYPE_DISPLAY_NAMES } from '../constants/TripType';

export const date = value => backendStringToViewString(value);

export const miles = value => {
  const number = formatNumberAsView(value, 0, false);
  return number !== '-' ? `${number} mi` : number;
};
export const hours = interval => intervalAsView(interval, 'hours', 1);

export const money = v => {
  if (!v) {
    return '-';
  }
  const sign = Math.sign(Number(v));
  const n = sign * Number(v);
  const withSign = s => (sign >= 0 ? s : `(${s})`);

  if (n < 1e3) {
    return withSign(`$${n.toFixed(2)}`);
  }
  if (n < 1e6) {
    return withSign(`$${(n / 1e3).toFixed(2)}k`);
  }
  if (n < 1e9) {
    return withSign(`$${(n / 1e6).toFixed(2)}M`);
  }
  if (n < 1e12) {
    return withSign(`$${(n / 1e9).toFixed(2)}B`);
  }
  return withSign(`$${(n / 1e12).toFixed(2)}T`);
};

export const toSnakeCase = s => s.replace(/[A-Z]/g, l => `_${l.toLowerCase()}`);

export const formatAsLaneTypeMiles = (distance, type) => {
  if (type === LANE_TYPE.POWER) {
    return `${String.fromCharCode(0x2248)} ${distance}`;
  }

  return distance;
};

export const formatAddressAsView = address => {
  if (typeof address !== 'undefined' && !isEmpty(address)) {
    if (address.city) {
      if (address.state && !address.zipCode) return `${address.city}, ${address.state}`;

      if (address.zipCode && !address.state) return `${address.city}, ${address.zipCode}`;

      return `${address.city}, ${address.state} ${address.zipCode}`;
    }
    if (address.state && address.zipCode) return `${address.state} ${address.zipCode}`;
    if (address.state) return address.state;
    if (address.zipCode) return address.zipCode;
    if (address.name) return address.name;
    if (address.originName) return address.originName;
    if (address.destinationName) return address.destinationName;
  }

  return '-';
};

export const formatLegs = (legs, tripType) => {
  const shouldShowLegs =
    tripType === TRIP_TYPE_DISPLAY_NAMES.CONTINUOUS_MOVE || tripType === TRIP_TYPE_DISPLAY_NAMES.CIRCUIT;

  if (!shouldShowLegs) return '';

  return ` (${legs} legs)`;
};

export const formatString = (stringToFormat, ...rest) => {
  const args = Array.prototype.slice.call(rest, 0);
  return stringToFormat.replace(/{(\d+)}/g, (match, number) =>
    typeof args[number] !== 'undefined' ? args[number] : match,
  );
};
