export const geoStates = [
  {
    key: 'AL',
    value: 'AL',
    text: 'AL',
    content: 'AL - Alabama',
    lat: 32.806671,
    lng: -86.79113,
    division: 'Southeast',
  },
  {
    key: 'AK',
    value: 'AK',
    text: 'AK',
    content: 'AK - Alaska',
    lat: 61.370716,
    lng: -152.404419,
    division: 'West',
  },
  {
    key: 'AZ',
    value: 'AZ',
    text: 'AZ',
    content: 'AZ - Arizona',
    lat: 33.729759,
    lng: -111.431221,
    division: 'Southwest',
  },
  {
    key: 'AR',
    value: 'AR',
    text: 'AR',
    content: 'AR - Arkansas',
    lat: 34.969704,
    lng: -92.373123,
    division: 'Southeast',
  },
  {
    key: 'CA',
    value: 'CA',
    text: 'CA',
    content: 'CA - California',
    lat: 36.116203,
    lng: -119.681564,
    division: 'West',
  },
  {
    key: 'CO',
    value: 'CO',
    text: 'CO',
    content: 'CO - Colorado',
    lat: 39.059811,
    lng: -105.311104,
    division: 'West',
  },
  {
    key: 'CT',
    value: 'CT',
    text: 'CT',
    content: 'CT - Connecticut',
    lat: 41.597782,
    lng: -72.755371,
    division: 'Northeast',
  },
  {
    key: 'DE',
    value: 'DE',
    text: 'DE',
    content: 'DE - Delaware',
    lat: 39.318523,
    lng: -75.507141,
    division: 'Northeast',
  },
  {
    key: 'DC',
    value: 'DC',
    text: 'DC',
    content: 'DC - District Of Columbia',
    lat: 38.897438,
    lng: -77.026817,
    division: 'West',
  },
  {
    key: 'FL',
    value: 'FL',
    text: 'FL',
    content: 'FL - Florida',
    lat: 27.766279,
    lng: -81.686783,
    division: 'Southeast',
  },
  {
    key: 'GA',
    value: 'GA',
    text: 'GA',
    content: 'GA - Georgia',
    lat: 33.040619,
    lng: -83.643074,
    division: 'Southeast',
  },
  {
    key: 'HI',
    value: 'HI',
    text: 'HI',
    content: 'HI - Hawaii',
    lat: 21.094318,
    lng: -157.498337,
    division: 'West',
  },
  {
    key: 'ID',
    value: 'ID',
    text: 'ID',
    content: 'ID - Idaho',
    lat: 44.240459,
    lng: -114.478828,
    division: 'West',
  },
  {
    key: 'IL',
    value: 'IL',
    text: 'IL',
    content: 'IL - Illinois',
    lat: 40.349457,
    lng: -88.986137,
    division: 'Midwest',
  },
  {
    key: 'IN',
    value: 'IN',
    text: 'IN',
    content: 'IN - Indiana',
    lat: 39.849426,
    lng: -86.258278,
    division: 'Midwest',
  },
  {
    key: 'IA',
    value: 'IA',
    text: 'IA',
    content: 'IA - Iowa',
    lat: 42.011539,
    lng: -93.210526,
    division: 'Midwest',
  },
  {
    key: 'KS',
    value: 'KS',
    text: 'KS',
    content: 'KS - Kansas',
    lat: 38.5266,
    lng: -96.726486,
    division: 'Midwest',
  },
  {
    key: 'KY',
    value: 'KY',
    text: 'KY',
    content: 'KY - Kentucky',
    lat: 37.66814,
    lng: -84.670067,
    division: 'Southeast',
  },
  {
    key: 'LA',
    value: 'LA',
    text: 'LA',
    content: 'LA - Louisiana',
    lat: 31.169546,
    lng: -91.867805,
    division: 'Southeast',
  },
  {
    key: 'ME',
    value: 'ME',
    text: 'ME',
    content: 'ME - Maine',
    lat: 44.693947,
    lng: -69.381927,
    division: 'Northeast',
  },
  {
    key: 'MD',
    value: 'MD',
    text: 'MD',
    content: 'MD - Maryland',
    lat: 39.063946,
    lng: -76.802101,
    division: 'Northeast',
  },
  {
    key: 'MA',
    value: 'MA',
    text: 'MA',
    content: 'MA - Massachusetts',
    lat: 42.230171,
    lng: -71.530106,
    division: 'Northeast',
  },
  {
    key: 'MI',
    value: 'MI',
    text: 'MI',
    content: 'MI - Michigan',
    lat: 43.326618,
    lng: -84.536095,
    division: 'Midwest',
  },
  {
    key: 'MN',
    value: 'MN',
    text: 'MN',
    content: 'MN - Minnesota',
    lat: 45.694454,
    lng: -93.900192,
    division: 'Midwest',
  },
  {
    key: 'MS',
    value: 'MS',
    text: 'MS',
    content: 'MS - Mississippi',
    lat: 32.741646,
    lng: -89.678696,
    division: 'Southeast',
  },
  {
    key: 'MO',
    value: 'MO',
    text: 'MO',
    content: 'MO - Missouri',
    lat: 38.456085,
    lng: -92.288368,
    division: 'Midwest',
  },
  {
    key: 'MT',
    value: 'MT',
    text: 'MT',
    content: 'MT - Montana',
    lat: 46.921925,
    lng: -110.454353,
    division: 'West',
  },
  {
    key: 'NE',
    value: 'NE',
    text: 'NE',
    content: 'NE - Nebraska',
    lat: 41.12537,
    lng: -98.268082,
    division: 'Midwest',
  },
  {
    key: 'NV',
    value: 'NV',
    text: 'NV',
    content: 'NV - Nevada',
    lat: 38.313515,
    lng: -117.055374,
    division: 'West',
  },
  {
    key: 'NH',
    value: 'NH',
    text: 'NH',
    content: 'NH - New Hampshire',
    lat: 43.452492,
    lng: -71.563896,
    division: 'Northeast',
  },
  {
    key: 'NJ',
    value: 'NJ',
    text: 'NJ',
    content: 'NJ - New Jersey',
    lat: 40.298904,
    lng: -74.521011,
    division: 'Northeast',
  },
  {
    key: 'NM',
    value: 'NM',
    text: 'NM',
    content: 'NM - New Mexico',
    lat: 34.840515,
    lng: -106.248482,
    division: 'Southwest',
  },
  {
    key: 'NY',
    value: 'NY',
    text: 'NY',
    content: 'NY - New York',
    lat: 42.165726,
    lng: -74.948051,
    division: 'Northeast',
  },
  {
    key: 'NC',
    value: 'NC',
    text: 'NC',
    content: 'NC - North Carolina',
    lat: 35.630066,
    lng: -79.806419,
    division: 'Southeast',
  },
  {
    key: 'ND',
    value: 'ND',
    text: 'ND',
    content: 'ND - North Dakota',
    lat: 47.528912,
    lng: -99.784012,
    division: 'Midwest',
  },
  {
    key: 'OH',
    value: 'OH',
    text: 'OH',
    content: 'OH - Ohio',
    lat: 40.388783,
    lng: -82.764915,
    division: 'Midwest',
  },
  {
    key: 'OK',
    value: 'OK',
    text: 'OK',
    content: 'OK - Oklahoma',
    lat: 35.565342,
    lng: -96.928917,
    division: 'Southwest',
  },
  {
    key: 'OR',
    value: 'OR',
    text: 'OR',
    content: 'OR - Oregon',
    lat: 44.572021,
    lng: -122.070938,
    division: 'West',
  },
  {
    key: 'PA',
    value: 'PA',
    text: 'PA',
    content: 'PA - Pennsylvania',
    lat: 40.590752,
    lng: -77.209755,
    division: 'Northeast',
  },
  {
    key: 'RI',
    value: 'RI',
    text: 'RI',
    content: 'RI - Rhode Island',
    lat: 41.680893,
    lng: -71.51178,
    division: 'Northeast',
  },
  {
    key: 'SC',
    value: 'SC',
    text: 'SC',
    content: 'SC - South Carolina',
    lat: 33.856892,
    lng: -80.945007,
    division: 'Southeast',
  },
  {
    key: 'SD',
    value: 'SD',
    text: 'SD',
    content: 'SD - South Dakota',
    lat: 44.299782,
    lng: -99.438828,
    division: 'Midwest',
  },
  {
    key: 'TN',
    value: 'TN',
    text: 'TN',
    content: 'TN - Tennessee',
    lat: 35.747845,
    lng: -86.692345,
    division: 'Southeast',
  },
  {
    key: 'TX',
    value: 'TX',
    text: 'TX',
    content: 'TX - Texas',
    lat: 31.054487,
    lng: -97.563461,
    division: 'Southwest',
  },
  {
    key: 'UT',
    value: 'UT',
    text: 'UT',
    content: 'UT - Utah',
    lat: 40.150032,
    lng: -111.862434,
    division: 'West',
  },
  {
    key: 'VT',
    value: 'VT',
    text: 'VT',
    content: 'VT - Vermont',
    lat: 44.045876,
    lng: -72.710686,
    division: 'Northeast',
  },
  {
    key: 'VA',
    value: 'VA',
    text: 'VA',
    content: 'VA - Virginia',
    lat: 37.769337,
    lng: -78.169968,
    division: 'Southeast',
  },
  {
    key: 'WA',
    value: 'WA',
    text: 'WA',
    content: 'WA - Washington',
    lat: 47.400902,
    lng: -121.490494,
    division: 'West',
  },
  {
    key: 'WV',
    value: 'WV',
    text: 'WV',
    content: 'WV - West Virginia',
    lat: 38.491226,
    lng: -80.954453,
    division: 'Southeast',
  },
  {
    key: 'WI',
    value: 'WI',
    text: 'WI',
    content: 'WI - Wisconsin',
    lat: 44.268543,
    lng: -89.616508,
    division: 'Midwest',
  },
  {
    key: 'WY',
    value: 'WY',
    text: 'WY',
    content: 'WY - Wyoming',
    lat: 42.755966,
    lng: -107.30249,
    division: 'West',
  },
];

export const divisions = ['Southeast', 'West', 'Southwest', 'Northeast', 'Midwest'];

export default geoStates.map(state => ({
  key: state.key,
  value: state.value,
  text: state.text,
  content: state.content,
  division: state.division,
}));
