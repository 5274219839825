export const CONTRACT_GROUP_STATUS = {
  FUTURE: 'FUTURE',
  ACTIVE: 'ACTIVE',
  PAST: 'PAST',
};

export const CONTRACT_GROUP_STATUS_DISPLAY_NAMES = {
  PAST: 'Past',
  FUTURE: 'Future',
  ACTIVE: 'Active',
};
