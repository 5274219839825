/* eslint-disable no-param-reassign */
import React, { useMemo } from 'react';

import { utility } from '@leaf/components';
import BudgetPortfolio from './BudgetPortfolioView';
import { showVerticalLineOnTooltipHover } from './utility';

const paddingRightYAxesLabel = 16;

const isLineChartTooltip = datasetIndex => datasetIndex === 0;

const getRelativeOptions = hasData => ({
  maintainAspectRatio: false,
  animation: {
    duration: 0,
  },
  legend: {
    position: 'bottom',
    labels: {
      boxWidth: 12,
      padding: 30,
    },
  },
  tooltips: {
    callbacks: {
      label: ({ value, datasetIndex }) =>
        isLineChartTooltip(datasetIndex)
          ? utility.numbers.calculateValue(+value * 1e6, `$`)
          : `${utility.numbers.formatNumberAsView(+value, 0, false)}%`,
    },
  },
  scales: {
    xAxes: [
      {
        gridLines: {
          display: false,
        },
        stacked: true,
        scaleLabel: { display: true, labelString: 'Phase start date' },
      },
    ],
    yAxes: [
      {
        ticks: {
          display: hasData,
          beginAtZero: true,
          stepSize: 25,
          callback: v => `${v}%`,
          padding: paddingRightYAxesLabel,
        },
        stacked: true,
        scaleLabel: {
          display: true,
          labelString: '% of Portfolio Leaf Activated (Annualized Spend)',
          fontSize: 11,
          fontStyle: 'bold',
        },
      },
      {
        scaleLabel: { display: true, labelString: 'Annual Savings', fontStyle: 'bold' },
        stacked: true,
        id: 'y2',
        position: 'right',
        ticks: {
          display: hasData,
          stepSize: 25,
          callback: v => utility.numbers.formatNumber(v * 1e6),
          padding: paddingRightYAxesLabel,
        },
      },
    ],
  },
});

export default ({ data }) => {
  const hasData = useMemo(() => data.length > 0, [data]);
  React.useEffect(() => showVerticalLineOnTooltipHover(), []);
  return <BudgetPortfolio data={data} options={getRelativeOptions(hasData)} />;
};
