export default [
  {
    name: 'Sunday',
    short: 'Su',
    shortName: 'Sun.',
    abbreviation: 'su',
    id: 'SUNDAY',
  },
  {
    name: 'Monday',
    short: 'Mo',
    shortName: 'Mon.',
    abbreviation: 'mo',
    id: 'MONDAY',
  },
  {
    name: 'Tuesday',
    short: 'Tu',
    shortName: 'Tue.',
    abbreviation: 'tu',
    id: 'TUESDAY',
  },
  {
    name: 'Wednesday',
    short: 'We',
    shortName: 'Wed.',
    abbreviation: 'we',
    id: 'WEDNESDAY',
  },
  {
    name: 'Thursday',
    short: 'Th',
    shortName: 'Thu.',
    abbreviation: 'th',
    id: 'THURSDAY',
  },
  {
    name: 'Friday',
    short: 'Fr',
    shortName: 'Fri.',
    abbreviation: 'fr',
    id: 'FRIDAY',
  },
  {
    name: 'Saturday',
    short: 'Sa',
    shortName: 'Sat.',
    abbreviation: 'sa',
    id: 'SATURDAY',
  },
];
