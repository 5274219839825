import React from 'react';
import { Box, Chip, Typography } from '@mui/material';

import styled from '@emotion/styled';
import { CONTRACT_STATUS_DISPLAY_NAMES, CONTRACT_STATUS } from '../constants/ContractStatus';

const ContractStatusChip = styled(Chip, {
  shouldForwardProp: prop => prop !== '$bgColor',
})`
  ${({ $bgColor }) => `
    background-color: ${$bgColor};
    color: white;
  `}
`;

const COLORS = {
  [CONTRACT_STATUS.CONTRACTED]: '#4CAF50',
  [CONTRACT_STATUS.DRAFT]: '#4A90E2',
};

const ContractStatus = ({ status }) => (
  <Box display="flex" alignItems="center">
    <ContractStatusChip
      $bgColor={COLORS[status]}
      label={<Typography variant="body1">{CONTRACT_STATUS_DISPLAY_NAMES[status] || status}</Typography>}
    />
  </Box>
);

export default ContractStatus;
