import React from 'react';
import { Typography } from '@mui/material';

export const leafForwardAgreement = (
  <div>
    <Typography variant="h4">LEAF FORWARD CONTRACT</Typography>
    <Typography variant="body1">
      THIS LEAF FORWARD CONTRACT (this &quot;Agreement&quot;), is made and entered into as of the .... day of
      ............, ......., by and between ............, a(n) ............ corporation, (&quot;Shipper&quot;), and
      ............, a(n) ............ corporation (&quot;Carrier&quot;). Shipper and Carrier are sometimes individually
      referred to herein as a &quot;Party&quot; and together as the &quot;Parties.&quot;
    </Typography>
    <Typography variant="body1">RECITALS:</Typography>
    <Typography variant="body1">
      WHEREAS, each of the Shipper and the Carrier are registered users of the facilities of the digital platform
      established and operated by Leaf Logistics (the &quot;Platform&quot;);
    </Typography>
    <Typography variant="body1">
      WHEREAS, the Shipper posted an order on the Platform requesting certain motor carrier transportation services for
      a shipment, which order included the pick-up date, the pick-up address, the delivery address and certain requested
      accessorials for such shipment (the &quot;Buy Order&quot;);
    </Typography>
    <Typography variant="body1">
      WHEREAS, the Carrier posted an order on the Platform offering to provide certain motor carrier transportation
      services for a shipment, which order includes the pick-up date, the pick-up location, the delivery location,
      certain offered accessorials, and the freight charges (the &quot;Sell Order&quot;);
    </Typography>
    <Typography variant="body1">
      WHEREAS, the Platform has matched the Sell Order with the Buy Order, and, in accordance with procedures set forth
      in the Trading Rules of the Platform (the &quot;Trading Rules&quot;), the Shipper has accepted the Sell Order; and
    </Typography>
    <Typography variant="body1">
      WHEREAS, the Parties agree and acknowledge that the Trading Rules are incorporated by reference into, and
      constitute a part of, this Agreement; and
    </Typography>
    <Typography variant="body1">
      WHEREAS, as a result of the Shipper&#39;s acceptance of the Sell Order, the Shipper and the Carrier have agreed to
      enter into this Agreement;
    </Typography>
    <Typography variant="body1">
      NOW, THEREFORE, for and in consideration of the foregoing premises and the mutual agreements and provisions
      hereinafter set forth, the Parties hereby mutually agree as follows:
    </Typography>
    <ol>
      <li>
        The Services. Carrier agrees to provide the Services as set forth in Exhibit A in accordance with the General
        Terms and Conditions for Leaf Forward Contracts set forth in Schedule 1 attached hereto and made a part hereof
        (the &quot;General Conditions&quot;).
      </li>
      <li>
        Rates, Charges, and Payment Terms. Shipper hereby agrees to pay the freight charges set forth in Exhibit A and
        the charges for the requested accessorials and the fuel surcharges, if any, in accordance with Trading Rules and
        the General Conditions.
      </li>
      <li>
        Successors and Assigns; Other Parties. This Agreement shall be binding upon and inure to the benefit of the
        Parties hereto and their respective successors and assigns. In addition to the foregoing, the Parties agree and
        acknowledge that, in accordance with the procedures set forth in the Trading Rules, either Party may &quot;trade
        out&quot; (a &quot;Trading Out Party&quot;) and that, upon the acceptance by the Trading Out Party of a Sell
        Order or Buy Order, as the case may be and as defined in the Trading Rules,(i) the Trading Out Party shall have
        no further rights or obligations under this Agreement, except as specifically set forth in the Trading Rules,
        (ii) this Agreement shall be terminated and of no further force and effect, and (iii) the other Party to this
        Agreement hereby consents to any such termination of this Agreement and agrees to enter into a new Shipping
        Agreement, on the terms and conditions set forth in the Trading Rules, with the relevant Contract Agent (as that
        term is defined in the Trading Rules), it being agreed and acknowledged, however, that the Contract Agent&#39;s
        responsibility under any such Shipping Agreement shall be limited to arranging for, but not actually performing,
        any services thereunder.
      </li>
      <li>
        Captions. The captions set forth in this Agreement are for convenience only and shall not be considered a part
        of this Agreement nor affect in any way the meaning of the terms and provisions hereof.
      </li>
      <li>
        Entire Agreement. This Agreement, together with the General Conditions and the Trading Rules, constitutes the
        entire agreement between the Parties hereto and supersedes all prior agreements, representations, warranties,
        statements, promises, information, arrangements, and understandings, whether oral, written, expressed, or
        implied, with respect to the subject matter hereof.
      </li>
      <li>
        Amendments. Except as provided in the Trading Rules in connection with an assignment or assumption as provided
        in Section 4 hereof, no amendment or modification of the terms of this Agreement shall be binding unless in
        writing and signed by the Parties.
      </li>
      <li>
        Severability. Any term or provision of this Agreement that is held to be invalid or unenforceable in any
        jurisdiction shall, as to such jurisdiction, be ineffective to the extent of such invalidity or unenforceability
        without rendering invalid or unenforceable the remaining terms and provisions of this Agreement or affecting the
        validity or enforceability of any of the terms or provisions of this Agreement in any other jurisdiction.
      </li>
      <li>
        Waiver. No waiver of any right, power, or privilege hereunder shall be binding upon any Party unless in writing
        and signed by or on behalf of the Party against which the waiver is asserted.
      </li>
      <li>
        Counterparts. This Agreement may be executed in one or more counterparts, any or all of which shall constitute
        one and the same instrument.
      </li>
    </ol>
    <Typography variant="body1">CARRIER</Typography>
    <Typography variant="body1">Name: ............ By: ............ Dated: ............</Typography>
    <Typography variant="body1">SHIPPER</Typography>
    <Typography variant="body1">Name: ............ By: ............ Dated: ............</Typography>
    <Typography variant="h4">EXHIBIT A - SHIPMENT AND RATE SPECIFICATION</Typography>
    <Typography variant="body1">Pickup date: ............</Typography>
    <Typography variant="body1">Equipment type: ............</Typography>
    <Typography variant="body1">Ship from: ............</Typography>
    <Typography variant="body1">Ship to: ............</Typography>
    <Typography variant="body1">Carrier: ............</Typography>
    <Typography variant="body1">
      The mileage between pickup and destination has been calculated as ............ using the current version of
      PC*Miler web API. If governmental restrictions prescribe specific routes to be used or avoided because of the size
      and/or weight of the shipment, the nature of the lading being transported, or there exist unusual road conditions,
    </Typography>
    <Typography variant="h5">Contracted services</Typography>
    <Typography variant="body1">\[Any additional contracted services will be listed here\]</Typography>
    <Typography variant="body1">
      For details refer to section &quot;Accessorial Charges&quot; of the General Terms and Conditions below.
    </Typography>
    <Typography variant="h5">Basic Freight Charges</Typography>
    <Typography variant="body1">
      The Carrier shall be paid a base rate of ...... per mile for the Services received as specified above, together
      with any applicable accessorial charges and fuel surcharges.
    </Typography>
    <Typography variant="h4">GENERAL TERMS AND CONDITIONS</Typography>
    <Typography variant="body1">
      The following terms set forth the rights and obligations of a Shipper and a Carrier in connection with the
      provision of certain motor carrier transportation services (the &quot;Services&quot;) under an Leaf Forward
      Contract. The term &quot;Shipper&quot; refers to any party that contracted for the Services under an original Leaf
      Forward Contract or any party that acquired the right to such Services as a result of a &quot;trading out&quot; in
      accordance with the Leaf Trading Rules, and the term &quot;Carrier&quot; refers to any party that agreed to
      provide the Services under an original Leaf Forward Contract or any party that assumed the obligation to provide
      the Services as a result of a &quot;trading out&quot; in accordance with the Leaf Trading Rules. Shipper and
      Carrier are sometimes individually referred to herein as a &quot;Party&quot; and together as the
      &quot;Parties.&quot; Other capitalized terms used herein and not otherwise defined shall have the meanings set
      forth in the Leaf Trading Rules.
    </Typography>
    <ol>
      <li>
        Carrier. Carrier represents and warrants that it is a motor carrier under 49 U.S.C. 13102(12), is duly
        registered with the Department of Transportation pursuant to 49 U.S.C. 13902 and 13905.
      </li>
      <li>
        Rates, Charges, and Payment Terms. a. Upon delivery, Leaf Logistics will debit the Leaf Account for the freight
        charges, the charges for the requested accessorials and the fuel surcharges, if any, set forth in Exhibit A to
        the Leaf Forward Contract. The Shipper agrees that no offsets may be taken against invoiced charges. b. On
        billings to third parties, Shipper, as the contracting party with Carrier, will be responsible for all freight
        and related charges for transportation under this Agreement. As an accommodation to Shipper, Carrier shall bill
        a third party upon notice on the freight documentation the Parties utilize, but Shipper agrees to guarantee
        payment and stand as primary debtor. Carrier shall: (i) advise Shipper if third party payment is not made within
        thirty (30) days of billing; (ii) assign to Shipper any rights Carrier may have to collect freight charges from
        the third party; and (iii) cooperate with Shipper in any collection proceeding instituted by Shipper, with the
        understanding that Carrier will be reimbursed reasonable expenses of so doing. Shipper will pay the third party
        freight bill within thirty (30) days of the assignment provided above.
      </li>
      <li>
        Freight Documentation. Carrier shall issue a Straight Bill of Lading to the Shipper or the Execution Partner, as
        the case may be, and the traffic shall move under the terms and conditions of such Bill of Lading. The terms and
        conditions of this Agreement shall prevail over those appearing on the Bill of Lading or any other form(s) used
        by the Parties for the delivery of freight. Any form(s) used by the Parties shall only be used for the purpose
        of documenting the pick-up and delivery of freight. Either Party, at its option, may supply any document
        required by or referenced in this Agreement in either paper or electronic form (including, but not limited to,
        an electronically imaged, faxed, photocopied, or online posted version), and any such version shall be
        sufficient for all purposes under this Agreement. Unless specifically agreed to by the Parties, any joint
        movement involving another transportation entity to or from a point outside the U.S. shall not be considered as
        moving on a &quot;through&quot; bill of lading. Carrier agrees not to subcontract, broker, interline, or to use
        &quot;substituted services&quot; by rail or motor carrier without the specific approval of Shipper. If for any
        reason this is done without permission, Carrier shall be liable to Shipper for any cargo loss, damage, or injury
        to the same extent as if Carrier performed the service.
      </li>
      <li>
        Permits. Carrier shall procure any permits for over-dimensional or overweight load, and Shipper agrees that
        Carrier may bill Shipper for the actual cost of any such permits or those costs for the use of any required
        escort vehicles. If the over-dimensional or overweight movement requires the payment of tolls over normal
        truckload tolls, Shipper shall absorb the difference in charges.
      </li>
      <li>
        Insurance. Carrier has posted on the Leaf Logistics a certificate of insurance from a reputable insurance
        company evidencing Carrier&#39;s (a) workers&#39; compensation insurance on all employees, as required by
        applicable state law, with a limit of liability of not less than $1,000,000 (b) automobile and property damage
        liability insurance with the applicable limits of liability per occurrence to pay claims for damage to property,
        and for injuries to or death of any person or persons with a combined single limit of not less than $1,000,000
        for any one occurrence, (c) cargo insurance to cover damage to or loss of cargo in the applicable amounts per
        occurrence, with a limit of liability of not less than $100,000 and (d) general liability insurance with limits
        of liability of not less than $1,000,000 for any one occurrence. Carrier will request that its insurance company
        provide 30 days&#39; advance notice to Shipper prior to cancellation of such insurance. Neither Party waives any
        right to subrogation it or its insurers may have arising out of service provided pursuant to this Agreement.
        Notwithstanding the foregoing, if Carrier meets all applicable federal requirements, Carrier may self-insure.
        Upon request, Carrier shall furnish Shipper with proof of self-insurance. Failure to maintain the insurance
        coverage specified herein shall be deemed a material breach of this Agreement and allow Shipper to immediately
        terminate this Agreement.
      </li>
      <li>
        Refused Shipment-Warehouseman Liability. If the consignee refuses the lading tendered by Carrier or if Carrier
        is unable to deliver the lading because of fault or mistake of Shipper or the consignee, or if Shipper advises
        and instructs Carrier to stop movement of the lading and to hold it in transit, Carrier&#39;s liability
        thereafter immediately shall be that of a warehouseman. The procedures which Carrier agrees to and will take as
        a warehouseman involve the use of ordinary care to keep the lading in a safe or suitable place or to store the
        lading properly. Carrier shall (a) attempt to give Shipper notice as soon as possible if the foregoing occurs,
        (b) place the lading in public storage, if available, unless Carrier receives contrary disposition instructions
        from Shipper within twenty-four (24) hours, and (c) if disposition instructions are not given by Shipper within
        ten (10) days of Carrier&#39;s initial notification to Shipper, Carrier may offer the lading for public sale. In
        the case of perishable lading, Carrier may dispose of the lading at a time and in a manner Carrier deems
        appropriate. Shipper will be responsible for storage costs and reasonable costs Carrier incurs in acting as a
        warehouseman. To the extent any sale or disposal revenues exceed the storage costs and the costs Carrier incurs
        as a warehouseman, Carrier shall remit the balance to Shipper. If Shipper gives Carrier timely disposition
        instructions, Carrier shall use any commercially reasonable steps to abide with such instructions. Shipper will
        pay Carrier&#39;s costs and any additional transportation costs Carrier incurs in doing so.
      </li>
      <li>
        Cargo Liability. a. Carrier shall be liable to Shipper for loss or damage to lading occurring while it is in
        Carrier&#39;s possession, except to the extent such loss or damage is caused by an act of God or a public enemy,
        a public authority, an act of Shipper, or the inherent vice or nature of the lading. Carrier&#39;s possession of
        lading under this Agreement shall begin when Carrier has executed the freight documentation form for such lading
        and shall terminate upon the lading being tendered for delivery to Shipper&#39;s consignee. b. Carrier&#39;s
        monetary liability for cargo loss and damage will be limited to the amount of Carrier&#39;s cargo insurance as
        set forth in the certificate of insurance referred to in Section 5 above. If Shipper asserts that the value of
        lading on a particular shipment shall exceed this amount, Carrier shall be advised twenty- four (24) hours
        before the time of tendering a load. Carrier may refuse the load or secure additional cargo insurance in the
        amount of liability Shipper claims, the cost of which shall be invoiced to Shipper as part of freight charges.
        Shipper also shall note any separately agreed value on the freight documentation form referenced in Section 3
        above. If the freight Shipper tenders consistently exceeds the amount of cargo insurance set forth in such
        certificate of insurance, the Parties shall agree in writing to an alternate cargo insurance amount, which will
        be reflected in freight charges otherwise assessed. c. Claims for loss or damage to lading must be filed in
        writing by Shipper within nine (9) months from date of delivery, or scheduled date of delivery for lost lading,
        or in the absence of a scheduled delivery date, the filing period shall begin after a reasonable time has
        elapsed for delivery, and a civil suit or arbitration proceeding shall be commenced by Shipper within two (2)
        years from the date Carrier gives Shipper written notice Carrier is disallowing the claim or any part of it.
        Claims will be filed and resolved in accordance with federal regulations codified at 49 C.F.R. Part 370. d. The
        measure of damages for loss of or physical damage to the cargo shall be the invoice value of the lading, or in
        the absence of an invoice, wholesale destination value. Carrier also shall be liable for the reasonable costs of
        the Shipper to mitigate its damages. e. In no event shall Carrier be liable to Shipper or anyone else for
        special, incidental, or consequential damages that relate to loss, damage or delay to a shipment, unless Shipper
        has informed Carrier in written or electronic form, prior to or when tendering a shipment or series of shipments
        to Carrier, of the potential nature and type of such damages, and Carrier specifically agrees in written or
        electronic form to accept responsibility for such damages. In no event shall Carrier be liable to Shipper or
        anyone else for punitive or exemplary damages that relate to loss, damage or delay to a shipment.
      </li>
      <li>
        Sealed Shipment. If Shipper loads and seals the lading in or on the trailer and Carrier does not have the
        opportunity to count the lading being loaded and the seal is intact upon delivery, Carrier shall be absolved
        from any liability for shortages or any damage to the lading except when proximately caused by independent
        action of Carrier. Such absolution of liability will also occur if (i) the seal is broken at the direction and
        under the supervision of an agent of a body politic, or (ii) trailers are preloaded and the adequacy of loading
        or count of such trailer is not practical by a representative of Carrier. Carrier agrees that if a seal is
        broken and an inspection made by an agent of a body politic, its operator or other representative will take all
        reasonable steps to secure the count, safety, and integrity of the lading. These steps will include requesting
        that the body politic reseal the trailer and/or make appropriate notation on the freight documentation form.
        Carrier may break the seal on a trailer if, upon Carrier&#39;s determination or that of its operator or other
        representative, it becomes reasonably necessary to do so to inspect, reposition, or protect the lading or
        Carrier&#39;s equipment or to comply with federal, state, municipal, or provincial laws, rules, and regulations.
        Shipper&#39;s consignee may not refuse delivery of a shipment solely because the seal on a trailer is broken.
      </li>
      <li>
        Salvage. Shipper will have the right reasonably to determine to repair, repackage, salvage, or scrap damaged
        lading. If Shipper elects to salvage lading, Shipper shall notify Carrier to return the lading to Shipper or
        allow Carrier to dispose of the lading. If salvage is sought, at least two independent bids shall be obtained,
        and the highest bid accepted. Any monies received in salvage, whether accomplished by Carrier or Shipper, will
        be credited, if applicable, against any amount Carrier may otherwise be responsible for in terms of the damages.
        Shipper may condition salvage upon the removal of all identifying marks or labels or the lading being
        permanently marked as &quot;damaged&quot; or with a similar notation. If Carrier is retained by Shipper to
        return the damaged lading for repair, salvage, or scrapping, Shipper agrees to pay Carrier freight charges
        otherwise provided in this Agreement, or at a negotiated rate to be reduced to writing, without prejudice to
        recovery of such freight charges as damages. Damaged lading will not be scrapped unless repair and/or salvage is
        not feasible. If Carrier salvages the lading, Carrier may bill a reasonable charge for doing so against salvage
        receipts.
      </li>
      <li>
        Indemnification. a. Carrier shall defend, indemnify, and hold Shipper and its employees and agents harmless from
        and against all claims, liabilities, losses, damages, fines, penalties, payments, costs, and expenses
        (including, without limitation, reasonable legal fees) caused by and resulting from (i) the negligence or
        intentional misconduct of Carrier or its employees or agents, or (ii) Carrier&#39;s or its employees&#39; or
        agents&#39; violation of applicable laws or regulations. b. Shipper shall defend, indemnify, and hold Carrier
        and its employees and agents harmless from and against all claims, liabilities, losses, damages, fines,
        penalties, payments, costs, and expenses (including, without limitation, reasonable legal fees) caused by and
        resulting from (i) the negligence or intentional misconduct of Shipper, its employees, or agents, or (ii)
        Shipper&#39;s or its employees&#39; or agents&#39; violation of applicable laws or regulations. c. In the event
        such claims, liabilities, losses, damages, fines, penalties, payments, costs, and expenses (including, without
        limitation, reasonable legal fees) are caused by the joint and concurrent negligence of the Parties, or the
        Parties and a third party, the indemnity obligations for such claims, liabilities, losses, damages, fines,
        penalties, payments, costs, and expenses (including, without limitation, reasonable legal fees) shall be borne
        by each Party in proportion to its degree of fault. d. In no event shall either Party be liable to the other
        under this Section 10 to the extent damages are incidental, consequential, special, punitive, or exemplary. Any
        indemnified party under this Section 10 shall promptly tender the defense of any claim to the indemnifying
        Party. Carrier&#39;s liability for cargo damage shall be governed by Section 7 above.
      </li>
      <li>
        Legal Restraint or Force Majeure. In the event performance by one Party is affected by any cause beyond the
        reasonable control of such Party, including without limitation, fire, labor strife, riot, war, weather
        conditions, acts of the public enemy, acts of God, acts of terrorism, local or national disruptions to
        transportation networks or operations, material equipment repairs, fuel shortages, governmental regulations, or
        governmental request or requisition for national defense, and provided that the applicable cause is not
        attributable to the acts or omissions of such Party, and such Party is taking reasonable measures to remove or
        mitigate the effects of the applicable cause, then the running of all periods of time mentioned herein and the
        performance of all obligations required herein shall be suspended during the continuance of such interruption,
        and such Party shall promptly notify the other Party of such interruption. If such notification is received
        within the two-day period prior to the scheduled pick-up date, the Party receiving such notification may
        terminate the relevant Leaf Forward Contract, and neither Party shall be liable to the other for any damages or
        obligations.
      </li>
      <li>
        Business and Employment Opportunity. Shipper agreeees to notify Carrier twenty-four (24) hours before tendering
        any load that would subject Carrier to regulation under any non-discrimination laws, rules, orders, and
        regulations of governmental authorities, including, but not limited to, Title VII of the Civil Rights Act of
        1964, as amended, Executive Order 11246, and the rules and regulations promulgated thereunder, the
        Rehabilitation Act of 1973, and the Vietnam Era Veterans Readjustment Act of 1974. If Carrier accepts such a
        load, the Parties agree to comply with any applicable non-discrimination laws, rules, orders, and regulations.
      </li>
      <li>
        Compliance with Laws and Regulations. The Parties shall at all times comply with all applicable federal, state,
        municipal, and provincial laws, rules, and regulations including, but not limited to, the federal and state
        safety regulations. To the extent this Agreement or any services provided hereunder shall conflict with such
        laws, rules, and regulations, this Agreement and the services provided hereunder shall be modified to comply
        with such laws, rules, and regulations, and the Parties shall not be deemed in breach of this Agreement or
        suffer any liability or penalty for compliance with such laws, rules, and regulations. In the event Carrier,
        through no fault of its own, is delayed or removed from service by or because of an inspection by any body
        politic, Carrier shall not be deemed in breach of this Agreement, nor shall it suffer any liability or penalty
        under the terms of this Agreement.
      </li>
      <li>
        Independent Contractor. Carrier shall be an independent contractor of Shipper. As between the Parties, Carrier
        shall have the sole and exclusive responsibility for the costs and over the manner in which its employees and/or
        independent contractors perform the transportation service, including the equipment provided.
      </li>
      <li>
        Confidentiality. The Parties shall keep in confidence and not disclose to any third party (a) the terms of this
        Agreement, and (b) any confidential or proprietary information either learns about the other Party, such as, but
        not limited to, the rates, value, origin, destination, or consignee of any shipment made hereunder. The Parties
        may disclose such terms and information to the extent required by law, to obtain financing, to substitute
        service providers to the extent necessary to provide such substitute service, or to auditors retained for the
        purpose of assessing the accuracy of freight bills.
      </li>
      <li>
        Compliance with Laws and Regulations. The Parties shall at all times comply with all applicable federal, state,
        municipal, and provincial laws, rules, and regulations including, but not limited to, the federal and state
        safety regulations. To the extent this Agreement or any services provided hereunder shall conflict with such
        laws, rules, and regulations, this Agreement and the services provided hereunder shall be modified to comply
        with such laws, rules, and regulations, and the Parties shall not be deemed in breach of this Agreement or
        suffer any liability or penalty for compliance with such laws, rules, and regulations. In the event Carrier,
        through no fault of its own, is delayed or removed from service by or because of an inspection by any body
        politic, Carrier shall not be deemed in breach of this Agreement, nor shall it suffer any liability or penalty
        under the terms of this Agreement.
      </li>
      <li>
        No Use of Name. Neither Party may use the other&#39;s name, trademarks, or trade names, or those of its
        subsidiaries or affiliates, in any manner, especially advertising, without the other&#39;s expressed written
        consent, which may be withheld in such Party&#39;s sole discretion.
      </li>
      <li>
        Governing Law. The Parties desire that the provisions of this Agreement will have precedence over any federal or
        state provisions governing or dealing with the specific provisions of this Agreement. The Parties agree that
        pursuant to 49 U.S.C. § 14101(b)(1) they expressly waive any and all rights and remedies under the Interstate
        Commerce Commission Termination Act and Interstate Commerce Act as amended, and regulations promulgated
        thereunder, including Part B of Subtitle IV Interstate Transportation, 49 U.S.C. § 13101, et seq, (the
        &quot;Acts&quot;) that are inconsistent with the provisions of this Agreement. No Party shall challenge any
        provision of this Agreement on the ground that any such provision or provisions violates the waived rights and
        remedies under the Acts. To the extent no conflicts exist with this Agreement or federal law, the law of the
        State indicated in the Shipper&#39;s address in the signatory provision hereof shall apply.
      </li>
      <li>
        Dispute Resolution. The Parties agree that this Agreement is being entered into in good faith and that if a
        dispute arises in its application or interpretation such dispute shall be resolved in accordance with the
        arbitration procedures set forth in the Trading Rules.
      </li>
    </ol>
    <Typography variant="h4">Accessorial Charges</Typography>
    <Typography variant="body1">
      In addition to the Basic Freight Charge and other charges set forth above, Carrier will invoice Shipper for the
      accessorial charges noted below.
    </Typography>
    <Typography variant="h5">Detention of Trailer With Tractor</Typography>
    <Typography variant="body1">
      Upon Carrier&#39;s offering of a trailer with tractor for loading or unloading, Shipper or Shipper&#39;s
      consignee, as the case may be, shall be allowed, without charge, 2 hours to complete such loading or unloading and
      release the equipment for dispatch. If Shipper or Shipper&#39;s consignee fails to complete the loading or
      unloading and release the equipment for dispatch within the 2-hour period, Shipper shall pay Carrier a detention
      charge of $50 per hour for each hour or fraction thereof in excess of the 2-hour period. Shipper shall use the
      trailer with tractor for the sole purpose of loading and/or unloading the lading within the scope of this
      Agreement.
    </Typography>
    <Typography variant="h5">Loading and Unloading</Typography>
    <Typography variant="body1">
      If the services Carrier agrees to perform under this Agreement include loading and unloading, Carrier shall be
      paid $100 per stop for loading/unloading activities.
    </Typography>
    <Typography variant="h5">Reconsignment</Typography>
    <Typography variant="body1">
      If Shipper reconsigns or otherwise changes the origin and/or destination of a shipment prior to delivery, the
      applicable rate shall be the rate that would be applied had Carrier been originally directed to pickup/deliver the
      shipment to the new origin/destination, provided that the new origin and destination are within 2 hours driving
      time from the contracted origin and destination. If the new origin/destination is more than 2 hours driving time
      from the contract origin/destination, an additional reconsignment charge equal to $0.20 per additional mile beyond
      the 2 hours driving time area will be added to the applicable base rate by virtue of the reconsignment.
    </Typography>
    <Typography variant="h5">In-Transit Stop-Off / Drop Charges</Typography>
    <Typography variant="body1">
      A single shipment may be stopped at the direction of Shipper for partial loading or partial unloading; provided,
      however, that in the event of any in-transit stop at the direction of Shipper or Shipper&#39;s consignee, Shipper
      shall pay Carrier, in addition to other freight charges due (a) $100 per additional stop, and (b) $0.20 per mile
      required to be deviated from the most practical route otherwise to be traversed from origin to destination in
      addition to the applicable base rate.
    </Typography>
    <Typography variant="h5">Hazardous Materials</Typography>
    <Typography variant="body1">
      Shipper shall identify any loads that contain Hazardous Materials, as defined in the Hazardous Materials
      Transportation Act, 49 U.S.C. §5101 et seq., as amended, and the regulations of the U.S. Department of
      Transportation made thereunder, at least 24 hours in advance of tendering to Carrier. Not less than 12 hours prior
      to the scheduled pick-up time, Carrier shall either: (i) decline such load, or (ii) accept such load on terms and
      conditions identified by Carrier in such acceptance, which terms may include market rates and the pass through of
      any associated costs to Shipper. If Carrier accepts such load, Carrier represents and warrants that it is fully
      qualified and authorized to transport Hazardous Materials in the United States. Carrier and Shipper certify that
      they are familiar with U.S. laws and regulations applicable to transportation of Hazardous Materials and that they
      will comply with all such laws and regulations. Carrier further certifies that its employees, including drivers,
      have been trained and instructed in the proper method of transporting Hazardous Materials. Upon Carrier request,
      Shipper will provide a copy of the Material Safety Data Sheet for the Hazardous Materials.
    </Typography>
    <Typography variant="body1">
      An additional $250.00 will apply to any hazardous shipment where driver certification and placards must be
      displayed.
    </Typography>
    <Typography variant="h5">Tanker Endorsement</Typography>
    <Typography variant="body1">
      Under the new tanker rules published in 2011 by the Federal Motor Carrier Safety Administration (FMCSA), a tank
      is: 1) &quot;Any commercial vehicle that is designed to transport any liquid or gaseous material within a tank or
      tanks having an individual rate capacity of more than 119 gallons,&quot; and 2) &quot;An aggregated capacity of
      1,000 gallons or more that is either permanently or temporarily attached to the vehicle or chassis.&quot; Tanks
      that are manifested either as being &quot;Empty&quot; or as &quot;Residue Last Contained&quot; on a bill of lading
      do not apply under the rule. Upon Shipper request, Carrier will provide tanker certification.
    </Typography>
    <Typography variant="body1">
      An additional $50 will apply to any shipment where a tanker certification is required.
    </Typography>
    <Typography variant="h4">Fuel surcharge</Typography>
    <Typography variant="body1">
      Freight charges will be subject to a fuel surcharge which will be billed as a separate charge on freight invoices.
      The charge will be adjusted up or down each Monday by the cost per mile adjustment listed on the following matrix:
    </Typography>
    <Typography variant="body1">
      The fuel surcharge shall be determined by reference to the U.S. Department of Energy (&quot;DOE&quot;) diesel fuel
      price national average. Shipper will pay a fuel surcharge of $0.01 per mile for each $0.065 per gallon increase
      above a base of $0.0 per gallon.
    </Typography>
    <Typography variant="body1">Therefore, the following schedule will apply:</Typography>
    <table>
      <thead>
        <tr>
          <td>DOE price</td>
          <td>Surcharge</td>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>0.0</td>
          <td>None</td>
        </tr>
        <tr>
          <td>0.01 to 0.065</td>
          <td>0.01/mile</td>
        </tr>
        <tr>
          <td>0.066 to 0.130</td>
          <td>0.02/mile</td>
        </tr>
        <tr>
          <td>0.131 to 0.195</td>
          <td>0.03/mile</td>
        </tr>
        <tr>
          <td>0.196 to 0.260</td>
          <td>0.04/mile</td>
        </tr>
        <tr>
          <td>0.261 to 0.325</td>
          <td>0.05/mile</td>
        </tr>
        <tr>
          <td>0.326 to 0.390</td>
          <td>0.06/mile</td>
        </tr>
        <tr>
          <td>0.391 to 0.455</td>
          <td>0.07/mile</td>
        </tr>
        <tr>
          <td>0.456 to 0.520</td>
          <td>0.08/mile</td>
        </tr>
        <tr>
          <td>0.521 to 0.585</td>
          <td>0.09/mile</td>
        </tr>
      </tbody>
    </table>

    <Typography variant="body1">
      The same formula will be used should the index reach or exceed $0.585. Adjustments will be made each Monday based
      upon the weekly DOE price.
    </Typography>
  </div>
);
