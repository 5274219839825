/* eslint-disable func-names */
import { companyDashboardNames } from './Constants';

export const getFilters = function (userType, onErrorHandler) {
  const conditionalPromises = [];
  if (userType !== 'admin') {
    conditionalPromises.push(
      this.api
        .getCounterpartyFilter(userType)
        .then(response =>
          this.setState({
            counterparties: response || [],
          }),
        )
        .catch(onErrorHandler),
    );
  } else if ([companyDashboardNames.CARRIER, companyDashboardNames.SHIPPER].includes(this.name)) {
    conditionalPromises.push(
      ...(this.name === companyDashboardNames.CARRIER
        ? [
            this.api
              .getShippersFilter()
              .then(response =>
                this.setState({
                  shippers: response || [],
                }),
              )
              .catch(onErrorHandler),
          ]
        : []),
      ...(this.name === companyDashboardNames.SHIPPER
        ? [
            this.api
              .getCarriersFilter()
              .then(response =>
                this.setState({
                  carriers: response || [],
                }),
              )
              .catch(onErrorHandler),
          ]
        : []),
    );
  } else {
    conditionalPromises.push(
      this.api
        .getShippersFilter()
        .then(response =>
          this.setState({
            shippers: response || [],
          }),
        )
        .catch(onErrorHandler),
      this.api
        .getCarriersFilter()
        .then(response =>
          this.setState({
            carriers: response || [],
          }),
        )
        .catch(onErrorHandler),
      this.api
        .getCohortsFilter()
        .then(response =>
          this.setState({
            cohorts: this.api.extractField(response, 'cohortNames'),
          }),
        )
        .catch(onErrorHandler),
      this.api
        .getTransportationModesFilter()
        .then(response =>
          this.setState({
            transportationModes: response,
          }),
        )
        .catch(onErrorHandler),
    );
  }
  Promise.all([
    this.api
      .getLaneFilter(userType)
      .then(response =>
        this.setState({
          lanes: this.api.extractField(response, 'lane'),
          origins: this.api.extractField(response, 'origin'),
          destinations: this.api.extractField(response, 'destination'),
        }),
      )
      .catch(onErrorHandler),
    this.api
      .getEquipmentFilter(userType)
      .then(response =>
        this.setState({
          equipment: response || [],
        }),
      )
      .catch(onErrorHandler),
    this.api
      .getNetworkMovesFilter(userType)
      .then(response =>
        this.setState({
          networkMoves: response || [],
        }),
      )
      .catch(onErrorHandler),
    this.api
      .getReportingLaneFilter(userType)
      .then(response =>
        this.setState({
          reportingLanes: this.api.extractField(response, 'lane'),
        }),
      )
      .catch(onErrorHandler),
    ...conditionalPromises,
  ])
    .catch(() => {
      this.setState({ hasFailedToLoadFilters: true });
    })
    .finally(() => this.setState({ areFiltersLoading: false }));
};
