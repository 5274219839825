export const QUOTE_STATUS = {
  CONTRACTED: 'CONTRACTED',
  CANCELED: 'CANCELED',
  EXPIRED: 'EXPIRED',
  DRAFT: 'DRAFT',
  BINDING: 'BINDING',
  NON_BINDING: 'NON_BINDING',
};

export const QUOTE_STATUS_DISPLAY_NAMES = {
  CONTRACTED: 'Contracted',
  CANCELED: 'Canceled',
  EXPIRED: 'Expired',
  DRAFT: 'Draft',
  BINDING: 'Binding',
  NON_BINDING: 'Non binding',
};

export const QUOTE_STATUS_WITH_ACTIVE = {
  CONTRACTED: 'CONTRACTED',
  CANCELED: 'CANCELED',
  EXPIRED: 'EXPIRED',
  DRAFT: 'DRAFT',
  ACTIVE: 'ACTIVE',
};

export const QUOTE_STATUS_DISPLAY_NAMES_WITH_ACTIVE = {
  CONTRACTED: 'Contracted',
  CANCELED: 'Canceled',
  EXPIRED: 'Expired',
  DRAFT: 'Draft',
  ACTIVE: 'Active',
};
