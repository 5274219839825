import { utility } from '@leaf/components';

// Right now only these business objectives are supported to be displayed in the dashboard.
const OBJECTIVES_PATHS = {
  COST_REDUCTION: 'cost-reduction',
  COST_AVOIDANCE: 'cost-avoidance',
  ON_TIME_DELIVERY: 'on-time-delivery',
};

class Objective {
  constructor(response) {
    Object.assign(this, {
      ...response,
      path: OBJECTIVES_PATHS[response.type],
    });

    this.view = {
      startValue: utility.numbers.formatNumber(this.startValue, this.unit),
      currentValue: utility.numbers.formatNumber(this.currentValue, this.unit),
      currentShouldValue: utility.numbers.formatNumber(this.currentShouldValue, this.unit),
      endValue: utility.numbers.formatNumber(this.endValue, this.unit),
      currentYearMonth: utility.date.to3LetterMonthAndFullYear(this.currentYearMonth),
      endYearMonth: utility.date.to3LetterMonthAndFullYear(this.endYearMonth),
    };
  }
}

export default Objective;
