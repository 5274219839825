import React from 'react';
import { Grid, TableCell, Card, CardContent } from '@mui/material';
import { MultiRowInfo, OverviewTable, utility, USER_TYPE } from '@leaf/components';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import ShipmentPickupDeliveryView from './ShipmentPickupDeliveryView';

const PickupWrapper = styled(Grid)`
  ${({ theme }) => `
		padding-right: ${theme.spacing(1)};
	`}
`;

const DeliveryWrapper = styled(Grid)`
  ${({ theme }) => `
		padding-left: ${theme.spacing(1)};
	`}
`;

const InnerCellSC = styled(TableCell)`
  ${({ theme }) => `
		background-color: ${theme.palette.background.default};
	`}
`;

const CardContentSC = styled(CardContent)`
  &&& {
    padding: 0;
    .MuiToolbar-gutters {
      padding-left: ${({ theme }) => theme.spacing(2)};
    }
  }
`;

const generatePickupDelivery = shipment =>
  shipment && (
    <Grid container>
      <PickupWrapper item xs={6}>
        <ShipmentPickupDeliveryView pickupDelivery={shipment.pickup.view} type="pickup" />
      </PickupWrapper>
      <DeliveryWrapper item xs={6}>
        <ShipmentPickupDeliveryView pickupDelivery={shipment.delivery.view} type="delivery" />
      </DeliveryWrapper>
    </Grid>
  );

const ShipmentPickupDeliveryContainer = ({ shipment, legs, companyType }) => {
  const renderInnerRow =
    columnsLength =>
    (_, { dataIndex }) => {
      const {
        view: { details: shipmentDetails },
      } = legs[dataIndex];
      return <InnerCellSC colSpan={columnsLength + 1}>{generatePickupDelivery(shipmentDetails)}</InnerCellSC>;
    };

  if (companyType === USER_TYPE.SHIPPER) {
    return generatePickupDelivery(shipment.view);
  }

  const columns = [
    {
      label: 'Legs',
      name: 'view.legNumber',
    },
    {
      label: 'Shipper',
      name: 'view.shipperName',
    },
    {
      label: 'Pickup',
      name: 'view.originAddress',
      options: {
        customBodyRender: origin => {
          const [primaryText, secondaryText] = origin || [];
          return <MultiRowInfo primaryContent={[primaryText]} secondaryContent={[secondaryText]} />;
        },
        sortCompare:
          order =>
          ({ data: origin1 }, { data: origin2 }) =>
            utility.table.sortCompare(origin1[0], origin2[0], order),
      },
    },
    {
      label: 'Pickup time',
      name: 'view.pickupEarliest',
      options: {
        sortCompare: utility.table.dateComparator,
      },
    },
    {
      label: 'Delivery',
      name: 'view.destinationAddress',
      options: {
        customBodyRender: destination => {
          const [primaryText, secondaryText] = destination || [];
          return <MultiRowInfo primaryContent={[primaryText]} secondaryContent={[secondaryText]} />;
        },
        sortCompare:
          order =>
          ({ data: destination1 }, { data: destination2 }) =>
            utility.table.sortCompare(destination1[0], destination2[0], order),
      },
    },
    {
      label: 'Delivery time',
      name: 'view.deliveryEarliest',
      options: {
        sortCompare: utility.table.dateComparator,
      },
    },
  ];

  const options = {
    selectableRows: false,
    selectableRowsHeader: false,
    expandableRows: true,
    expandableRowsHeader: false,
    expandableRowsOnClick: true,
    renderExpandableRow: renderInnerRow(columns.length),
  };

  return (
    <Card>
      <CardContentSC>
        <OverviewTable data={legs} columns={columns} options={options} title="Pickup and delivery" />
      </CardContentSC>
    </Card>
  );
};

ShipmentPickupDeliveryContainer.propTypes = {
  legs: PropTypes.arrayOf(PropTypes.object),
  shipment: PropTypes.objectOf(PropTypes.object).isRequired,
  companyType: PropTypes.string.isRequired,
};

ShipmentPickupDeliveryContainer.defaultProps = {
  legs: [],
};

export default ShipmentPickupDeliveryContainer;
