import React from 'react';
import styled from '@emotion/styled';
import { Card, CardHeader, CardContent, Typography } from '@mui/material';

const CustomCard = styled(Card)`
  height: 100%;
  width: 100% !important;
  background-color: ${({ disabled }) => disabled && '#eeeeee !important'};
  color: ${({ disabled }) => disabled && 'rgba(0, 0, 0, 0.38) !important'};
  box-shadow: 0px 0px 4px rgba(37, 40, 43, 0.22);
`;

const CustomHeader = styled(Typography)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 60px;
  padding-left: ${({ theme }) => `${theme.spacing(3)}`};
  padding-right: ${({ theme }) => `${theme.spacing(3)}`};
  border-bottom: ${({ theme }) => `1px solid ${theme.palette.divider}`};
`;

const CardContentFullHeight = styled(CardContent)`
  height: 100%;
`;

export default ({ title, disabled, children }) => {
  const renderCustomHeader = () => <CustomHeader>{title}</CustomHeader>;

  return (
    <CustomCard disabled={disabled}>
      {title && <CardHeader component={renderCustomHeader} />}
      {children && <CardContentFullHeight>{children}</CardContentFullHeight>}
    </CustomCard>
  );
};
