import React, { useEffect, useReducer } from 'react';
import { withRouter } from 'react-router-dom';
import { Grid } from '@mui/material';
import { useSnackbar } from 'notistack';
import { LazySpinner } from '@leaf/components';
import { getInitialPromises } from 'shipments/common';
import ShipmentOverview from './overview/ShipmentOverview';
import ShipmentTrackingEvents from './trackingEvents/ShipmentTrackingEvents';
import ShipmentInvoiceDetails from './invoiceDetails/ShipmentInvoiceDetails';
import ShipmentInvoiceSummary from './invoiceSummary/ShipmentInvoiceSummary';
import shipmentDetailsReducer, {
  GET_SHIPMENT_DETAILS_STOP,
  SET_SHIPMENT_DETAILS,
  SET_SHIPMENT_LEGS,
} from './shipmentDetailsReducer';
import ShipmentPickupDeliveryContainer from './pickupDelivery/ShipmentPickupDeliveryContainer';

const initialState = {
  isLoading: true,
  details: null,
  legs: [],
  invoice: null,
  trackingEvents: null,
};

const ShipmentDetailsContainer = ({
  match: {
    params: { id },
  },
  companyType,
}) => {
  const [{ details: shipment, legs: legsDetails, invoice, trackingEvents, isLoading }, dispatch] = useReducer(
    shipmentDetailsReducer,
    initialState,
  );

  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (companyType) {
      Promise.all(getInitialPromises(companyType, id))
        .then(([details, legs]) => {
          dispatch({ type: GET_SHIPMENT_DETAILS_STOP });
          dispatch({ type: SET_SHIPMENT_DETAILS, payload: details });
          if (legs) {
            dispatch({ type: SET_SHIPMENT_LEGS, payload: legs });
          }
        })
        .catch(error => {
          dispatch({ type: GET_SHIPMENT_DETAILS_STOP });
          enqueueSnackbar(error.message);
        });
    }
  }, [id, companyType]);

  if (isLoading) {
    return <LazySpinner />;
  }

  return (
    <Grid container spacing={2}>
      {shipment && (
        <Grid item xs={12}>
          <ShipmentOverview shipment={shipment} />
        </Grid>
      )}
      {shipment && (
        <Grid item xs={12}>
          <ShipmentPickupDeliveryContainer shipment={shipment} legs={legsDetails} companyType={companyType} />
        </Grid>
      )}
      {invoice && (
        <Grid item xs={12}>
          <ShipmentInvoiceSummary invoiceSummary={invoice.summary} />
        </Grid>
      )}
      {invoice && (
        <Grid item xs={12}>
          <ShipmentInvoiceDetails invoiceDetails={invoice.details} />
        </Grid>
      )}
      {trackingEvents && (
        <Grid item xs={12}>
          <ShipmentTrackingEvents trackingEvents={trackingEvents} />
        </Grid>
      )}
    </Grid>
  );
};

export default withRouter(ShipmentDetailsContainer);
