import { Button } from '@mui/material';
import React from 'react';

/**
 * Componrnt: Button
 *
 * @props {...props} List of properties
 *
 * @returns
 * Returns button component based on provided props.
 */
export default props => <Button variant="contained" {...props} />;
