import _ from 'lodash';
import { filterTypes } from './Constants';
import { filterProps } from './Constants/filterTypes';

export const makeEmptySelectedByTypeObj = () =>
  Object.values(filterTypes).reduce((acc, filterType) => {
    acc[filterType] = {};
    return acc;
  }, {});

const makeTrueObjectFromArr = arr => {
  const obj = {};
  arr.forEach(val => {
    obj[val] = true;
  });
  return obj;
};

const calcSelectedItemsArrById = selected => Object.keys(selected).filter(id => selected[id] === true);

export const getActiveFilters = state => {
  const params = makeEmptySelectedByTypeObj();
  Object.values(filterTypes).forEach(key => {
    if (state.activeFilters[key]) {
      params[key] = makeTrueObjectFromArr(
        Array.isArray(state.activeFilters[key]) ? state.activeFilters[key] : [state.activeFilters[key]],
      );
    }
  });
  const { from, to } = state.activeFilters;
  return { from, to, ...params };
};

export const pickActiveFilters = state => {
  const { activeFilters, initialFilters } = state;
  return _.pick(activeFilters, [...filterProps, ...Object.keys(initialFilters)]);
};

export const setActiveFilters = (selectedByType, setState, state) => {
  const params = {};
  const { initialFilters } = state;
  Object.keys({ ...selectedByType, ...initialFilters }).forEach(key => {
    params[key] = calcSelectedItemsArrById({ ...selectedByType, ...initialFilters }[key]);
  });
  setState({ activeFilters: { ...params, from: selectedByType.from, to: selectedByType.to } });
};
