/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Grid } from '@mui/material';
import enLocale from 'date-fns/locale/en-US';
import { LocalizationProvider, MobileDateRangePicker } from '@mui/x-date-pickers-pro';
import { AdapterDateFns as DateFnsAdapter } from '@mui/x-date-pickers-pro/AdapterDateFns';
import { startOfWeek, endOfWeek } from 'date-fns';
import { VIEW_FORMAT } from '../../utility/date';
import Input from './Input';

export default ({ onChange, variant = 'outlined', fullWidth = false, ...props }) => {
  const handleChange = values => {
    const [start, end] = values;
    if (!start && !end) {
      onChange([null, null]);
    } else {
      onChange([startOfWeek(start), endOfWeek(end)]);
    }
  };
  return (
    <LocalizationProvider dateAdapter={DateFnsAdapter} locale={enLocale}>
      <MobileDateRangePicker
        onChange={handleChange}
        format={VIEW_FORMAT}
        renderInput={(startProps, endProps) => (
          <Grid container spacing={2}>
            <Grid item xs={fullWidth ? 12 : 6}>
              <Input
                {...startProps}
                helperText={null}
                inputProps={{ ...startProps.inputProps, placeholder: '' }}
                variant={variant}
                fullWidth
              />
            </Grid>
            <Grid item xs={fullWidth ? 12 : 6}>
              <Input
                {...endProps}
                helperText={null}
                inputProps={{ ...endProps.inputProps, placeholder: '' }}
                variant={variant}
                fullWidth
              />
            </Grid>
          </Grid>
        )}
        disableAutoMonthSwitching
        {...props}
      />
    </LocalizationProvider>
  );
};
