import { merge } from 'lodash';

import { numbers as numbersService } from '../../../../utility';

export const showOnlyFirstAndLast = (value, index, values) => {
  if (index === values.length - 1 || index === 0) {
    return value;
  }
  return undefined;
};

export const showAll = value => value;

export const addPercentSign = value => `${value}%`;
export const addUnitSign = value => numbersService.formatNumberAsView(value);

export const calcOptions = ({ rest, optionsOverride, calcOptionsFromPropsFn }) => {
  const optionsGeneratedFromProps = calcOptionsFromPropsFn(rest);
  return merge(optionsGeneratedFromProps, optionsOverride || {});
};
