import React from 'react';
import { MultiRowInfo, ShipmentStatus, CompanyAvatar, USER_TYPE } from '@leaf/components';

export const getColumns = filterOptions => ({
  [USER_TYPE.SHIPPER]: [
    {
      name: 'id',
      label: 'Shipment',
      options: {
        sort: false,
        filter: false,
      },
    },
    {
      label: 'Execution partner name',
      name: 'executionPartnerName',
      options: { ...filterOptions.executionPartnerName, display: false },
    },
    {
      name: 'view.pickupEarliest',
      label: 'Pickup date',
      field: 'pickupAppointment',
      options: {
        filter: false,
      },
    },
    {
      name: 'view.originAddress',
      label: 'Origin',
      field: 'origin',
      options: {
        ...filterOptions.origin,
        customBodyRender: origin => {
          const [primaryText, secondaryText] = origin;
          return <MultiRowInfo primaryContent={[primaryText]} secondaryContent={[secondaryText]} />;
        },
      },
    },
    {
      name: 'view.destinationAddress',
      label: 'Destination',
      field: 'destination',
      options: {
        ...filterOptions.destination,
        customBodyRender: destination => {
          const [primaryText, secondaryText] = destination;
          return <MultiRowInfo primaryContent={[primaryText]} secondaryContent={[secondaryText]} />;
        },
      },
    },
    {
      name: 'view.leg',
      field: 'legNumber',
      label: 'Leg',
      options: {
        ...filterOptions.leg,
      },
    },
    {
      name: 'view.tripId',
      label: 'Trip ID',
      field: 'tripId',
      options: {
        ...filterOptions['view.tripId'],
      },
    },
    {
      name: 'view.carrier',
      field: 'carrierName',
      label: 'Carrier',
      options: {
        ...filterOptions['view.carrier'],
        customBodyRender: carrier => (carrier ? <CompanyAvatar name={carrier.name} id={carrier.image} isChip /> : '-'),
      },
    },
    {
      name: 'view.equipment',
      label: 'Equipment',
      field: 'equipmentTypeName',
      options: {
        filter: false,
      },
    },
    {
      name: 'view.status',
      field: 'status',
      label: 'Status',
      options: {
        ...filterOptions.status,
        customBodyRender: status => <ShipmentStatus status={status} />,
      },
    },
  ],
  [USER_TYPE.CARRIER]: [
    {
      name: 'id',
      label: 'ID',
      options: {
        display: 'excluded',
        filter: false,
        sort: false,
      },
    },
    {
      label: 'Execution partner name',
      name: 'executionPartnerName',
      options: { ...filterOptions.executionPartnerName, display: false },
    },
    {
      name: 'view.tripId',
      label: 'Trip ID',
      field: 'tripId',
      options: {
        filter: false,
      },
    },
    {
      name: 'view.startDate',
      label: 'Start date',
      field: 'startDate',
      options: {
        filter: false,
      },
    },
    {
      name: 'view.originAddress',
      label: 'Origin',
      field: 'origin',
      options: {
        ...filterOptions.origin,
        customBodyRender: origin => {
          const [primaryText, secondaryText] = origin;
          return <MultiRowInfo primaryContent={[primaryText]} secondaryContent={[secondaryText]} />;
        },
      },
    },
    {
      name: 'view.stops',
      label: 'Stops',
      field: 'stops',
      options: {
        filter: false,
      },
    },
    {
      name: 'view.destinationAddress',
      label: 'Destination',
      field: 'destination',
      options: {
        ...filterOptions.destination,
        customBodyRender: destination => {
          const [primaryText, secondaryText] = destination;
          return <MultiRowInfo primaryContent={[primaryText]} secondaryContent={[secondaryText]} />;
        },
      },
    },
    {
      name: 'view.shipper',
      label: 'Shipper',
      field: 'shipper',
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: 'view.equipment',
      label: 'Equipment',
      field: 'equipmentTypeName',
      options: {
        filter: false,
      },
    },
    {
      name: 'view.status',
      label: 'Status',
      field: 'status',
      options: {
        ...filterOptions.status,
        customBodyRender: status => <ShipmentStatus status={status} />,
      },
    },
  ],
  default: [],
});

export const getTitles = (companyType, field) => {
  if (!companyType || !field) {
    return '';
  }
  return {
    [USER_TYPE.SHIPPER]: {
      overviewTitle: 'Shipments',
      detailsTitle: 'Shipment',
      tableTitle: 'All shipments',
    },
    [USER_TYPE.CARRIER]: {
      overviewTitle: 'Trips',
      detailsTitle: 'Trip',
      tableTitle: 'All trips',
    },
  }[companyType]?.[field];
};

export const extractAddress = data => {
  const dataColumns = ['city', 'state', 'zipCode', 'businessName', 'street'];
  return data.split(';').reduce((acc, segment, index) => {
    acc[dataColumns[index]] = segment;
    return acc;
  }, {});
};
