export default class Address {
  constructor(response) {
    Object.assign(this, response);

    const { street = '-', city = '-', country = '-', zipCode = '-', state = '-', geo } = response;

    this.street = street;
    this.city = city;
    this.country = country;
    this.zipCode = zipCode;
    this.state = state;
    this.geo = geo;
  }

  static mapAddress = (city, state, zipCode) => {
    if (city) {
      if (state && !zipCode) {
        return `${city}, ${state}`;
      }
      if (zipCode && !state) {
        return `${city}, ${zipCode}`;
      }
      return `${city}, ${state} ${zipCode}`;
    }
    if (state && zipCode) {
      return `${state} ${zipCode}`;
    }
    if (state) {
      return state;
    }
    if (zipCode) {
      return zipCode;
    }
    return '-';
  };
}
