import React from 'react';
import _ from 'lodash';
import { Section } from '../AddEditWidgetModal.styled';
import Title from './Title';
import { Autocomplete } from '../../../form/library';
import { orderOptions } from '../../utility';
import { orderDisplayNames } from '../../Constants/order';

export default ({ options, control, errors }) => (
  <Section boxShadow={2} p={2} mt={2} mb={2}>
    <Title title="Order" hasDelete={false} />
    <Autocomplete
      control={control}
      name="order.name"
      inputProps={{ error: _.get(errors, 'order.name') }}
      label="Field"
      placeholder="Name"
      getOptionLabel={option => options.find(({ key }) => option === key)?.text}
      isOptionEqualToValue={(option, value) => option === value}
      options={options.map(({ key }) => key)}
    />
    <Autocomplete
      control={control}
      name="order.direction"
      inputProps={{ error: _.get(errors, 'order.direction') }}
      label="Direction"
      placeholder="Ascending"
      options={orderOptions.map(({ key }) => key)}
      getOptionLabel={option => orderDisplayNames[option]}
      isOptionEqualToValue={(option, value) => option === value}
    />
  </Section>
);
