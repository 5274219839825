import React from 'react';
import {
  scaleLinear,
  scaleTime,
  scaleOrdinal,
  extent,
  max,
  select,
  selectAll,
  line,
  area,
  axisLeft,
  axisBottom,
  timeFormat,
} from 'd3';
import { legendColor } from 'd3-svg-legend';

const magic = {
  width: 780,
  height: 550,
  margins: { top: 20, right: 20, bottom: 50, left: 40 },
  flexColor: 'rgba(0,128,0,0.25)',
  trendColor: 'rgba(70, 130, 180)',
  dailyColor: 'rgba(70, 130, 180, 0.25)',
  yAxisLabelColor: 'black',
  yAxisLabelOffset: '1.5em',
  legendShapeWidth: 30,
  legendPositionX: 50,
  legendPositionY: 20,
};

const DailyVolume = ({ data }) => {
  const svg = React.useRef(null);

  React.useEffect(() => {
    selectAll('#daily-volume-chart > *').remove();

    const width = magic.width - magic.margins.left - magic.margins.right;
    const height = magic.height - magic.margins.top - magic.margins.bottom;

    // X
    const x = scaleTime().rangeRound([0, width]);
    x.domain(extent(data, r => r.period));

    // Y
    const y = scaleLinear().rangeRound([0, height]);
    y.domain([max(data.map(d => d.shipments)) + 1, 0]);

    // Daily volume
    const daily = line()
      .x(d => x(d.period))
      .y(d => y(d.shipments));

    // Trend volume
    const trend = line()
      .x(d => x(d.period))
      .y(d => y(d.trend));

    // Flex volume
    const flex = area()
      .x(d => x(d.period))
      .y0(() => y(0))
      .y1(d => y(d.leaf));

    // Set up node
    const g = select(svg.current)
      .attr('width', magic.width + magic.margins.left)
      .attr('height', magic.height + magic.margins.bottom)
      .append('g')
      .attr('transform', `translate(${magic.margins.left}, ${magic.margins.top})`);

    // Add flex volume
    g.append('path').datum(data).style('fill', magic.flexColor).attr('d', flex);
    // Add daily volume
    g.append('path').datum(data).attr('fill', 'none').attr('stroke', magic.dailyColor).attr('d', daily);
    // Add trend volume
    g.append('path').datum(data).attr('fill', 'none').attr('stroke', magic.trendColor).attr('d', trend);

    // X-axis label
    g.append('g')
      .attr('transform', `translate(0,${height})`)
      .call(axisBottom(x).tickFormat(timeFormat("%b %d '%y")))
      .selectAll('text')
      .attr('transform', 'rotate(-45)')
      .style('text-anchor', 'end');

    // Y-axis label
    g.append('g')
      .call(axisLeft(y))
      .append('text')
      .attr('fill', magic.yAxisLabelColor)
      .attr('transform', 'rotate(-90)')
      .attr('dy', magic.yAxisLabelOffset)
      .attr('text-anchor', 'end')
      .text('Daily loads');

    // Legend
    const legendScale = scaleOrdinal()
      .domain(['Daily volume', 'Trend volume', 'Leaf flex volume'])
      .range([magic.dailyColor, magic.trendColor, magic.flexColor]);
    const legend = legendColor().shapeWidth(magic.legendShapeWidth).scale(legendScale);
    g.append('g').call(legend).attr('transform', `translate(${magic.legendPositionX},${magic.legendPositionY})`);
  }, [data, svg]);

  return <svg id="daily-volume-chart" ref={svg} />;
};

export default DailyVolume;
