import React from 'react';
import { Card, CardContent } from '@mui/material';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import { OverviewTable } from '@leaf/components';

const columns = [
  {
    label: 'Invoice date',
    name: 'invoiceDate',
  },
  { label: 'Invoice number', name: 'invoiceNumber' },
  { label: 'Total invoice amount', name: 'total' },
  { label: 'Linehaul', name: 'linehaul' },
  { label: 'Fuel', name: 'fuel' },
  { label: 'Accesorials (total)', name: 'accessorial' },
  { label: 'Detention', name: 'detentions' },
  { label: 'TONU', name: 'tonu' },
];

const CardContentSC = styled(CardContent)`
  padding: 0;
  .MuiToolbar-gutters {
    padding-left: ${({ theme }) => theme.spacing(2)};
  }
`;

const ShipmentInvoiceDetails = ({ invoiceDetails }) => (
  <Card>
    <CardContentSC>
      <OverviewTable data={invoiceDetails} columns={columns} title="Invoices" />
    </CardContentSC>
  </Card>
);

ShipmentInvoiceDetails.propTypes = {
  invoiceDetails: PropTypes.array.isRequired,
};

export default ShipmentInvoiceDetails;
