/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import DayPickerInput, { DateUtils } from 'react-day-picker';
import 'react-day-picker/lib/style.css';
import classNames from 'classnames';

import { date as dateTimeService } from '../../../utility';
import { daysOfWeek } from '../Constants';
import { ActionLink } from '.';

import './DateRangePicker.scss';

const fromDatePlaceholder = 'From';
const toDatePlaceholder = 'To';

const areDatesInSameYear = (from, to) => dateTimeService.getYear(from) === dateTimeService.getYear(to);
const getFromDate = (from, to) => {
  if (areDatesInSameYear(from, to)) {
    return dateTimeService.to3LetterMonthAndDay(from);
  }

  return dateTimeService.to3LetterMonthDayAndYear(from);
};
const getToDate = (from, to) => {
  if (areDatesInSameYear(from, to)) {
    return dateTimeService.to3LetterMonthAndDay(to);
  }

  return dateTimeService.to3LetterMonthDayAndYear(to);
};

const DateRangePickerText = ({ textMap }) =>
  textMap.map((textData, index) => (
    <span
      key={index} // eslint-disable-line react/no-array-index-key
      className={classNames({
        'date-range-picker-text': true,
        'date-range-picker-text--emphasized': textData.isEmphasized,
      })}
    >
      {textData.text}
    </span>
  ));

export default class DateRangePicker extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      disabledDays: props.dayPickerProps.disabledDays,
      from: props.dayPickerProps.selectedDays.from,
      to: props.dayPickerProps.selectedDays.to,
      month: this.props.dayPickerProps.month,
      isResetButtonOptionDisabled: props.isResetButtonOptionDisabled || false,
    };

    this.handleDayClick = this.handleDayClick.bind(this);
    this.handleResetClick = this.handleResetClick.bind(this);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      nextProps.dayPickerProps.selectedDays.from !== this.props.dayPickerProps.selectedDays.from ||
      nextProps.dayPickerProps.selectedDays.to !== this.props.dayPickerProps.selectedDays.to
    ) {
      this.setState(
        {
          from: nextProps.dayPickerProps.selectedDays.from,
          to: nextProps.dayPickerProps.selectedDays.to,
        },
        this.props.input.onChange({
          from: nextProps.dayPickerProps.selectedDays.from,
          to: nextProps.dayPickerProps.selectedDays.to,
        }),
      );
    }

    if (nextProps.dayPickerProps.disabledDays.before !== this.props.dayPickerProps.disabledDays.before) {
      this.setState({
        disabledDays: nextProps.dayPickerProps.disabledDays,
        fromMonth: nextProps.dayPickerProps.disabledDays.before,
      });
    }

    if (nextProps.dayPickerProps.month !== this.props.dayPickerProps.month) {
      this.setState({
        month: nextProps.dayPickerProps.month,
      });
    }

    if (nextProps.isResetButtonOptionDisabled !== this.props.isResetButtonOptionDisabled) {
      this.setState({
        isResetButtonOptionDisabled: nextProps.isResetButtonOptionDisabled,
      });
    }
  }

  handleDayClick(day, onChangeCallback) {
    if (this.isSelectedDayDisabled(day)) {
      return;
    }

    const range = DateUtils.addDayToRange(day, this.state);
    this.setState(range);
    onChangeCallback(range);
  }

  handleResetClick(onChangeCallback) {
    const resetState = {
      from: undefined,
      to: undefined,
    };

    this.setState(resetState);
    onChangeCallback(resetState);
  }

  isSelectedDayDisabled(day) {
    if (this.props.dayPickerProps.disabledDays.before) {
      return (
        dateTimeService.getDateForDatePicker(day) <
        dateTimeService.getDateForDatePicker(this.props.dayPickerProps.disabledDays.before)
      );
    }

    return false;
  }

  render() {
    const { className, hideHeader, selectedPickupEveryWeekdays, numberOfMonths = 2, onChange, ...props } = this.props;
    const { disabledDays, from, to, month, isResetButtonOptionDisabled } = this.state;
    const modifiers = {
      start: from,
      end: to,
      weekday: {
        daysOfWeek: selectedPickupEveryWeekdays || [],
      },
    };

    return (
      <div>
        <div
          className={classNames(className, {
            'date-range-picker': true,
            'date-range-picker--one-month': numberOfMonths === 1,
          })}
        >
          {!hideHeader && (
            <header>
              {!from && !to && (
                <DateRangePickerText
                  textMap={[{ text: `${fromDatePlaceholder} - ${toDatePlaceholder}`, isEmphasized: false }]}
                />
              )}
              {from && !to && (
                <DateRangePickerText
                  textMap={[
                    {
                      text: `${dateTimeService.to3LetterMonthAndDay(from)} - `,
                      isEmphasized: true,
                    },
                    { text: toDatePlaceholder, isEmphasized: false },
                  ]}
                />
              )}
              {from && to && (
                <DateRangePickerText
                  textMap={[
                    {
                      text: `${getFromDate(from, to)} - ${getToDate(from, to)}`,
                      isEmphasized: true,
                    },
                  ]}
                />
              )}
              {from && to && !isResetButtonOptionDisabled && (
                <ActionLink onClick={() => this.handleResetClick(onChange)}>Reset</ActionLink>
              )}
            </header>
          )}
          <DayPickerInput
            className="Selectable"
            modifiers={modifiers}
            numberOfMonths={numberOfMonths}
            disabledDays={disabledDays}
            month={month}
            weekdaysShort={[
              daysOfWeek.sunday.short,
              daysOfWeek.monday.short,
              daysOfWeek.tuesday.short,
              daysOfWeek.wednesday.short,
              daysOfWeek.thursday.short,
              daysOfWeek.friday.short,
              daysOfWeek.saturday.short,
            ]}
            selectedDays={[from, { from, to }]}
            onDayClick={day => this.handleDayClick(day, onChange)}
            {...props}
          />
        </div>
      </div>
    );
  }
}

DateRangePicker.defaultProps = {
  hideHeader: false,
};
