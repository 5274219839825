/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { MenuItem, TextField } from '@mui/material';

export default ({ options = [], ...props }) => (
  <TextField {...props} select>
    {options.map(option => (
      <MenuItem key={option.id} value={option.id} disabled={option.disabled}>
        {option.label}
      </MenuItem>
    ))}
  </TextField>
);
