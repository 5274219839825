import React from 'react';
import { Box } from '@mui/material';
import styled from '@emotion/styled';
import FleetOpportunityDetails from 'fleet-opportunities/details/FleetOpportunityDetails';
import ShareAppContainer from './ShareAppContainer';

const OpportunityPage = styled(Box)`
  height: calc(100vh - 60px);
  background-color: ${({ theme }) => `${theme.palette.background.default}`};
`;

export default () => (
  <ShareAppContainer>
    <OpportunityPage>
      <FleetOpportunityDetails />
    </OpportunityPage>
  </ShareAppContainer>
);
