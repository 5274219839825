/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Controller } from 'react-hook-form';
import Datepicker from '../generic/Datepicker';

export default ({ name, label, control, disabled, variant = 'outlined', error, ...theRestOfTheProps }) => (
  <Controller
    as={
      <Datepicker
        label={label}
        inputVariant={variant}
        useFieldNameHandler={false}
        disabled={disabled}
        error={error}
        {...theRestOfTheProps}
      />
    }
    name={name}
    control={control}
  />
);
