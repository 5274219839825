/* eslint-disable no-param-reassign */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/destructuring-assignment */
import React, { useEffect, useMemo, useState } from 'react';
import { FormControl as FormControlMUI, MenuItem, TextField, FormControlLabel, Checkbox, Box } from '@mui/material';
import styled from '@emotion/styled';
import { v4 } from 'uuid';
import enLocale from 'date-fns/locale/en-US';
import { LocalizationProvider, MobileDatePicker, MobileDateRangePicker } from '@mui/x-date-pickers-pro';
import { AdapterDateFns as DateFnsAdapter } from '@mui/x-date-pickers-pro/AdapterDateFns';
import { date } from '../utility';
import { Generic } from '../form';

const FormControl = styled(FormControlMUI)`
  min-width: 300px;
  .MuiAutocomplete-popper {
    margin-top: 41px;
  }
`;

const InRowContainer = styled.div`
  display: flex;
  align-items: center;
`;

const getTablePropsForInput = (inputType, inputData) => {
  switch (inputType) {
    case 'text': {
      return {
        customFilterListOptions: { render: v => v },
        filterType: 'textField',
      };
    }
    case 'dropdown': {
      const { filterOptions } = inputData;
      return {
        customFilterListOptions: {
          render: v => v.map(selectedValue => filterOptions.find(({ value }) => value === selectedValue)?.label),
        },
        filterOptions: {
          display: (filterList, onChange, index, column) => (
            <FormControl>
              <TextField
                select
                fullWidth
                id={column.name}
                name={column.name}
                label={column.label}
                value={filterList[index][0]}
                onChange={event => {
                  filterList[index] = [event.target.value];
                  onChange(filterList[index], index, column);
                }}
              >
                {filterOptions.map(({ value, label }) => (
                  <MenuItem key={v4()} value={value}>
                    {label}
                  </MenuItem>
                ))}
              </TextField>
            </FormControl>
          ),
        },
      };
    }
    case 'autocomplete': {
      const { filterOptions, isMultiselect = true } = inputData;
      const getValue = (filterList, index) => {
        const list = filterList[index];
        const values = Array.isArray(list) ? list.flat(1) : list;
        return values.map(v => filterOptions.find(({ value: labelValue }) => v === labelValue));
      };
      return {
        customFilterListOptions: {
          render: v => {
            const values = Array.isArray(v) ? v.flat(1) : [v];
            return values.map(selectedValue => filterOptions.find(({ value }) => value === selectedValue)?.label);
          },
        },
        filterOptions: {
          display: (filterList, onChange, index, column) => (
            <FormControl>
              <Generic.Autocomplete
                id={column.name}
                name={column.name}
                options={filterOptions}
                getOptionLabel={options => {
                  const [option] = Array.isArray(options) ? options : [options];
                  return option === undefined || typeof option === 'string' ? option : option.label;
                }}
                isOptionEqualToValue={(option, selected) =>
                  option.value === (typeof selected === 'string' ? selected : selected?.value)
                }
                value={getValue(filterList, index)}
                onChange={(event, value) => {
                  const values = Array.isArray(value) ? value : [value];
                  filterList[index] = values.map(({ value: labelValue }) => labelValue);
                  onChange(filterList[index], index, column);
                }}
                getOptionDisabled={isMultiselect ? option => filterList[index].some(v => v === option.value) : null}
                renderInput={params => <TextField label={column.label} {...params} />}
                multiple={isMultiselect}
                fullWidth
              />
            </FormControl>
          ),
        },
      };
    }
    case 'checkbox': {
      const { filterOptions: checkedValue } = inputData;
      return {
        /*
          The field "customFilterListOptions" needs to be added to the column itself. For example:
          customFilterListOptions: {
            render: () => 'Only my accounts',
          }
        */
        filterOptions: {
          display: (filterList, onChange, index, column) => {
            // eslint-disable-next-line react/destructuring-assignment
            const isChecked = filterList[index][0];
            const handleChange = event => {
              const value = event.target.checked ? [checkedValue ?? true] : [];
              onChange(value, index, column);
            };
            return (
              <FormControl>
                <FormControlLabel
                  label={column.label}
                  control={<Checkbox checked={isChecked} onChange={handleChange} />}
                />
              </FormControl>
            );
          },
        },
      };
    }
    case 'date': {
      return {
        customFilterListOptions: {
          render: v => v,
        },
        filterOptions: {
          display: (filterList, onChange, index, column) => (
            <FormControl>
              <LocalizationProvider dateAdapter={DateFnsAdapter} locale={enLocale}>
                <MobileDatePicker
                  id={column.name}
                  name={column.name}
                  label={column.label}
                  value={filterList[index][0] ?? null}
                  onChange={value => {
                    filterList[index] = [date.formatDate(value, date.VIEW_FORMAT)];
                    onChange(filterList[index], index, column);
                  }}
                  renderInput={params => <TextField {...params} label={column.label} helperText={null} />}
                />
              </LocalizationProvider>
            </FormControl>
          ),
        },
      };
    }
    case 'daterange': {
      return {
        customFilterListOptions: {
          render: v => v,
        },
        filterOptions: {
          display: (filterList, onChange, index, column) => (
            <FormControl>
              <LocalizationProvider dateAdapter={DateFnsAdapter} locale={enLocale}>
                <MobileDateRangePicker
                  startText={`${column.label} from`}
                  endText={`${column.label} to`}
                  value={[filterList[index][0] ?? null, filterList[index][1] ?? null]}
                  disableCloseOnSelect
                  onChange={value => {
                    filterList[index] = value.map(item => item && [date.formatDate(item, date.VIEW_FORMAT)]);
                    onChange(filterList[index], index, column);
                  }}
                  renderInput={(startProps, endProps) => (
                    <>
                      <Generic.Input {...startProps} helperText={null} fullWidth />
                      <Box m={2}>-</Box>
                      <Generic.Input {...endProps} helperText={null} fullWidth />
                    </>
                  )}
                />
              </LocalizationProvider>
            </FormControl>
          ),
        },
      };
    }
    case 'min-max': {
      return {
        customFilterListOptions: {
          render: v => v,
        },
        filterOptions: {
          display: (filterList, onChange, index, column) => (
            <FormControl>
              <InRowContainer>
                <TextField
                  fullWidth
                  id={column.name}
                  name={column.name}
                  label={`${column.label} min`}
                  value={filterList[index][0]}
                  onChange={event => {
                    filterList[index][0] = Number(event.target.value);
                    onChange(filterList[index], index, column);
                  }}
                  InputLabelProps={{ shrink: typeof filterList[index][0] === 'number' }}
                  type="number"
                />
                <Box m={2}>-</Box>
                <TextField
                  fullWidth
                  id={column.name}
                  name={column.name}
                  label={`${column.label} max`}
                  value={filterList[index][1]}
                  onChange={event => {
                    if (!filterList[index][0]) filterList[index][0] = 0;
                    filterList[index][1] = Number(event.target.value);
                    onChange(filterList[index], index, column);
                  }}
                  type="number"
                />
              </InRowContainer>
            </FormControl>
          ),
        },
      };
    }
    default: {
      throw new Error('Input type not supported');
    }
  }
};

export default (filters, areFiltersMemoized = false) => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const memoizedFilters = areFiltersMemoized ? filters : useMemo(() => filters, []);

  const [state, setState] = useState({});

  useEffect(() => {
    const promises = Promise.allSettled(
      memoizedFilters.map(f => {
        let { filterOptionsGetter } = f;
        if (!filterOptionsGetter) {
          filterOptionsGetter = () => Promise.resolve();
        }
        return filterOptionsGetter();
      }),
    );

    promises.then(res => {
      setState(
        res.reduce((acc, response, reduceIndex) => {
          const filterOptions = response.status === 'fulfilled' ? response.value : [];
          const { columnName, inputType, isMultiselect } = memoizedFilters[reduceIndex];
          acc[columnName] = {
            filter: true,
            filterType: 'custom',
            ...getTablePropsForInput(inputType, { filterOptions, isMultiselect }),
          };
          return acc;
        }, {}),
      );
    });
  }, [memoizedFilters]);

  const addFilterOption = (filterName, inputType, filterOptions, isMultiselect) => {
    setState(prevState => ({
      ...prevState,
      [filterName]: {
        filter: true,
        filterType: 'custom',
        ...getTablePropsForInput(inputType, { filterOptions, isMultiselect }),
      },
    }));
  };

  return [state, addFilterOption];
};
