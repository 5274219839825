const succesMessage = 'Copied';
const errorMessage = 'Failed to generate link';

export default (onSuccess, onError, successText, errorText) => {
  const handleCopy = text => {
    if (navigator?.clipboard && (typeof text === 'string' || typeof text === 'number')) {
      navigator.clipboard
        .writeText(text.toString())
        .then(() => onSuccess?.(successText ?? succesMessage))
        .catch(() => onError?.(errorText ?? errorMessage));
    } else {
      onError?.(errorMessage);
    }
  };
  return handleCopy;
};
