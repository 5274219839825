import React, { useState, useEffect } from 'react';
import { SpacedContainer, PageContainer, PageContentLayout, DetailsOverview } from '@leaf/components';
import { Grid } from '@mui/material';
import useGQL from 'hooks/useGQL';
import { getContractDetails } from './domain/contractDetailsModel';
import {
  getCompanyGeneralDetails,
  getCompanyTimeAndVolumeDetails,
  getCompanyPricingDetails,
} from './domain/contractDetailsDataMapper';

const ContractDetails = ({ contractId }) => {
  const [contract, setContract] = useState(null);

  const getGQLClient = useGQL();

  useEffect(() => {
    getContractDetails(getGQLClient, contractId).then(({ data }) => {
      setContract(data);
    });
  }, []);

  return (
    <PageContainer>
      <SpacedContainer container spacing={2} autoWidth>
        <Grid item xs={4}>
          <PageContentLayout hasSubheader withoutSpacing fullHeight={false}>
            <DetailsOverview title="General" data={getCompanyGeneralDetails(contract)} />
          </PageContentLayout>
        </Grid>
        <Grid item xs={4}>
          <PageContentLayout hasSubheader withoutSpacing fullHeight={false}>
            <DetailsOverview title="Timeframe And Volume" data={getCompanyTimeAndVolumeDetails(contract)} />
          </PageContentLayout>
        </Grid>
        <Grid item xs={4}>
          <PageContentLayout hasSubheader withoutSpacing fullHeight={false}>
            <DetailsOverview title="Pricing" data={getCompanyPricingDetails(contract)} />
          </PageContentLayout>
        </Grid>
      </SpacedContainer>
    </PageContainer>
  );
};

export default ContractDetails;
