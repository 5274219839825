import { gql } from 'graphql-request';

export const GQL_EQUIPMENT_TYPES = gql`
  query getEquipmentTypes {
    equipment_type {
      name
      id
    }
  }
`;
