import { Button } from '@mui/material';
import React from 'react';
import styled from '@emotion/styled';

const Wrapper = styled.span`
  svg {
    color: ${({ labelColor }) => labelColor};
  }
  & > label {
    min-width: 51px;
    height: 51px;
    width: 51px;
    border-radius: 50%;
    :hover {
      background-color: rgba(0, 0, 0, 0.04);
    }
  }
`;

const UploadButton = React.memo(
  ({ variant = 'contained', labelColor, color = 'primary', label, handleUpload, icon, fileTypes }) => (
    <Wrapper labelColor={labelColor}>
      <Button color={color} variant={variant} component="label">
        <input
          type="file"
          hidden
          onChange={e => handleUpload(e.target.files[0])}
          onClick={event => {
            // eslint-disable-next-line no-param-reassign
            event.target.value = null;
          }}
          accept={fileTypes || ''}
        />
        {icon} {label}
      </Button>
    </Wrapper>
  ),
);

export default UploadButton;
