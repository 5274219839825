export const SHIPMENT_STATUS = {
  DECLINED: 'DECLINED',
  UNASSIGNED: 'UNASSIGNED',
  ASSIGNED: 'ASSIGNED',
  QUOTES_RECEIVED: 'QUOTES_RECEIVED',
  QUOTES_REQUESTED: 'QUOTES_REQUESTED',
  DISPATCHED: 'DISPATCHED',
  NOT_TENDERED: 'NOT_TENDERED',
  TENDERED: 'TENDERED',
  CANCELED: 'CANCELED',
  IN_TRANSIT: 'IN_TRANSIT',
  DELIVERED: 'DELIVERED',
  FAILED: 'FAILED',
  UNKNOWN: 'UNKNOWN',
  COMPLETED: 'COMPLETED',
  TONU: 'TONU',
};

export const SHIPMENT_STATUS_DISPLAY_NAMES = {
  DECLINED: 'Declined',
  QUOTES_RECEIVED: 'Quotes received',
  QUOTES_REQUESTED: 'Quotes requested',
  UNASSIGNED: 'Unassigned',
  ASSIGNED: 'Assigned',
  DISPATCHED: 'Dispatched',
  TENDERED: 'Tendered',
  NOT_TENDERED: 'Not tendered',
  CANCELED: 'Canceled',
  IN_TRANSIT: 'In transit',
  DELIVERED: 'Delivered',
  FAILED: 'Missed',
  UNKNOWN: 'Unknown',
  COMPLETED: 'Completed',
  TONU: 'Tonu',
};
