export const IMPACT_OBJECTIVE_STATUS = {
  ON_TRACK: 'ON_TRACK',
  CAUTION: 'CAUTION',
  DELAYED: 'DELAYED',
  NO_ACTUAL_GOAL: 'NO_ACTUAL_GOAL',
};

export const IMPACT_OBJECTIVE_STATUS_DISPLAY_NAMES = {
  [IMPACT_OBJECTIVE_STATUS.ON_TRACK]: 'On track',
  [IMPACT_OBJECTIVE_STATUS.CAUTION]: 'Caution',
  [IMPACT_OBJECTIVE_STATUS.DELAYED]: 'Delayed',
  [IMPACT_OBJECTIVE_STATUS.NO_ACTUAL_GOAL]: '',
};

export const IMPACT_OBJECTIVE_COLORS = {
  [IMPACT_OBJECTIVE_STATUS.ON_TRACK]: '#4a90e2',
  [IMPACT_OBJECTIVE_STATUS.DELAYED]: '#ff9800',
};
