import { deepmerge } from '@mui/utils';

const caseAllCaps = {
  textTransform: 'uppercase',
};
const defaultFontFamily = '"Avenir", "Open sans", "Arial", sans-serif !important';

const createTypography = () => {
  const fontFamily = defaultFontFamily;
  const fontSize = 18;
  const fontWeightLight = 300;
  const fontWeightRegular = 400;
  const fontWeightMedium = 500;
  const fontWeightBold = 800;
  const htmlFontSize = 16; // 16px is the default font-size used by browsers.

  const coef = fontSize / 14;
  const pxToRem = size => `${(size / htmlFontSize) * coef}rem`;

  const buildVariant = (fontWeight, size, lineHeight, letterSpacing, casing) => ({
    fontFamily,
    fontWeight,
    fontSize: pxToRem(size),
    lineHeight: `${lineHeight}%`,
    letterSpacing: `${letterSpacing}rem`,
    ...casing,
  });

  const variants = {
    h1: buildVariant(fontWeightMedium, 48, 130, -0.015),
    h2: buildVariant(fontWeightMedium, 36, 130, -0.005),
    h3: buildVariant(fontWeightMedium, 24, 130, 0),
    h4: buildVariant(fontWeightMedium, 18, 130, 0.0025),
    h5: buildVariant(fontWeightMedium, 16, 130, 0),
    h6: buildVariant(fontWeightMedium, 14, 130, 0.0015),
    subtitle1: buildVariant(fontWeightLight, 18, 130, 0.0015),
    subtitle2: buildVariant(fontWeightLight, 16, 130, 0.1),
    body1: buildVariant(fontWeightLight, 12, 130, 0.0025),
    body2: buildVariant(fontWeightMedium, 14, 130, 0.15),
    button: buildVariant(fontWeightMedium, 12, 130, 0.004, caseAllCaps),
    caption: buildVariant(fontWeightMedium, 12, 130, 0.0015),
    overline: buildVariant(fontWeightMedium, 10, 130, 0.0015, caseAllCaps),
  };

  return deepmerge(
    {
      htmlFontSize,
      pxToRem,
      fontFamily,
      fontSize,
      fontWeightLight,
      fontWeightRegular,
      fontWeightMedium,
      fontWeightBold,
      ...variants,
    },
    {
      clone: false,
    },
  );
};

export default createTypography;
