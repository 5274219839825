import React, { memo, useEffect, useState } from 'react';
import styled from '@emotion/styled';

import { InputAdornment, TextField } from '@mui/material';

import { Search } from '@mui/icons-material';

const SearchIcon = styled(Search)`
  opacity: 0.54;
`;

const TextFieldWrapper = styled(TextField)`
  padding-left: 10px;
  & > div {
    border-bottom: 1px solid #e5e5e5;
    &:before {
      border-bottom: 1px solid #e5e5e5;
    }
  }
`;

export default memo(({ initialSearchText = '', onTextChange, placeholder = 'Search...' }) => {
  const [searchText, setSearchText] = useState(initialSearchText);

  useEffect(() => {
    onTextChange(searchText);
  }, [searchText]);

  const onChange = event => {
    setSearchText(event.target.value);
  };

  return (
    <TextFieldWrapper
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon />
          </InputAdornment>
        ),
      }}
      value={searchText}
      placeholder={placeholder}
      onChange={onChange}
    />
  );
});
