import React from 'react';
import { Box } from '@mui/material';
import InfoCard from '../../../InfoCard';
import { formattingFns } from '../../../utility/formattingHelper';
import { sharedChartPropTypes } from './types';

const DashboardListInfo = ({ data, config, metricsOptions = [] }) => {
  const values = data.metrics.map((metric, index) => {
    const fieldFormat = config.metrics[index].numberFormat;
    const formattingFn = formattingFns[fieldFormat] || (value => value);
    const valueToDisplay = fieldFormat ? formattingFn(metric.values[0]) : metric.values[0];
    return {
      label: config.metrics[index]?.displayName || metricsOptions.find(m => m.name === metric.metric)?.displayName,
      value: valueToDisplay,
      variant: config.metrics[index].fontVariant || 'h5',
      ...(config.metrics[index].isDivider && { hasDivider: true }),
    };
  });
  return (
    <Box overflow="auto">
      <InfoCard content={values} />
    </Box>
  );
};

DashboardListInfo.propTypes = sharedChartPropTypes;

export default DashboardListInfo;
