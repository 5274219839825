export default {
  monday: {
    short: 'Mo',
    long: 'Monday',
    longCapital: 'MONDAY',
    keyName: 'mon',
    keyNameInFormReview: 'isMondayAPickupDay',
    number: 1,
  },
  tuesday: {
    short: 'Tu',
    long: 'Tuesday',
    longCapital: 'TUESDAY',
    keyName: 'tue',
    keyNameInFormReview: 'isTuesdayAPickupDay',
    number: 2,
  },
  wednesday: {
    short: 'We',
    long: 'Wednesday',
    longCapital: 'WEDNESDAY',
    keyName: 'wed',
    keyNameInFormReview: 'isWednesdayAPickupDay',
    number: 3,
  },
  thursday: {
    short: 'Th',
    long: 'Thursday',
    longCapital: 'THURSDAY',
    keyName: 'thu',
    keyNameInFormReview: 'isThursdayAPickupDay',
    number: 4,
  },
  friday: {
    short: 'Fr',
    long: 'Friday',
    longCapital: 'FRIDAY',
    keyName: 'fri',
    keyNameInFormReview: 'isFridayAPickupDay',
    number: 5,
  },
  saturday: {
    short: 'Sa',
    long: 'Saturday',
    longCapital: 'SATURDAY',
    keyName: 'sat',
    keyNameInFormReview: 'isSaturdayAPickupDay',
    number: 6,
  },
  sunday: {
    short: 'Su',
    long: 'Sunday',
    longCapital: 'SUNDAY',
    keyName: 'sun',
    keyNameInFormReview: 'isSundayAPickupDay',
    number: 0,
  },
  unknown: {
    short: '-',
    long: 'N/A',
  },
};
