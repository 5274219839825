import React from 'react';
import { withRouter } from 'react-router-dom';
import { ServerSideTable, useTableCustomFilters } from '@leaf/components';
import { default as NetworkMovesDomain } from 'domain/adaptBoard/NetworkMoves';
import { StateContext } from 'state/StateProvider';
import { SET_ADAPT_BOARD_NETWORK_MOVES_TABLE_STATE } from 'state/stateReducer';
import { useSnackbar } from 'notistack';

import { getColumns } from './networkMovesColumns';

const getOptions = history => ({
  filter: true,
  download: false,
  onRowClick: rowData => {
    const id = rowData[1];

    history.push(`/adapt-board-network-moves/${id}`);
  },
  textLabels: {
    body: {
      noMatch: 'Data not yet available',
    },
  },
});

const NetworkMoves = ({ history }) => {
  const [filters, setFilters] = React.useState({
    moveType: [],
    networkType: [],
    equipmentClass: [],
  });
  const { enqueueSnackbar } = useSnackbar();

  const {
    state: {
      tables: { adaptBoardNetworkMoves: tableState },
    },
    dispatch,
  } = React.useContext(StateContext);

  React.useEffect(() => {
    NetworkMovesDomain.getFilters().then(setFilters).catch(enqueueSnackbar);
  }, []);

  const tableFilters = React.useMemo(
    () => [
      {
        inputType: 'autocomplete',
        columnName: 'moveType',
        filterOptionsGetter: () => Promise.resolve(filters.moveType),
      },
      {
        inputType: 'autocomplete',
        columnName: 'equipmentClass',
        filterOptionsGetter: () => Promise.resolve(filters.equipmentClass),
      },
      {
        inputType: 'autocomplete',
        columnName: 'networkType',
        filterOptionsGetter: () => Promise.resolve(filters.networkType),
      },
    ],
    [filters],
  );
  const [filterOptions] = useTableCustomFilters(tableFilters, true);

  const handleChange = setTableState =>
    dispatch({ type: SET_ADAPT_BOARD_NETWORK_MOVES_TABLE_STATE, payload: setTableState(tableState) });

  return (
    <ServerSideTable
      options={getOptions(history)}
      columns={getColumns(filterOptions)}
      fn={NetworkMovesDomain.getAll}
      tableState={tableState}
      setTableState={handleChange}
    />
  );
};

export default withRouter(NetworkMoves);
