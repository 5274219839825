import React from 'react';
import styled from '@emotion/styled';
import { Typography, Box } from '@mui/material';
import { utility } from '..';

const phaseBoxColors = [
  '#83C1FF',
  '#6FA8E0',
  '#6095C8',
  '#5283B3',
  '#426F9B',
  '#3E6A94',
  '#38628A',
  '#2F577D',
  '#264C6F',
  '#183B5A',
];

const PhaseIconContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  justify-content: space-between;
`;

const PhaseColorBox = styled.div`
  height: 16px;
  width: 16px;
  border-radius: 2px;
  margin-right: ${({ theme }) => theme.spacing(2)};
  background-color: ${({ $index, $name }) =>
    // eslint-disable-next-line no-nested-ternary
    $index <= 10 ? `${phaseBoxColors[$index]}` : $name === 'Out of scope' ? `#F88078` : '#bdbdbd'};
`;

const TypographyScoped = styled(Typography)`
  font-size: 13px;
  color: rgba(0, 0, 0, ${({ opacity }) => `${opacity}`});
`;

const PhaseWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const PhaseIcon = ({ phaseName, phaseIndex, phaseDate }) => (
  <PhaseIconContainer>
    <PhaseWrapper>
      <PhaseColorBox $index={phaseIndex} $name={phaseName} />
      <Typography variant="h6">{phaseName}</Typography>
    </PhaseWrapper>
    {phaseDate && (
      <TypographyScoped variant="body1" opacity="0.54">{`Start date ${utility.date.formatStringDate(
        phaseDate,
        utility.date.VIEW_FORMAT,
      )}`}</TypographyScoped>
    )}
  </PhaseIconContainer>
);

export default PhaseIcon;
