import React, { useState } from 'react';
import { Box } from '@mui/material';
import styled from '@emotion/styled';
import PivotTableUI from 'react-pivottable/PivotTableUI';
import { sharedChartPropTypes } from './types';
import 'react-pivottable/pivottable.css';
import './DashboardPivotTable.scss';

const TableWrapper = styled(Box)`
  overflow: auto;
`;

const DashboardTableWidget = ({ data, config, dimensionOptions }) => {
  const [state, setState] = useState(null);

  const dimensionRows =
    data.dimensions?.filter(({ dimension: dimensionName }) =>
      config.pivotRows.find(({ name }) => name === dimensionName),
    ) ?? [];
  const dimensionColumns =
    data.dimensions?.filter(({ dimension: dimensionName }) =>
      config.pivotColumns.find(({ name }) => name === dimensionName),
    ) ?? [];

  const headers = [
    ...dimensionRows.map(
      ({ dimension }, index) =>
        config.pivotRows[index].displayName || dimensionOptions.find(({ name }) => name === dimension)?.displayName,
    ),
    ...dimensionColumns.map(
      ({ dimension }, index) =>
        config.pivotColumns[index].displayName || dimensionOptions.find(({ name }) => name === dimension)?.displayName,
    ),
    data.metrics[0].metric,
  ];
  const array = [];
  dimensionRows[0]?.values.forEach((_, i) => {
    array.push([
      ...dimensionRows.map(({ values }) => values[i]),
      ...dimensionColumns.map(({ values }) => values[i]),
      ...data.metrics.map(({ values }) => values[i]),
    ]);
  });
  return (
    <TableWrapper>
      <PivotTableUI
        data={[headers, ...array]}
        onChange={setState}
        aggregatorName="Sum"
        cols={dimensionColumns.map(
          ({ dimension }, index) =>
            config.pivotColumns[index].displayName ||
            dimensionOptions.find(({ name }) => name === dimension)?.displayName,
        )}
        rows={dimensionRows.map(
          ({ dimension }, index) =>
            config.pivotRows[index].displayName || dimensionOptions.find(({ name }) => name === dimension)?.displayName,
        )}
        vals={data.metrics.map(({ metric }) => metric)}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...state}
      />
    </TableWrapper>
  );
};

DashboardTableWidget.propTypes = sharedChartPropTypes;

export default DashboardTableWidget;
