export const ON_TIME_STATUS = {
  LATE: 'LATE',
  ON_TIME: 'ON_TIME',
  NOT_AVAILABLE: 'NOT_AVAILABLE',
};

export const ON_TIME_STATUS_DISPLAY_NAMES = {
  NOT_AVAILABLE: 'Not available',
  LATE: 'Late',
  ON_TIME: 'On time',
};
