import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { Collapse } from '@mui/material';
import { NavigationItemWrapper } from './NavigationItem';

export const NestedNavigationItemWrapper = styled(NavigationItemWrapper, {
  shouldForwardProp: prop => prop !== '$expanded',
})`
  padding-left: ${({ $expanded }) => ($expanded ? '4.45em' : '1em')};
`;

export const NestedNavigationItemParent = styled(NavigationItemWrapper, {
  shouldForwardProp: prop => prop !== '$expanded',
})`
  ${({ active, $expanded }) =>
    !$expanded &&
    css`
      &:first-child {
        background-color: ${active ? '#001530  !important' : '#183B5A  !important'};
        cursor: default;
        color: ${active ? '#fff  !important' : 'rgba(255, 255, 255, 0.5)  !important'};

        .MuiSvgIcon-root {
          color: ${active ? '#fff  !important' : 'rgba(255, 255, 255, 0.5)  !important'};
        }
      }
      &:first-child:hover {
        background-color: ${active ? '#001530 !important' : '#142743 !important'};
        cursor: default;
        color: ${active ? '#fff  !important' : 'rgba(255, 255, 255, 0.8) !important'};

        .MuiSvgIcon-root {
          color: ${active ? '#fff  !important' : 'rgba(255, 255, 255, 0.8) !important'};
        }
      }
    `}

  background-color: ${({ active }) => (active ? '#001530' : '#183B5A')};
  color: ${({ active }) => (active ? '#fff' : 'rgba(255,255,255,0.5)')};
  &&& {
    & > div > svg {
      color: ${({ active }) => (active ? '#fff' : 'rgba(255,255,255,0.5)')};
    }
  }
  border-top-right-radius: ${({ expanded }) => (expanded ? '0px' : '4px')};

  &.sidebar-parent {
    .MuiListItemText-root {
      padding-left: 12px;
    }
  }
`;

export const CollapsWrapper = styled(Collapse)`
  background-color: #123250;
`;
