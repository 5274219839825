import React, { memo } from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';

import { Typography, Box } from '@mui/material';
import MultiRowInfo from '../MultiRowInfo';
import { LANE_TYPE } from '../constants/LaneType';
import { PowerlaneIcon, PointToPointIcon } from '../icons';
import CompanyAvatar from '../CompanyAvatar';

const Wrapper = styled.div`
  ${props =>
    props.displayAsGrid &&
    css`
      display: grid;
      grid-template-columns: ${props.shouldShowShipperColumn ? '160px' : null} 220px 50px 220px ${props.shouldShowIdColumns
          ? '150px'
          : null};
      grid-gap: 10px;
      align-items: center;

      p {
        text-overflow: ellipsis;
        overflow: hidden;
        height: 1.2em;
        white-space: nowrap;
      }

      #company-avatar-chip {
        width: 100%;
        background: #e0e0e0;
      }

      .MuiChip-root {
        justify-content: space-between;
      }

      .MuiChip-label {
        width: 100%;
        text-align: ${!props.shipper?.image ? 'center' : 'left'};
      }

      #lane-type-icon {
        svg {
          vertical-align: middle;
        }
      }
    `}

  ${props =>
    !props.displayAsGrid &&
    css`
      display: flex;
      justify-content: ${props.fullWidth ? 'space-between' : ''};
      align-items: center;
      width: 100%;

      #lane-type-icon {
        padding: 0 16px;
        svg {
          vertical-align: middle;
        }
      }
    `}
`;

const Lane = memo(
  ({
    origin: { primaryContent: originPrimaryContent, secondaryContent: originSecondaryContent },
    destination: { primaryContent: destinationPrimaryContent, secondaryContent: destinationSecondaryContent },
    type,
    shipper,
    id,
    guid,
    displayAsGrid = false,
    viewId,
    fullWidth = true,
  }) => (
    <Wrapper
      displayAsGrid={displayAsGrid}
      shouldShowShipperColumn={shipper}
      shouldShowIdColumns={id || guid}
      shipper={shipper}
      fullWidth={fullWidth}
    >
      {shipper && <CompanyAvatar name={shipper.name} id={shipper.id} isChip />}
      <MultiRowInfo primaryContent={originPrimaryContent} secondaryContent={originSecondaryContent} hasNoWrap />
      <div id="lane-type-icon">{type === LANE_TYPE.POWER ? <PowerlaneIcon /> : <PointToPointIcon />}</div>
      <MultiRowInfo
        primaryContent={destinationPrimaryContent}
        secondaryContent={destinationSecondaryContent}
        hasNoWrap
      />
      {(viewId || id || guid) && (
        <Box>
          {(viewId || id) && <Typography variant="body1">ID - {viewId || id}</Typography>}
          {guid && <Typography variant="body1">GUID - {guid}</Typography>}
        </Box>
      )}
    </Wrapper>
  ),
  (prevProps, nextProps) => prevProps?.id === nextProps?.id,
);

export default Lane;
