/* eslint-disable no-nested-ternary */
import React from 'react';
import { Typography, Chip } from '@mui/material';
import styled from '@emotion/styled';

const OverviewTitleWrapper = styled.div`
  align-items: center;
  display: flex;
  padding-right: ${({ theme }) => `${theme.spacing(2)}`};
  padding-left: ${({ theme }) => `${theme.spacing(2)}`};
  border-bottom: 1px solid ${({ theme }) => `${theme.palette.divider}`};
  height: ${({ titleContainerHeight }) => `${titleContainerHeight}px`};
  background: ${({ bgColor }) => bgColor};
  justify-content: space-between;
`;

const OverviewContentContainer = styled.div`
  padding: ${({ theme }) => `${theme.spacing(2)} ${theme.spacing(3)}`};
  overflow-y: auto;
  height: ${({ titleContainerHeight }) => `calc(100% - ${titleContainerHeight}px)`};
`;

const Beta = styled(Chip)`
  margin-left: ${({ theme }) => theme.spacing(2)};
`;

const COLORS = {
  blue: 'linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), #4a90e2',
  green: 'linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), #4caf50',
  yellow: 'linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), #ff9800',
};

const Overview = React.memo(({ title, children, titleBgColor = '#eee', titleBadge, titleContainerHeight = 60 }) => (
  <div>
    <OverviewTitleWrapper bgColor={COLORS[titleBgColor] ?? titleBgColor} titleContainerHeight={titleContainerHeight}>
      {React.isValidElement(title) ? title : <Typography variant="h6">{title}</Typography>}
      {titleBadge ? (
        React.isValidElement(titleBadge) ? (
          titleBadge
        ) : (
          <Beta label={<Typography variant="caption">{titleBadge}</Typography>} color="primary" size="small" />
        )
      ) : null}
    </OverviewTitleWrapper>
    <OverviewContentContainer titleContainerHeight={titleContainerHeight}>{children}</OverviewContentContainer>
  </div>
));

export default Overview;
