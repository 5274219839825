export default class Spend {
  constructor(response) {
    Object.assign(this, {
      period: new Date(response.period),
      lsp: {
        rpm_mean: response.mean_linehaul_lsp_basis_rpm,
        rpm_min: response.min_linehaul_lsp_basis_rpm,
        rpm_max: response.max_linehaul_lsp_basis_rpm,
      },
      shipper: {
        rpm_mean: response.mean_linehaul_shipper_basis_rpm,
        rpm_min: response.min_linehaul_shipper_basis_rpm,
        rpm_max: response.max_linehaul_shipper_basis_rpm,
      },
    });
  }

  static get = async (client, companyId, laneId) =>
    client.get(`/analytics/explorer/spends/companies/${companyId}/lanes/${laneId}`).then(({ spends }) => {
      const equipmentClasses = Object.keys(spends);
      return equipmentClasses.reduce(
        (acc, key) => {
          acc[key] = spends[key].map(spend => new Spend(spend));
          return acc;
        },
        { equipmentClasses },
      );
    });
}
