import React from 'react';
import { Box, Button } from '@mui/material';
import styled from '@emotion/styled';
import { withRouter } from 'react-router-dom';
import { LeafLogoWhite } from '@leaf/components';
import { useAuth0 } from '@auth0/auth0-react';

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const ShareNavigationBar = styled.div`
  background-color: #13324c;
  min-height: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const LoginButton = styled(Button)`
  &&& {
    background-color: ${({ theme }) => `${theme.palette.blue.leaf}`};
    padding: ${({ theme }) => theme.spacing(1)};
    width: 160px;
    color: white;
    border-radius: 50px;
    :hover {
      opacity: 0.9;
    }
  }
`;

const GetStartedButton = styled(Button)`
  &&& {
    background-image: ${({ theme }) =>
      `linear-gradient(to right, ${theme.palette.blue.leaf}, ${theme.palette.green.leaf})`};
    padding: ${({ theme }) => theme.spacing(1)};
    width: 160px;
    color: white;
    border-radius: 50px;
    :hover {
      opacity: 0.9;
    }
  }
`;

export default withRouter(({ children, history }) => {
  const { loginWithRedirect, isAuthenticated } = useAuth0();

  const handleLogin = () => {
    if (!isAuthenticated) loginWithRedirect();

    history.push('/');
  };

  return (
    <Container>
      <ShareNavigationBar>
        <Box height={1}>
          <LeafLogoWhite width="110px" height="30px" />
        </Box>
        <Box display="flex" gap={2} pr={2}>
          <LoginButton variant="contained" onClick={handleLogin}>
            Login
          </LoginButton>
          <GetStartedButton
            variant="contained"
            onClick={() => {
              window.location = 'https://www.leaflogistics.com/get-started';
            }}
          >
            Get Started
          </GetStartedButton>
        </Box>
      </ShareNavigationBar>
      {children}
    </Container>
  );
});
