import { parseISO, parse, format, startOfDay } from 'date-fns';

export const API_FORMAT = 'yyyy-MM-dd HH:mm:ss.SSS';
export const VIEW_FORMAT = 'MM/dd/yyyy hh:mm aa';

// Warning this method returns different times for US and EU timezones.
export const formatBackendDatetime = backendValue => {
  if (!backendValue) {
    return '-';
  }
  return format(parseISO(backendValue), 'MM/dd/yyyy hh:mm aa');
};

// Used for Java >= 8
export const transformIsoDateTime = string => {
  if (string) {
    return parseISO(string);
  }
  return null;
};

// Used for Java < 8
export const backendStringToNormalizedUtcDateTime = (string, dateFormat = API_FORMAT) => {
  if (string) {
    return parse(string, dateFormat, new Date());
  }
  return null;
};

export const normalizedUtcDateToViewStringTime = date => {
  if (date) {
    return format(date, VIEW_FORMAT);
  }
  return '-';
};

export const setDateStartDay = date => {
  if (date) {
    if (typeof date === 'string') {
      return startOfDay(format(date, API_FORMAT));
    }
    return startOfDay(format(date, API_FORMAT));
  }
  return startOfDay(new Date());
};
