import { ArrowUpward } from '@mui/icons-material';
import React from 'react';
import styled from '@emotion/styled';

const IconWrapper = styled.div`
  svg {
    height: ${({ sizeProp }) => `${sizeProp}`};
    width: ${({ sizeProp }) => `${sizeProp}`};
    color: rgba(0, 0, 0, 0.54);
  }
`;

const PickupIcon = ({ size = 16 }) => (
  <IconWrapper sizeProp={size}>
    <ArrowUpward />
  </IconWrapper>
);

export default PickupIcon;
