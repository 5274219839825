import React, { PureComponent } from 'react';
import { Doughnut as ChartJSDoughnut } from 'react-chartjs-2';
import { merge } from 'lodash';

import ChartsDefaults from './chartsDefaults';

import './Charts.scss';

export default class extends PureComponent {
  constructor(props) {
    super(props);
    ChartsDefaults.doughnutChartInit(props);
  }

  getOptions() {
    return {
      elements: {
        arc: {
          borderWidth: 7,
        },
      },
      tooltips: { enabled: true },
      maintainAspectRatio: false,
      legend: !this.props.hideLegend
        ? merge(
            {
              position: 'bottom',
              labels: {
                boxWidth: 10,
                padding: 15,
                fontSize: 10,
                fontColor: '#333333',
                fontFamily: "'Avenir'",
              },
            },
            this.props.legend,
          )
        : undefined,
    };
  }

  render() {
    const { data, centeredText = undefined, ...rest } = this.props;
    return (
      <div style={{ position: 'relative', width: rest.width || '100%', height: rest.height || '100%' }}>
        <ChartJSDoughnut data={data} options={this.getOptions()} />
        {centeredText && <span className="chart-centered-text">{centeredText}</span>}
      </div>
    );
  }
}
