export const SET_SHIPMENTS_OVERVIEW_TABLE_STATE = 'set.shipments-overview.table.state';
export const SET_CONTRACT_SHIPMENTS_TABLE_STATE = 'set.contract-shipments.table.state';
export const SET_CONTRACT_TRIPS_TABLE_STATE = 'set.contract-trips.table.state';
export const SET_ADAPT_BOARD_LANES_TABLE_FILTER_STATE = 'set.adapt-board-lanes.table-filter.state';
export const SET_ADAPT_BOARD_LANE_PHASES_STATE = 'set.adapt-board-lanes-phases.table.state';
export const SET_ADAPT_BOARD_NETWORK_MOVES_TABLE_STATE = 'set.adapt-board-network-moves.table.state';
export const SET_ADAPT_BOARD_LANES_TABLE_STATE = 'set.adapt-board-lanes.table.state';
export const SET_SHIPPER_CONTRACTS_OVERVIEW_TABLE_STATE = 'set.shipper-contracts-overview.table.state';
export const SET_COMPANIES = 'set.companies.state';
export const CHANGE_STATE = 'CHANGE_STATE';

export default (state, action) => {
  switch (action.type) {
    case CHANGE_STATE: {
      return {
        ...state,
        [action.target]: action.payload,
      };
    }
    case SET_SHIPMENTS_OVERVIEW_TABLE_STATE: {
      return {
        ...state,
        tables: {
          ...state.tables,
          shipmentsOverview: action.payload,
        },
      };
    }
    case SET_CONTRACT_SHIPMENTS_TABLE_STATE: {
      return {
        ...state,
        tables: {
          ...state.tables,
          contractShipmentsOverview: action.payload,
        },
      };
    }
    case SET_CONTRACT_TRIPS_TABLE_STATE: {
      return {
        ...state,
        tables: {
          ...state.tables,
          contractTripsOverview: action.payload,
        },
      };
    }
    case SET_ADAPT_BOARD_LANES_TABLE_STATE: {
      return {
        ...state,
        tables: {
          ...state.tables,
          adaptBoardLanes: action.payload,
        },
      };
    }
    case SET_ADAPT_BOARD_LANES_TABLE_FILTER_STATE: {
      let filter = {};
      if (action.payload.length === 0) {
        const { phase, ...newFilter } = state.tables.adaptBoardLanes.filters;
        filter = { ...newFilter };
      } else {
        filter = { ...state.tables.adaptBoardLanes.filters, phase: action.payload };
      }
      return {
        ...state,
        tables: {
          ...state.tables,
          adaptBoardLanes: {
            ...state.tables.adaptBoardLanes,
            filters: {
              ...filter,
            },
          },
        },
      };
    }
    case SET_ADAPT_BOARD_LANE_PHASES_STATE: {
      return {
        ...state,
        lanePhases: {
          ...state.lanePhases,
          lanePhasesByShipper: action.payload,
        },
      };
    }
    case SET_ADAPT_BOARD_NETWORK_MOVES_TABLE_STATE: {
      return {
        ...state,
        tables: {
          ...state.tables,
          adaptBoardNetworkMoves: action.payload,
        },
      };
    }
    case SET_COMPANIES: {
      return {
        ...state,
        companies: action.payload,
      };
    }
    case SET_SHIPPER_CONTRACTS_OVERVIEW_TABLE_STATE: {
      return {
        ...state,
        tables: {
          ...state.tables,
          contractsOverview: action.payload,
        },
      };
    }
    default:
      throw new Error('Action not defined');
  }
};
