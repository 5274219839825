import React from 'react';
import { Typography, Box } from '@mui/material';
import styled from '@emotion/styled';
import { utility } from '..';

const PhaseBodyContrainer = styled(Box)`
  display: flex;
  flex-direction: column;
  margin-top: ${({ theme }) => theme.spacing(1)};
`;

const PhaseBodyItem = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: ${({ theme }) => theme.spacing(1)};
`;

const TypographyScoped = styled(Typography)`
  font-size: 13px;
  color: rgba(0, 0, 0, ${({ opacity }) => `${opacity}`});
`;

const PhasePanelBody = ({ phase }) => (
  <PhaseBodyContrainer>
    {phase.numberOfLanes !== undefined && (
      <PhaseBodyItem>
        <TypographyScoped variant="body1" opacity="0.84">
          Lanes
        </TypographyScoped>
        <TypographyScoped variant="body1" opacity="0.84">
          {utility.numbers.formatNumber(phase.numberOfLanes, 0, false)}
        </TypographyScoped>
      </PhaseBodyItem>
    )}
    {phase.networkMoves !== undefined && (
      <PhaseBodyItem>
        <TypographyScoped variant="body1" opacity="0.84">
          Network moves
        </TypographyScoped>
        <TypographyScoped variant="body1" opacity="0.84">
          {utility.numbers.formatNumber(phase.networkMoves, 0, false)}
        </TypographyScoped>
      </PhaseBodyItem>
    )}
    {phase.originDestinationFacilities !== undefined && (
      <PhaseBodyItem>
        <TypographyScoped variant="body1" opacity="0.84">
          Origin/destination facilities
        </TypographyScoped>
        <TypographyScoped variant="body1" opacity="0.84">
          {utility.numbers.formatNumber(phase.originDestinationFacilities, 0, false)}
        </TypographyScoped>
      </PhaseBodyItem>
    )}
    {phase.customers !== undefined && (
      <PhaseBodyItem>
        <TypographyScoped variant="body1" opacity="0.84">
          Customers
        </TypographyScoped>
        <TypographyScoped variant="body1" opacity="0.84">
          {utility.numbers.formatNumber(phase.customers, 0, false)}
        </TypographyScoped>
      </PhaseBodyItem>
    )}
    {phase.annualLoads !== undefined && (
      <PhaseBodyItem>
        <TypographyScoped variant="body1" opacity="0.84">
          Annual volume
        </TypographyScoped>
        <TypographyScoped variant="body1" opacity="0.84">
          {utility.numbers.formatNumber(phase.annualLoads, 0, false)}
        </TypographyScoped>
      </PhaseBodyItem>
    )}
    {phase.annualSpend !== undefined && (
      <PhaseBodyItem>
        <TypographyScoped variant="body1" opacity="0.84">
          Annualized spend
        </TypographyScoped>
        <TypographyScoped variant="body1" opacity="0.84">
          {utility.numbers.formatNumber(phase.annualSpend)}
        </TypographyScoped>
      </PhaseBodyItem>
    )}
    {phase.annualReduction !== undefined && (
      <PhaseBodyItem>
        <TypographyScoped variant="body1" opacity="0.84">
          Annualized C02 reduction (t)
        </TypographyScoped>
        <TypographyScoped variant="body1" opacity="0.84">
          {utility.numbers.formatNumber(phase.annualReduction, 0, false)}
        </TypographyScoped>
      </PhaseBodyItem>
    )}
    {phase.annualSavings !== undefined && (
      <PhaseBodyItem>
        <TypographyScoped variant="body1" opacity="0.84">
          Annualized cost savings ($)
        </TypographyScoped>
        <TypographyScoped variant="body1" opacity="0.84">
          {utility.numbers.formatNumber(phase.annualSavings)}
        </TypographyScoped>
      </PhaseBodyItem>
    )}
    {phase.annualEmptyMilesEliminated !== undefined && (
      <PhaseBodyItem>
        <TypographyScoped variant="body1" opacity="0.84">
          Annualized empty miles eliminated
        </TypographyScoped>
        <TypographyScoped variant="body1" opacity="0.84">
          {utility.format.miles(phase.annualEmptyMilesEliminated)}
        </TypographyScoped>
      </PhaseBodyItem>
    )}
    {phase.targetOntimeDelivery !== undefined && (
      <PhaseBodyItem>
        <TypographyScoped variant="body1" opacity="0.84">
          Target on-time delivery (%)
        </TypographyScoped>
        <TypographyScoped variant="body1" opacity="0.84">
          {`${phase.targetOntimeDelivery} %`}
        </TypographyScoped>
      </PhaseBodyItem>
    )}
  </PhaseBodyContrainer>
);

export default PhasePanelBody;
