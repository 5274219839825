import React from 'react';
import styled from '@emotion/styled';
import { Box, Typography } from '@mui/material';
import { grey } from '@mui/material/colors';
import { CancelRounded, Save, CheckCircle, AlarmOff, Lock } from '@mui/icons-material';

import {
  QUOTE_STATUS,
  QUOTE_STATUS_DISPLAY_NAMES,
  QUOTE_STATUS_DISPLAY_NAMES_WITH_ACTIVE,
} from '../constants/QuoteStatus';

const ContractedIcon = styled(CheckCircle)`
  color: #4a90e2;
`;
const ExpiredIcon = styled(AlarmOff)`
  color: ${grey[400]};
`;
const CanceledIcon = styled(CancelRounded)`
  color: ${grey[400]};
`;
const DraftIcon = styled(Save)`
  color: ${grey[400]};
`;
const BindingIcon = styled(Lock)`
  color: #4caf50;
`;

const IconWrapperSC = styled.div`
  margin-right: ${({ theme }) => `${theme.spacing(1)}`};
  svg {
    vertical-align: middle;
  }
`;

const QuoteStatusIcons = {
  [QUOTE_STATUS.CONTRACTED]: <BindingIcon />,
  [QUOTE_STATUS.EXPIRED]: <ExpiredIcon />,
  [QUOTE_STATUS.CANCELED]: <CanceledIcon />,
  [QUOTE_STATUS.DRAFT]: <DraftIcon />,
  [QUOTE_STATUS.BINDING]: <ContractedIcon />,
  [QUOTE_STATUS.NON_BINDING]: <ContractedIcon />,
};

/**
 * Componrnt: QuoteStatus
 *
 * @status {string}
 *
 * @returns
 * Returns chip based on status parameter that user provide.
 */
const QuoteStatus = ({ status }) => {
  const icon = QuoteStatusIcons[status];

  const getStatusName = () => {
    if (status === QUOTE_STATUS.BINDING || status === QUOTE_STATUS.NON_BINDING) {
      return QUOTE_STATUS_DISPLAY_NAMES_WITH_ACTIVE.ACTIVE;
    }
    if (QUOTE_STATUS_DISPLAY_NAMES[status]) return QUOTE_STATUS_DISPLAY_NAMES[status];

    return status;
  };

  return (
    <Box display="flex" alignItems="center">
      {icon && <IconWrapperSC>{icon}</IconWrapperSC>}
      <Typography variant="body1">{getStatusName()}</Typography>
    </Box>
  );
};

export default QuoteStatus;
