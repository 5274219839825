import React from 'react';

export default ({ size = 24 }) => (
  <svg width={size} height={size} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.66666 9.33341C7.24399 9.33341 9.33332 7.24408 9.33332 4.66675C9.33332 3.95102 9.1722 3.27292 8.88424 2.66675H11.3333V5.33341H13.3333L15.3333 8.00008V11.3334H14C14 12.4401 13.1067 13.3334 12 13.3334C10.8933 13.3334 9.99999 12.4401 9.99999 11.3334H5.99999C5.99999 12.4401 5.10666 13.3334 3.99999 13.3334C2.89332 13.3334 1.99999 12.4401 1.99999 11.3334H0.666656V7.07181C1.48325 8.42703 2.96911 9.33341 4.66666 9.33341ZM2.99999 11.3334C2.99999 11.8867 3.44666 12.3334 3.99999 12.3334C4.55332 12.3334 4.99999 11.8867 4.99999 11.3334C4.99999 10.7801 4.55332 10.3334 3.99999 10.3334C3.44666 10.3334 2.99999 10.7801 2.99999 11.3334ZM14.3067 8.00008L13 6.33341H11.3333V8.00008H14.3067ZM11 11.3334C11 11.8867 11.4467 12.3334 12 12.3334C12.5533 12.3334 13 11.8867 13 11.3334C13 10.7801 12.5533 10.3334 12 10.3334C11.4467 10.3334 11 10.7801 11 11.3334Z"
      fill="black"
      fillOpacity="0.54"
    />
    <path
      d="M4.66666 0.666748C2.45866 0.666748 0.666656 2.45875 0.666656 4.66675C0.666656 6.87475 2.45866 8.66675 4.66666 8.66675C6.87466 8.66675 8.66666 6.87475 8.66666 4.66675C8.66666 2.45875 6.87466 0.666748 4.66666 0.666748ZM1.46666 4.66675C1.46666 2.89875 2.89866 1.46675 4.66666 1.46675C5.40666 1.46675 6.08666 1.71875 6.62666 2.14275L2.14266 6.62675C1.71866 6.08675 1.46666 5.40675 1.46666 4.66675ZM4.66666 7.86675C3.92666 7.86675 3.24666 7.61475 2.70666 7.19075L7.19066 2.70675C7.61466 3.24675 7.86666 3.92675 7.86666 4.66675C7.86666 6.43475 6.43466 7.86675 4.66666 7.86675Z"
      fill="black"
      fillOpacity="0.54"
    />
  </svg>
);
