import { gql } from 'graphql-request';

export const getContractDetailsGQL = id =>
  gql`
    query getContractDetails {
      contract(where: { id: { _eq: "${id}" } }) {
        id
        contract_type
        is_fleet
        end_date
        include_weekends
        notes
        start_date
        contract_status
        volume_type
        volume_frequency
        max_volume
        min_volume
        equipment_type {
          name
        }
        pricing_mechanism
        linehaul_rpm
        pricing_index
        pricing_index_premium
        fuel_surcharge_schedule {
          name
        }
        asset_pricing
        asset_pricing_frequency
        min_chargeable_miles
        min_chargeable_miles_frequency
        volume_pattern
      }
    }
  `;

export const getContractDetailsLaneDescriptionGQL = id =>
  gql`
    query getContractDetails {
      contract(where: { id: { _eq: "${id}" } }) {
        id
        buyerId: buyer_id
        sellerId: seller_id
        contractStatus: contract_status
        contractRoutes: contract_routes(where: {is_primary: {_eq: true}}) {
          route {
            legs(order_by: {sequence: asc}) {
              lane {
                destinationName: destination_name
                originName: origin_name
              }
            }
          }
        }
      }
    }
  `;

export const getContractRoutesGQL = id =>
  gql`
    query getContractRoutes {
      contract(where: { id: { _eq: "${id}" } }) {
        id
        contractRoutes: contract_routes {
          isPrimary: is_primary
          route {
            avgDeadheadMiles: avg_deadhead_miles
            avgTotalMiles: avg_total_miles
            tripType: trip_type
            routeId: id
            legs(order_by: {sequence: asc}) {
              deadheadMiles:deadhead_miles
              deadheadPath:deadhead_path
              lane {
                destinationName: destination_name
                destinationH3Cells: destination_h3
                originName: origin_name
                originH3Cells: origin_h3
                leafMiles: leaf_miles
                laneId: id
                pickupType: pickup_type
                deliveryType: delivery_type
                pickupStops: origin_stops
                deliveryStops: destination_stops
                destinationPoint: destination_point
                destinationGeo: destination_geo
                originGeo: origin_geo
                originPoint: origin_point
                pathGeo: path
                path
              }
            }
          }
        }
      }
    }
  `;
