import React from 'react';
import axios from 'axios';
import { useSnackbar } from 'notistack';

import { useLoading, LazySpinner } from '@leaf/components';

import AdaptCompanyData from 'domain/adapt/AdaptCompanyData';

import CompanyDashboard from 'company/company-dashboard';
import Page from '../components/Page';

const Company = ({ data: { company } }) => {
  const { enqueueSnackbar } = useSnackbar();

  const [accountPlanPhases, setAccountPlanPhases] = React.useState(null);
  const [adaptCompanyData, setAdaptCompanyData] = React.useState(null);

  const [getAccountPlanPhases, isAccountPlanPhasesLoading] = useLoading(
    async () =>
      axios
        .get(`view/account-plan-phases/shippers/${company.id}`)
        .then(({ accountPlanPhases: response }) => setAccountPlanPhases(response))
        .catch(() => setAccountPlanPhases([])),
    true,
  );

  const [getAdaptCompanyData, isAdaptCompanyDataLoading] = useLoading(async () =>
    AdaptCompanyData.read(company.id).then(setAdaptCompanyData).catch(enqueueSnackbar),
  );

  React.useEffect(() => {
    if (company?.id) {
      getAdaptCompanyData();
      getAccountPlanPhases();
    }
  }, [company]);

  if (isAdaptCompanyDataLoading || isAccountPlanPhasesLoading) {
    return <LazySpinner />;
  }
  return (
    <Page title="Company">
      <CompanyDashboard plans={accountPlanPhases} company={company} adapt={adaptCompanyData} />
    </Page>
  );
};

export default Company;
