import _ from 'lodash';

export default class WeeklyVolume {
  constructor(response) {
    Object.assign(this, {
      ...response,
      start_date: new Date(response.start_date),
      volume: _.toPairs(response.volume),
    });
  }

  static get = async (client, companyId, laneId) =>
    client
      .get(`/analytics/explorer/volumes/companies/${companyId}/lanes/${laneId}?agg=byWeek`)
      .then(({ weekly_volumes }) => {
        const equipmentClasses = Object.keys(weekly_volumes);
        return equipmentClasses.reduce(
          (acc, key) => {
            acc[key] = weekly_volumes[key].map(weeklyVolume => new WeeklyVolume(weeklyVolume));
            return acc;
          },
          { equipmentClasses },
        );
      });
}
