import { chartTypes, ORDER_QUERY_PARAM_SEPARATOR } from './Constants';
import { chartTypesDisplayNames } from './Constants/chartTypes';
import functions, { fnDisplayNames } from './Constants/functions';
import order, { orderDisplayNames } from './Constants/order';

export const mapFiltersOptions = values =>
  values?.map(f => {
    if (f?.guid) {
      return {
        key: f.guid,
        value: f.guid,
        text: f.guid,
      };
    }
    return {
      key: f?.toString(),
      value: f?.toString(),
      text: f?.toString(),
    };
  }) ?? [];

export const sortByTextField = (a, b) => a.text.localeCompare(b.text);

export const chartTypeOptions = Object.values(chartTypes)
  .map(t => ({
    key: t,
    value: t,
    text: chartTypesDisplayNames[t],
  }))
  .filter(t => t.value !== chartTypes.MAP)
  .sort(sortByTextField);

export const getOrderOptions = (selectedDimensions = [], selectedMetrics = [], metricsOptions, dimensionOptions) => {
  const ds = selectedDimensions
    .filter(d => d?.name)
    .map(({ name }) => ({
      key: name,
      value: name,
      text: dimensionOptions.find(({ value }) => value === name)?.text,
    }));
  const ms = selectedMetrics
    .filter(m => m?.name)
    .map(({ name, fn }) => {
      const metric = metricsOptions.find(m => m.name === name);
      return {
        key: name,
        value: `${name}${ORDER_QUERY_PARAM_SEPARATOR}${fn}`,
        text: `${metric?.displayName} - ${fn}`,
      };
    });
  return [...ds, ...ms];
};

export const orderOptions = Object.values(order).map(o => ({
  key: o,
  value: o,
  text: orderDisplayNames[o],
}));

export const required = value => (value ? undefined : 'Required');

export const minMaxNumber = (min, max) => value =>
  value <= max && value >= min ? undefined : `Must be between ${min} and ${max}`;

export const getSwapInputProps = (fields, index, swap, remove) => ({
  onDelete: () => remove(index, 1),
  onArrowUp: () => swap(index, index - 1),
  onArrowDown: () => swap(index, index + 1),
  hasDelete: index !== 0,
  hasArrowUp: index !== 0,
  hasArrowDown: index !== fields.length - 1,
});

export const getPivotDimensionOptions = (
  selectedRowDimensions = [],
  selectedColumnDimensions = [],
  dimensionOptions,
  self,
) => {
  const selectedDimensions = [...selectedRowDimensions, ...selectedColumnDimensions];
  const { name: selectedName } = self;
  const result = [
    ...(selectedName
      ? [
          {
            key: selectedName,
            value: selectedName,
            text: dimensionOptions.find(({ value }) => value === selectedName)?.text,
          },
        ]
      : []),
    ...dimensionOptions.filter(({ value }) => !selectedDimensions.map(({ name }) => name).includes(value)),
  ];
  return result;
};

export const fnOptions = Object.values(functions).map(fn => ({
  key: fn,
  value: fn,
  text: fnDisplayNames[fn],
}));
