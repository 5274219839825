import React from 'react';
import { Box, Typography } from '@mui/material';

import Progress from '../Progress';

const Completed = React.memo(({ value }) => (
  <Box width="100%">
    <Box display="flex" justifyContent="space-between" pb={1}>
      <Typography variant="body1">Completed</Typography>
      <Typography variant="caption">{`${value}%`}</Typography>
    </Box>
    <Progress value={value} />
  </Box>
));

export default Completed;
