/* eslint-disable import/no-cycle */
import chartTypes from './chartTypes';
import dimensions from './dimensions';
import metrics from './metrics';
import * as date from './date';
import filterTypes, { filterTypeDisplayNames } from './filterTypes';
import functions from './functions';
import * as widgetsConfig from './widgetsConfig/index';
import chartLayoutRestrictions from './chartLayoutRestrictions';
import { formattingTypes } from '../../utility/formattingHelper';
import fontVariants from './fontVariants';

export {
  chartTypes,
  dimensions,
  filterTypes,
  filterTypeDisplayNames,
  functions,
  metrics,
  widgetsConfig,
  chartLayoutRestrictions,
  formattingTypes,
  date,
  fontVariants,
};

export const ORDER_QUERY_PARAM_SEPARATOR = '|';
export const LOCAL_FILTERS_QUERY_PARAM_SEPARATOR = ';';

export const queryStringOptions = { parseBooleans: true };

export const defaultColorOption = { from: 0, color: '#67a23f' };

export const companyDashboardNames = {
  SHIPPER: 'companyShipper',
  CARRIER: 'companyCarrier',
};

export const draftWidgetTypes = {
  NEW: 'new',
  EDIT: 'edit',
};
