import React from 'react';
import { Card, CardContent, CardHeader, Divider } from '@mui/material';
import { CompanyAvatar, DoughnutChart, OverviewListRow } from '@leaf/components';
import styled from '@emotion/styled';
import { StopRounded } from '@mui/icons-material';
import PropTypes from 'prop-types';
import { ChartSectionColors } from './ChartSections';

const CompanyAvatarWrapper = styled.div`
  ${({ theme }) => `
		margin: ${theme.spacing(1)};
	`}
`;

export const ChartWrapperLg = styled.div`
  width: 500px;
  height: 243px;
  margin: 16px auto;
`;

const SummaryContainer = styled.div`
  display: flex;
  & > div {
    width: 50%;
  }
`;

const StopRoundedSC = styled(StopRounded)`
  color: ${({ iconColor }) => iconColor};
`;

const ListRowContainer = styled.div`
  display: flex;
  p {
    font-weight: 500;
  }
`;

const CardHeaderSC = styled(CardHeader)`
  .MuiCardHeader-title {
    font-size: 1rem;
  }
`;

const ShipmentInvoiceSummary = ({ invoiceSummary }) => (
  <Card>
    <CardHeaderSC
      title="Invoice summary"
      action={
        <CompanyAvatarWrapper>
          <CompanyAvatar name="Shipper 1" />
        </CompanyAvatarWrapper>
      }
    />
    <CardContent>
      <SummaryContainer>
        <div>
          <ListRowContainer>
            <StopRoundedSC iconColor={ChartSectionColors.Linehaul} />
            <OverviewListRow label="Linehaul" value={invoiceSummary.linehaul} />
          </ListRowContainer>
          <ListRowContainer>
            <StopRoundedSC iconColor={ChartSectionColors['Fuel surcharge']} />
            <OverviewListRow label="Fuel surcharge" value={invoiceSummary.fuel} />
          </ListRowContainer>
          <ListRowContainer>
            <StopRoundedSC iconColor={ChartSectionColors.Accessorials} />
            <OverviewListRow label="Accessorials" value={invoiceSummary.accessorial} />
          </ListRowContainer>
          <ListRowContainer>
            <StopRoundedSC iconColor={ChartSectionColors['Transactional fee']} />
            <OverviewListRow label="Transactional fee" value={invoiceSummary.fee} />
          </ListRowContainer>
          <Divider />
          <ListRowContainer>
            <OverviewListRow label="TOTAL" value={invoiceSummary.total} />
          </ListRowContainer>
        </div>
        <div>
          <ChartWrapperLg>
            <DoughnutChart
              data={{
                labels: Object.keys(ChartSectionColors),
                values: [invoiceSummary.linehaul, invoiceSummary.fuel, invoiceSummary.accessorial, invoiceSummary.fee],
                colors: Object.keys(ChartSectionColors).map(data => ChartSectionColors[data]),
              }}
              hideLegend
            />
          </ChartWrapperLg>
        </div>
      </SummaryContainer>
    </CardContent>
  </Card>
);

ShipmentInvoiceSummary.propTypes = {
  invoiceSummary: PropTypes.object.isRequired,
};

export default ShipmentInvoiceSummary;
