import React from 'react';
import { v4 } from 'uuid';
import { Typography, Box } from '@mui/material';
import { TRIP_TYPE, TripType, Lane, utility, LeafLogo, LANE_TYPE } from '@leaf/components';

import styled from '@emotion/styled';

const TableCellWrapper = styled.div`
  text-align: right;
`;

const LaneWrapper = styled.div`
  margin-bottom: ${({ isSingleRow, theme }) => `${!isSingleRow ? theme.spacing(1) : 0}`};
  .MuiTypography-body2 {
    text-overflow: ellipsis;
    overflow: hidden;
    width: 220px;
    white-space: nowrap;
  }
`;

const MOVE_TYPES_MAPPED = {
  'TRIANGULAR CIRCUIT': TRIP_TYPE.CIRCUIT,
  'ROUND TRIP': TRIP_TYPE.ROUND_TRIP,
  'CM': TRIP_TYPE.CONTINUOUS_MOVE,
  'SHORTY': TRIP_TYPE.SHORTY,
};

export const getColumns = filterOptions => [
  {
    label: 'Type',
    name: 'view.moveType',
    field: 'moveType',
    options: {
      ...filterOptions.moveType,
      customBodyRender: ({ type, isExternal }) => {
        const tripType = MOVE_TYPES_MAPPED[type?.toUpperCase()];
        return tripType ? (
          <div>
            {isExternal && (
              <Box display="flex" alignItems="center" gap={8}>
                <LeafLogo size={15} />
                <Typography variant="body2">NETWORK</Typography>
              </Box>
            )}
            <TripType type={tripType} />
          </div>
        ) : (
          type
        );
      },
    },
  },
  {
    label: 'ID',
    name: 'networkMoveId',
    options: {
      display: false,
      filter: false,
    },
  },
  {
    label: 'Shipper',
    name: 'shipperName',
    options: {
      display: false,
      filter: false,
    },
  },
  {
    label: 'Lanes',
    name: 'view.lanes',
    field: 'l0Name',
    options: {
      filter: false,
      customBodyRender: lanes =>
        lanes
          ?.filter(item => item?.lane)
          .map(({ lane }) => (
            <LaneWrapper key={v4()} isSingleRow={lanes.length === 1}>
              <Lane
                displayAsGrid
                origin={{ primaryContent: [lane?.split('-')[0] ?? '-'] }}
                destination={{ primaryContent: [lane?.split('-')[1] ?? '-'] }}
                type={LANE_TYPE.POWER}
              />
            </LaneWrapper>
          )),
    },
  },
  {
    label: 'Notes',
    name: 'notes',
    options: { display: false, filter: false },
  },
  {
    label: 'Annual loads',
    name: 'annualLoads',
    options: {
      display: false,
      filter: false,
    },
  },
  {
    label: 'Low volume legs',
    name: 'view.lowLegsCount',
    options: {
      display: 'excluded',
      ...utility.table.rightAlignColumnProps(),
    },
  },
  {
    label: 'Equipment type',
    name: 'equipmentClass',
    options: {
      ...filterOptions.equipmentClass,
    },
  },
  {
    label: 'Annualized Cost Savings',
    name: 'benefit',
    field: 'benefit',
    options: {
      display: 'excluded',
      customBodyRender: benefit => <TableCellWrapper>{utility.table.signed(benefit)}</TableCellWrapper>,
    },
  },
  {
    label: 'Annual Cost Savings',
    name: 'margin',
    field: 'margin',
    options: {
      filter: false,
      display: false,
      customBodyRender: margin => <TableCellWrapper>{utility.table.signed(margin)}</TableCellWrapper>,
    },
  },
  {
    label: 'Total miles per trip',
    name: 'view.totalMiles',
    field: 'totalMiles',
    options: {
      filter: false,
      ...utility.table.rightAlignColumnProps(),
    },
  },
  {
    label: 'Possible annual trips',
    name: 'view.minTrips',
    field: 'minTrips',
    options: { filter: false, ...utility.table.rightAlignColumnProps() },
  },
  {
    label: 'Balance Type',
    name: 'balanceType',
    options: { display: 'excluded', filter: false },
  },
  {
    label: 'Complexity',
    name: 'complexity',
    options: { display: 'excluded', filter: false, ...utility.table.rightAlignColumnProps() },
  },
  {
    label: 'Loads impacted',
    name: 'view.loadsImpacted',
    options: {
      display: false,
      filter: false,
      ...utility.table.rightAlignColumnProps(),
    },
  },
  {
    label: 'Cost reduction',
    name: 'view.costReduction',
    options: {
      display: false,
      filter: false,
      ...utility.table.rightAlignColumnProps(),
    },
  },
  {
    label: 'CO2 reduction',
    name: 'view.CO2Reduction',
    options: {
      display: false,
      filter: false,
      ...utility.table.rightAlignColumnProps(),
    },
  },
  {
    label: 'Network type',
    name: 'networkType',
    options: {
      display: false,
      ...filterOptions.networkType,
    },
  },
  {
    label: 'Phase',
    name: 'phase',
    options: {
      display: false,
      filter: false,
    },
  },
  {
    label: 'Start date',
    name: 'startDate',
    options: {
      display: false,
      filter: false,
    },
  },
  {
    label: 'Status',
    name: 'status',
    options: {
      display: false,
      filter: false,
    },
  },
];
