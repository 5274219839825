import React from 'react';

import { platformSettings } from '../../Common/Config';
import { formattingFns } from '../../../utility/formattingHelper';
import { numbers } from '../../../utility';
import { sharedChartPropTypes } from './types';

import './DashboardKpi.scss';
import './shared.scss';

const DashboardKpi = React.memo(({ data, config }) => {
  const fieldFormat = config.metrics[0].numberFormat;
  const formattingFn = formattingFns[fieldFormat] || (value => value);
  const valueToDisplay = fieldFormat ? formattingFn(data.metrics[0].values[0]) : data.metrics[0].values[0];
  const metricTwoValue = data.metrics[1]?.values[0];
  const metricTwoName = config.metrics[1]?.metric?.replaceAll('_', ' ');
  const metricTwoDescription = config.metrics[1]?.displayName;

  return (
    <div className="dashboard-kpi">
      <span className="dashboard-kpi__value">
        {valueToDisplay === null ? platformSettings.notAvailableDataString : valueToDisplay}
      </span>
      {metricTwoValue && (metricTwoDescription || metricTwoName) && (
        <span className="dashboard-kpi__second-value">
          {numbers.numberWithCommas(metricTwoValue)} {metricTwoDescription || metricTwoName}
        </span>
      )}
    </div>
  );
});

DashboardKpi.propTypes = sharedChartPropTypes;

export default DashboardKpi;
