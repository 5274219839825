import { utility } from '@leaf/components';
import axios from 'axios';
import { extractAddress } from 'shipments/utilities';

export default class ShipmentSimple {
  constructor({ origin, destination, ...response }) {
    Object.assign(this, {
      ...response,
    });

    const laneOrigin = extractAddress(origin);
    const laneDestination = extractAddress(destination);

    this.view = {
      originAddress: [
        `${laneOrigin.city}, ${laneOrigin.state} ${laneOrigin.zipCode}`,
        `${laneOrigin.businessName}, ${laneOrigin.street}`,
      ],
      destinationAddress: [
        `${laneDestination.city}, ${laneDestination.state} ${laneDestination.zipCode}`,
        `${laneDestination.businessName}, ${laneDestination.street}`,
      ],
      leg: this.legNumber ?? '-',
      pickupEarliest: utility.date.stringTo3LetterMonthDayAndYear(this.pickupAppointment),
      tripId: this.tripId || '-',
      carrier: this.carrierName
        ? {
            name: this.carrierName,
            image: this.carrierLogo,
          }
        : null,
      equipment: this.equipmentTypeName || '-',
      status: this.status || '-',
    };
  }

  static download = async (params, tableContext) => {
    if (tableContext?.isShipper) {
      return axios
        .get(`view/shipment-tenders/download-for-shippers?${utility.pagination.toServerSideParams(params)}`)
        .then(res => utility.table.triggerBrowserDownload(res, 'shipmentsTableDownload'));
    }
    return tableContext?.errorHandler('Operation not yet implemented');
  };
}
