import React from 'react';
import { Box, Typography } from '@mui/material';
import { applyIfNotNull, calcColorForValue } from './chartsHelper';
import { numbers as numbersService } from '../../../utility';
import { sharedChartPropTypes } from './types';
import DoughnutChart from '../../../DoughnutChart/DoughnutChart';
import styled from '@emotion/styled';
import { formatNumber } from '../../../utility/numbers';

const CenteredChartLabel = styled.div`
  top: 50%;
  left: 50%;
  display: flex;
  position: absolute;
  flex-direction: column;
  align-items: center;
  transform: translate(-50%, -25%);
  p {
    margin: 0;
  }
`;

const DashboardDonut = ({ data, config, metricsOptions = [] }) => {
  let sum = 0;
  const transformedData = {
    labels: data.metrics.map(
      ({ metric }, index) =>
        config.metrics[index]?.displayName || metricsOptions.find(m => m.name === metric)?.displayName,
    ),
    datasets: [
      data.metrics.reduce((dataset, metric, index) => {
        sum += metric.values.reduce((acc, value) => acc + parseFloat(value), 0);
        const colors = config.metrics[index] && config.metrics[index].colors;
        const color = calcColorForValue(0, colors);
        // eslint-disable-next-line no-param-reassign
        dataset = {
          data: [
            ...(dataset.data || []),
            ...(metric.values?.map(v => applyIfNotNull(v, numbersService.numberAsStringWithCommaToFloat)) || []),
          ],
          backgroundColor: [...(dataset.backgroundColor || []), color],
          borderColor: [...(dataset.borderColor || []), color],
        };
        return dataset;
      }, {}),
    ],
  };
  return (
    <Box height={1} m={1}>
      <DoughnutChart shouldTransformData={false} data={transformedData} />
      <CenteredChartLabel>
        {sum > 0 && <Typography variant="h3">{formatNumber(sum, null)}</Typography>}
      </CenteredChartLabel>
    </Box>
  );
};

DashboardDonut.propTypes = sharedChartPropTypes;

export default DashboardDonut;
