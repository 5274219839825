import React from 'react';
import classNames from 'classnames';
import { Close } from '@mui/icons-material';
import { IconButton } from '@mui/material';

import './HeaderPanel.scss';

export default React.memo(({ children, className, hasTopBorder }) => (
  <div className={classNames('header-panel', className, { 'header-panel--top-border': hasTopBorder })}>{children}</div>
));

export const HeaderPanelLeft = React.memo(({ children, className, hasLargeMargin }) => (
  <div className={classNames('header-panel-left', className, { 'header-panel-left--large-padding': hasLargeMargin })}>
    {children}
  </div>
));

export const HeaderPanelCenter = React.memo(({ children, className }) => (
  <div className={`header-panel-center ${className || ''}`}>{children}</div>
));

export const HeaderPanelRight = React.memo(({ children, className, hasCloseButton = false, onCloseButtonClick }) => (
  <div className={`header-panel-right ${className || ''}`}>
    {children}
    {hasCloseButton && (
      <IconButton onClick={onCloseButtonClick} size="large">
        <Close title="Close" />
      </IconButton>
    )}
  </div>
));

export const HeaderPanelTitle = React.memo(
  ({ hasIcon, title, isMedium = false, isSmall = false, className, onIconClick }) => (
    <div className={`header-panel-title ${className || ''}`}>
      {hasIcon && (
        <IconButton onClick={onIconClick} size="large">
          <Close />
        </IconButton>
      )}
      <span
        className={classNames('header-panel-title-title', {
          'header-panel-title-title--medium': isMedium,
          'header-panel-title-title--small': isSmall,
        })}
      >
        {title}
      </span>
    </div>
  ),
);
