import { useLocation, NavLink, useHistory } from 'react-router-dom';
import {
  ExitToApp,
  Dashboard as DashboardIcon,
  LocalShipping as ShipmentsIcon,
  Explore as AdaptIcon,
  Business as CompanyIcon,
  Help as HelpIcon,
  AssignmentTurnedIn as ContractsIcon,
} from '@mui/icons-material';
import { useContext, useMemo } from 'react';
import { useAuth0 } from '@auth0/auth0-react';

import { USER_TYPE } from '@leaf/components';

import { removeImpersonatedCompanyId, UserContext } from 'authentication';
import { StateContext } from 'state/StateProvider';

export default () => {
  const location = useLocation();
  const history = useHistory();
  const { currentUser } = useContext(UserContext);
  const { state } = useContext(StateContext);
  const { logout } = useAuth0();

  let items = [
    {
      to: '/dashboard',
      text: 'Dashboard',
      icon: DashboardIcon,
      link: NavLink,
      location,
    },
    {
      to: '/contracts',
      text: 'Contracts',
      icon: ContractsIcon,
      link: NavLink,
      location,
    },
    {
      to: '/shipments',
      icon: ShipmentsIcon,
      text: 'Shipments',
      link: NavLink,
      location,
    },
    {
      to: '/help',
      text: 'Help',
      icon: HelpIcon,
      link: NavLink,
      location,
    },
    {
      text: 'LOGOUT',
      icon: ExitToApp,
      click: () => {
        removeImpersonatedCompanyId();
        logout({
          returnTo: window.location.origin,
        });
      },
    },
  ];

  if (currentUser?.company?.type === USER_TYPE.SHIPPER && state.adapt) {
    items = [
      {
        to: '/company',
        text: 'Company',
        icon: CompanyIcon,
        link: NavLink,
        location,
      },
      {
        to: '/adapt-board',
        text: 'Adapt',
        icon: AdaptIcon,
        link: NavLink,
        location,
      },
      ...items,
    ];
  }
  return useMemo(() => items, [currentUser, history]);
};
