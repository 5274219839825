import { accessorials } from './accessorials';
import { fuelSurcharge } from './fuelSurcharge';
import { leafForwardAgreement } from './leafForwardAgreement';
import { privacyPolicy } from './privacyPolicy';
import { termsAndConditions } from './termsAndConditions';
import { trendingRules } from './trendingRules';
import { releaseNotes } from './releaseNotes';

export const getHelpContent = fileName => {
  switch (fileName) {
    case 'trading_rules':
      return trendingRules;
    case 'accessorials':
      return accessorials;
    case 'fuel_surcharge':
      return fuelSurcharge;
    case 'leaf_forward_agreement':
      return leafForwardAgreement;
    case 'privacy_policy':
      return privacyPolicy;
    case 'terms_and_conditions':
      return termsAndConditions;
    case 'release_notes':
      return releaseNotes;
    default:
      return '';
  }
};
