import React, { useContext } from 'react';
import { useSnackbar } from 'notistack';
import { withRouter } from 'react-router-dom';

import {
  ServerSideTable,
  useTableCustomFilters,
  PageContentLayout,
  PageContainer,
  useCopyToClipboard,
  TRIP_TYPE_DISPLAY_NAMES,
  CONTRACT_TYPE,
  CONTRACT_TYPE_DISPLAY_NAMES,
  Beta,
} from '@leaf/components';

import { SET_SHIPPER_CONTRACTS_OVERVIEW_TABLE_STATE } from 'state/stateReducer';
import { StateContext } from 'state/StateProvider';
import Page from 'components/Page';

import useGQL from 'hooks/useGQL';
import { UserContext } from 'authentication';
import { getAccessorials, getContracts, getEquipmentTypes, contractsQuickFilters } from './domain/contractModel';
import { getColumns, getOptions, getQuickFilters } from './table-config';

export default withRouter(({ history }) => {
  const {
    state: {
      tables: { contractsOverview: tableState },
    },
    dispatch,
  } = useContext(StateContext);

  const { currentUser } = useContext(UserContext);
  const company = currentUser?.company;

  const getGQLClient = useGQL();
  const { enqueueSnackbar } = useSnackbar();

  const copy = useCopyToClipboard(
    successMessage => enqueueSnackbar(successMessage, { variant: 'success' }),
    errorMessage => enqueueSnackbar(errorMessage, { variant: 'error' }),
  );

  const [filterOptions] = useTableCustomFilters([
    {
      inputType: 'autocomplete',
      columnName: 'equipmentType',
      filterOptionsGetter: () => getEquipmentTypes(getGQLClient),
    },
    {
      inputType: 'autocomplete',
      columnName: 'accessorials',
      filterOptionsGetter: () => getAccessorials(getGQLClient),
    },
    {
      inputType: 'autocomplete',
      columnName: 'tripTypes',
      filterOptionsGetter: () =>
        Promise.resolve(
          Object.keys(TRIP_TYPE_DISPLAY_NAMES).map(key => ({
            label: TRIP_TYPE_DISPLAY_NAMES[key],
            value: key,
          })),
        ),
    },
    {
      inputType: 'autocomplete',
      columnName: 'contractType',
      filterOptionsGetter: () =>
        Promise.resolve(
          Object.keys(CONTRACT_TYPE).map(key => ({
            label: CONTRACT_TYPE_DISPLAY_NAMES[key],
            value: key,
          })),
        ),
    },
    {
      inputType: 'daterange',
      columnName: 'rangeDate',
    },
  ]);

  const handleChange = setTableState =>
    dispatch({ type: SET_SHIPPER_CONTRACTS_OVERVIEW_TABLE_STATE, payload: setTableState(tableState) });

  return (
    <Page title="Contracts" ComponentTitleRight={<Beta />}>
      <PageContainer>
        <PageContentLayout hasSubheader={false}>
          <ServerSideTable
            options={getOptions(history)}
            columns={getColumns(filterOptions, company?.type, copy)}
            fn={() => getContracts(getGQLClient, tableState, false, company)}
            quickFilterComponents={getQuickFilters()}
            gqlQuickFiltersMeta={contractsQuickFilters}
            tableState={tableState}
            setTableState={handleChange}
            errorHandler={enqueueSnackbar}
          />
        </PageContentLayout>
      </PageContainer>
    </Page>
  );
});
