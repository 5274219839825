import { defaults } from 'react-chartjs-2';

class ChartsDefaults {
  static init() {
    defaults.global.defaultFontFamily = "'Avenir', 'Open sans', sans-serif";
    defaults.global.defaultFontSize = 10;
    defaults.global.defaultFontStyle = 'normal';
    defaults.global.defaultFontColor = '#ccc';
  }

  static barChartInit() {
    defaults.scale.gridLines.color = '#e5e5e5';
    defaults.scale.gridLines.zeroLineColor = '#e5e5e5';
    defaults.scale.gridLines.drawBorder = false;
  }

  static lineChartInit() {
    defaults.global.elements.line.tension = 0;
    defaults.global.elements.line.borderCapStyle = 'butt';
    defaults.global.elements.line.borderDashOffset = 0.0;
    defaults.global.elements.line.borderJoinStyle = 'miter';
    defaults.global.elements.line.pointBackgroundColor = '#fff';
    defaults.global.elements.line.pointHoverRadius = 5;
    defaults.global.elements.line.pointHoverBorderWidth = 2;
    defaults.global.elements.line.pointBorderWidth = 0;
    defaults.global.elements.line.pointRadius = 0;
    defaults.global.elements.line.pointHitRadius = 10;
    defaults.global.elements.line.borderDash = [];
    defaults.global.elements.line.borderDash = [];
  }

  static doughnutChartInit(props) {
    defaults.doughnut.cutoutPercentage = props.cutoutPercentage || 88;
    defaults.doughnut.rotation = 0;
  }
}

export default ChartsDefaults;
