import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { Box } from '@mui/material';

const Center = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
`;

export default () => {
  const [isHidden, setIsHidden] = useState(true);
  useEffect(() => {
    // Added timeout to wait for /me EP to return response without flickering the 404 screen
    setTimeout(() => {
      setIsHidden(false);
    }, 500);
  }, []);
  return (
    !isHidden && (
      <Center>
        <h1>404</h1>
        <p>This is not the web page you are looking for.</p>
      </Center>
    )
  );
};
