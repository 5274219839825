/* eslint-disable no-restricted-globals */
import {
  addMinutes,
  subMonths,
  addMonths,
  format,
  parseISO,
  differenceInWeeks,
  differenceInDays,
  isValid,
  isAfter,
  isEqual,
  isDate,
  addDays,
  startOfWeek,
  endOfWeek,
  getDate,
  getDay,
  addYears,
  subWeeks,
  getMonth,
  getMinutes,
  isSameYear,
  isBefore,
} from 'date-fns';

export const VIEW_FORMAT = 'MM/dd/yyyy';
export const VIEW_DATE_FORMAT = 'MMM dd, yyyy';
export const VIEW_DATE_TIME_FORMAT = 'MM/dd/yyyy hh:mm a';
export const DATE_BACKEND_FORMAT = 'yyyy-MM-dd';

const PLACEHOLDER = '-';

const isServerDate = date => {
  if (typeof date === 'string') {
    const [year, month, day] = date.split('-');
    return month !== undefined && day !== undefined && year?.length === 4;
  }
  return false;
};

// GMT+x subtracts and GMT-x adds x hours in minutes.
const normalize = date => addMinutes(date, date.getTimezoneOffset());

// Pads a time unit with leading zero if single digit.
const pad = u => u.toString().padStart(2, 0);

// Creates an UTC date from year, month and day.
const utc = (year, month, day) => new Date(Date.UTC(year, month, day));

// Returns current TZ-normalized UTC date.
export const normalizedUtcNow = () => {
  const raw = new Date();
  return normalize(utc(raw.getFullYear(), raw.getMonth(), raw.getDate()));
};

// Takes 'yyyy-MM-dd' string and returns a valid TZ-normalized UTC Javascript Date or null.
export const backendStringToNormalizedUtcDate = string => {
  if (string) {
    const [year, month, day] = string.split('-');
    return normalize(utc(year, month - 1, day));
  }
  return null;
};

// Takes TZ-normalized Javascript Date in UTC and returns a 'MM/dd/yyyy' string or placeholder if null.
export const normalizedUtcDateToViewString = (date, placeholder = PLACEHOLDER, twoDigitYear = false) => {
  if (date) {
    return `${pad(date.getMonth() + 1)}/${pad(date.getDate())}/${
      twoDigitYear ? date.getFullYear().toString().substr(-2) : date.getFullYear()
    }`;
  }
  return placeholder;
};

// Takes a 'yyyy-MM-dd' string and formats it to 'MM/dd/yyyy' or placeholder if null.
export const backendStringToViewString = (date, placeholder = PLACEHOLDER) => {
  if (date) {
    const [year, month, day] = date.split('-');
    return `${month}/${day}/${year}`;
  }
  return placeholder;
};

// Takes a Javascript Date and returns it as string of format 'yyyy-MM-dd' or null.
export const transformApiDate = date => {
  if (isServerDate(date)) {
    return date;
  }
  if (date && !isNaN(date.getDate())) {
    return format(date, DATE_BACKEND_FORMAT);
  }
  return null;
};

export const subtractDaysFromDate = (date, days) => {
  const result = new Date(date);
  result.setDate(result.getDate() - days);
  return result;
};

export const subtractWeeksFromDate = (date, numWeeks) => subtractDaysFromDate(date, numWeeks * 7);

export const subtractMonthsFromDate = (date, numMonths) => subMonths(date, numMonths);

export const addMonthsFromDate = (date, numMonths) => addMonths(date, numMonths);

export const getYear = date => date.getFullYear();

export const to3LetterMonthAndDay = date => format(normalize(date), 'MMM d');

export const to3LetterMonthDayAndYear = date => `${to3LetterMonthAndDay(date)}, ${getYear(date)}`;

export const stringTo3LetterMonthDayAndYear = stringDate =>
  stringDate ? to3LetterMonthDayAndYear(normalize(new Date(stringDate))) : '-';

export const stringToBackendFormat = stringDate => (stringDate ? format(parseISO(stringDate), 'yyyy-MM-dd') : '-');

export const to3LetterMonthAndYear = date => format(normalize(date), 'MMM `yy');

export const to3LetterMonthAndFullYear = date => {
  if (!date) {
    return undefined;
  }
  if (typeof date === 'string') {
    return format(normalize(new Date(date)), 'MMM yyyy');
  }
  return format(normalize(date), 'MMM yyyy');
};

export const to3LetterMonthAndShortYear = date => {
  if (!date) {
    return undefined;
  }
  if (typeof date === 'string') {
    return format(normalize(new Date(date)), 'MM/yy');
  }
  return format(normalize(date), 'MM/yy');
};

export const formatDate = (date, dateFormat) => format(date, dateFormat);

export const formatStringDate = (date, dateFormat) => format(parseISO(date), dateFormat);

export const getWeekDifference = (startDate, endDate) => {
  if (startDate && endDate) {
    if (typeof startDate === 'string' && typeof endDate === 'string') {
      return differenceInWeeks(parseISO(endDate), parseISO(startDate));
    }
    return differenceInWeeks(endDate, startDate);
  }

  return null;
};

export const isDateAfterNow = date => {
  if (isValid(date)) return isAfter(date, new Date());

  return undefined;
};

export const isDateAfter = (firstDate, secondDate) => {
  if (isValid(firstDate) && isValid(secondDate)) return isAfter(firstDate, secondDate);

  return false;
};

export const addDaysToDate = (date, days = 1) => {
  if (date) return addDays(date, days);

  return new Date();
};

export const getStartOfWeek = date => {
  if (date) {
    if (typeof date === 'string') return startOfWeek(parseISO(date));

    return startOfWeek(date);
  }

  return startOfWeek(new Date());
};

export const getEndOfWeek = date => {
  if (date) {
    if (typeof date === 'string') return endOfWeek(parseISO(date));

    return endOfWeek(date);
  }

  return endOfWeek(new Date());
};

export const getDayDifference = (dateFrom, dateTo) => {
  if (dateFrom && dateTo) {
    if (typeof startDate === 'string' && typeof dateTo === 'string') {
      return differenceInDays(parseISO(dateTo), parseISO(dateFrom));
    }
    return differenceInDays(dateTo, dateFrom);
  }

  return null;
};

export const convertToDate = date => {
  if (date) {
    if (typeof date === 'string') return parseISO(date);

    return date;
  }

  return new Date();
};

export const isEqualDates = (firstDate, secondData) => {
  if (firstDate && secondData) {
    if (typeof firstDate === 'string' && typeof secondData === 'string') {
      return isEqual(parseISO(firstDate), parseISO(secondData));
    }
    return isEqual(firstDate, secondData);
  }

  return false;
};

export const isDateValue = date => {
  if (date) {
    if (typeof date === 'string') {
      return isDate(parseISO(date));
    }
    return isDate(date);
  }

  return false;
};

export const getDayOfMonth = date => {
  if (date) {
    if (typeof date === 'string') {
      return getDate(parseISO(date));
    }
    return getDate(date);
  }

  return getDate(new Date());
};

export const isDateValid = date => isValid(date);

export const getDayOfWeek = date => {
  if (date) {
    if (typeof date === 'string') {
      return getDay(parseISO(date));
    }
    return getDay(date);
  }

  return getDay(new Date());
};

export const addYearsToDate = (date, years = 1) => {
  if (date) {
    if (typeof date === 'string') {
      return addYears(parseISO(date), years);
    }
    return addYears(date, years);
  }

  return addYears(new Date(), years);
};

export const subWeeksFromDate = (date, numOfWeeks = 1) => {
  if (date) {
    if (typeof date === 'string') {
      return subWeeks(parseISO(date), numOfWeeks);
    }
    return subWeeks(date, numOfWeeks);
  }

  return subWeeks(new Date(), numOfWeeks);
};

export const getMonthsFromDate = date => {
  if (date) {
    if (typeof date === 'string') {
      return getMonth(parseISO(date));
    }
    return getMonth(date);
  }

  return getMonth(new Date());
};

export const getMinutesFromDate = date => {
  if (date) {
    if (typeof date === 'string') {
      return getMinutes(parseISO(date));
    }
    return getMinutes(date);
  }

  return getMinutes(new Date());
};

export const isSameOrAfter = (firstDate, secondDate) => {
  if (firstDate && secondDate) {
    if (typeof firstDate === 'string' && typeof secondDate === 'string') {
      return isEqual(parseISO(firstDate), parseISO(secondDate)) || isAfter(parseISO(firstDate), parseISO(secondDate));
    }
    return isEqual(firstDate, secondDate) || isAfter(firstDate, secondDate);
  }

  return false;
};

export const isSameOrBefore = (firstDate, secondDate) => {
  if (firstDate && secondDate) {
    if (typeof firstDate === 'string' && typeof secondDate === 'string') {
      return isEqual(parseISO(firstDate), parseISO(secondDate)) || isBefore(parseISO(firstDate), parseISO(secondDate));
    }
    return isEqual(firstDate, secondDate) || isBefore(firstDate, secondDate);
  }

  return false;
};

export const isSameYearValue = (firstDate, secondDate) => {
  if (firstDate && secondDate) {
    if (typeof firstDate === 'string' && typeof secondDate === 'string') {
      return isSameYear(parseISO(firstDate), parseISO(secondDate));
    }
    return isSameYear(firstDate, secondDate);
  }

  return false;
};

export const isDateBefore = (firstDate, secondDate) => {
  if (isValid(firstDate) && isValid(secondDate)) return isBefore(firstDate, secondDate);

  return false;
};

export const addMinutesOnDateTime = (date, minuts) => {
  if (isValid(date)) return addMinutes(date, minuts);

  return null;
};
