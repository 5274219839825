import React, { useContext } from 'react';
import styled from '@emotion/styled';
import { Box, Typography } from '@mui/material';

import { CompanyAvatar } from '@leaf/components';

import { UserContext } from 'authentication/UserProvider';

const UserInfo = styled.div`
  margin-left: ${({ theme }) => theme.spacing(1)};
  text-transform: none;
`;

const CurrentUserInfo = () => {
  const { currentUser: user } = useContext(UserContext);

  return (
    <Box display="flex" alignItems="center">
      <CompanyAvatar name={user?.company?.name} id={user?.company?.id} />
      <UserInfo>
        <Typography variant="body2">
          {user?.firstName} {user?.lastName}
        </Typography>
        <Typography variant="body1">{user?.company?.name}</Typography>
      </UserInfo>
    </Box>
  );
};

export default CurrentUserInfo;
