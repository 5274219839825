import React from 'react';
import styled from '@emotion/styled';
import { Typography, Box, Divider } from '@mui/material';
import { v4 } from 'uuid';
import { utility, PhaseIcon, PhasePanelBody, PHASE_TYPE, PHASE_TYPE_DISPLAY_NAMES } from '..';

const PhasePanelContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${({ theme }) => theme.spacing(2)};
  background-color: ${({ $index }) => ($index % 2 === 0 ? '#f5f5f5' : '#fff')};
  border-bottom: ${({ $isLastElement }) => ($isLastElement ? '3px solid #0064b0' : 'none')};
`;

const PhaseHeaderContrainer = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: ${({ theme }) => theme.spacing(3)};
`;

const TypographyScoped = styled(Typography)`
  font-size: 13px;
  color: rgba(0, 0, 0, ${({ opacity }) => `${opacity}`});
`;

export const PhasePanel = ({ data = [] }) => (
  <>
    {data.length > 0 &&
      data?.map((item, index) => (
        <PhasePanelContainer key={v4()} $index={index} $isLastElement={index === data.length - 1}>
          <PhaseHeaderContrainer>
            {item.phase === PHASE_TYPE.UNASSIGNED || item.phase === PHASE_TYPE.OUT_OF_SCOPE ? (
              <PhaseIcon phaseName={PHASE_TYPE_DISPLAY_NAMES[item.phase]} />
            ) : (
              <PhaseIcon phaseName={item.phase} phaseIndex={index} />
            )}
            {item.startDate && (
              <TypographyScoped variant="body1" opacity="0.54">
                Starts {utility.date.formatDate(new Date(item.startDate), utility.date.VIEW_FORMAT) ?? '-'}
              </TypographyScoped>
            )}
          </PhaseHeaderContrainer>
          <Divider />
          <PhasePanelBody phase={item} />
        </PhasePanelContainer>
      ))}
  </>
);

export default PhasePanel;
