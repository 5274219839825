export const ORDER_STATUS = {
  CONTRACTED: 'CONTRACTED',
  IN_PLANNING: 'IN_PLANNING',
  EXPIRED: 'EXPIRED',
  READY_TO_CONTRACT: 'READY_TO_CONTRACT',
  DRAFT: 'DRAFT',
  CANCELED: 'CANCELED',
};

export const ORDER_STATUS_DISPLAY_NAMES = {
  EXPIRED: 'Expired',
  READY_TO_CONTRACT: 'Ready',
  CONTRACTED: 'Contracted',
  IN_PLANNING: 'In planning',
  DRAFT: 'Draft',
  CANCELED: 'Canceled',
};
