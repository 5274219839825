import React from 'react';
import { Typography, Chip } from '@mui/material';
import styled from '@emotion/styled';

const Beta = styled(Chip)`
  margin-left: ${({ theme }) => theme.spacing(2)};
`;

export default ({ title = 'BETA' }) => (
  <Beta label={<Typography variant="caption">{title}</Typography>} color="primary" size="small" />
);
