import { createTheme, adaptV4Theme } from '@mui/material/styles';

import { deepmerge } from '@mui/utils';

import createOverrides from './createOverrides';
import createPalette from './createPalette';
import createTypography from './createTypography';
import shadows from './shadows';

const palette = createPalette();
const typography = createTypography();

const leafTheme = deepmerge({
  palette,
  shadows,
  typography,
  overrides: createOverrides(typography, palette, shadows),
});

const createMyTheme = options =>
  createTheme(
    adaptV4Theme({
      ...leafTheme,
      ...options,
    }),
  );

export default createMyTheme({
  ...leafTheme,
});
