/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Box, Grid, Typography } from '@mui/material';
import { PageContentLayout, SpacedContainer } from '@leaf/components';
import styled from '@emotion/styled';

import ImpactObjectives from './impact-objectives/ImpactObjectives';
import CompanyMetrics from './company-metrics/CompanyMetrics';
import BudgetPortfolioContainer from './budget-portfolios/BudgetPortfolioContainer';
import ShipperNetworkMap from './shipperNetworkMap/ShipperNetworkMap';
import ShipperNetworkMapLegend from './shipperNetworkMap/ShipperNetworkMapLegend';

const Container = styled(Grid)`
  padding: ${({ theme }) => theme.spacing(1)};
`;

const ChartWidget = styled(Grid)`
  height: calc(100vh - 180px - 32px - 60px);
`;

const CompanyDetails = styled(Grid)`
  overflow-x: hidden;
  overflow-y: auto;
  height: calc(100vh - 76px);
`;

const MapHeader = styled.div`
  height: 60px;
  display: flex;
  align-items: center;
  padding-left: 24px;
  border-bottom: 1px solid #e5e5e5;
  padding-right: 24px;
  justify-content: space-between;
`;

const PageContentLayoutWrapper = styled(PageContentLayout)`
  overflow: hidden;
`;

export default ({ company, adapt, plans }) => (
  <SpacedContainer container style={{ margin: '8px' }}>
    <Grid item xs={3}>
      <Box m={-1}>
        <PageContentLayoutWrapper fullHeight={false}>
          <CompanyMetrics company={company} adapt={adapt} />
        </PageContentLayoutWrapper>
      </Box>
    </Grid>

    <CompanyDetails item xs={9}>
      <ImpactObjectives data={adapt.objectives} />

      <Container container spacing={2}>
        <ChartWidget item xs={6}>
          <BudgetPortfolioContainer data={plans} />
        </ChartWidget>

        <Grid item xs={6}>
          <Box boxShadow={2} height={1} bgcolor="white">
            <MapHeader>
              <Typography variant="h6">Transportation Grid</Typography>

              <ShipperNetworkMapLegend />
            </MapHeader>

            <ShipperNetworkMap />
          </Box>
        </Grid>
      </Container>
    </CompanyDetails>
  </SpacedContainer>
);
