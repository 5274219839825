import React, { PureComponent } from 'react';
import { merge } from 'lodash';

import { Bar as ChartJSBar } from 'react-chartjs-2';
import ChartsDefaults from './chartsDefaults';
import { showOnlyFirstAndLast, showAll } from './chartsHelper';
import { numbers } from '../../../../utility';

export default class extends PureComponent {
  constructor(props) {
    super(props);
    ChartsDefaults.init();
    ChartsDefaults.barChartInit();
  }

  calcOptions = (rest, optionsOverride) => {
    const optionsGeneratedFromProps = this.calcOptionsFromProps(rest);
    return merge(optionsGeneratedFromProps, optionsOverride || {});
  };

  calcOptionsFromProps(rest) {
    const legend = rest.hasLegend
      ? {
          position: 'bottom',
          labels: {
            boxWidth: 10,
            padding: 30,
            fontColor: '#333333',
          },
          onClick: false,
        }
      : { display: false };

    return {
      tooltips: {
        enabled: rest.isTooltipEnabled || false,
        intersect: false,
        position: 'nearest',
        mode: 'label',
        callbacks: {
          footer: tooltipItems => {
            const total = tooltipItems.reduce((a, e) => a + parseInt(e.yLabel, 10), 0);
            return `Total: ${numbers.formatNumberAsView(total, null, rest.shouldShowUnits)}`;
          },
        },
      },
      maintainAspectRatio: false,
      legend,
      scales: {
        yAxes: [
          {
            id: 'y',
            stacked: rest.isStacked,
            ticks: {
              beginAtZero: true,
              fontSize: rest.fontSize,
              fontColor: rest.yAxisFontColor,
              suggestedMax: rest.yAxisSuggestedMax || 150,
              stepSize: rest.yAxisStepSize,
              padding: rest.yAxisTicksPadding || 20,
            },
            gridLines: {
              display: true,
              color: rest.gridlLinesColor || '#e5e5e5',
              zeroLineColor: rest.gridlLinesColor || '#e5e5e5',
            },
          },
        ],
        xAxes: [
          {
            id: 'x',
            stacked: rest.isStacked,
            ticks: {
              fontSize: rest.fontSize,
              fontColor: rest.xAxisFontColor,
              padding: rest.xAxisTicksPadding || 0,
              callback: rest.doShowOnlyFirstAndLastXAxisValue ? showOnlyFirstAndLast : showAll,
            },
            gridLines: {
              display: true,
            },
            barPercentage: rest.xAxisBarPercentage || 0.45,
            categoryPercentage: 1,
          },
        ],
      },
    };
  }

  render() {
    const { data, options, ...rest } = this.props;
    return (
      <div style={{ position: 'relative', width: rest.width || '100%', height: rest.height || '100%' }}>
        <ChartJSBar
          width={rest.width ? rest.width : undefined}
          height={rest.height ? rest.height : undefined}
          options={this.calcOptions(rest, options)}
          data={data}
        />
      </div>
    );
  }
}
