import React, { useCallback } from 'react';
import { ChevronLeft } from '@mui/icons-material';
import { IconButton } from '@mui/material';

/**
 * Componrnt: BackButton
 *
 * @param {object} history
 *
 * @returns component for going back from details page
 */
const BackButton = ({ history, path, onClick }) => {
  const handleClick = useCallback(() => {
    if (onClick) onClick();

    if (path) {
      history.push(path);
    } else {
      history.goBack();
    }
  }, []);

  return (
    <div>
      <IconButton onClick={handleClick} size="large">
        <ChevronLeft />
      </IconButton>
    </div>
  );
};

export default BackButton;
