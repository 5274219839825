import React from 'react';

import { Typography } from '@mui/material';
import styled from '@emotion/styled';
import { CircularProgressBar } from '../../Common/Components';
import { calcColorForValue } from './chartsHelper';
import { sharedChartPropTypes } from './types';
import { platformSettings } from '../../Common/Config';

import './DashboardCircularProgressBar.scss';
import './shared.scss';

import { numbers } from '../../../utility';

const ShippmentsDataWrapper = styled(Typography)`
  position: absolute;
  top: 80%;
  left: 3%;
  font-family: Avenir;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 130%;
  color: rgba(0, 0, 0, 0.54);
`;

const textForPercentage = v => `${v}%`;
const textForNotAvialable = () => platformSettings.notAvailableDataString;

const DashboardCircularProgressBar = React.memo(({ data, config }) => {
  const value = data.metrics[0].values[0];
  const valueMetricTwo = data.metrics[1]?.values[0];
  const metricNameTwo = data.metrics[1]?.metric.replaceAll('_', ' ');
  const metricDescriptionNameTwo = config.metrics[1]?.displayName;
  const isNotAvailable = value === null || value === 'null';
  const intValue = isNotAvailable ? 0 : parseInt(value);

  return (
    <div className="dashboard-circular-progress-bar">
      <div className="dashboard-circular-progress-bar__doughnut">
        <CircularProgressBar
          width={1}
          height={1}
          strokeWidth={3}
          percentage={intValue}
          textForPercentage={isNotAvailable ? textForNotAvialable : textForPercentage}
          strokeColor={calcColorForValue(intValue, config.metrics[0].colors)}
        />
        {valueMetricTwo && (metricDescriptionNameTwo || metricNameTwo) && (
          <ShippmentsDataWrapper variant="body1">{`${numbers.numberWithCommas(valueMetricTwo)} ${
            metricDescriptionNameTwo || metricNameTwo
          }`}</ShippmentsDataWrapper>
        )}
      </div>
    </div>
  );
});

DashboardCircularProgressBar.propTypes = sharedChartPropTypes;

export default React.memo(DashboardCircularProgressBar);
