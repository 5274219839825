/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { TextField } from '@mui/material';

import { Controller } from 'react-hook-form';
import styled from '@emotion/styled';
import { Autocomplete } from '../generic';

const InputField = styled(TextField)`
  .MuiOutlinedInput-root {
    padding: 0 9px;
  }
`;

export default ({
  name,
  label,
  defaultValue,
  control,
  rules,
  noOptionsText = 'No options',
  inputProps,
  error,
  ...autocompleteProps
}) => (
  <Controller
    render={({ onChange, ...renderProps }) => (
      <Autocomplete
        renderInput={params => (
          <InputField
            {...params}
            {...inputProps}
            variant="outlined"
            label={label}
            required={rules?.required}
            error={error}
          />
        )}
        onChange={(event, data) => onChange(data)}
        noOptionsText={noOptionsText}
        {...renderProps}
        {...autocompleteProps}
      />
    )}
    onChange={([, data]) => data}
    defaultValue={defaultValue}
    name={name}
    control={control}
    rules={rules}
  />
);
