import _ from 'lodash';
import axios from 'axios';

export default class Dashboard {
  constructor({ ...response }) {
    Object.assign(this, {
      ...response,
    });
  }

  static getDashboardConfig = dashboardName => axios.get(`dashboard/client/dashboard-config/${dashboardName}`);

  static updateDashboardConfig = (dashboardName, config) =>
    axios.put(`dashboard/client/dashboard-config/${dashboardName}`, config);

  static saveDashboardConfig = config => axios.post(`dashboard/client/dashboard-config`, config);

  static getWidgetData = queryString => axios.get(`dashboard/client/query?${queryString}`);

  static getEquipmentFilter = userType =>
    axios.get('dashboard/client/filters/equipment-types', {
      headers: {
        'Company-Type': userType?.toUpperCase(),
      },
    });

  static getNetworkMovesFilter = userType =>
    axios
      .get('dashboard/client/filters/network-moves', {
        headers: {
          'Company-Type': userType?.toUpperCase(),
        },
      })
      .then(res =>
        res?.map(({ networkMove, networkMoveIds }) => ({
          name: networkMove,
          guid: networkMoveIds,
        })),
      );

  static getCounterpartyFilter = userType =>
    axios.get('dashboard/client/filters/counter-parties', {
      headers: {
        'Company-Type': userType?.toUpperCase(),
      },
    });

  static getLaneFilter = userType =>
    axios.get('dashboard/client/filters/lanes', {
      headers: {
        'Company-Type': userType?.toUpperCase(),
      },
    });

  static getReportingLaneFilter = userType =>
    axios.get('dashboard/client/filters/reporting-lanes', {
      headers: {
        'Company-Type': userType?.toUpperCase(),
      },
    });

  static getMetrics = () => axios.get('dashboard/client/metrics');

  static getDimensions = () => axios.get('dashboard/client/dimensions');

  static getFilterValues = filterName =>
    axios.get(`dashboard/client/filters/dimensions/${filterName}`).then(res => {
      if (!Array.isArray(res)) {
        return [];
      }
      return res.map(({ [filterName]: name, guid }) => guid ?? name) ?? [];
    });

  static getFilterOptions = () => axios.get('dashboard/client/filters');

  // utility functions

  static extractField = (response, field) => {
    if (!response) {
      return [];
    }
    const list = response.map(r => r[field]);
    list.sort((a, b) => a.localeCompare(b));
    return _.sortedUniq(list); // to remove duplicates for origin and destination
  };
}
