/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Button, Chip, ToggleButtonGroup, ToggleButton, Typography, Box } from '@mui/material';
import styled from '@emotion/styled';
import { TableFilterList } from 'mui-datatables';
import { v4 } from 'uuid';

export const ApplyFilter = styled(Button)`
  margin-top: 2em;
`;

export const ChipSC = styled(Chip)`
  margin: ${({ theme }) => theme.spacing(1)};
`;

export const CustomChip = ({ label }) => <ChipSC label={label} onDelete={null} />;

export const getQuickFilterInput = type =>
  ({
    'toggle-button': (tableState, setTableState, { name, values }, gqlQuickFiltersMeta) => {
      const { quickFilters } = tableState;
      return (
        <ToggleButtonGroup
          size="small"
          color="primary"
          value={quickFilters[name]}
          aria-label={name}
          onChange={(_, filterValues) =>
            setTableState(previousTableState => ({
              ...previousTableState,
              quickFilters: { ...previousTableState.quickFilters, [name]: filterValues },
              gqlQuickFiltersMeta,
            }))
          }
        >
          {values.map(v => (
            <ToggleButton value={v} aria-label={v} key={v4()}>
              <Typography variant="caption">{v}</Typography>
            </ToggleButton>
          ))}
        </ToggleButtonGroup>
      );
    },
  }[type]);

export const CustomFilterList = ({
  tableState,
  setTableState,
  quickFilterComponents,
  gqlQuickFiltersMeta,
  ...rest
}) => (
  <>
    {Array.isArray(quickFilterComponents) && (
      <Box m={3} display="flex" gap={4}>
        {quickFilterComponents.map(({ inputType, label, ...filterProps }) => (
          <Box display="flex" alignItems="center" gap={1} key={v4()}>
            <Typography variant="caption">{label}</Typography>
            <div>{getQuickFilterInput(inputType)(tableState, setTableState, filterProps, gqlQuickFiltersMeta)}</div>
          </Box>
        ))}
      </Box>
    )}
    <TableFilterList {...rest} ItemComponent={CustomChip} />
  </>
);
