/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useCallback, useMemo } from 'react';
import { IconButton, TablePagination, Tooltip, TableFooter, TableRow } from '@mui/material';

import { CloudDownload } from '@mui/icons-material';
import { numbers } from '../utility';
import { useLoading } from '../hooks';
import { debounceCustomSearchRender } from '../CustomSearchField';

import { OverviewTable, onTableChange } from '../OverviewTable';
import { getColumns } from '../OverviewTable/OverviewTable';
import { ApplyFilter, CustomFilterList } from './table-config';

/**
 * Known bugs and improvements:
 * GUIDs can't be searched or sorted because they are computed
 * Sorting leads to different table dimensions before/after load
 * Multiple requests can be triggered at once
 * Pagination might throw an error and needs to be resetted
 */
export default ({
  columns,
  fn,
  options: propOptions,
  tableState,
  dispatch,
  stateSetterAction,
  setTableState,
  customSearch,
  shouldAutoFocusSearch = false,
  errorHandler = console.error,
  onPageRowsLoadedCb,
  components,
  quickFilterComponents,
  gqlQuickFiltersMeta,
  // context is a variable which should include any data for a given table context
  context,
  title,
  onResolve,
}) => {
  const [response, setResponse] = React.useState([]);

  const tableStateSetter = useCallback(
    cb => {
      if (setTableState) {
        return setTableState(cb);
      }
      if (!dispatch || !stateSetterAction) {
        throw Error('No state handler defined');
      }
      return dispatch({ type: stateSetterAction, payload: cb(tableState) });
    },
    [setTableState, dispatch, stateSetterAction, tableState],
  );

  const getFn = async params =>
    fn(params)
      .then(res => {
        setResponse(res);
        onResolve?.(res);
      })
      .catch(error => {
        errorHandler(typeof error !== 'string' ? error.message : error);
      });
  const [getInitial, initialLoading] = useLoading(getFn);

  const onDownloadHandler = useCallback(() => {
    const { sort, filters, search } = tableState;
    tableState.onDownloadHandler(
      {
        filters,
        sort,
        query: search,
      },
      context,
    );
  }, [tableState]);

  // Init
  React.useEffect(() => {
    const { page, rowsPerPage, sort, filters, search, quickFilters } = tableState;
    getInitial({
      offset: rowsPerPage * page,
      limit: rowsPerPage,
      filters,
      sort,
      query: search, // TODO sanitize input?
      quickFilters,
    });
  }, [tableState]);

  React.useEffect(() => {
    if (response?.data) {
      onPageRowsLoadedCb?.(response.data);
    }
  }, [response]);

  const handleFilterSubmit = applyFilters => {
    const filterList = applyFilters();

    const filters = filterList.reduce((acc, v, i) => {
      if (v.length) {
        const column = columns[i];
        const filterName = column.field || column.name;
        acc[filterName] = v;
      }
      return acc;
    }, {});

    const filterState = Object.keys(filters).reduce((acc, k) => {
      acc[k] = Array.isArray(filters[k]) ? filters[k] : [filters[k]];
      return acc;
    }, {});
    tableStateSetter(previousTableState => ({
      ...previousTableState,
      gqlQuickFiltersMeta,
      filters: filterState,
      page: 0,
    }));
  };

  const options = {
    download: false,
    filter: false,
    serverSide: true,
    count: response.total,
    searchText: tableState.search,
    page: tableState.page,
    rowsPerPage: tableState.rowsPerPage,
    rowsPerPageOptions: [25, 50, 100],
    customSearchRender: debounceCustomSearchRender(800, shouldAutoFocusSearch),
    confirmFilters: true,
    customFilterDialogFooter: (__, applyNewFilters) => (
      <ApplyFilter variant="contained" onClick={() => handleFilterSubmit(applyNewFilters)}>
        Apply
      </ApplyFilter>
    ),
    onFilterChipClose: (__, ___, filterList) => handleFilterSubmit(() => filterList),
    onTableChange: onTableChange({ setTableState: tableStateSetter, columns, isServerSide: true }),
    ...(tableState?.sort && {
      sortOrder: {
        name: tableState.sort.name,
        direction: tableState.sort.order,
      },
    }),
    ...(tableState?.persistentFilterChips && {
      disabledChips: tableState.persistentFilterChips,
      onFilterDialogOpen: () => {
        setTimeout(() => {
          if (tableState?.persistentFilterChips) {
            const resetFiltersButton = document.querySelector("[data-testid='filterReset-button']");
            resetFiltersButton?.remove();
          }
        });
      },
    }),
    customFooter: (count, page, rowsPerPage, changeRowsPerPage, changePage, textLabels) => (
      <TableFooter>
        <TableRow>
          <TablePagination
            count={count}
            page={page}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={({ target }) => changeRowsPerPage(target.value)}
            onPageChange={(_, currentPage) => changePage(currentPage)}
            component="td"
            rowsPerPageOptions={[25, 50, 100]}
            labelDisplayedRows={({ from, to, count: totalRowsNumber }) =>
              `${from}-${to} ${textLabels.displayRows} ${numbers.formatNumberAsView(totalRowsNumber, 0, false)}`
            }
          />
        </TableRow>
      </TableFooter>
    ),
  };

  const persistentTableOptions = useMemo(
    () => ({
      ...(tableState.onDownloadHandler && {
        download: false,
        customToolbar: () => (
          <>
            <Tooltip title="Download">
              <IconButton onClick={onDownloadHandler}>
                <CloudDownload />
              </IconButton>
            </Tooltip>
            {propOptions?.customToolbar?.()}
          </>
        ),
      }),
    }),
    [tableState, propOptions],
  );

  return (
    <OverviewTable
      data={response.data}
      columns={getColumns(columns, tableState)}
      options={{ ...options, ...propOptions, ...persistentTableOptions }}
      isLoading={initialLoading}
      customSearch={customSearch}
      title={title}
      components={{
        TableFilterList: props => (
          <CustomFilterList
            {...props}
            quickFilterComponents={quickFilterComponents}
            gqlQuickFiltersMeta={gqlQuickFiltersMeta}
            tableState={tableState}
            setTableState={tableStateSetter}
          />
        ),
        ...components,
      }}
    />
  );
};
