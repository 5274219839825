import React from 'react';
import { IconButton } from '@mui/material';
import { Close } from '@mui/icons-material';
import { SnackbarProvider } from 'notistack';

export default ({ children }) => {
  const snackbarRef = React.createRef();
  const onClickDismiss = key => () => {
    snackbarRef.current.closeSnackbar(key);
  };
  const DismissButton = key => (
    <IconButton onClick={onClickDismiss(key)} size="large">
      <Close />
    </IconButton>
  );

  return (
    <SnackbarProvider ref={snackbarRef} action={DismissButton} variant="error">
      {children}
    </SnackbarProvider>
  );
};
