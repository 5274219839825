import { utility, toCustomFilterOptions } from '@leaf/components';
import axios from 'axios';
import NetworkMovesDetails from './NetworkMovesDetails';

export default class NetworkMoves {
  constructor(response) {
    Object.assign(this, {
      ...response,
    });

    this.view = {
      id: response.networkMoveId,
      l2Name: response.l2Name ?? '-',
      l2OwnerName: response.l2OwnerName ?? '-',
      moveType: {
        type: response.moveType,
        isExternal: response.networkType === 'EXTERNAL',
      },
      lanes: [
        response.shipperId === response?.l0OwnerId
          ? {
              lane: response.l0Name,
              owner: response.l0OwnerName,
            }
          : undefined,
        response.shipperId === response?.l1OwnerId
          ? {
              lane: response.l1Name,
              owner: response.l1OwnerName,
            }
          : undefined,
        response.shipperId === response?.l2OwnerId
          ? {
              lane: response.l2Name,
              owner: response.l2OwnerName,
            }
          : undefined,
      ],
      loadsImpacted: response.loadsImpacted ?? '-',
      costReduction: response.costReduction ?? '-',
      CO2Reduction: response.CO2Reduction ?? '-',
      lowLegsCount: this.countLowLegs(response.l0Low, response.l1Low, response.l1Low),
      totalMiles: utility.format.miles(response.totalMiles),
      minTrips: utility.numbers.formatNumberAsView(response.minTrips, 0, false),
    };

    const [l0Origin, l0Destination] = response.l0Name.split(' - ');
    this.view.l0Origin = l0Origin;
    this.view.l0Destination = l0Destination;
    this.view.l0ShipperName = response.l0OwnerName;
    this.view.l0ShipperLogo = response.l0OwnerLogo;

    const [l1Origin, l1Destination] = response.l1Name.split(' - ');
    this.view.l1Origin = l1Origin;
    this.view.l1Destination = l1Destination;
    this.view.l1ShipperName = response.l1OwnerName;
    this.view.l1ShipperLogo = response.l1OwnerLogo;

    if (response.l2Name) {
      const [l2Origin, l2Destination] = response.l2Name.split(' - ');
      this.view.l2Origin = l2Origin;
      this.view.l2Destination = l2Destination;
      this.view.l2ShipperName = response.l2OwnerName;
      this.view.l2ShipperLogo = response.l2OwnerLogo;
    }
  }

  static getAll = async params =>
    axios.get(`view/adapt-board-network-moves?${utility.pagination.toServerSideParams(params)}`).then(resp => ({
      ...resp,
      data: resp.data.map(date => new NetworkMoves(date)),
    }));

  static get = async id => axios.get(`view/adapt/network-moves/${id}`).then(data => new NetworkMovesDetails(data));

  static getFilters() {
    return axios.get(`view/adapt-board-network-moves/filters`).then(response => ({
      moveType: toCustomFilterOptions(response.moveType),
      networkType: toCustomFilterOptions(response.networkType),
      equipmentClass: toCustomFilterOptions(response.equipmentClass),
    }));
  }

  countLowLegs = (l0Low = false, l1Low = false, l2Low = false) => {
    let count = 0;
    if (l0Low) count += 1;
    if (l1Low) count += 1;
    if (l2Low) count += 1;

    return count;
  };
}
