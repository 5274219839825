import React from 'react';

import MapWithPolyline from '../../Common/Components/Map/MapPolyline';
import mergeArraysIntoArrayOfObjects from './mergeArraysIntoArrayOfObjects';

//mockData
const originValuesToLatLngMap = {
  'Columbia, OH 89899': [40.78, -73.91],
  'Columbia, OH 89900': [45.51, -122.68],
};

const destinationValuesToLatLngMap = {
  'Villainy, NY 98989': [41.83, -87.62],
  'Villainy, NY 98900': [37.77, -122.43],
};

const metricsValuesToLatLngMap = {
  '101,303.39': 2,
  '234,303.39': 4,
};
//mockData

const getTransformedData = data => {
  const array = mergeArraysIntoArrayOfObjects(
    data.dimensions[0].values.map(v => originValuesToLatLngMap[v]),
    data.dimensions[1].values.map(v => destinationValuesToLatLngMap[v]),
    'originLatLng',
    'destinationLatLng',
  );
  const weights = data.metrics[0].values.map(v => metricsValuesToLatLngMap[v]);

  array.forEach((el, index) => (el.weight = weights[index]));

  return {
    array,
    color: '#000000', // metricsConfig[data.metrics[0].metric].colors.backgroundColor
  };
};

//example of desired response
const mapdata = [
  {
    originLatLng: [40.78, -73.91],
    destinationLatLng: [41.83, -87.62],
    weight: 1,
  },
  {
    originLatLng: [45.51, -122.68],
    destinationLatLng: [37.77, -122.43],
    weight: 2,
  },
];
//example of desired response

export default React.memo(({ data }) => {
  const transformedData = getTransformedData(data);

  return <MapWithPolyline data={transformedData.array} color={transformedData.color} />;
});
