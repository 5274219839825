/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import enLocale from 'date-fns/locale/en-US';
import { TextField } from '@mui/material';
import { LocalizationProvider, MobileDatePicker } from '@mui/x-date-pickers-pro';
import { Today } from '@mui/icons-material';
import styled from '@emotion/styled';
import { AdapterDateFns as DateFnsAdapter } from '@mui/x-date-pickers-pro/AdapterDateFns';
import { VIEW_FORMAT } from '../../utility/date';

const DateIcon = styled(Today)`
  color: ${({ disabled }) => `${!disabled ? 'rgba(0, 0, 0, 0.54)' : 'rgba(0, 0, 0, 0.24)'}`};
  margin-right: ${({ theme }) => `${theme.spacing(2)}`};
`;

export default ({ inputVariant = 'outlined', ...props }) => (
  <LocalizationProvider dateAdapter={DateFnsAdapter} adapterLocale={enLocale}>
    <MobileDatePicker
      renderInput={inputProps => (
        <TextField
          fullWidth
          variant={inputVariant}
          {...inputProps}
          InputProps={{
            endAdornment: <DateIcon fontSize="small" disabled={inputProps.disabled} />,
          }}
        />
      )}
      format={VIEW_FORMAT}
      autoOk
      {...props}
    />
  </LocalizationProvider>
);
