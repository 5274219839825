import React from 'react';
import styled from '@emotion/styled';
import { Box, Chip, Typography } from '@mui/material';

import { CONTRACT_GROUP_STATUS, CONTRACT_GROUP_STATUS_DISPLAY_NAMES } from '../constants/ContractGroupStatus';

const ContractGroupStatusChip = styled(Chip)`
  ${({ $bgColor }) => `
    background-color: ${$bgColor} !important;
    color: white !important;
  `}
`;

const COLORS = {
  [CONTRACT_GROUP_STATUS.ACTIVE]: '#4CAF50',
  [CONTRACT_GROUP_STATUS.FUTURE]: '#4A90E2',
  [CONTRACT_GROUP_STATUS.PAST]: '#BDBDBD',
};

/**
 * Componrnt: ContractGroupStatus
 *
 * @status {string}
 *
 * @returns
 * Returns chip based on status parameter that user provide.
 */
const ContractGroupStatus = ({ status }) => (
  <Box display="flex" alignItems="center">
    <ContractGroupStatusChip
      $bgColor={COLORS[status]}
      label={<Typography variant="body1">{CONTRACT_GROUP_STATUS_DISPLAY_NAMES[status]}</Typography>}
    />
  </Box>
);

export default ContractGroupStatus;
