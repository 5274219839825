import React from 'react';
import {
  scaleLinear,
  scaleOrdinal,
  scaleTime,
  area,
  line,
  extent,
  max,
  format,
  select,
  selectAll,
  axisLeft,
  axisBottom,
  timeFormat,
} from 'd3';
import { legendColor } from 'd3-svg-legend';
import _ from 'lodash';
import { differenceInDays } from 'date-fns';

const magic = {
  width: 1550,
  height: 480,
  margins: { top: 20, right: 20, bottom: 60, left: 60 },
};

const RateHistoryChart = ({ data, uom }) => {
  const svg = React.useRef();

  React.useEffect(() => {
    selectAll('#rate-history-chart > *').remove();
    const width = magic.width - magic.margins.left - magic.margins.right;
    const height = magic.height - magic.margins.top - magic.margins.bottom;

    // TODO: Move to BE
    const meanRpms = data.map(r => r[uom].rpm_mean);
    const meanRpm12Months = meanRpms.reduce((agg, curr) => agg + curr, 0) / meanRpms.length;

    const maximumPeriod = _.maxBy(data, 'period').period;
    const meanRpm90Days = _.mean(
      data.filter(r => differenceInDays(maximumPeriod, r.period) < 90).map(r => r[uom].rpm_mean),
    );

    const x = scaleTime().rangeRound([0, width]);
    const y = scaleLinear().rangeRound([0, height]);

    const minMaxArea = area()
      .x(d => x(d.period))
      .y0(d => y(d[uom].rpm_min))
      .y1(d => y(d[uom].rpm_max));

    const avgLine = line()
      .x(d => x(d.period))
      .y(d => y(d[uom].rpm_mean));

    const maxRpms = data.map(r => r[uom].rpm_max);

    x.domain(extent(data, r => r.period));
    y.domain([max(maxRpms) + 1, 0]);

    const g = select(svg.current)
      .attr('width', magic.width)
      .attr('height', magic.height)
      .append('g')
      .attr('transform', `translate(${magic.margins.left}, ${magic.margins.top})`);

    g.append('path').datum(data).attr('fill', '#93d0ae').attr('d', minMaxArea);

    g.append('path').datum(data).attr('fill', 'none').attr('stroke', 'green').attr('d', avgLine);

    g.append('g')
      .attr('transform', `translate(0,${height})`)
      .call(axisBottom(x).tickFormat(timeFormat("%b %d '%y")))
      .selectAll('text')
      .attr('transform', 'rotate(-45)')
      .style('text-anchor', 'end');

    g.append('g')
      .call(axisLeft(y))
      .append('text')
      .attr('fill', '#000')
      .attr('transform', 'rotate(-90)')
      .attr('y', 6)
      .attr('dy', '0.71em')
      .attr('text-anchor', 'end')
      .text('Rate per mile ($)');

    const legendScale = scaleOrdinal().domain(['Daily spread', 'Daily average']).range(['#93d0ae', 'green']);

    const legend = legendColor().shapeWidth(30).scale(legendScale);

    g.append('g').call(legend).attr('transform', 'translate(50,20)');

    g.append('g')
      .append('text')
      .attr('fill', '#000')
      .attr('y', 75)
      .attr('x', 50)
      .attr('dy', '0.5em')
      .text(`12-month daily mean rpm: ${format('$.2f')(meanRpm12Months)}`);

    g.append('g')
      .append('text')
      .attr('fill', '#000')
      .attr('y', 95)
      .attr('x', 50)
      .attr('dy', '0.5em')
      .text(`90-day daily mean rpm: ${format('$.2f')(meanRpm90Days)}`);
  }, [data, uom, svg]);

  return <svg id="rate-history-chart" ref={svg} />;
};

export default RateHistoryChart;
