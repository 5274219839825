import React from 'react';
import styled from '@emotion/styled';
import { Typography } from '@mui/material';

const TableContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: ${({ theme }) => theme.spacing(4)};
  width: 100%;
`;

const Box = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing(1)};
`;

const ErrorSquare = styled.div`
  width: 12px;
  height: 12px;
  background: #e31b0c;
`;

const WarningSquare = styled.div`
  width: 12px;
  height: 12px;
  background: #ffb547;
`;

export default ({ numberOfIssues = 0, warning = false, error = false }) => (
  <TableContainer>
    <Typography variant="h6">{numberOfIssues} issues found</Typography>
    {error && (
      <Box>
        <ErrorSquare />
        <Typography variant="h6">Critical errors</Typography>
      </Box>
    )}
    {warning && (
      <Box>
        <WarningSquare />
        <Typography variant="h6">Alerts</Typography>
      </Box>
    )}
  </TableContainer>
);
