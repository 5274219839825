import { date as dateTimeService } from '../../utility';

function Preset(name, from, to) {
  this.name = name;
  this.from = from;
  this.to = to;
}

// eslint-disable-next-line no-unused-vars
export const getDisabledDays = dashboardName => {
  const disabledDays = {};
  // Example: disabledDays.after = new Date() and/or disabledDays.before = new Date()
  return disabledDays;
};

export const datePickerPresets = {
  performance: [
    new Preset('Past week', dateTimeService.subtractWeeksFromDate(new Date(), 1), new Date()),
    new Preset('Past month', dateTimeService.subtractMonthsFromDate(new Date(), 1), new Date()),
    new Preset('Past 3 months', dateTimeService.subtractMonthsFromDate(new Date(), 3), new Date()),
    new Preset('Past 6 months', dateTimeService.subtractMonthsFromDate(new Date(), 6), new Date()),
    new Preset('Past year', dateTimeService.subtractMonthsFromDate(new Date(), 12), new Date()),
    new Preset('Past 2 years', dateTimeService.subtractMonthsFromDate(new Date(), 24), new Date()),
  ],
};
