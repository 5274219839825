import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import { calcConfigurableDimension } from './chartsHelper';
import Select from './Select';

import './ConfigurableDimensionRow.scss';

const ConfigurableDimensionRow = ({ configurableDimensions, onDimensionSelect, selectedDimensionId, className }) => {
  const configurableDimension = calcConfigurableDimension(configurableDimensions);
  const options = configurableDimensions[configurableDimension];

  return options ? (
    <div className={classNames('dashboard-chart-configurable-dimension-row margin-bottom-base', className)}>
      <Select options={options} onSelect={onDimensionSelect} selectedId={selectedDimensionId} />
    </div>
  ) : null;
};

ConfigurableDimensionRow.propTypes = {
  configurableDimensions: PropTypes.object.isRequired,
  onDimensionSelect: PropTypes.func.isRequired,
  selectedDimensionId: PropTypes.any.isRequired,
  className: PropTypes.string,
};

export default React.memo(ConfigurableDimensionRow);
