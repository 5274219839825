import React, { memo } from 'react';

import { Box } from '@mui/material';
import styled from '@emotion/styled';
import { GoogleAnalytics, utility } from '@leaf/components';
import { getCurrentUserMetadata, getImpersonatedCompanyId } from 'authentication/token';
import HeaderPanel, { HeaderPanelLeft, HeaderPanelTitle } from './HeaderPanel';

const ContentMarkdown = styled.div`
  margin: ${({ theme }) => theme.spacing(4)};
`;

const Container = styled.div`
  height: calc(100% - 60px);
  overflow: auto;
`;

const Content = memo(
  ({ markdown, title }) => (
    <Box height={1}>
      <HeaderPanel>
        <HeaderPanelLeft>
          <HeaderPanelTitle title={title} />
        </HeaderPanelLeft>
      </HeaderPanel>
      <Container>
        <ContentMarkdown>{markdown}</ContentMarkdown>
      </Container>
    </Box>
  ),
  (prevProps, props) => {
    const getPageDataForTracking = companyType => {
      const trackedPage = {
        page: '/help/{0}',
        title: 'Help - {0}',
      };
      const isImpersonated = !!getImpersonatedCompanyId();

      return {
        page: utility.format.formatString(trackedPage.page, props.pageName),
        title: utility.format.formatString(trackedPage.title, props.title),
        userType: companyType,
        isImpersonatedUser: isImpersonated,
      };
    };
    if (props.markdown) {
      props.getAccessTokenSilently().then(res => {
        const { company_type: companyType } = getCurrentUserMetadata(res);
        const pageData = getPageDataForTracking(companyType);
        GoogleAnalytics.trackPageView(pageData);
      });
    }
  },
);

export default Content;
