export const GET_SHIPMENT_DETAILS_START = 'le.get.shipment.details.start';
export const GET_SHIPMENT_DETAILS_STOP = 'le.get.shipment.details.stop';
export const SET_SHIPMENT_DETAILS = 'le.set.shipment.details';
export const SET_SHIPMENT_LEGS = 'le.set.shipment.legs';

export default (state, action) => {
  switch (action.type) {
    case GET_SHIPMENT_DETAILS_START: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case GET_SHIPMENT_DETAILS_STOP: {
      return {
        ...state,
        isLoading: false,
      };
    }
    case SET_SHIPMENT_DETAILS: {
      return {
        ...state,
        details: action.payload,
      };
    }
    case SET_SHIPMENT_LEGS: {
      return {
        ...state,
        legs: action.payload,
      };
    }
    default:
      throw new Error('Action not defined');
  }
};
