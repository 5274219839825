import React from 'react';
import { CircularProgress, Dialog, DialogTitle, Typography, DialogContent } from '@mui/material';
import styled from '@emotion/styled';

export const DialogContentContainer = styled(DialogContent)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 300px;
`;

export default ({ title, text = 'Loading...', open }) => (
  <Dialog fullWidth maxWidth="sm" open={open}>
    <DialogTitle>{title}</DialogTitle>
    <DialogContentContainer dividers>
      <CircularProgress />
      <Typography>{text}</Typography>
    </DialogContentContainer>
  </Dialog>
);
