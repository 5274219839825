import React from 'react';
import { Typography } from '@mui/material';
import { LocalShipping } from '@mui/icons-material';
import styled from '@emotion/styled';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  color: ${({ theme }) => theme.palette.deEmphasize};
  .MuiSvgIcon-root {
    width: 40px;
    height: 40px;
  }
`;

const DataNotAvailable = () => (
  <Wrapper className="no-data">
    <LocalShipping />
    <Typography variant="caption">Data not yet available</Typography>
  </Wrapper>
);

export default DataNotAvailable;
