import React from 'react';
import styled from '@emotion/styled';
import { Chip, Avatar, Typography } from '@mui/material';

import { USER_ROLE, USER_ROLE_DISPLAY_NAMES, USER_ROLE_INITIALS } from '../constants/UserRole';

const UserRoleChip = styled(Chip, {
  shouldForwardProp: prop => prop !== '$textColor' && prop !== '$bgColor',
})`
  ${({ $bgColor, $textColor }) => `
    background-color: ${$bgColor} !important;
    color: ${$textColor} !important;
  `}
`;

const COLORS = {
  [USER_ROLE.COMPANY_ADMIN]: {
    bgColor: '#001530',
    textColor: 'white',
  },
  [USER_ROLE.LE_ADMIN]: {
    bgColor: '#4A90E2',
    textColor: 'white',
  },
  [USER_ROLE.TRADING_USER]: {
    bgColor: '#EEEEEE',
    textColor: '#757575',
  },
  [USER_ROLE.USER]: {
    bgColor: '#4CAF50',
    textColor: 'white',
  },
};

/**
 * Componrnt: UserRole
 *
 * @role {string}
 *
 * @returns
 * Returns chip based on role parameter that user provide.
 */
const UserRole = ({ role }) => (
  <UserRoleChip
    $bgColor={COLORS[role].bgColor}
    $textColor={COLORS[role].textColor}
    avatar={<Avatar>{USER_ROLE_INITIALS[role]}</Avatar>}
    label={<Typography variant="body1">{USER_ROLE_DISPLAY_NAMES[role] || role}</Typography>}
  />
);

export default UserRole;
