/* eslint-disable react/jsx-props-no-spreading */
import React, { useContext, useEffect, useState } from 'react';
import { Dialog, DialogActions, DialogTitle } from '@mui/material';
import { useForm, useFieldArray } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import chartTypes, { chartTypesDisplayNames } from '../Constants/chartTypes';

import Input from '../../form/library/Input';
import Checkbox from '../../form/library/Checkbox';
import Autocomplete from '../../form/library/Autocomplete';
import { fnOptions, getOrderOptions } from '../utility';
import DashboardContext from '../DashboardContext';
import Button from '../../Button';
import { Container, DialogContent } from './AddEditWidgetModal.styled';
import FiltersSection from './WidgetSections/Filters';
import OrderSection from './WidgetSections/Order';
import MetricsSection from './WidgetSections/Metrics';
import DimensionsSection from './WidgetSections/Dimensions';
import addEditWidgetValidationSchema from './addEditWidgetValidationSchema';
import { chartLayoutRestrictions } from '../Constants';
import { initialRestrinctions } from '../Constants/chartLayoutRestrictions';

export const chartTypeOptions = Object.values(chartTypes)
  .map(t => t)
  .filter(t => t !== chartTypes.MAP);

export default ({
  onSubmit,
  onCancel,
  modalTitle,
  metricsOptions,
  dimensionOptions,
  filterOptions,
  pivotRows: dimensionRows,
  pivotColumns: dimensionColumns,
  initialValues,
}) => {
  const { api, onErrorHandler } = useContext(DashboardContext);

  const [filterValues, setFilterValues] = useState({});

  useEffect(() => {
    const filterPromises =
      initialValues?.filters?.map(({ name }) => api.getFilterValues(name).then(res => ({ [name]: res }))) ?? [];
    Promise.all(filterPromises)
      .then(res => {
        setFilterValues(
          res?.reduce(
            (acc, options) => ({
              ...acc,
              ...options,
            }),
            {},
          ),
        );
      })
      .catch(onErrorHandler);
  }, []);

  const defaultValues = {
    ...initialValues,
    metrics: initialValues?.metrics?.map(metric => ({
      ...metric,
      numberFormat: metricsOptions?.find(m => m.name === metric.name)?.numberFormat,
    })),
    dimensions: initialValues?.dimensions?.filter(d => d),
  };

  const { control, errors, watch, handleSubmit, setValue } = useForm({
    defaultValues,
    mode: 'all',
    reValidateMode: 'all',
    resolver: yupResolver(addEditWidgetValidationSchema),
  });

  const handleSave = handleSubmit(data => {
    const widgetLayout =
      initialValues?.chartType !== data.chartType
        ? { ...initialValues?.layout, ...initialRestrinctions, ...chartLayoutRestrictions[data.chartType] }
        : initialValues?.layout;
    onSubmit({ layout: widgetLayout, ...data });
  });

  const arrayFields = useFieldArray({
    control,
    name: 'metrics',
  });

  const dimensionsArrayFields = useFieldArray({
    control,
    name: 'dimensions',
  });

  const dimensionsPivotRowsArrayFields = useFieldArray({
    control,
    name: 'pivotRows',
  });

  const dimensionsPivotColumnsArrayFields = useFieldArray({
    control,
    name: 'pivotColumns',
  });

  const filtersArrayFields = useFieldArray({
    control,
    name: 'filters',
  });

  const chartType = watch('chartType');
  const dimensionsState = watch('dimensions');
  const metricsState = watch('metrics');
  const filtersState = watch('filters');

  const hasFontVariantOption = chartType && (chartType === chartTypes.LIST || chartType === chartTypes.TABLE);
  const hasSubtitle = chartType && chartType !== chartTypes.KPI && chartType !== chartTypes.CIRCULAR_PROGRESS_BAR;
  const hasDimensionsSection =
    chartType &&
    chartType !== chartTypes.KPI &&
    chartType !== chartTypes.CIRCULAR_PROGRESS_BAR &&
    chartType !== chartTypes.DONUT &&
    chartType !== chartTypes.LIST &&
    chartType !== chartTypes.PIVOT_TABLE;
  const hasColorsSection = [
    chartTypes.AREA,
    chartTypes.LINE,
    chartTypes.PROGRESS_BAR,
    chartTypes.DONUT,
    chartTypes.BAR,
  ].includes(chartType);
  const hasOrderSection =
    chartType &&
    chartType !== chartTypes.KPI &&
    chartType !== chartTypes.LIST &&
    chartType !== chartTypes.PIVOT_TABLE &&
    chartType !== chartTypes.CIRCULAR_PROGRESS_BAR;

  return (
    <Dialog open id="addEditWidgetModal" fullWidth maxWidth="md">
      <DialogTitle>{modalTitle}</DialogTitle>
      <DialogContent dividers>
        <Container>
          <Input type="text" name="title" label="Title" control={control} error={errors.title} />
          {hasSubtitle && (
            <Input
              type="text"
              name="subtitle"
              label="Subtitle"
              placeholder="(in $)"
              control={control}
              error={errors.subtitle}
            />
          )}
          <Autocomplete
            name="chartType"
            label="Chart type"
            inputProps={{ error: errors.chartType }}
            options={chartTypeOptions}
            getOptionLabel={option => chartTypesDisplayNames[option]}
            isOptionEqualToValue={(option, value) => option === value}
            control={control}
          />
          {chartType === chartTypes.AREA && <Checkbox name="isStacked" label="Stacked area chart" control={control} />}
          {(chartType === chartTypes.LINE || chartType === chartTypes.PROGRESS_BAR) && (
            <Checkbox name="isPercentBased" label="Values are in percents" control={control} />
          )}
        </Container>
        {chartType === chartTypes.PIVOT_TABLE && <h4>Values</h4>}
        <MetricsSection
          control={control}
          errors={errors}
          metricsOptions={metricsOptions}
          fnOptions={fnOptions}
          arrayFields={arrayFields}
          hasFontVariantOption={hasFontVariantOption}
          setValue={setValue}
          isListInfoWidget={chartType && chartType === chartTypes.LIST}
          hasColorsSection={hasColorsSection}
        />
        {hasDimensionsSection && (
          <DimensionsSection
            control={control}
            errors={errors}
            arrayFields={dimensionsArrayFields}
            dimensionOptions={dimensionOptions}
            hasFontVariantOption={hasFontVariantOption}
          />
        )}
        {chartType === chartTypes.PIVOT_TABLE && (
          <>
            <h4>Rows</h4>
            <DimensionsSection
              name="pivotRows"
              errors={errors}
              control={control}
              arrayFields={dimensionsPivotRowsArrayFields}
              dimensionOptions={dimensionOptions}
              hasFontVariantOption={hasFontVariantOption}
              chartType={chartTypes.PIVOT_TABLE}
              dimensionRows={dimensionRows}
              dimensionColumns={dimensionColumns}
            />
          </>
        )}
        {chartType === chartTypes.PIVOT_TABLE && (
          <>
            <h4>Columns</h4>
            <DimensionsSection
              name="pivotColumns"
              control={control}
              errors={errors}
              arrayFields={dimensionsPivotColumnsArrayFields}
              dimensionOptions={dimensionOptions}
              hasFontVariantOption={hasFontVariantOption}
              chartType={chartTypes.PIVOT_TABLE}
              dimensionRows={dimensionRows}
              dimensionColumns={dimensionColumns}
            />
          </>
        )}
        {hasOrderSection && (
          <OrderSection
            control={control}
            errors={errors}
            options={getOrderOptions(dimensionsState, metricsState, metricsOptions, dimensionOptions)}
          />
        )}
        {chartType && (
          <FiltersSection
            filterOptions={filterOptions}
            api={api}
            control={control}
            errors={errors}
            arrayFields={filtersArrayFields}
            onErrorHandler={onErrorHandler}
            filterValues={filterValues}
            setFilterValues={setFilterValues}
            filtersState={filtersState}
            onChangeReset={index => {
              setValue(`filters[${index}].value`, []);
            }}
          />
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel} variant="contained">
          Cancel
        </Button>
        <Button color="primary" variant="contained" onClick={handleSave}>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};
