import React, { useContext, useEffect, useMemo } from 'react';
import Page from 'components/Page';
import { PageContentLayout, PageContainer, ServerSideTable, useTableCustomFilters, USER_TYPE } from '@leaf/components';
import { StateContext } from 'state/StateProvider';
import { SET_SHIPMENTS_OVERVIEW_TABLE_STATE } from 'state/stateReducer';
import { withRouter } from 'react-router-dom';
import initialState from 'state/initialState';
import { useSnackbar } from 'notistack';
import { getColumns, getTitles } from './utilities';
import { endpoints, getFilterOptions } from './common';

const Shipments = withRouter(({ history, companyType }) => {
  const { enqueueSnackbar } = useSnackbar();

  const {
    state: {
      tables: { shipmentsOverview: tableState },
    },
    dispatch,
  } = useContext(StateContext);

  const shipmentEndpoints = useMemo(() => endpoints[companyType], [companyType]);
  const [filterOptions] = useTableCustomFilters(getFilterOptions(companyType) ?? []);

  useEffect(() => {
    const unregister = history.listen(location => {
      const toShipmentDetails = location.pathname.startsWith('/shipments/');
      const toShipmentsOverview = location.pathname.startsWith('/shipments');
      if (!toShipmentDetails && !toShipmentsOverview) {
        dispatch({ type: SET_SHIPMENTS_OVERVIEW_TABLE_STATE, payload: initialState.tables.shipmentsOverview });
      }
    });
    return () => unregister();
  }, []);

  const options = useMemo(
    () => ({
      filter: true,
      download: false,
      onRowClick: rowData => {
        const shipmentId = rowData[0];
        history.push(`/shipments/${shipmentId}`);
      },
    }),
    [history],
  );

  const handleChange = setTableState =>
    dispatch({ type: SET_SHIPMENTS_OVERVIEW_TABLE_STATE, payload: setTableState(tableState) });

  return (
    <Page title={getTitles(companyType, 'overviewTitle')}>
      <PageContainer>
        <PageContentLayout hasSubheader={false}>
          <ServerSideTable
            options={options}
            columns={getColumns(filterOptions)[companyType]}
            fn={shipmentEndpoints?.getAll ?? (() => Promise.resolve([]))}
            tableState={tableState}
            context={{ isShipper: companyType === USER_TYPE.SHIPPER, errorHandler: enqueueSnackbar }}
            setTableState={handleChange}
          />
        </PageContentLayout>
      </PageContainer>
    </Page>
  );
});

export default Shipments;
