// These are only the metrics used as a default layout
export default {
  ON_TIME_PICKUP_PERFORMANCE: 'on_time_pickup_performance',
  ON_TIME_DELIVERY_PERFORMANCE: 'on_time_delivery_performance',
  EXECUTED_SHIPMENTS: 'shipments_executed',
  SHIPMENTS_TODO: 'shipments_todo',
  SHIPMENTS_EXECUTED_DAY_OF: 'shipments_executed_day_of',
  SHIPMENTS_EXECUTED_WEEK_OF: 'shipments_executed_week_of',
  SHIPMENTS_EXECUTED_UNCOMMITTED: 'shipments_executed_uncommitted',
  SHIPMENTS_EXECUTED_SPOT: 'shipments_executed_spot',
  SHIPMENTS_SHIPPER_FAILED: 'shipments_shipper_failed',
  SHIPMENTS_CARRIER_FAILED: 'shipments_carrier_failed',
  SHIPMENTS_FAILED_OTHER: 'shipments_failed_other',
  SHIPMENTS_IN_PROGRESS: 'shipments_in_progress',
  CONTRACTS_NOT_TENDERED: 'contracts_not_tendered',
  EXECUTED_CO2_REDUCTION_TONS: 'executed_co2_reduction_tons',
  EXPECTED_SHIPPER_SPEND: 'expected_shipper_spend',
  EXPECTED_SHIPPER_SAVINGS: 'expected_shipper_savings',
  TENDER_ACCEPTANCE: 'tender_acceptance',
  TENDERS: 'tenders',
  TENDERS_DECLINED: 'tenders_declined',
  TENDERS_CANCELED: 'tenders_canceled',
  TENDERS_ACCEPTED: 'tenders_accepted',
  ON_TIME_PICKUP_TOTAL_SHIPMENTS: 'on_time_pickup_total_shipments',
  ON_TIME_DELIVERY_TOTAL_SHIPMENTS: 'on_time_delivery_total_shipments',
  EXPECTED_SHIPPER_SAVINGS_SHIPMENTS: 'expected_shipper_savings_shipments',
  EXPECTED_CARRIER_SAVINGS_SHIPMENTS: 'expected_carrier_savings_shipments',
  EXPECTED_MARGIN_SHIPPER_SPEND: 'expected_margin_shipper_spend',
  EXPECTED_MARGIN_CARRIER_SPEND: 'expected_margin_carrier_spend',
  EXPECTED_MARGIN_SHIPMENTS: 'expected_margin_shipments',
  EXPECTED_MARGIN: 'expected_margin',
  ACTUAL_MARGIN: 'actual_margin',
  ACTUAL_SHIPPER_SAVINGS: 'actual_shipper_savings',
  CONTRACTED_LH_RPM_SHIPPER: 'contracted_lh_rpm_shipper',
  ACTUAL_MARGIN_SHIPPER_SPEND: 'actual_margin_shipper_spend',
  ACTUAL_MARGIN_CARRIER_SPEND: 'actual_margin_carrier_spend',
};
