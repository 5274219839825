import React, { PureComponent } from 'react';
import _ from 'lodash';
import { HorizontalBar as ChartJSHorizontalBar } from 'react-chartjs-2';

import ChartsDefaults from './chartsDefaults';

export default class extends PureComponent {
  constructor(props) {
    super(props);
    ChartsDefaults.init();
  }

  getOptions() {
    const { ...rest } = this.props;
    return {
      tooltips: { enabled: false },
      maintainAspectRatio: false,
      legend: { display: false },
      scales: {
        yAxes: [
          {
            id: 'y',
            ticks: {
              fontSize: 12,
              fontColor: rest.yAxisFontColor,
              padding: rest.yAxisTicksPadding || -40,
              mirror: true,
            },
            gridLines: {
              display: false,
            },
            barThickness: 30,
          },
        ],
        xAxes: [
          {
            id: 'x',
            stacked: rest.isStacked,
            ticks: {
              beginAtZero: true,
              fontColor: rest.xAxisFontColor,
              suggestedMax: rest.xAxisSuggestedMax || 150,
              stepSize: rest.xAxisStepSize || 50,
              padding: 0,
              callback: (value, index, values) => (rest.xAxisValuesShown.includes(value) ? value : null),
            },
            gridLines: {
              display: true,
            },
          },
        ],
      },
    };
  }

  render() {
    const { data, ...rest } = this.props;
    return (
      <div style={{ position: 'relative', width: rest.width || '100%', height: rest.height || '100%' }}>
        <ChartJSHorizontalBar
          width={rest.width ? rest.width : undefined}
          height={rest.height ? rest.height : undefined}
          data={data}
          options={this.getOptions()}
          redraw
        />
      </div>
    );
  }
}
