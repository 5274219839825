export default [
  {
    label: 'Regular',
    id: 'body1',
  },
  {
    label: 'Bold',
    id: 'h5',
  },
];
