import React from 'react';
import styled from '@emotion/styled';
import { OverviewListRow, Divider } from '..';

const Contrainer = styled.div`
  display: flex;
  column-gap: ${({ theme }) => theme.spacing(6)};
`;

const Inner = styled.div`
  width: 100%;
`;

export default ({ data }) => (
  <Contrainer>
    {data.map(item => (
      <Inner>
        <OverviewListRow label={item.label} value={item.value} />
        {item.divider && <Divider />}
      </Inner>
    ))}
  </Contrainer>
);
