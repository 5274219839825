import React from 'react';
import _ from 'lodash';
import { useFieldArray } from 'react-hook-form';
import Input from '../../../form/library/Input';
import { Section } from '../AddEditWidgetModal.styled';
import Button from '../../../Button';
import Title from './Title';

export default ({ metricIndex, control, errors }) => {
  const { fields, append, remove } = useFieldArray({
    control,
    name: `metrics[${metricIndex}].colors`,
  });

  return (
    <div>
      {fields.map((field, index) => (
        <Section boxShadow={2} p={2} mt={2} mb={2} key={field.id}>
          <Title title={`Color #${index + 1}`} onDelete={() => remove(index)} hasDelete />
          <Input
            name={`metrics[${metricIndex}].colors[${index}].color`}
            label="Color"
            type="text"
            placeholder="#FC45CF"
            control={control}
            error={_.get(errors, `metrics[${metricIndex}].colors[${index}].color`)}
          />

          <Input
            name={`metrics[${metricIndex}].colors[${index}].from`}
            type="number"
            label="Starting from percent"
            placeholder="0"
            disabled={index === 0}
            control={control}
            error={_.get(errors, `metrics[${metricIndex}].colors[${index}].from`)}
          />
        </Section>
      ))}
      <Button onClick={() => append({})} color="primary">
        ADD COLOR
      </Button>
    </div>
  );
};
