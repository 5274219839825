import React from 'react';
import { Box, Button, Typography, IconButton } from '@mui/material';
import { Close, Warning } from '@mui/icons-material';
import styled from '@emotion/styled';

import ProgressLoader from '../ProgressLoader/ProgressLoader';
import OverviewTable from '../OverviewTable/OverviewTable';
import {
  Dialog,
  DialogTitleContainer,
  DialogActionsContainer,
  DialogContentWrapper,
} from './ImportSummaryTable.styled';
import TableTitle from './TableTitle';

const IconButtonWrapper = styled(IconButton)`
  color: #ff9800;
`;

const TypographyError = styled(Typography)`
  color: #e31b0c;
`;

const isErrorComponent = (value, rowIndex, tableData) => {
  if (tableData[rowIndex]?.status === 'error') {
    return <TypographyError>{value}</TypographyError>;
  }

  return value;
};

const columns = [
  {
    label: 'Row',
    name: 'row',
    options: {
      filter: false,
      sort: false,
      customBodyRender: (value, { rowIndex, tableData }) => isErrorComponent(value, rowIndex, tableData),
    },
  },
  {
    label: 'Error description',
    name: 'errorDescritpion',
    options: {
      filter: false,
      sort: false,
      customBodyRender: (value, { rowIndex, tableData }) => isErrorComponent(value, rowIndex, tableData),
    },
  },
  {
    label: 'Column name',
    name: 'columnName',
    options: {
      filter: false,
      sort: false,
      customBodyRender: (value, { rowIndex, tableData }) => isErrorComponent(value, rowIndex, tableData),
    },
  },
  {
    label: 'Input',
    name: 'input',
    options: {
      filter: false,
      sort: false,
      customBodyRender: (value, { rowIndex, tableData }) => isErrorComponent(value, rowIndex, tableData),
    },
  },
  {
    label: ' ',
    name: 'status',
    options: {
      filter: false,
      sort: false,
      customBodyRender: status =>
        status === 'warning' && (
          <IconButtonWrapper>
            <Warning />
          </IconButtonWrapper>
        ),
    },
  },
];

export default ({ importSummary, onClose, submitData, isLoading = false, importProgress = 0 }) => {
  const [tableData, setTableData] = React.useState([]);

  React.useEffect(() => {
    if (importSummary) {
      let tableDataHelper = [];
      importSummary?.records?.forEach(item => {
        const errors = item?.errors?.map(error => ({
          status: 'error',
          row: item.rowNumber,
          errorDescritpion: error.message,
          columnName: error.field,
          input: error.value,
        }));
        const warnings = item?.warnings?.map(warning => ({
          status: 'warning',
          row: item.rowNumber,
          errorDescritpion: warning.message,
          columnName: warning.field,
          input: warning.value,
        }));

        tableDataHelper = [...tableDataHelper, ...errors, ...warnings];
      });

      setTableData(tableDataHelper.sort((a, b) => a.status.localeCompare(b.status)));
    }
  }, [importSummary]);

  return (
    <Dialog fullWidth maxWidth="lg" onClose={onClose} isLoading={isLoading} open>
      {!isLoading ? (
        <>
          <DialogTitleContainer>
            <Typography variant="body2">CONTRACT VERIFICATION</Typography>
            <IconButton onClick={onClose}>
              <Close />
            </IconButton>
          </DialogTitleContainer>
          <DialogContentWrapper dividers>
            <OverviewTable
              title={
                <TableTitle
                  numberOfIssues={tableData?.length ?? 0}
                  error={tableData.find(item => item.status === 'error')}
                  warning={tableData.find(item => item.status === 'warning')}
                />
              }
              isLoading={isLoading}
              data={tableData}
              columns={columns}
              options={{
                search: false,
                download: true,
                viewColumns: false,
                filter: false,
                setRowProps: (row, dataIndex, rowIndex) => ({
                  style: {
                    background:
                      tableData[rowIndex]?.status === 'error' &&
                      'linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), #f44336',
                  },
                }),
              }}
              customSearch={false}
            />
          </DialogContentWrapper>
          <DialogActionsContainer>
            <Button variant="outlined" onClick={onClose}>
              CANCEL
            </Button>
            {submitData && (
              <Button
                variant="contained"
                color="primary"
                onClick={submitData}
                disabled={tableData.find(item => item.status === 'error')}
              >
                IMPORT
              </Button>
            )}
          </DialogActionsContainer>
        </>
      ) : (
        <Box m={2}>
          <ProgressLoader progress={importProgress} />
        </Box>
      )}
    </Dialog>
  );
};
