/* eslint-disable array-callback-return */
import React, { useState } from 'react';
import { Grid, Typography } from '@mui/material';
import { withRouter } from 'react-router-dom';
import { getById } from 'fleet-opportunities/details/domain/FleetOpportunity';
import {
  SpacedContainer,
  PageContentLayout,
  Page,
  LazySpinner,
  CopyToClipboardButton,
  RoutesOverview,
} from '@leaf/components';
import { useSnackbar } from 'notistack';
import styled from '@emotion/styled';
import Overview from './Overview';

const RouteHeader = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const RouteItem = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;

const PageContentLayoutWrapper = styled(PageContentLayout)`
  .MuiGrid-root {
    margin: 0px !important;
  }
`;

const Details = ({
  match: {
    params: { fleetId },
  },
}) => {
  const [routes, setRoutes] = useState();
  const [laneData, setLaneData] = useState();

  const { enqueueSnackbar } = useSnackbar();

  const getOpportunity = () => {
    getById(fleetId)
      .then(data => {
        if (data) {
          setLaneData(data.laneData);
          setRoutes(data);
        }
      })
      .catch(({ message }) => {
        enqueueSnackbar(message);
      });
  };

  React.useEffect(() => {
    const getOpportunityDetails = async () => {
      getOpportunity();
    };
    getOpportunityDetails().catch(enqueueSnackbar);
  }, []);

  if (!laneData) return <LazySpinner />;

  return (
    <Page
      ComponentTitleLeft={
        <RouteHeader>
          {laneData && (
            <RouteItem>
              <Typography variant="body2">{laneData.domicile ?? ''}</Typography>
              <Typography variant="body1">{`Fleet opportunity ID #${fleetId}`}</Typography>
            </RouteItem>
          )}
          <CopyToClipboardButton onMessageHandler={enqueueSnackbar} />
        </RouteHeader>
      }
    >
      <SpacedContainer container spacing={2}>
        <Grid item xs={2.5}>
          <PageContentLayout hasSubheader withoutSpacing fullHeight={false}>
            <Overview opportunity={laneData} />
          </PageContentLayout>
        </Grid>
        <Grid item xs={9.5}>
          <PageContentLayoutWrapper hasSubheader withoutSpacing fullHeight={false}>
            <RoutesOverview routesData={routes} />
          </PageContentLayoutWrapper>
        </Grid>
      </SpacedContainer>
    </Page>
  );
};

export default withRouter(Details);
