import React from 'react';
import styled from '@emotion/styled';
import { Typography } from '@mui/material';

const TYPOGRAPHY = {
  H1: 'h1',
  H2: 'h2',
  H3: 'h3',
  H4: 'h4',
  H5: 'h5',
  H6: 'h6',
  SUBTITLE: 'subtitle1',
  SUBTITLE2: 'subtitle2',
  BODY1: 'body1',
  BODY2: 'body2',
  CAPTION: 'caption',
  BUTTON: 'button',
  OVERLINE: 'overline',
};

export const Subtitle1 = ({ className, children }) => (
  <Typography className={className} variant={TYPOGRAPHY.SUBTITLE}>
    {children}
  </Typography>
);

export const H3 = ({ className, children }) => (
  <Typography className={className} variant={TYPOGRAPHY.H3}>
    {children}
  </Typography>
);

export const H4 = ({ className, children }) => (
  <Typography className={className} variant={TYPOGRAPHY.H4}>
    {children}
  </Typography>
);

export const H5 = ({ className, children }) => (
  <Typography className={className} variant={TYPOGRAPHY.H5}>
    {children}
  </Typography>
);

export const H6 = ({ className, children }) => (
  <Typography className={className} variant={TYPOGRAPHY.H6}>
    {children}
  </Typography>
);

export const Body1 = ({ className, children }) => (
  <Typography className={className} variant={TYPOGRAPHY.BODY1}>
    {children}
  </Typography>
);

export const Body2 = ({ className, children }) => (
  <Typography className={className} variant={TYPOGRAPHY.BODY2}>
    {children}
  </Typography>
);

export const Caption = ({ className, children }) => (
  <Typography className={className} variant="caption">
    {children}
  </Typography>
);

export const Bold = styled.span`
  font-weight: 500;
`;
