import { ErrorMessage } from '@hookform/error-message';
import { Typography } from '@mui/material';
import React from 'react';
import styled from '@emotion/styled';

const ErrorText = styled(Typography)`
  font-weight: 400;
`;

export default ({ name, errors, messagesByErrorType }) => {
  const errorType = errors[name]?.type;
  const userDefinedMessage = messagesByErrorType?.[errorType];
  return (
    <ErrorMessage
      errors={errors}
      name={name}
      render={({ message }) => (
        <ErrorText variant="body1" color="error">
          {userDefinedMessage ?? message}
        </ErrorText>
      )}
    />
  );
};
