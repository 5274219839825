import React from 'react';
import _ from 'lodash';
import { Section } from '../AddEditWidgetModal.styled';
import Button from '../../../Button';
import Title from './Title';
import { Autocomplete } from '../../../form/library';
import { mapFiltersOptions } from '../../utility';

export default ({
  arrayFields,
  filterOptions,
  filterValues,
  setFilterValues,
  filtersState,
  api,
  onErrorHandler,
  onChangeReset,
  control,
  errors,
}) => {
  const { fields, append, remove } = arrayFields;
  return (
    <div>
      {fields.map((field, index) => {
        const selectedFilterValues = mapFiltersOptions(filterValues[filtersState[index]?.name] ?? []);
        return (
          <Section boxShadow={2} p={2} mb={2} key={field.id}>
            <Title
              title={`Filter #${index + 1}`}
              onDelete={() => {
                remove(index);
                setFilterValues(currentState => {
                  const values = { ...currentState };
                  const { name } = filtersState[index] ?? {};
                  if (name) {
                    delete values[filtersState[index]?.name];
                  }
                  return values;
                });
              }}
            />
            <Autocomplete
              name={`filters[${index}].name`}
              label="Actual on-time delivery performance"
              control={control}
              inputProps={{ error: _.get(errors, `filters[${index}].name`) }}
              options={filterOptions.map(({ key }) => key)}
              getOptionLabel={option => filterOptions.find(({ key }) => option === key)?.text}
              isOptionEqualToValue={(option, value) => option === value}
              onBlur={event => {
                const { key } = filterOptions.find(({ text }) => text === event.target.value);
                onChangeReset(index);
                if (key) {
                  api
                    .getFilterValues(key)
                    .then(res =>
                      setFilterValues(currentState => ({
                        ...currentState,
                        [key]: res,
                      })),
                    )
                    .catch(onErrorHandler);
                }
              }}
            />

            <Autocomplete
              name={`filters[${index}].value`}
              label="Filter value"
              control={control}
              getOptionLabel={option => option}
              isOptionEqualToValue={(option, value) => option === value}
              inputProps={{ error: _.get(errors, `filters[${index}].value`) }}
              id={index}
              multiple
              options={selectedFilterValues.map(({ key }) => key)}
            />
          </Section>
        );
      })}
      <Button onClick={() => append({})} color="primary">
        ADD FILTER
      </Button>
    </div>
  );
};
