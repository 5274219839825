import React from 'react';
import { ContractType, DetailsOverview, OrderStatus, CONTRACT_TYPE } from '@leaf/components';

export default React.memo(({ opportunity }) => (
  <DetailsOverview
    title="OPPORTUNITY DETAILS"
    data={[
      {
        type: 'row',
        data: {
          label: 'Contract',
          value: <ContractType type={opportunity?.contract} />,
        },
      },
      {
        type: 'row',
        data: {
          label: 'Status',
          value: <OrderStatus status={opportunity?.status} />,
        },
      },
      {
        type: 'row',
        data: {
          label: 'Equipment',
          value: opportunity?.equipmentType?.name,
        },
      },
      {
        type: 'divider',
      },
      {
        type: 'row',
        data: {
          label: 'TIMEFRAME & VOLUME',
          isSubHeader: true,
        },
      },
      {
        type: 'row',
        data: {
          label: 'Start',
          value: opportunity?.startDate,
        },
      },
      {
        type: 'row',
        data: {
          label: 'End',
          value: opportunity?.endDate,
        },
      },
      {
        type: 'row',
        data: {
          label: <ContractType type={CONTRACT_TYPE.DAY_OF} />,
          value: opportunity?.dayOfExecutions,
        },
      },
      {
        type: 'row',
        data: {
          label: <ContractType type={CONTRACT_TYPE.WEEK_OF} />,
          value: opportunity?.weeklyContractedTrips,
        },
      },
      {
        type: 'row',
        data: {
          label: <ContractType type={CONTRACT_TYPE.UNCOMMITTED} />,
          value: opportunity?.avgWeeklyNonContractedTrips,
        },
      },
      {
        type: 'divider',
      },
      {
        type: 'row',
        data: {
          label: 'TRIP DETAILS',
          isSubHeader: true,
        },
      },
      {
        type: 'row',
        data: {
          label: 'Distance',
          value: opportunity?.totalMiles,
        },
      },
      {
        type: 'row',
        data: {
          label: 'Legs',
          value: opportunity?.legs,
        },
      },
      {
        type: 'row',
        data: {
          label: 'Loaded',
          value: opportunity?.loadedMiles,
        },
      },
      {
        type: 'row',
        data: {
          label: 'Deadhead',
          value: opportunity?.deadheadMiles,
        },
      },
    ]}
  />
));
