/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect, useCallback } from 'react';
import { FormControl as FormControlMUI, InputAdornment } from '@mui/material';
import { debounce } from 'lodash';
import styled from '@emotion/styled';
import { Generic } from '../form';

const FormControl = styled(FormControlMUI)`
  min-width: 300px;
  .MuiAutocomplete-popper {
    margin-top: 41px;
  }
`;

const InRowContainer = styled.div`
  display: flex;
  align-items: center;
  grid-gap: ${({ theme }) => `${theme.spacing(2)}`};
`;

const MINIMUM_CHARACTER = 2; // minimum user can write is for e.g CA or OH

const LOCATION_TYPES = {
  CITY: location => `${location.city}, ${location.state} ${location.zipCode}`,
  STATE: location => `${location.state} (state)`,
};

const LocationFilter = ({ filterList, onChange, index, column, getLocations, defaultRadius = 50 }) => {
  const [searchTerm, setSearchTerm] = useState();
  const [options, setOptions] = useState([]);
  const [disabledRadius, setDisabledRadius] = useState(false);
  const latitude = filterList[index][0];
  const longitude = filterList[index][1];
  const radius = filterList[index][2];
  const location = filterList[index][3];

  const debounceSetLocations = useCallback(
    debounce(inputVal => {
      getLocations(inputVal).then(response =>
        setOptions(
          response.map(item => ({
            label: LOCATION_TYPES?.[item.type ?? 'CITY'](item).trimEnd(),
            value: [item.latitude, item.longitude],
          })),
        ),
      );
    }, 250),
    [],
  );

  useEffect(() => {
    if (filterList[index].length === 3) {
      getLocations(`${latitude},${longitude}`).then(({ response }) => {
        if (response.length === 1) {
          setOptions(
            response.map(item => ({
              label: LOCATION_TYPES?.[item.type ?? 'CITY'](item).trimEnd(),
              value: [item.latitude, item.longitude],
            })),
          );
        }
      });
    }
  }, []);

  useEffect(() => {
    if (searchTerm) {
      debounceSetLocations(searchTerm);
    }
  }, [searchTerm]);

  const onChangeLocation = (e, value) => {
    if (value) {
      if (value.label?.indexOf('(state)') !== -1) {
        setDisabledRadius(true);
        onChange([...value.value, undefined, value.label.replace(',', '')], index, column);
      } else {
        setDisabledRadius(false);
        onChange([...value.value, radius ?? defaultRadius, value.label.replace(',', '')], index, column);
      }
    } else {
      setDisabledRadius(false);
      onChange([], index, column);
    }
  };

  const setInputValue = (e, value) => {
    if (value.length >= MINIMUM_CHARACTER) setSearchTerm(value);
  };

  const getValue = () => {
    if (filterList[index]?.length > 0) {
      return {
        label: location,
        value: [latitude, longitude],
      };
    }

    return undefined;
  };

  const renderInput = params => <Generic.Input label={column.label} {...params} />;

  const onChangeRadius = ({ target }) => {
    if (filterList[index].length === 0) {
      onChange([0, 0, target.value, ''], index, column);
    }
    onChange([latitude, longitude, target.value, location], index, column);
  };

  return (
    <FormControl>
      <InRowContainer>
        <Generic.Autocomplete
          fullWidth
          id={column.name}
          name={column.name}
          autoComplete
          filterOptions={x => x}
          filterSelectedOptions
          getOptionLabel={option => option.label}
          isOptionEqualToValue={(option, selected) =>
            option.value === (typeof selected === 'string' ? selected : selected?.value)
          }
          includeInputInList
          onChange={onChangeLocation}
          onInputChange={setInputValue}
          options={options}
          renderInput={renderInput}
          value={getValue()}
        />
        <Generic.Input
          id={column.name}
          name={column.name}
          label="Radius"
          value={radius}
          onChange={onChangeRadius}
          type="number"
          InputProps={{
            endAdornment: (
              <InputAdornment sx={{ mr: 2 }} position="end">
                miles
              </InputAdornment>
            ),
            inputProps: { min: 5 },
          }}
          InputLabelProps={{ shrink: radius }}
          disabled={disabledRadius}
        />
      </InRowContainer>
    </FormControl>
  );
};

export default LocationFilter;
