import axios from 'axios';
import {
  TRIP_TYPE_DISPLAY_NAMES,
  utility,
  ORDER_STATUS_DISPLAY_NAMES,
  CONTRACT_TYPE,
  TRIP_TYPE,
} from '@leaf/components';

const mapData = data => ({
  ...data,
  routes: data.routes.map((route, index) => ({
    ...route,
    deadheadMiles: utility.format.miles(route?.avgDeadheadMiles),
    totalMiles: utility.format.miles(route?.avgTotalMiles),
    tripType: TRIP_TYPE_DISPLAY_NAMES[route?.tripType],
    id: route?.id,
    displayOriginName: route.legs[0].lane?.originName,
    displayDestinationName:
      route.tripType === TRIP_TYPE.ROUND_TRIP
        ? route.legs[0].lane?.destinationName
        : route.legs[route.legs.length - 1].lane?.destinationName,
    isPrimary: index === 0,
    legs: route?.legs.map(leg => ({
      origin: leg?.lane?.originName,
      pickupType: leg?.lane?.pickupType,
      destination: leg?.lane?.destinationName,
      deliveryType: leg?.lane?.deliveryType,
      miles: utility.format.miles(leg?.lane?.leafMiles),
      laneId: leg?.lane?.id,
      deadheadMiles: utility.format.miles(leg?.deadheadMiles) ?? '',
      originGeo: leg?.lane?.originGeoJson,
      destinationGeo: leg?.lane?.destinationGeoJson,
      pathGeo: leg?.lane?.pathGeoJson,
    })),
  })),
  laneData: {
    origin: data.routes[0].legs[0].lane.originName,
    destination: data.routes[0].legs[0].lane.destinationName,
    contract: data.contractType,
    status: ORDER_STATUS_DISPLAY_NAMES[data.contractStatus],
    equipmentType: data.equipmentType,
    startDate: utility.date.formatDate(new Date(data.startDate), utility.date.VIEW_DATE_FORMAT),
    endDate: utility.date.formatDate(new Date(data.endDate), utility.date.VIEW_DATE_FORMAT),
    dayOfExecutions: data.contractType === CONTRACT_TYPE.DAY_OFF ? 1 : '-',
    weeklyContractedTrips: data.contractType === CONTRACT_TYPE.WEEK_OF ? 1 : '-',
    avgWeeklyNonContractedTrips: data.contractType === CONTRACT_TYPE.UNCOMMITTED ? 1 : '-',
    totalMiles: utility.format.miles(data.routes.reduce((a, b) => a + b.avgTotalMiles, 0)),
    legs: data.routes.reduce((a, b) => a + b.legs.length, 0),
    loadedMiles: utility.format.miles(data.routes.reduce((a, b) => a + b.avgLoadedMiles, 0)),
    deadheadMiles: utility.format.miles(data.routes.reduce((a, b) => a + b.avgDeadheadMiles, 0)),
  },
});

export const getById = async contractId =>
  axios
    .get(`view/opportunities/${contractId}`, {
      baseURL: `${process.env.REACT_APP_API}`,
    })
    .then(({ data }) => mapData(data));
