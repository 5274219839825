import React from 'react';
import { Typography } from '@mui/material';

export const fuelSurcharge = (
  <div>
    <Typography variant="body1">
      Freight charges will be subject to a fuel surcharge which will be billed as a separate charge on freight invoices.
      The charge will be adjusted up or down each Monday by the cost per mile adjustment listed on the following matrix:
    </Typography>
    <Typography variant="body1">
      The fuel surcharge shall be determined by reference to the U.S. Department of Energy (&quot;DOE&quot;) diesel fuel
      price national average. Shipper will pay a fuel surcharge of $0.01 per mile for each $0.065 per gallon increase
      above a base of $0.0 per gallon.
    </Typography>
    <Typography variant="body1">Therefore, the following schedule will apply:</Typography>
    <table>
      <thead>
        <tr>
          <td>DOE price</td>
          <td>Surcharge</td>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>0.0</td>
          <td>None</td>
        </tr>
        <tr>
          <td>0.01 to 0.065</td>
          <td>0.01/mile</td>
        </tr>
        <tr>
          <td>0.066 to 0.130</td>
          <td>0.02/mile</td>
        </tr>
        <tr>
          <td>0.131 to 0.195</td>
          <td>0.03/mile</td>
        </tr>
        <tr>
          <td>0.196 to 0.260</td>
          <td>0.04/mile</td>
        </tr>
        <tr>
          <td>0.261 to 0.325</td>
          <td>0.05/mile</td>
        </tr>
        <tr>
          <td>0.326 to 0.390</td>
          <td>0.06/mile</td>
        </tr>
        <tr>
          <td>0.391 to 0.455</td>
          <td>0.07/mile</td>
        </tr>
        <tr>
          <td>0.456 to 0.520</td>
          <td>0.08/mile</td>
        </tr>
        <tr>
          <td>0.521 to 0.585</td>
          <td>0.09/mile</td>
        </tr>
      </tbody>
    </table>

    <Typography variant="body1">
      The same formula will be used should the index reach or exceed $0.585. Adjustments will be made each Monday based
      upon the weekly DOE price.
    </Typography>
  </div>
);
