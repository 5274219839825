import React from 'react';
import styled from '@emotion/styled';

const IconWrapper = styled.div`
  svg {
    height: ${({ sizeProp }) => `${sizeProp}`};
    width: ${({ sizeProp }) => `${sizeProp}`};
    color: rgba(0, 0, 0, 0.54);
  }
`;

export default ({ size = 16 }) => (
  <IconWrapper sizeProp={size}>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23 1C23 1 15.025 1.02753 11.9725 1.02753C5.89528 1.02753 1 5.95619 1 12.0138V15.6208L5.3725 14.2991V12.0138C5.3725 7.801 7.76527 5.29537 12 5.29537H18.6828C18.6828 7.85607 18.7653 10.2791 18.7653 12.0138C18.7653 16.2265 16.235 18.7046 12 18.7046H9.7175L8.425 23C10.5703 23 10.7903 22.9725 12.0278 22.9725C18.105 22.9725 23 18.0438 23 11.9862V1Z"
        fill="#183B5A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.02728 17.796V23H6.25228L9.60728 14.4092L1.02728 17.796Z"
        fill="#1DD264"
      />
    </svg>
  </IconWrapper>
);
