import React from 'react';
import classNames from 'classnames';

import { colorSettings } from '../../Config';
import { BarLoader, Icon, IconSizes, ActionLink } from '../';

import './EmptyPage.scss';

export default React.memo(
  ({
    className,
    hasSpinner,
    hasIcon,
    iconName,
    isIconSmall = false,
    textLine1,
    textLine2,
    hasActionLink,
    actionLinkText,
    onActionLinkClick,
  }) => (
    <div className={classNames('empty-page', className)}>
      {hasSpinner && (
        <div className="empty-page__spinner-wrapper">
          <BarLoader className="empty-page__spinner" color={colorSettings.spinnerColor} width={150} />
        </div>
      )}
      {hasIcon && (
        <Icon
          name={iconName}
          size={isIconSmall ? IconSizes.xxxl : IconSizes.xxxxl}
          className={classNames({
            'empty-page__icon': true,
            'empty-page__icon--small': isIconSmall,
          })}
        />
      )}
      <div className="empty-page__text">{textLine1}</div>
      <div className="empty-page__text">{textLine2}</div>
      {hasActionLink && (
        <div className="empty-page__action-link-wrapper">
          <ActionLink onClick={onActionLinkClick}>{actionLinkText}</ActionLink>
        </div>
      )}
    </div>
  ),
);
