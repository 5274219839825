import React from 'react';
import styled from '@emotion/styled';
import { Typography, Select, MenuItem, Box } from '@mui/material';
import { Today } from '@mui/icons-material';
import { v4 } from 'uuid';
import { utility, PHASE_TYPE, PHASE_TYPE_DISPLAY_NAMES, PhaseIcon, Form } from '..';

const SelectWrapper = styled(Select)`
  width: 172px;
`;

const PhaseIconContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const DateIconBox = styled(Box)`
  width: 20px;
  height: 20px;
  border-radius: 4px;
  background-color: ${({ bgcolor }) => `${bgcolor}`};
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: ${({ theme }) => theme.spacing(2)};

  .MuiSvgIcon-root {
    width: 20px;
    height: 20px;
  }
`;

const phaseOptions = Object.keys(PHASE_TYPE_DISPLAY_NAMES).reduce((acc, key) => {
  acc[key] = PHASE_TYPE_DISPLAY_NAMES[key];
  return acc;
}, {});

const PhaseSelect = ({
  startDate,
  shipperId,
  status,
  laneIds,
  data,
  patchData,
  updateLanePhases,
  displayEmpty = false,
  equipmentTypes,
  variant = 'standard',
}) => {
  const [openPicker, setOpenPicker] = React.useState(false);
  const [selectedDate, setSelectedDate] = React.useState();
  const [selectedDateFromPicker, setSelectedDateFromPicker] = React.useState();

  const getPhaseKey = () => {
    if (selectedDate) {
      if (selectedDate === PHASE_TYPE.UNASSIGNED) return PHASE_TYPE.UNASSIGNED;
      if (selectedDate === PHASE_TYPE.OUT_OF_SCOPE) return PHASE_TYPE.OUT_OF_SCOPE;

      return data.find(item => item.shipperId === shipperId && item.startDate === selectedDate)?.startDate;
    }
    if (status === PHASE_TYPE.OUT_OF_SCOPE) return PHASE_TYPE.OUT_OF_SCOPE;
    if (startDate) {
      const itemExists = data.find(item => item.shipperId === shipperId && item.startDate === startDate);

      if (itemExists?.startDate) return itemExists.startDate;

      return PHASE_TYPE.OUT_OF_SCOPE;
    }
    if (displayEmpty) return '';

    return PHASE_TYPE.UNASSIGNED;
  };

  const handleChangePhase = event => {
    event.stopPropagation();
    if (event.target.value === 'DATE_PICKER') {
      setOpenPicker(true);
    } else if (event.target.value === PHASE_TYPE.OUT_OF_SCOPE) {
      const payload = {
        recordKeys: laneIds.map((laneId, index) => ({ lane_id: laneId, equipment_class: equipmentTypes[index] })),
        properties: [
          {
            field: 'is_out_of_scope',
            value: true,
          },
          {
            field: 'start_date',
            value: null,
          },
        ],
      };
      patchData(payload).then(() => {
        setSelectedDate(PHASE_TYPE.OUT_OF_SCOPE);
        updateLanePhases();
      });
    } else if (event.target.value === PHASE_TYPE.UNASSIGNED) {
      const payload = {
        recordKeys: laneIds.map((laneId, index) => ({ lane_id: laneId, equipment_class: equipmentTypes[index] })),
        properties: [
          {
            field: 'is_out_of_scope',
            value: false,
          },
          {
            field: 'start_date',
            value: null,
          },
        ],
      };
      patchData(payload).then(() => {
        setSelectedDate(PHASE_TYPE.UNASSIGNED);
        updateLanePhases();
      });
    } else if (event.target.value) {
      const payload = {
        recordKeys: laneIds.map((laneId, index) => ({ lane_id: laneId, equipment_class: equipmentTypes[index] })),
        properties: [
          {
            field: 'is_out_of_scope',
            value: false,
          },
          {
            field: 'start_date',
            value: event.target.value,
          },
        ],
      };
      patchData(payload).then(retVal => {
        setSelectedDate(retVal.records[0].record.start_date);
        updateLanePhases();
      });
    }
  };

  const handleCloseDatepicker = () => {
    setOpenPicker(false);
  };

  const handleAcceptDate = date => {
    const payload = {
      recordKeys: laneIds.map((laneId, index) => ({ lane_id: laneId, equipment_class: equipmentTypes[index] })),
      properties: [
        {
          field: 'is_out_of_scope',
          value: false,
        },
        {
          field: 'start_date',
          value: utility.date.formatDate(date, utility.date.DATE_BACKEND_FORMAT),
        },
      ],
    };
    patchData(payload).then(retVal => {
      setSelectedDate(retVal.records[0].record.start_date);
      updateLanePhases();
    });
  };

  const handleDateChange = date => {
    setSelectedDateFromPicker(date);
  };

  const renderDatePicker = () => (
    <Form.Generic.Datepicker
      label="Select phase date"
      open={openPicker}
      value={selectedDateFromPicker}
      onClose={handleCloseDatepicker}
      onChange={handleDateChange}
      onAccept={handleAcceptDate}
    />
  );

  return (
    <>
      <SelectWrapper
        disableUnderline
        value={getPhaseKey()}
        onChange={handleChangePhase}
        displayEmpty={displayEmpty}
        variant={variant}
      >
        {displayEmpty && (
          <MenuItem value="">
            <PhaseIconContainer>
              <Typography variant="h6">Select</Typography>
            </PhaseIconContainer>
          </MenuItem>
        )}
        {data.map((item, index) => (
          <MenuItem key={v4()} value={item.startDate}>
            <PhaseIconContainer>
              <PhaseIcon phaseIndex={index} />
              <Typography variant="h6">
                {utility.date.formatStringDate(item.startDate, utility.date.VIEW_FORMAT)}
              </Typography>
            </PhaseIconContainer>
          </MenuItem>
        ))}
        {Object.keys(phaseOptions).map(key => (
          <MenuItem key={key} value={key}>
            <PhaseIconContainer>
              <PhaseIcon phaseName={PHASE_TYPE_DISPLAY_NAMES[key]} />
            </PhaseIconContainer>
          </MenuItem>
        ))}
        <MenuItem value="DATE_PICKER">
          <PhaseIconContainer>
            <DateIconBox>
              <Today />
            </DateIconBox>
            <Typography variant="h6">Select date</Typography>
          </PhaseIconContainer>
        </MenuItem>
      </SelectWrapper>
      {openPicker && renderDatePicker()}
    </>
  );
};

export default PhaseSelect;
