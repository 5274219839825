import React from 'react';
import classNames from 'classnames';

import './Icon.scss';
import styled from '@emotion/styled';
import { css } from '@emotion/react';

export const sizes = {
  xxs: 'xxs',
  xs: 'xs',
  sm: 'sm',
  base: 'base',
  md: 'md',
  lg: 'lg',
  xl: 'xl',
  xxl: 'xxl',
  xxxl: 'xxxl',
  xxxxl: 'xxxxl',
};

export const IconWrapper = styled.span`
  svg {
    height: ${({ theme, size }) => theme.spacing(size || 3)};
    width: ${({ theme, size }) => theme.spacing(size || 3)};
    ${({ isClickable }) =>
      isClickable &&
      css`
        cursor: pointer;
      `}
  }
`;

const Icon = ({
  name,
  className,
  onClick,
  isUpsideDown = false,
  isInfo = false,
  isSuccess = false,
  isWarning = false,
  isDanger = false,
  isDeEmphasized = false,
  isAction = false,
  isClose = false,
  isDark = false,
  isBlack = false,
  isInverted = false,
  isVerticalAlignedMiddle = false,
  tooltip = undefined,
  size = sizes.base,
  hasIndicator = false,
  ...props
}) => (
  <i
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...props}
    className={classNames(className, {
      'le-icon': true,
      [`icon-${name}`]: true,
      [`le-icon-${size}`]: true,
      'le-icon--clickable': !!onClick,
      'le-icon--upside-down': isUpsideDown,
      'le-icon--action': isAction,
      'le-icon--close': isClose,
      'le-icon--dark': isDark,
      'le-icon--black': isBlack,
      'le-icon--inverted': isInverted,
      'le-icon--info': isInfo,
      'le-icon--success': isSuccess,
      'le-icon--warning': isWarning,
      'le-icon--danger': isDanger,
      'le-icon--deemphasized': isDeEmphasized,
      'le-icon--middle': isVerticalAlignedMiddle,
    })}
    role="button"
    tabIndex={0}
    title={tooltip}
    onClick={onClick}
    onKeyPress={onClick}
  >
    {hasIndicator && <Icon name="indicator-buy-draft-copy" className="le-icon__indicator" size={sizes.xxs} />}
  </i>
);

export default Icon;
