import { TextField as TextFieldMUI, MenuItem } from '@mui/material';
import React from 'react';
import styled from '@emotion/styled';

import './Select.scss';

const TextField = styled(TextFieldMUI)`
  .MuiInputBase-root {
    height: 36px;
  }
  .MuiSelect-select:focus {
    background-color: white;
  }
`;

const Select = React.memo(({ options, onSelect, selectedId }) => (
  <TextField
    size="small"
    select
    variant="outlined"
    defaultValue={selectedId}
    onChange={event => onSelect(event.target.value)}
  >
    {options.map(option => (
      <MenuItem key={option.id} value={option.id}>
        {option.name}
      </MenuItem>
    ))}
  </TextField>
));

export default Select;
