import React, { useCallback } from 'react';
import { IconButton, Typography, List } from '@mui/material';
import { ChevronLeft, ChevronRight, ArrowDropDown, ArrowDropUp } from '@mui/icons-material';
import { v4 } from 'uuid';
import { Drawer, Footer, ToggleNavbar, ToggleSidebar, Toolbar } from './NavigationMenu.styled';

import NavigationItem from './NavigationItem';
import NestedNavigation from './NestedNavigation';

export default ({ menuItems, menuTitle }) => {
  const [isNavbarOpen, setIsNavbarOpen] = React.useState(false);
  const [sidebarExpanded, setSidebarExpanded] = React.useState(localStorage.getItem('sidebarExpanded') === 'true');

  const toggleOpenSidebar = () => {
    localStorage.setItem('sidebarExpanded', !sidebarExpanded);
    setSidebarExpanded(!sidebarExpanded);
    setTimeout(() => window.dispatchEvent(new Event('resize')), 250);
  };

  const toggleOpenNavbars = () => {
    setIsNavbarOpen(!isNavbarOpen);
  };

  const renderMenu = () => {
    if (menuItems?.length > 0) {
      return menuItems.map(item =>
        item?.childs ? (
          <NestedNavigation
            key={item.text}
            parent={{ Icon: item.icon, text: item.text }}
            childs={item.childs}
            link={item.link}
            location={item.location}
            isNavbarOpen={isNavbarOpen}
            isSidebarExpanded={sidebarExpanded}
          />
        ) : (
          <List key={v4()}>
            <NavigationItem text={item.text} to={item.to} link={item.link} onClick={item.click}>
              <item.icon />
            </NavigationItem>
          </List>
        ),
      );
    }

    return null;
  };

  const haveNestedItems = useCallback(() => menuItems?.some(item => item.childs), [menuItems]);

  return (
    <Drawer variant="permanent" open={sidebarExpanded} className={sidebarExpanded ? '' : 'sidebar-collapsed'}>
      <Toolbar>
        <img src="/logo.svg" alt="Logo" />
        <Typography variant="h4">{menuTitle}</Typography>
        {haveNestedItems() && (
          <ToggleSidebar open={isNavbarOpen} display="flex">
            <IconButton onClick={toggleOpenNavbars} size="large">{isNavbarOpen ? <ArrowDropUp /> : <ArrowDropDown />}</IconButton>
          </ToggleSidebar>
        )}
      </Toolbar>

      {renderMenu()}

      <Footer>
        <ToggleNavbar open={sidebarExpanded} display="flex">
          <IconButton onClick={toggleOpenSidebar} size="large">{sidebarExpanded ? <ChevronLeft /> : <ChevronRight />}</IconButton>
        </ToggleNavbar>
      </Footer>
    </Drawer>
  );
};
