export const USER_ROLE = {
  COMPANY_ADMIN: 'COMPANY_ADMIN',
  LE_ADMIN: 'LE_ADMIN',
  TRADING_USER: 'TRADING_USER',
  USER: 'USER',
};

export const USER_ROLE_DISPLAY_NAMES = {
  COMPANY_ADMIN: 'Company Admin',
  LE_ADMIN: 'LE Admin',
  TRADING_USER: 'Trading user',
  USER: 'User',
};

export const USER_ROLE_INITIALS = {
  COMPANY_ADMIN: 'CA',
  LE_ADMIN: 'LA',
  TRADING_USER: 'TU',
  USER: 'U',
};
