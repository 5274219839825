import { utility } from '@leaf/components';
import { extractAddress } from 'shipments/utilities';

export default class ShipmentDelivery {
  constructor({ destination, delivery, ...response }) {
    Object.assign(this, {
      ...response,
    });

    const laneDestination = extractAddress(destination);

    this.view = {
      destinationAddress: [
        `${laneDestination.city}, ${laneDestination.state} ${laneDestination.zipCode}`,
        `${laneDestination.businessName}, ${laneDestination.street}`,
      ],
      status: delivery.status,
      inOutTime: [
        delivery.arrivalActual
          ? utility.date.formatStringDate(delivery.arrivalActual, utility.date.VIEW_DATE_TIME_FORMAT)
          : 'N/A',
        delivery.departureActual
          ? utility.date.formatStringDate(delivery.departureActual, utility.date.VIEW_DATE_TIME_FORMAT)
          : 'N/A',
      ],
      delay: delivery.delay || '-',
      appointmentTime: delivery.appointmentTime
        ? utility.date.formatStringDate(delivery.appointmentTime, utility.date.VIEW_DATE_TIME_FORMAT)
        : '-',
    };
  }
}
