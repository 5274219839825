import React from 'react';
import { Typography } from '@mui/material';

export const trendingRules = (
  <div>
    <Typography variant="h4">Introduction</Typography>
    <Typography variant="body1">
      Leaf Logistics (Leaf) brings financial market efficiency to the transportation marketplace. Our platform connects
      carriers, shippers, and Logistics Service Providers to intelligently match demand with capacity. Upon agreeing to
      our contracting and the trading terms outlined in this document, buyers and sellers can enter into binding Leaf
      Forward contracts. As needs evolve, contracted parties can trade these forward contracts in accordance with the
      rules outlined in this document.{' '}
    </Typography>
    <Typography variant="body1">
      The laws of the United States are the ultimate authority over business contracts for any goods or services. The
      parties intending to buy, sell and trade contracts on Leaf will need to rely on a fair, efficient system for
      dispute resolution. Leaf offers a simple and complete arbitration remedy in the event a dispute arises between
      contracted parties. The processes by which either or both parties can request Leaf Arbitration are set forth in
      this document.{' '}
    </Typography>
    <Typography variant="h4">Definitions</Typography>
    <Typography variant="body1">
      Capitalized terms used in these Trading Rules and not otherwise defined below shall have the meanings set forth in
      the standard form of Leaf Forward Contract, including the General Conditions attached thereto. The following terms
      shall have the meanings set forth below.
    </Typography>
    <Typography variant="body1">
      &quot;Alternative Panel Selection&quot; means the method by which a Petitioner and a Respondent each choose an
      arbitrator, and these two arbitrators choose a third arbitrator to complete a panel.
    </Typography>
    <Typography variant="body1">
      &quot;Arbitrator&quot; or &quot;Arbitrator(s)&quot; means the person(s) selected under these Rules to hear the
      controversy and render a decision. They shall become such and assume their office upon their notifying the
      Secretary of their acceptance and taking the oath.
    </Typography>
    <Typography variant="body1">
      &quot;Base Rate&quot; means the rate per mile to be charged by a Carrier under a Forward Contract, as such Base
      Rate may be increased or decreased in connection with Trade-out Sell Orders and Trade-out Buy Orders.
    </Typography>
    <Typography variant="body1">
      &quot;Business Days&quot; means the days of the week from Monday through Friday, excluding all legal holidays as
      recognized by the State of New York and all declared closings of Leaf offices.
    </Typography>
    <Typography variant="body1">
      &quot;Buy Order&quot; means an order posted on the Platform by a User wishing to buy trucking capacity. Each Buy
      Order shall include the applicable Lane Specifications.{' '}
    </Typography>
    <Typography variant="body1">
      &quot;Carrier&quot; means any motor carrier under 49 U.S.C. 13102(12) that is duly registered with the Department
      of Transportation pursuant to 49 U.S.C. 13902 and 13905 and that has registered as a User on the Platform.
    </Typography>
    <Typography variant="body1">
      &quot;Contract Agent&quot; means a party that (i) acts as an Execution Partner in the absence of a Contract
      Failure or (ii) acts as a broker in the event of a Contract Failure.
    </Typography>
    <Typography variant="body1">
      &quot;Contract Failure&quot; means the failure by a party to a Forward Contract to perform its duties under such
      Forward Contract.
    </Typography>
    <Typography variant="body1">
      &quot;Execution Partner&quot; means any party that a User has designated to act as its agent in connection with a
      Forward Contract.
    </Typography>
    <Typography variant="body1">
      &quot;Expiration Date&quot; means, with respect to an Order, the date that is one (1) week after the date on which
      such Order is posted, unless the User posting such Order specifies another date, provided, however, that such
      other date may not be less than two (2) days prior to the applicable first pick-up date.
    </Typography>
    <Typography variant="body1">
      &quot;Forward Contract&quot; means an Leaf Forward Contract entered into by Users of the Platform.
    </Typography>
    <Typography variant="body1">
      &quot;Lane Specifications&quot; means the pick-up date, the pick-up address, the delivery address, the equipment
      type and any requested accessorials set forth in a Buy Order.
    </Typography>
    <Typography variant="body1">
      &quot;LE Account&quot; means the account that Leaf will establish and maintain for each User. Each Leaf Account
      will be credited with any amounts payable to, and debited for any amounts owing by, such User with under Forward
      Contracts, Trade-out Sell Orders and Trade-out Buy Orders, as well as transaction fees charged by Leaf.
    </Typography>
    <Typography variant="body1">
      &quot;Line Haul Charge&quot; means, with respect to any shipment, an amount equal to the then applicable Base
      Rate, multiplied by the mileage of the shipment determined by PC Miler.
    </Typography>
    <Typography variant="body1">
      &quot;Order&quot; means a Buy Order, a Sell Order, a Trade-out Buy Order or a Trade-out Sell Order, as applicable.
    </Typography>
    <Typography variant="body1">
      &quot;Panel&quot; or &quot;Arbitration Panel&quot; means the empaneled arbitrators who are members of Leaf.
    </Typography>
    <Typography variant="body1">
      &quot;Party&quot; or &quot;Parties&quot; shall mean each and all of those who are named as litigants in the
      arbitration and in favor of whom or against whom a claim is or may be made. The &quot;Petitioner&quot; is the
      party who commences the arbitration. The &quot;Respondent&quot; is the other party thereto.
    </Typography>
    <Typography variant="body1">
      &quot;Person&quot; means an individual, partnership, corporation, or other entity either singular or plural.
    </Typography>
    <Typography variant="body1">
      &quot;Platform&quot; means the web platform and driver app, POS systems and other digital assets used by Leaf
      Logistics to operate the leaflogistics.com website.
    </Typography>
    <Typography variant="body1">&quot;Secretary&quot; means the Secretary of the Arbitration Panel of Leaf.</Typography>
    <Typography variant="body1">
      &quot;Sell Order&quot; means an order posted on the Platform by a User wishing to sell trucking capacity. Each
      Sell Order shall include the pick-up date(s), the zip codes in which pick-up and delivery may be made, the
      equipment type, the Base Rate being offered by such User and the accessorials that are being made available.{' '}
    </Typography>
    <Typography variant="body1">
      &quot;Specified Pick-up Date&quot; means the pick-up date specified in a Forward Contract.
    </Typography>
    <Typography variant="body1">
      &quot;Trade-out Buy Order&quot; means a Buy Order posted on the Platform by a User that has entered into a Forward
      Contract but no longer wishes to provide the trucking services under that Forward Contract. Each Trade-out Buy
      Order will include the Lane Specifications of the Forward Contract being traded out.
    </Typography>
    <Typography variant="body1">
      &quot;Trade-out Sell Order&quot; means a Sell Order posted on the Platform by a User that has entered into a
      Forward Contract but no longer wishes to utilize the trucking services under that Forward Contract. Each Trade-out
      Sell Order shall include the Lane Specifications of the Forward Contract being traded out, as well as the Base
      Rate being offered by such User, &quot;User&quot; means any party that has registered as a user of the Platform
      and has agreed to the Terms and Conditions of the Platform.
    </Typography>
    <Typography variant="h4">Formation of Original Forward Contracts</Typography>
    <ol>
      <li>
        The Platform will match Buy Orders with Sell Orders that conform to the Lane Specifications set forth in the Buy
        Order.{' '}
      </li>
      <li>
        The Platform will notify each User that has posted a Buy Order of the Sell Order(s) that conform to the Lane
        Specifications set forth in such User&#39;s Buy Order and provide the most favorable Base Rate. Such
        notification shall also include the Expiration Date for each such Sell Order.
      </li>
      <li>
        Users agree that, upon the acceptance of a Sell Order, prior to the Expiration Date for such Sell Order, by a
        User that has posted a Buy Order, such User and the User whose Sell Order is accepted shall enter into a Forward
        Contract. The Lane Specifications set forth in the Buy Order and the Base Rate set forth in the Sell Order will
        be included in Exhibit A to the Forward Contract. Leaf will post on the restricted page of each User a PDF of
        the Forward Contract that they have entered into, together with any applicable fuel surcharges and accessorial
        charges
      </li>
      <li>
        The parties to each Forward Contract will agree to perform their respective obligations in accordance with the
        General Terms and Conditions, which will be attached as Schedule 1 to each Forward Contract.{' '}
      </li>
    </ol>
    <Typography variant="h4">Trading Forward Contracts</Typography>
    <ol>
      <li>
        <Typography variant="body1">
          If a User that has entered into a Forward Contract, including any Forward Contract entered into as a result of
          a trading-out as provided in these Trading Rules (each, an &quot;Original Contract&quot;), but no longer
          wishes to utilize the trucking services under that Original Contract, such User (the &quot;Retiring
          Shipper&quot;) shall post a Trade-out Sell Order. Upon the acceptance of such Trade-out Sell Order by a User
          that has posted a Buy Order (the &quot;New Shipper&quot;), then:
        </Typography>
        <ul>
          <li>
            <Typography variant="body1">
              (a) The Original Contract shall be terminated and of no further force and effect.
            </Typography>
          </li>
          <li>
            <Typography variant="body1">
              (b) The Retiring Shipper shall have no further rights or obligations under the Original Contract.
            </Typography>
          </li>
          <li>
            <Typography variant="body1">
              (c) The New Shipper and a Contract Agent selected by the New Shipper from those listed on the Platform
              will enter into a new Forward Contract (the &quot;New Shipper Contract&quot;), and the Contract Agent will
              also enter into a new Forward Contract with the Carrier party to the Original Contract (the &quot;Carrier
              Contract&quot;).{' '}
            </Typography>
          </li>
          <li>
            <Typography variant="body1">
              (d) Exhibit A to the New Shipper Contract will include (i) the identity of the Carrier, (ii) the Lane
              Specifications included in the Buy Order posted by the New Shipper and (iii) the Base Rate included in the
              Trade-out Sell Order posted by the Retiring Shipper.
            </Typography>
          </li>
          <li>
            <Typography variant="body1">
              (e) Exhibit A to the Carrier Contract will include (i) the Base Rate included in the Original Contract and
              (ii) the Lane Specifications set forth in the Buy Order posted by the New Shipper.{' '}
            </Typography>
          </li>
          <li>
            <Typography variant="body1">
              (f) LE will post (i) a PDF of the New Shipper Contract on the restricted page of the New Shipper, (ii) a
              PDF of the Carrier Contract on the restricted page of the Carrier and (iii) notification that Original
              Contract has been terminated on the restricted page of the Retiring Shipper.
            </Typography>
          </li>
          <li>
            <Typography variant="body1">
              (g) Upon the termination of the Original Contract, and the effectiveness of the New Shipper Contract and
              the Carrier Contract, the Line Haul Charge will be recalculated based on the information set forth in
              Exhibit A, including the Lane Specifications and Base Rate, to the New Shipper Contract. The Leaf Account
              of the Retiring Shipper will be credited or debited, as the case may be, to reflect (i) the amount, if
              any, by which such recalculated Line Haul Charge is greater or less than the Line Haul Charge that was
              calculated based on the information set forth in Exhibit A, including the Lane Specifications and Base
              Rate, to the Original Contract and (ii) the applicable transaction fee payable to Leaf. Upon completion of
              the shipment, (i) the Carrier will invoice the Contract Agent, based on the information, including the
              Lane Specifications and Base Rate, set forth in Exhibit A to the Carrier Contract, and (ii) the Contract
              Agent will invoice the New Shipper, based on the information, including the Lane Specifications and Base
              Rate, set forth in Exhibit A to the New Shipper Contract. The amounts owed or owing by the parties will be
              accordingly debited or credited to their respective Leaf Accounts, and statements reflecting the net
              balances, positive or negative, will be forwarded to all Users as provided below.
            </Typography>
          </li>
        </ul>
      </li>
      <li>
        <Typography variant="body1">
          If a User that has entered into an Original Contract, but no longer wishes to provide the trucking services
          under that Original Contract, such User (the &quot;Retiring Carrier&quot;) shall post Trade-out Buy Order. The
          Platform will notify the Retiring Carrier of the Sell Order(s) that conform to the Lane Specifications set
          forth in the Retiring Carrier&#39;s Trade-out Buy Order and provide the most favorable Base Rate. Such
          notification shall also include the Expiration Date for each such Sell Order. Upon the acceptance by the
          Retiring Carrier of a Sell Order prior to its Expiration Date, then:
        </Typography>
        <ul>
          <li>(a) The Original Contract shall be terminated and of no further force and effect.</li>
          <li>(b) The Retiring Carrier shall have no further rights or obligations under the Original Contract.</li>
          <li>
            (c) The User whose Sell Order is accepted (the &quot;New Carrier&quot;) and a Contract Agent selected by the
            New Carrier from those listed on the Platform will enter into a Forward Contract (a &quot;New Carrier
            Contract&quot;), and the Contract Agent will also enter into a new Forward Contract with the Shipper party
            to the Original Contract (the &quot;Shipper Contract&quot;).{' '}
          </li>
          <li>
            (d) Exhibit A to the New Carrier Contract will include (i) the Lane Specifications set forth in the Original
            Contract and (ii) the Base Rate set forth in the Sell Order posted by the New Carrier.{' '}
          </li>
          <li>
            (e) Exhibit A to the Shipper Contract will include (i) identity of the New Carrier, (ii) the Lane
            Specifications set forth in the Original Contract and (iii) the Base Rate set forth in the Original
            Contract.
          </li>
          <li>
            (f) LE will post (i) a PDF of the New Carrier Contract on the restricted page of the New Carrier, (ii) a PDF
            of the Shipper Agreement on the restricted page of the Shipper and (iii) notification that Original Contract
            has been terminated on the restricted page of the Retiring Carrier.{' '}
          </li>
          <li>
            (g) Upon the termination of the Original Contract, and the effectiveness of the New Carrier Contract and the
            Shipper Agreement, the Line Haul Charge will be recalculated based on the information set forth in Exhibit
            A, including the Lane Specifications and Base Rate, to the New Carrier Contract. The Leaf Account of the
            Retiring Carrier will be credited or debited, as the case may be, to reflect (i) the amount, if any, by
            which such recalculated Line Haul Charge is greater or less than the Line Haul Charge that was calculated
            based on the information set forth in Exhibit A, including the Lane Specifications and Base Rate, to the
            Original Contract and (ii) the applicable transaction fee payable to Leaf. Upon completion of the shipment,
            (i) the New Carrier will invoice the Contract Agent, based on the information, including the Lane
            Specifications and Base Rate, set forth in Exhibit A to the New Carrier Contract, and (ii) the Contract
            Agent will invoice the Shipper, based on the information, including the Lane Specifications and Base Rate,
            set forth in Exhibit A to the Original Contract. The amounts owed or owing by the parties will be
            accordingly debited or credited to their respective Leaf Accounts, and statements reflecting the net
            balances, positive or negative, will be forwarded to all Users as provided below.
          </li>
        </ul>
      </li>
    </ol>
    <Typography variant="h4">Contract Failure</Typography>
    <ol>
      <li>
        In the event that either (i) by the close of business on the date that is two days prior to the Specified
        Pick-up Date a party to a Forward Contract has not sent to the other party confirmation that it will perform as
        agreed under the Forward Contract or (ii) at any time during the two-day period prior to the Specified Pick-up
        Date, a party to a Forward Contract determines that it will not be able to fulfill its obligations under such
        Forward Contract (each such party, a &quot;Defaulting Party&quot;), the Defaulting Party shall immediately
        notify Leaf . Upon receipt of any such notification, Leaf shall inform the other party to the Forward Contract
        (each such party, a &quot;Non-defaulting Party&quot;) of the Contract Failure under the Forward Contract (the
        &quot;Defaulted Contract&quot;), and the Non-defaulting Party shall enter into a new Forward Contract with a
        Contract Agent selected by the Non-defaulting Party from those listed on the Platform (the &quot;Replacement
        Contract&quot;). Pursuant to any such Replacement Contract, the Contract Agent shall procure for the
        Non-defaulting Party a replacement shipper or carrier, as the case may be, to provide the shipment(s) or
        trucking capacity, as the case may be, that the Defaulting Party was to provide under the Defaulted Contract.{' '}
      </li>
      <li>
        If the Defaulting Party was a carrier, the replacement carrier shall provide the Non-defaulting Party with the
        trucking capacity specified in the Defaulted Contract, and the Line Haul Charge shall be based on the Lane
        Specifications set forth in Exhibit A to the Defaulted Contract and a Base Rate specified by the replacement
        carrier.
        <ul>
          <li>
            a) If the Defaulting Party was a carrier, the Initial Carrier is responsible for the increase (if any) in
            Line Haul rate between the Line Haul Charge in the Defaulted Contract and the Line Haul Charge in the
            Replacement Contract.
          </li>
        </ul>
      </li>
      <li>
        If the Defaulting Party was a shipper, the replacement shipper shall provide the Non-defaulting Party with
        shipment(s) having the same pick-up date as the one set forth in Exhibit A to the Defaulted Contract and pick-up
        and delivery addresses each within a two hour driving radius of the original pick-up and delivery address, as
        calculated by PC Miler, and the Line Haul Charge shall be based on such modified Lane Specifications and the
        Base Rate set forth in Exhibit A to the Defaulted Contract.
        <ul>
          <li>
            a) If the Defaulting Party was the Shipper, and the Line Haul Charge of the Replacement Contract is less
            than the Defaulted Contract, the Shipper is responsible for the difference between the Line Haul Charge of
            the Defaulted Contract and the Line Haul Charge of the Replacement Contract.
          </li>
          <li>
            b) If the Defaulting Party was the Shipper and a Replacement Shipper was not available, the Shipper is
            responsible for the Line Haul Charge of the Defaulted Contract.
          </li>
        </ul>
      </li>
      <li>
        Upon completion of the shipment under a Replacement Contract, the carrier shall invoice the Contract Agent for
        the applicable Line Haul Charge. The Defaulting Party shall be liable for any increased costs incurred by a
        Non-defaulting Party as a result of the default by the Defaulting Party, unless the failure of the Defaulting
        Party to perform under the Defaulted Contract was due to Force Majeure .
      </li>
      <li>
        If a Shipper party to Forward Contract terminates that contract as a result of the Carrier&#39;s failure to
        comply with the insurance requirements under paragraph 5 of the General Conditions, the Shipper shall enter into
        a new Forward Contract with a Contract Agent selected by the Shipper from those listed on the Platform. The
        Contract Agent shall procure for the Shipper a replacement carrier to provide the trucking capacity that was to
        be provided under the terminated Forward Contract. The replacement carrier shall provide the Shipper with the
        trucking capacity specified in the terminated Forward Contract, and the Line Haul Charge shall be based on the
        Lane Specifications set forth in Exhibit A to the terminated Forward Contract and a Base Rate specified by the
        replacement carrier.
      </li>
    </ol>
    <Typography variant="h4">Invoicing</Typography>
    <Typography variant="body1">
      On the first business day of each week Leaf shall post on each User&#39;s restricted page on the Platform a
      statement for such User&#39;s Leaf Account, which statement shall reflect the credits and debits to such Leaf
      Account for transactions effected by such User on the Platform in the immediately preceding week, all transactions
      fees payable with respect to such transactions, amounts owed by such User upon the completion of a shipment under
      a Forward Contract, the receipt of amounts paid for the account such User upon the completion of a shipment under
      a Forward Contract and the net balance, positive or negative, in such User&#39;s Leaf Account as of the last day
      of the preceding week. Each statement shall be accompanied by an invoice with respect to any negative balance in
      such User&#39;s Leaf Account, which invoice shall be payable by wire transfer of immediately available funds no
      later than the third (3rd) business day following the date of such invoice. Leaf shall remit to each User, no
      later than the third (3rd) business day of each week by wire transfer of immediately available funds the amount of
      any positive balance in such User&#39;s Leaf Account as of the last business day of the immediately preceding
      week.
    </Typography>
    <Typography variant="h4">Dispute Resolution</Typography>
    <h2>Authority</h2>
    <Typography variant="body1">
      It is clearly stated in the Terms and Conditions of utilizing the Leaf Platform that: &quot;All controversies
      relating to, in connection with, or arising out of this contract, its modification, making or the authority or
      obligations of the signatories hereto, and whether involving the principals, agents, brokers, or others who
      actually subscribe hereto, shall be settled by arbitration in accordance with the &#39;Rules of Arbitration&#39;
      of Leaf., as they exist at the time of the arbitration (including provisions as to payment of fees and expenses).
      Arbitration is the sole remedy hereunder, and it shall be held in accordance with the procedures set forth herein,
      and judgment on any award may be entered in the courts of that State. All notices or judicial service in reference
      to arbitration or enforcement shall be deemed given if transmitted as required by the aforesaid rules.&quot; The
      Rules of Arbitration referred to in this contract clause are those that apply to all disputes arising under any
      Leaf contracts. Leaf does administer the arbitration procedure under these Rules and it designates the
      arbitrators. It is, however, the arbitrators who conduct the hearings, determine and decide the issue, and they
      alone have the power and authority to make an award.{' '}
    </Typography>
    <h2>Arbitration Process</h2>
    <h3>How to File an Arbitration</h3>
    <Typography variant="body1">
      Any person seeking arbitration shall commence arbitration in the following manner:{' '}
    </Typography>
    <ol>
      <li>By Requesting Arbitration on the Leaf Platform. </li>
      <li>
        Completing the Demand Form, with copies of contract attached, accompanied by details of payment for the
        arbitration settlement and fee amounts, as needed, shall be filed with the Secretary within three business days
        after the original issuance of the demand.
      </li>
      <li>
        The Secretary shall thereupon assign a number to the original demand and retain it for use by the Leaf Platform.{' '}
      </li>
      <li>One copy shall be returned to the Petitioner. </li>
      <li>
        The final copy shall be served upon the Respondent by the Secretary by secure email from Leaf. The
        Respondent&#39;s copy of the Demand Form shall be mailed to or delivered to the following place:
        <ul>
          <li>a. If Respondent is a listed member of the Leaf Platform, then to the address shown on its records.</li>
          <li>
            b. If not a listed Leaf Member, then to the address, if any, shown on the contract upon which arbitration is
            based.{' '}
          </li>
          <li>
            c. In any case where the person is not a resident of, with his own place of business within, the United
            States, then to the agent or broker of such person to the extent provided in X of these Trading Rules.{' '}
          </li>
          <li>
            If either none of the foregoing applies: d.c. To the place certified under oath by the Petitioner as the
            last known business address, and to the place of abode as well, if known, of any individual involved as a
            signatory to the contract and party to the arbitration.{' '}
          </li>
        </ul>
      </li>
      <li>
        With the permission of the. Secretary or the Arbitrators, the parties, by mutual agreement, in writing, and
        filed with the Secretary, may modify or change the time periods prescribed, provisions, or applications of these
        rules for the purposes of the arbitration in question.
      </li>
    </ol>
    <h3>The Answer</h3>
    <ol>
      <li>
        The Respondent shall file with the Secretary his Answer to the Demand Form in the form specified as part these
        rules as the Answer Form, as the case requires.{' '}
      </li>
      <li>
        The Answer shall be filed within ten (10) business days as defined herein after receipt of the Demand. If no
        such Answer is filed within the stated time nor any extension thereof duly granted, it will nevertheless be
        assumed that the claim made is denied. Failure to file such Answer shall not interrupt or delay the arbitration.{' '}
      </li>
      <li>
        The Secretary shall forward one copy of the Answer to the Petitioner and return one copy to the Respondent. The
        Secretary shall retain the original Answer for the use of the Leaf Platform.
      </li>
    </ol>
    <h3>The Pleadings</h3>
    <ol>
      <li>
        The Demand for Arbitration and the Answer, together with all replies, if any, shall constitute and be known as
        the Pleadings.{' '}
      </li>
      <li>
        A written reply shall be made to every Answer containing a counterclaim. Replies shall be made in such time as
        required for an Answer. If no such reply is made within the stated time or any extension thereof duly granted,
        it will never the less be assumed that the counterclaim made is denied.{' '}
      </li>
      <li>
        The Pleadings shall be clear, brief, and concise. The parties may request that a decision be rendered by the
        Leaf arbitrators on the basis of written submissions only.{' '}
      </li>
      <li>
        If the arbitration location specified at time of contract is outside of New York, the Secretary will arrange for
        the approved arbitration site to be prepared and selection of arbitrators will proceed in accordance with the
        Alternative Panel Selection method.{' '}
      </li>
      <li>If a hearing is requested, the arbitration will be held at the Leaf offices in New York. </li>
      <li>
        The terms, time limits for performance and service quality in any arbitration is governed by the terms and
        conditions of the Forward Contract.{' '}
      </li>
      <li>
        Whenever common issues of fact or law involving the same parties are in dispute, those issues may be
        consolidated into one (1) arbitration. It is in the sole discretion of the arbitrators to consolidate
        arbitrations.{' '}
      </li>
    </ol>
    <h2>Arbitration Panels and Processes</h2>
    <Typography variant="body1">
      All transportation and logistics professionals with proper credentials and recommendations are eligible to be
      appointed to serve on Arbitration Panels.{' '}
    </Typography>
    <h3>Maintenance of the Arbitration Panel</h3>
    <Typography variant="body1">
      The Board of Directors of Leaf will be charged with maintaining the Arbitration Panels. This includes regular
      review of the status and eligibility of current arbitrators and the examination of new candidates for the
      respective panels. The decisions of the Arbitration Committee regarding the eligibility of any person to serve on
      an Arbitration panel will be final.
    </Typography>
    <h3>Designation of Arbitrators</h3>
    <Typography variant="body1">
      When the final Answer or reply has been filed, or the time when same is due has expired, the Secretary shall take
      the following step:{' '}
    </Typography>
    <ol>
      <li>
        Determine from the nature of the controversy which arbitrator or panel of arbitrators shall be used. If the
        dispute involves less than $25,000 a single arbitrator shall be used. The Secretary shall remove from the
        appropriate and current list of Official Arbitrators the names of individuals known by him to be directly or
        indirectly employed or connected with either party to the arbitration or to have a personal or financial
        interest in the matter in dispute.
      </li>
    </ol>
    <h3>Oath of Arbitrators</h3>
    <ol>
      <li>
        Prior to the commencement of any arbitration each selected arbitrator is directed to state in writing or orally
        to the Secretary that there is no circumstance known to him which does or might create any bias or appearance of
        bias or interest on his part and that there is no fact known to him which might disqualify him. He also must
        state that he is knowledgeable about the subject matter at issue in the arbitration.{' '}
      </li>
      <li>
        In the case of a disqualification the vacancy shall be filled by the Secretary as provided in these Rules. Any
        question or dispute as to the qualification of an arbitrator shall be referred to the Leaf Board of Directors
        for resolution.{' '}
      </li>
      <li>
        Upon receipt of acceptance from the arbitrators, the Secretary shall arrange for administration of the oath
        which shall be in the form annexed to these Rules as Form X. Such oath shall be taken before any testimony is
        heard.{' '}
      </li>
      <li>All decisions of the arbitrators, including the award, shall be by majority vote of the selected panel.</li>
    </ol>
    <h3>Arbitration Hearings - Process</h3>
    <ol>
      <li>
        Hearings shall be oral, held as soon as practicable, and be expedited to achieve a speedy determination. All
        pleadings required by the rules shall be submitted prior to the Hearing. The Secretary upon the direction of the
        arbitrators shall set the date and time, format, and location for the hearing notifying the parties thereof and
        allowing the parties at least ten (10) business days as defined herein. S/he shall attempt to set a date
        acceptable to all parties and their counsel but if he is unable to do so by mutual agreement he shall in his
        sole discretion fix a date. Thereafter any application for adjournment shall be made to the arbitrators. All
        such applications to the arbitrators shall be in writing, shall specify in detail the reasons for requesting an
        adjournment, and must show good and sufficient cause for that adjournment.{' '}
      </li>
      <li>
        Each party shall have the right to be heard, to present evidence pertinent and relevant to the issues, to
        cross-examine witnesses, and at his own expense, to call witnesses. Documents to be submitted at the Hearing
        shall be submitted in time to be formatted for electronic transmission to the relevant Parties. When documents
        are submitted to the arbitrators, a copy must be submitted to the opposing party at the same time.{' '}
      </li>
      <li>
        The parties may waive oral hearings by agreeing to such waiver in writing, in which case the pleadings and such
        other statements of fact, evidence, documents, records, or affidavits, all verified to the extent required by
        the arbitrators, may be considered. Notwithstanding the parties&#39; waiver, the arbitrators may, at their
        discretion, require a hearing, may require the production of such records or evidence as they deem proper, and
        may direct the parties to attend.{' '}
      </li>
      <li>Any party may be represented by counsel. </li>
      <li>
        No statements or other forms of oral communication shall be made to any of the arbitrators by any party or any
        agent of such party unless the other party has been notified in advance and given the opportunity to
        participate. All written communications with the arbitrators or the Secretary must follow the rules and must be
        copied to the other party.
      </li>
      <li>
        Only the parties to the arbitration, their duly designated attorney, and persons authorized by the arbitrators
        may be present at any hearing. Arbitrators shall determine whether witnesses may attend the hearing.{' '}
      </li>
      <li>
        The arbitrators shall, among themselves, agree upon a Chairperson and it will be his/her duty and responsibility
        to conduct the arbitration and hearings.{' '}
      </li>
      <li>
        The order of procedure, the manner in which the hearings shall be conducted, and the evidence which shall be
        admitted or excluded shall be governed and ordered by the arbitrators who shall afford full, fair, and equal
        opportunity to all parties for the presentation of evidence. The arbitrators shall be the sole judges of the
        materiality and competence of all evidence offered. Conformity to or compliance with legal rules of evidence
        shall not be necessary. Witness statements or affidavits may be accepted as well as letters and other business
        documents and the arbitrators shall determine the admissibility and weight of such evidence as well as all other
        evidence.{' '}
      </li>
      <li>Arbitration awards will be based upon the evidence submitted by the parties. </li>
    </ol>
    <h2>The Award and Appeal</h2>
    <Typography variant="body1">
      No Party to an arbitration can be awarded more than the sum asked for in the petition or pleadings.{' '}
    </Typography>
    <h3>The Award</h3>
    <ol>
      <li>
        Absent valid reasons, the Award of the arbitrators shall be rendered within 30 days after the parties have made
        their final submissions. The Award shall be final and binding upon the parties to the dispute. .{' '}
      </li>
      <li>
        The Award may require specific performance of a contract, declare a contract breached in whole or in part,
        and/or award money damages in the alternative or otherwise; but the foregoing shall not obligate or limit the
        power of the arbitrators who may grant any remedy or relief which they deem just and equitable.{' '}
      </li>
      <li>
        The Award shall be made in writing and shall be filed with the Secretary. The Award shall determine the
        liability of each of the parties to the arbitration and the proportion of expenses thereof to be borne by each
        party. Unless otherwise provided in the Award, settlement of the Award shall be within seven (7) calendar days
        from the date of receipt thereof by the unsuccessful party. Where interest is allowed, it shall continue to
        accrue up to the time when the award is satisfied.{' '}
      </li>
      <li>
        If an Arbitration Award remains unsatisfied thirty days after the date of said Award, the party in whose favor
        the Award was given may request that Leaf publish notice that the Award remains unsatisfied. Upon receipt of
        such written request, the Secretary shall notify the party in arrears of LE&#39;s intention to publish such
        information, and update rating data and related algorithms on the Leaf Platform.{' '}
      </li>
      <li>
        The Arbitration Award may be made a judgment of any court having jurisdiction over the matter if it remains
        unsatisfied thirty days after the date of such Award.{' '}
      </li>
    </ol>
    <h2>Arbitration Fees and Charges</h2>
    <Typography variant="body1">
      The intent of Leaf Arbitration is to save the logistics Industry costly litigation expense, and to adjudicate
      disputes in a fair, transparent and efficient manner. However, it is necessary that fees for arbitration be
      charged and that these fees offset the costs to Leaf to conduct arbitration{' '}
    </Typography>
    <h3>Fee Schedule</h3>
    <ol>
      <li>$250.00 minimum per order.</li>
      <li>From the fees received, Leaf shall pay a fee of $50.00 per arbitrator </li>
      <li>
        In the event that an arbitration is withdrawn or cancelled before an Answer is filed, the sum of $100.00 shall
        be retained by Leaf as a filing fee out of the Arbitration fee deposited providing a hearing has not yet begun.
        The balance of the arbitration fee shall be returned to the depositor. When a hearing has been scheduled and
        held or appeal and any settlement is reached between the parties, or they mutually agree to withdraw the
        arbitration or appeal, such settlement or agreement shall provide for forfeiture of the arbitration fee to Leaf
        by the depositor as the Panel sees fit.{' '}
      </li>
      <li>
        When a decision has been rendered by the Panel, the arbitrators shall assess the arbitration fee on one or both
        of the parties as they see fit. All other expenses incurred, shall be borne in such manner as fixed in the
        award. Other deposits received shall be refunded to the parties entitled thereto.{' '}
      </li>
    </ol>
    <Typography variant="h4">Frequently Asked Questions regarding Leaf Arbitrations and Procedures</Typography>
    <Typography variant="body1">
      <em>What are the advantages of Leaf arbitration?</em>
      Arbitration is a fair, transparent and impartial process for resolving disputes between contract parties. The
      Award rendered by the arbitrator or arbitrators is final and binding. Leaf members save significant time and money
      by using Leaf arbitrations instead of more costly legal proceedings.
    </Typography>
    <Typography variant="body1">
      <em>Who initiates an arbitration, the buyer or the seller, and how?</em>
      Either party to a contract may demand Leaf arbitration on any tendered portion of a contract, unless provided for
      otherwise in the contract. A demand for arbitration can be submitted directly on the Leaf Platform for a contract
      under dispute, or by formally submitting the request to Leaf via the email form available on our website. One
      party files and the other is given the opportunity to answer. If both parties agree on a statement of the facts
      and issues in dispute, they may file jointly.
    </Typography>
    <Typography variant="body1">
      <em>How are arbitrators designated?</em>
      Based on the nature of the controversy, Leaf determines which classification of arbitrators shall be selected from
      a list of official Arbitrator Panel members maintained by Leaf. Leaf removes from this list any individuals known
      to be directly or indirectly connected with either party. Each panel consists of three arbitrators chosen by lot.
    </Typography>
    <Typography variant="body1">
      <em>How is impartiality maintained?</em>
      Each selected arbitrator is directed to confirm to Leaf that there is no circumstance known to him/her, which does
      or might create any bias or appearance of bias or interest on his/her part, and that there is no fact known to
      him/her, which might disqualify him/her. He/she also must state that he/she is knowledgeable about the type of
      dispute or subject matter at issue.
    </Typography>
    <Typography variant="body1">
      <em>What happens during a hearing?</em>
      Each party has the right to be heard, present evidence relevant to the issues and cross-examine witnesses, if any.
    </Typography>
    <Typography variant="body1">
      <em>Can one bring and attorney?</em>
      Any party may be represented by counsel upon proper notification.
    </Typography>
    <Typography variant="body1">
      <em>How are the issues of service quality or performance resolved?</em>
      Variations from the service quality or performance criteria described in the contract are the only basis for a
      service, quality or performance arbitration. The empaneled arbitrators review evidence, and based on their
      analysis of the Trading Rules and contract specifications, render a binding decision. The panel may also elect to
      recommend amendments / updates to the trading rules, which will be reviewed at periodic interval by the full Leaf
      Arbitration Panel.
    </Typography>
    <Typography variant="body1">
      <em>Can decisions be appealed?</em>
      No.
    </Typography>
    <Typography variant="body1">
      This is intended only as a summation. For more information, please contact Leaf.
    </Typography>
  </div>
);
