import React, { useCallback } from 'react';
import { Box, IconButton as IconButtonMUI, Tooltip, MenuItem, Menu } from '@mui/material';
import { MoreVert } from '@mui/icons-material';
import { v4 } from 'uuid';
import styled from '@emotion/styled';

const IconButton = styled(IconButtonMUI)`
  padding: 0;
`;

const MenuWrapper = styled(Menu)`
  .MuiMenu-paper {
    box-shadow: 0 1px 5px rgb(0, 0, 0, 0.12);
  }
`;

export default ({ title, options }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const MenuButton = useCallback(
    () => (
      <IconButton onClick={handleClick} size="large">
        <MoreVert />
      </IconButton>
    ),
    [],
  );
  return (
    <>
      <Box>
        {title ? (
          <Tooltip title={title}>
            <MenuButton />
          </Tooltip>
        ) : (
          <MenuButton />
        )}
      </Box>
      <MenuWrapper anchorEl={anchorEl} open={open} onClose={handleClose} onClick={handleClose}>
        {options.map(option => (
          <MenuItem key={v4()} onClick={option.onClick}>
            {option.label}
          </MenuItem>
        ))}
      </MenuWrapper>
    </>
  );
};
