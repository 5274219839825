import React from 'react';
import { Box, LinearProgress, Typography } from '@mui/material';

import withStyles from '@mui/styles/withStyles';

const Progress = withStyles(theme => ({
  root: {
    height: 4,
  },
  colorPrimary: {
    backgroundColor: theme.palette.grey[300],
  },
  bar: {
    borderRadius: 5,
    backgroundColor: theme.palette.success.light,
  },
}))(LinearProgress);

const Label = ({ label }) => (
  <Box pb={1}>
    <Typography variant="body1">{`${label}%`}</Typography>
  </Box>
);

export default ({ value = 0, label = '' }) => (
  <Box>
    {label && <Label label={label} />}
    <Progress variant="determinate" value={value} />
  </Box>
);
