import React from 'react';
import { Typography } from '@mui/material';
import styled from '@emotion/styled';
import { DAYS_OF_WEEK, Form, ContractType, ContractStatus, utility, CONTRACT_TYPE } from '@leaf/components';

const Info = styled(Typography)`
  color: rgba(0, 0, 0, 0.54);
`;

const DaysContainer = styled.div`
  display: flex;
  gap: ${({ theme }) => `${theme.spacing(2)}`};
`;

const { Input } = Form.Generic;

export const getCompanyGeneralDetails = data => [
  {
    type: 'row',
    data: {
      label: 'Contract Type',
      value: <ContractType type={data?.contractType} />,
    },
  },
  {
    type: 'divider',
  },
  {
    type: 'row',
    data: {
      label: 'Contract Party',
      value: data?.contractParty,
    },
  },
  {
    type: 'divider',
  },
  {
    type: 'row',
    data: {
      label: 'Contract Status',
      value: <ContractStatus status={data?.contractStatus} />,
    },
  },
  {
    type: 'divider',
  },
  {
    type: 'row',
    data: {
      label: 'Equipment Type',
      value: data?.equipmentType,
    },
  },
  {
    type: 'divider',
  },
  {
    type: 'row',
    data: {
      label: 'Accessorials',
      value: data?.accessorials,
    },
  },
  {
    type: 'divider',
  },
  {
    type: 'row',
    data: {
      label: 'Incumbent Contract ID',
      value: data?.incumbentContract,
    },
  },
  {
    type: 'divider',
  },
  {
    type: 'col',
    data: {
      label: 'Notes',
      value: <Typography variant="caption">{data?.notes}</Typography>,
    },
  },
  {
    type: 'divider',
  },
];

export const getCompanyTimeAndVolumeDetails = data => [
  {
    type: 'row',
    data: {
      label: 'Start Date',
      value: data?.startDate,
    },
  },
  {
    type: 'divider',
  },
  {
    type: 'row',
    data: {
      label: 'End Date',
      value: data?.endDate,
    },
  },
  {
    type: 'divider',
  },
  {
    type: 'row',
    data: {
      label: 'Volume Type',
      value: data?.volumeType,
    },
  },
  {
    type: 'divider',
  },
  {
    type: 'row',
    data: {
      label: 'Volume Frequency',
      value: data?.volumeFrequency,
    },
  },
  {
    type: 'divider',
  },
  {
    type: 'row',
    data: {
      label: 'Min Volume',
      value: data?.minVolume,
    },
  },
  {
    type: 'divider',
  },
  {
    type: 'row',
    data: {
      label: 'Max Volume',
      value: data?.maxVolume,
    },
  },
  {
    type: 'divider',
  },
  {
    type: 'row',
    data: {
      label: 'Include Weekends',
      value: data?.includeWeekends,
    },
  },
  {
    type: 'divider',
  },
  {
    type: 'col',
    data: {
      label: 'Day Of Flex Volume',
      value: (
        <DaysContainer>
          {DAYS_OF_WEEK.map(day => (
            <Input name={day.abbreviation} label={day.short} value={data?.volumePattern[day.id]} disabled />
          ))}
        </DaysContainer>
      ),
    },
  },
];

export const getCompanyPricingDetails = data => [
  {
    type: 'subtitle',
    data: {
      label: 'RPM Pricing',
    },
  },
  {
    type: 'row',
    data: {
      label: 'Pricing Mechanism',
      value: data?.pricingMechanism,
    },
  },
  {
    type: 'divider',
  },
  {
    type: 'row',
    data: {
      label: 'Linehaul RPM',
      value: utility.numbers.formatNumberAsView(data?.linehaulRPM, 2),
    },
  },
  {
    type: 'divider',
  },
  ...(data?.contractType === CONTRACT_TYPE.SPOT
    ? [
        {
          type: 'row',
          data: {
            label: <Info variant="body1">Pricing Index</Info>,
            value: data?.pricingIndex,
          },
        },
        {
          type: 'divider',
        },
        {
          type: 'row',
          data: {
            label: <Info variant="body1">Pricing Index Premium</Info>,
            value: data?.pricingIndexPremium,
          },
        },
        {
          type: 'divider',
        },
      ]
    : []),
  {
    type: 'row',
    data: {
      label: 'Fuel Surcharge Schedule',
      value: data?.fuelSurchargeSchedule,
    },
  },
  {
    type: 'divider',
  },
  ...([CONTRACT_TYPE.DEDICATED, CONTRACT_TYPE.FLEET].includes(data?.contractType)
    ? [
        {
          type: 'subtitle',
          data: {
            label: 'Asset Pricing',
          },
        },
        {
          type: 'row',
          data: {
            label: 'Rate Per Asset',
            value: data?.ratePerAsset,
          },
        },
        {
          type: 'divider',
        },

        {
          type: 'row',
          data: {
            label: 'Asset Pricing Frequency',
            value: data?.assetPricingFrequency,
          },
        },
        {
          type: 'divider',
        },
        {
          type: 'row',
          data: {
            label: 'Min Chargeable Miles',
            value: data?.minChargeableMiles,
          },
        },
        {
          type: 'divider',
        },
        {
          type: 'row',
          data: {
            label: 'Min Chargeable Miles Frequency',
            value: data?.minChargeableMilesFrequency,
          },
        },
        {
          type: 'divider',
        },
      ]
    : []),
];
