import React, { useEffect, useRef } from 'react';

import { Map as LeafletMap, Marker, TileLayer, Tooltip } from 'react-leaflet';
import styled from '@emotion/styled';
import leaflet from 'leaflet';

import ReactDOMServer from 'react-dom/server';
// import { PowerlaneIcon } from '../icons';
import { v4 } from 'uuid';
import MapMarkerNumerated from '../MapMarkerNumerated';
import { StartMapMarkerIcon } from '../icons';
// import TooltipDialog from './TooltipDialog';

import './PolylineMap.scss';
import {
  calculateLaneColor,
  calculateLaneWeight,
  calculateLaneOpacity,
  getLatLangValues,
  getPathClass,
  getMarkerClass,
  getGeoJSONData,
} from './mapUtils';

const Map = styled(LeafletMap)`
  height: 100%;
  position: relative;
`;

export default ({
  dataList = [],
  className = 'width-auto',
  showPositionMarkers = true,
  showTypeMarkers = true,
  isOutlinedLine = false,
  isLaneReversOrder = false,
}) => {
  const mapRef = useRef();
  let featureMap = null;

  const geoJsonObjects = getGeoJSONData(dataList, isOutlinedLine, isLaneReversOrder);

  useEffect(() => {
    const { current = {} } = mapRef;
    const { leafletElement: map } = current;
    if (!map) return;

    leaflet
      .geoJSON(geoJsonObjects, {
        style: feature => ({
          color: calculateLaneColor(feature.properties.index, feature.properties.order),
          weight: calculateLaneWeight(feature.properties.order),
          opacity: calculateLaneOpacity(feature.properties.order),
          className: getPathClass(feature.properties.index),
        }),
      })
      .addTo(map);
  }, []);

  featureMap = leaflet.geoJSON(geoJsonObjects);
  const bounds = featureMap?.getBounds();

  const options = {
    center: [39.5, -98.35],
    maxBoundsViscosity: 1.0,
    maxBounds: leaflet.latLngBounds(leaflet.latLng(-90, -200), leaflet.latLng(90, 200)),
    bounds: bounds?.isValid() ? bounds : null,
    zoom: 3,
    minZoom: 2,
    maxZoom: 8,
    attributionControl: false,
  };

  const getIconItem = (index, iconNumber) =>
    leaflet.divIcon({
      className: getMarkerClass(index),
      html: ReactDOMServer.renderToString(
        <MapMarkerNumerated markerNumber={iconNumber} color={calculateLaneColor(index)} />,
      ),
    });

  const getStartIconItem = () =>
    leaflet.divIcon({
      className: 'start-marker-icon-wrapper-first',
      html: ReactDOMServer.renderToString(<StartMapMarkerIcon />),
    });

  // const getPowerLaneIcon = () =>
  //   leaflet.divIcon({
  //     className: 'power-lane-icon-wrapper',
  //     html: ReactDOMServer.renderToString(<PowerlaneIcon size={20} />),
  //   });

  const renderMapMarker = () =>
    dataList.map((item, index) => {
      // const halfIndexOfList = Math.ceil(item.geo?.geometry?.coordinates[0]?.length / 2);
      if (item?.geo?.geometry?.coordinates?.length) {
        return (
          <div key={v4()}>
            {showPositionMarkers && (
              <>
                {index === 0 && (
                  <Marker
                    position={getLatLangValues(item.geo.geometry.coordinates[0][0])}
                    icon={getStartIconItem(index)}
                  />
                )}
                <Marker
                  position={getLatLangValues(item.geo.geometry.coordinates[0][0])}
                  icon={getIconItem(index, index * 2 + 1)}
                />
                <Marker
                  position={getLatLangValues(item.geo.geometry.coordinates[0].at(-1))}
                  icon={getIconItem(index, index * 2 + 2)}
                />
              </>
            )}
            {/* Power markers currently removed from map */}
            {/* {item.laneType === 'POWER' && showTypeMarkers && (
              <Marker
                position={getLatLangValues(item.geo.geometry.coordinates[0][halfIndexOfList])}
                icon={getPowerLaneIcon()}
              >
                {item.allPowerLanes && (
                  <Tooltip>
                    <TooltipDialog ptpLanes={item.allPowerLanes} />
                  </Tooltip>
                )}
              </Marker>
            )} */}
          </div>
        );
      }

      return null;
    });

  return (
    <Map ref={mapRef} className={className} {...options}>
      <TileLayer url="https://api.mapbox.com/styles/v1/mapbox/light-v10/tiles/256/{z}/{x}/{y}?access_token=pk.eyJ1IjoibG9naXN0aWNzZXhjaGFuZ2UiLCJhIjoiY2prbDB4cHJ2MXdldTNwbWwxdHNuaWFoYyJ9.6jlvQU7fxCoDDYwS9C6uVQ" />
      {renderMapMarker()}
    </Map>
  );
};
