import { USER_TYPE, SHIPMENT_STATUS_DISPLAY_NAMES } from '@leaf/components';
import Trip from 'domain/shipments/trips/Trip';
import Shipment from 'domain/shipments/Shipment';

export const endpoints = {
  [USER_TYPE.CARRIER]: {
    getAll: Trip.getAll,
    getById: Trip.getById,
    getLegs: Trip.getLegs,
    getExecutionPartners: Trip.getExecutionPartners,
    getDestinations: Trip.getDestinations,
    getOrigins: Trip.getOrigins,
  },
  [USER_TYPE.SHIPPER]: {
    getAll: Shipment.getAll,
    getById: Shipment.getById,
    getExecutionPartners: Shipment.getExecutionPartners,
    getCarriers: Shipment.getCarriers,
    getLegNumbers: Shipment.getLegNumbers,
    getDestinations: Shipment.getDestinations,
    getOrigins: Shipment.getOrigins,
    getTripIds: Shipment.getTripIds,
  },
  default: {},
};

export const getInitialPromises = (companyType, id) => {
  if (USER_TYPE.SHIPPER === companyType) {
    return [endpoints[companyType]?.getById(id)];
  }
  if (USER_TYPE.CARRIER === companyType) {
    return [endpoints[companyType]?.getById(id), endpoints[companyType]?.getLegs(id)];
  }
  return [];
};

export const getFilterOptions = companyType =>
  ({
    [USER_TYPE.SHIPPER]: [
      {
        inputType: 'autocomplete',
        columnName: 'executionPartnerName',
        filterOptionsGetter: endpoints[companyType]?.getExecutionPartners,
      },
      { inputType: 'autocomplete', columnName: 'leg', filterOptionsGetter: endpoints[companyType]?.getLegNumbers },
      { inputType: 'autocomplete', columnName: 'origin', filterOptionsGetter: endpoints[companyType]?.getOrigins },
      {
        inputType: 'autocomplete',
        columnName: 'destination',
        filterOptionsGetter: endpoints[companyType]?.getDestinations,
      },
      {
        inputType: 'autocomplete',
        columnName: 'view.tripId',
        filterOptionsGetter: endpoints[companyType]?.getTripIds,
      },
      {
        inputType: 'autocomplete',
        columnName: 'view.carrier',
        filterOptionsGetter: endpoints[companyType]?.getCarriers,
      },
      {
        inputType: 'autocomplete',
        columnName: 'status',
        filterOptionsGetter: () =>
          Promise.resolve(
            Object.keys(SHIPMENT_STATUS_DISPLAY_NAMES).map(key => ({
              label: SHIPMENT_STATUS_DISPLAY_NAMES[key],
              value: key,
            })),
          ),
      },
    ],
    [USER_TYPE.CARRIER]: [
      {
        inputType: 'autocomplete',
        columnName: 'executionPartnerName',
        filterOptionsGetter: endpoints[companyType]?.getExecutionPartners,
      },
      { inputType: 'autocomplete', columnName: 'origin', filterOptionsGetter: endpoints[companyType]?.getOrigins },
      {
        inputType: 'autocomplete',
        columnName: 'destination',
        filterOptionsGetter: endpoints[companyType]?.getDestinations,
      },
      {
        inputType: 'autocomplete',
        columnName: 'status',
        filterOptionsGetter: () =>
          Promise.resolve(
            Object.keys(SHIPMENT_STATUS_DISPLAY_NAMES).map(key => ({
              label: SHIPMENT_STATUS_DISPLAY_NAMES[key],
              value: key,
            })),
          ),
      },
    ],
  }[companyType]);
