import React from 'react';
import styled from '@emotion/styled';
import { Typography } from '@mui/material';

import { CONTRACT_TYPE_COLORS, CONTRACT_TYPE_DISPLAY_NAMES } from '../constants/ContractType';

const Container = styled.div`
  display: flex;
  align-items: center;
`;

const TypeBox = styled.div`
  height: ${({ theme }) => `${theme.spacing(1)}`};
  width: ${({ theme }) => `${theme.spacing(1)}`};
  border-radius: 2px;
  margin-right: ${({ theme }) => `${theme.spacing(1)}`};
  background-color: ${({ bgColor }) => `${bgColor}`};
`;

/**
 * Componrnt: ContractType
 *
 * @param type:string
 *
 * @param variant:string
 *
 * @returns
 * Returns chip based on type parameter that user provide.
 */
const ContractType = ({ type, variant = 'body1' }) => (
  <Container>
    <TypeBox bgColor={CONTRACT_TYPE_COLORS[type]} />
    <Typography variant={variant}>{CONTRACT_TYPE_DISPLAY_NAMES[type]}</Typography>
  </Container>
);

export default ContractType;
