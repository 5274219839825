import React, { PureComponent } from 'react';

export default class Checkbox extends PureComponent {
  render() {
    const { label, name, isChecked, onChangeHandler, isDisabled, ...props } = this.props;

    return (
      <div>
        <label htmlFor={name}>
          <input
            type="checkbox"
            name={name}
            id={name}
            style={{ cursor: 'pointer' }}
            checked={isChecked || false}
            onChange={this.onChangeHandler}
            disabled={isDisabled}
            {...props}
          />
          <span>{label}</span>
        </label>
      </div>
    );
  }
}
