import axios from 'axios';
import { TRIP_TYPE_DISPLAY_NAMES, utility } from '@leaf/components';
import { v4 } from 'uuid';

const mapData = data => ({
  ...data,
  routes: data.routes?.map((route, index) => ({
    ...route,
    deadheadMiles: utility.format.miles(route?.deadheadMiles),
    totalMiles: utility.format.miles(route?.totalMiles),
    tripType: TRIP_TYPE_DISPLAY_NAMES.CONTINUOUS_MOVE,
    id: v4(),
    displayOriginName: route.lanes[0]?.originName,
    displayDestinationName: route.lanes[route.lanes.length - 1]?.destinationName,
    isPrimary: index === 0,
    legs: route?.lanes?.map(lane => ({
      origin: lane?.originName,
      pickupType: lane?.pickupType,
      destination: lane?.destinationName,
      deliveryType: lane?.deliveryType,
      miles: utility.format.miles(lane?.miles),
      laneId: lane?.id,
      deadheadMiles: utility.format.miles(route?.deadheadMiles) ?? '',
      originGeo: lane?.originGeo,
      destinationGeo: lane?.destinationGeo,
      pathGeo: lane?.path,
    })),
  })),
  laneData: {
    domicile: data?.domicile,
    contract: 'Fleet',
    trucks: data?.trucks,
    avgWeeklyTotalMiles: utility.format.miles(data?.avgWeeklyTotalMiles),
  },
});

export const getById = async fleetId =>
  axios
    .get(`view/fleet/${fleetId}`, {
      baseURL: `${process.env.REACT_APP_API}`,
    })
    .then(({ data }) => mapData(data));
