import React from 'react';
import { Map as MapContainer, TileLayer, GeoJSON } from 'react-leaflet';
import { io } from 'jsts/dist/jsts';
import { parse } from 'wellknown';

const magic = {
  origin: {
    color: 'blue',
    fillColor: 'blue',
    opacity: 0.3,
    fillOpacity: 0.3,
    weight: 1,
  },
  destination: {
    color: 'green',
    fillColor: 'green',
    opacity: 0.3,
    fillOpacity: 0.3,
    weight: 1,
  },
  path: {
    color: 'green',
    fillColor: 'green',
    opacity: 0.3,
    fillOpacity: 0.3,
    weight: 4,
  },
};

const LaneMap = ({ data, mapboxUrl }) => {
  const { origin, destination, path } = data;
  const reader = new io.WKTReader();
  const poly = reader.read(origin).union(reader.read(destination));
  const coords = poly.getEnvelope().getCoordinates();

  let minLat;
  let maxLat;
  let minLon;
  let maxLon;
  coords.forEach(coord => {
    if (coord.y < minLat || minLat === undefined) minLat = coord.y;
    if (coord.y > maxLat || maxLat === undefined) maxLat = coord.y;
    if (coord.x < minLon || minLon === undefined) minLon = coord.x;
    if (coord.x > maxLon || maxLon === undefined) maxLon = coord.x;
  });
  const bounds = [
    [maxLat, minLon],
    [minLat, maxLon],
  ];

  const originGeo = parse(origin);
  const destinationGeo = parse(destination);
  const pathGeo = parse(path);

  return (
    <MapContainer bounds={bounds}>
      <TileLayer id="mapbox/light-v10" url={mapboxUrl} />
      <GeoJSON
        data={originGeo}
        color={magic.origin.color}
        opacity={magic.origin.opacity}
        fillColor={magic.origin.fillColor}
        fillOpacity={magic.origin.fillOpacity}
        weight={magic.origin.weight}
      />
      <GeoJSON
        data={destinationGeo}
        color={magic.destination.color}
        opacity={magic.destination.opacity}
        fillColor={magic.destination.fillColor}
        fillOpacity={magic.destination.fillOpacity}
        weight={magic.destination.weight}
      />
      <GeoJSON
        data={pathGeo}
        color={magic.path.color}
        opacity={magic.path.opacity}
        fillColor={magic.path.fillColor}
        fillOpacity={magic.path.fillOpacity}
        weight={magic.path.weight}
      />
    </MapContainer>
  );
};

export default LaneMap;
